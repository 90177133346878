import { Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";
import CustomDateRangePicker from "../../../components/common/DateRangePicker/DateRangePicker.tsx";
import FileUploader from "../../../components/common/FileUploader/FileUploader.tsx";
import { useAppStore } from "../../../hooks/useStores.tsx";
import TabsLayout from "../../../layout/TabsLayout/TabsLayout.tsx";
import { getTodayDateRange } from "../../../utils";
import styles from "./AttributionReports.module.scss";
import { useAttributionReports } from "../../../hooks/useAttributionReports.tsx";
import { saveAs } from "file-saver";
import { toJS } from "mobx";
import RouterConstants from "../../../constants/RouterConstants.ts";

const AttributionReports = () => {
	const navigate = useNavigate();
	const { t } = useTranslation("translation", { keyPrefix: "account.attributionReports" });
	const { authStore, attributionReportsStore } = useAppStore();
	const { generateReportExcelFile, getSelectedReport, isSelectedReportLoading } = useAttributionReports()
	const [dateRange, setDateRange] = useState<[Date, Date]>(getTodayDateRange());

	useEffect(() => {
		if (authStore.user?.isSuperAdmin()) {
			navigate(RouterConstants.GENERATION_REPORT.fullPath, { replace: true });
		} else {
			navigate(RouterConstants.CATALOG_REPORT.fullPath, { replace: true });
		}
	}, []);
	useEffect(() => {
		attributionReportsStore.resetStore()
		attributionReportsStore.selectedDateRange = dateRange;
	}, [JSON.stringify(toJS(dateRange))]);


	let subTabs: string[];
	if (authStore.user?.isSuperAdmin()) {
		subTabs = ["generation-report", "catalog-report", "top-images", "partners-earnings"];
	} else {
		subTabs = ["catalog-report", "top-images"];
	}

	const handleDownloadingReports = () => {
		const excelBlob = generateReportExcelFile()
		if (!excelBlob) return;
		saveAs(excelBlob, `${attributionReportsStore.selectedTab}.xlsx`)
	};
	const handleSelectDateRange = (range: Date[]) => {
		if (range && range.length === 2 && range[0] instanceof Date && range[1] instanceof Date) {
			setDateRange([range[0], range[1]]);
		}
	};
	const secondaryButton = (
		<FileUploader
			inputProps={{ accept: ".csv,text/csv" }}
			buttonText={<Typography>{t("uploadFinancials")}</Typography>}
			disabled={
				attributionReportsStore.isTopImagesDataLoading ||
				!attributionReportsStore.topImagesData.length ||
				Boolean(attributionReportsStore.topImagesData[0]?.topPaid?.length)
			}
			onUpload={(e) => (attributionReportsStore.topPaidImagesPPGFile = e.target.files && e.target.files[0])}
			loading={attributionReportsStore.isTopImagesDataLoading}
		/>
	);
	return (
		<>
			<TabsLayout
				className={styles.reportsTabsLayout}
				title={attributionReportsStore.selectedTab}
				subTabs={subTabs}
				primaryButtonLabel={t("primaryButton")}
				primaryButtonClickHandler={handleDownloadingReports}
				primaryButtonDisabled={!getSelectedReport().length || isSelectedReportLoading()}
				secondaryButtonLabel={
					authStore.user?.isSuperAdmin() && attributionReportsStore.selectedTab === t("tabs.topImages") && secondaryButton
				}
				extraComponent={
					<CustomDateRangePicker
						loading={false}
						placement="autoVerticalEnd"
						value={dateRange}
						onChange={handleSelectDateRange}
						cleanable={false}
					/>
				}
			>
				<Outlet />
			</TabsLayout>
		</>
	);
};

export default observer(AttributionReports);
