import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppStore } from "../../../../hooks/useStores.tsx";
import TableLayout, { TableRow } from "../../../../layout/TableLayout/TableLayout.tsx";
import { ITopImages } from "../../../../models/attribution-reports.ts";
import { showErrorToast } from "../../../../utils/toast.tsx";
import styles from "./TopImages.module.scss";
import TopImagesTable from "./TopImagesTable.tsx";
import { toJS } from "mobx";

const TopImages = () => {
	const { t } = useTranslation("translation", { keyPrefix: "account.attributionReports" });
	const { attributionReportsStore } = useAppStore();
	const isMounted = useRef<boolean>(false);
	const [topImagesTableData, setTopImagesTableData] = useState<TableRow<ITopImages>[]>([]);
	const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
	const tableHeaders = t("tables.topImages.headers", { returnObjects: true }) as string[];

	useEffect(() => {
		const loadTopHitsData = async () => {
			if (!attributionReportsStore.topImagesData.length || isMounted.current) {
				try {
					await attributionReportsStore.loadTopHitsImagesData();
				} catch (e: any) {
					showErrorToast(t("pleaseTryAgainLater"));
				}
			}
		};
		loadTopHitsData();
	}, [JSON.stringify(toJS(attributionReportsStore.selectedDateRange))]);

	useEffect(() => {
		const loadTopPaidData = async () => {
			if (
				(attributionReportsStore.topImagesData.length &&
					!attributionReportsStore.topImagesData[0].topPaid?.length) ||
				isMounted.current
			) {
				try {
					await attributionReportsStore.loadTopPaidImagesData();
				} catch (e: any) {
					showErrorToast(t("pleaseTryAgainLater"));
				}
			}
		};
		loadTopPaidData();
	}, [attributionReportsStore.topPaidImagesPPGFile]);

	useEffect(() => {
		setIsTableLoading(true)
		setTopImagesTableData(
			attributionReportsStore.topImagesData.map((data: ITopImages) => {
				return {
					rowStyle: {},
					data: data,
				};
			}),
		);
		setIsTableLoading(false)
	}, [attributionReportsStore.topImagesData]);

	useEffect(() => {
		isMounted.current = true;
		attributionReportsStore.setSelectedTab(t("tabs.topImages"));
	}, []);

	return (
		<Box className={styles.topImagesTable}>
			<TableLayout
				hideHeader={true}
				headerArray={tableHeaders}
				tableData={topImagesTableData}
				customTable={TopImagesTable}
				loading={attributionReportsStore.isTopImagesDataLoading || isTableLoading}
				emptyStateComponent={
					<Typography>{t("tables.partnersEarnings.emptyState")}</Typography>
				}
			/>
		</Box>
	);
};

export default observer(TopImages);
