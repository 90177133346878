import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import ConfirmationPopup from "../../../../../components/common/ConfirmationPopup/ConfirmationPopup.tsx";
import SuggestAccessMethod from "../../../Integrations/SuggestAccessMethod/SuggestAccessMethod.tsx";
import styles from "./SuggestAccessMethodPopup.module.scss";

type SuggestAccessMethodPopupProps = {
	open: boolean;
	onClose: () => void;
};

const SuggestAccessMethodPopup = ({ open, onClose }: SuggestAccessMethodPopupProps) => {
	const { t } = useTranslation("translation", { keyPrefix: "newTg.suggestAccessMethod" });
	return (
		<ConfirmationPopup
			open={open}
			title={t("header")}
			maxWidth="475px"
			hideActions={true}
			onClose={onClose}
			contentClassName={styles.container}
		>
			<SuggestAccessMethod onSuccess={onClose} />
		</ConfirmationPopup>
	);
};

export default observer(SuggestAccessMethodPopup);
