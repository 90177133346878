import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import RequestAccessContainer, {
	RequestAccessContainerProps,
} from "../../../../components/TailoredGeneration/RequestAccessContainer/RequestAccessContainer";
import { CrmEventTargets, CrmOrgEvents, CrmUserEvents } from "../../../../constants/crm.ts";
import { useAppStore } from "../../../../hooks/useStores";
import { OrgFeatures } from "../../../../models/billing";
import styles from "./TgRequestAccessButton.module.scss";

type Props = {} & Partial<RequestAccessContainerProps>;

const TgRequestAccessButton = ({ children, ...rest }: Props) => {
	const { t } = useTranslation("translation", { keyPrefix: "newTg" });
	const { authStore } = useAppStore();

	return (
		<RequestAccessContainer
			className={styles.requestButton}
			requestTitle={t(`requestAccess.title`)}
			requestMessage={`${t(`requestAccess.message`)}`}
			requestFeature={"Tailored Models"}
			crmEvents={[
				{
					eventTarget: CrmEventTargets.ORG,
					eventType: CrmOrgEvents.ORG_REQUESTED_TAILORED_MODELS,
				},
				{
					eventTarget: CrmEventTargets.USER,
					eventType: CrmUserEvents.USER_REQUESTED_TAILORED_MODELS,
				},
			]}
			buttonMessage={t("requestAccess.button")}
			buttonLoading={authStore.isLoadingOrgSubscriptions}
			condition={authStore.isFeatureEnabled(OrgFeatures.TAILORED_GENERATION)}
			buttonType={"textMedium"}
			crownColor={"#5300c9"}
			paperClass={styles.paper}
			{...rest}
		>
			{children}
		</RequestAccessContainer>
	);
};

export default observer(TgRequestAccessButton);
