import { useTranslation } from "react-i18next";
import { DropDownItem } from "../BriaDropDown/BriaDropDown";

type Props<T> = {
	tKeyPrefix: string;
	excludedItems?: T[];
};

const useStaticDropdown = <T>({ tKeyPrefix, excludedItems = [] }: Props<T>) => {
	const { t } = useTranslation("translation", { keyPrefix: "" });

	const getDropdownItems = <T>(items: readonly T[]): DropDownItem<T>[] =>
		items
			.filter((item) => !excludedItems.includes(item as any))
			.map((item) => ({ key: t(`${tKeyPrefix}.${item}`), value: item }));

	const items = getDropdownItems<T>(Object.keys(t(tKeyPrefix, { returnObjects: true })) as T[]);

	return {
		items,
	};
};

export default useStaticDropdown;

export type Status = "Active" | "Pending" | "Inactive" | "Deleted";
