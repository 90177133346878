import { Box } from "@mui/material"
import { observer } from "mobx-react-lite"
import { useEffect, useRef, useState } from "react"
import { getImageDimensionsByImageUrl } from "../../../../DesignEditor/views/DesignEditor/utils/smartImageUtils"
import type { AutomotiveReflectionLayer } from "../../../../models/playground"
import styles from "./AutomotiveCanvas.module.scss"

type AutomotiveCanvasProps = {
  baseImageUrl: string
  reflectionLayers: AutomotiveReflectionLayer[] | undefined
}

const AutomotiveCanvas = ({ baseImageUrl, reflectionLayers }: AutomotiveCanvasProps) => {
  const layerCanvasRefs = useRef<Map<string, HTMLCanvasElement>>(new Map())
  const [width, setWidth] = useState<number>()
  const [height, setHeight] = useState<number>()

  useEffect(() => {
    getImageDimensions()
  }, [baseImageUrl])

  const getImageDimensions = async () => {
    const dimensions = await getImageDimensionsByImageUrl(baseImageUrl)
    if (dimensions) {
      setWidth(dimensions.width)
      setHeight(dimensions.height)
    }
  }

  const drawnLayersRef = useRef<Set<string>>(new Set())

  useEffect(() => {
    if (!reflectionLayers || !width || !height) return
  
    reflectionLayers.forEach((layer) => {
      const canvas = layerCanvasRefs.current.get(layer.name)
      if (!canvas) return
  
      const isVisible = !layer.hidden
      const isDrawn = drawnLayersRef.current.has(layer.name)
  
      if (isVisible && !isDrawn) {
        drawSingleLayer(layer)
        drawnLayersRef.current.add(layer.name)
      } else if (!isVisible && isDrawn) {
        drawnLayersRef.current.delete(layer.name)
      } else if (isVisible && isDrawn) {
        canvas.style.opacity = `${layer.intensity}`
      }
    })
  }, [reflectionLayers, width, height])
  

  const drawSingleLayer = async (layer: AutomotiveReflectionLayer) => {
    if (!width || !height || !layer.url) return
  
    const canvas = layerCanvasRefs.current.get(layer.name)
    if (!canvas) return
  
    if (canvas.getAttribute("data-url") === layer.url) {
      canvas.style.opacity = `${layer.intensity}`
      return
    }
  
    canvas.width = width
    canvas.height = height
    const ctx = canvas.getContext("2d")
    if (!ctx) return
  
    ctx.clearRect(0, 0, width, height)
  
    try {
      await new Promise<void>((resolve, reject) => {
        const layerUrl = layer.url as string;
        const img = new Image()
        img.crossOrigin = "anonymous"
        img.src = layerUrl
        img.onload = () => {
          ctx.globalAlpha = 1
          ctx.drawImage(img, 0, 0, width, height)
          canvas.setAttribute("data-url", layerUrl)
          resolve()
        }
        img.onerror = reject
  
        setTimeout(resolve, 5000)
      })
    } catch (error) {
      console.error(`Failed to load image for layer ${layer.name}:`, error)
    }
  }
  

  return (
    <Box className={styles.canvasContainer}>
      <img src={baseImageUrl} className={styles.baseCanvas} />
      {reflectionLayers
        ?.filter((layer) => !layer.hidden)
        .map((layer) => (
          <canvas
            key={layer.name}
            ref={(el) => {
              if (el) {
                layerCanvasRefs.current.set(layer.name, el)
                if (width && height && layer.url) {
                  drawSingleLayer(layer)
                }
              }
            }}
            className={styles.layerCanvas}
            style={{ opacity: layer.intensity }}
          />
        ))}
    </Box>
  )
}

export default observer(AutomotiveCanvas)

