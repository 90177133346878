import { makeAutoObservable, runInAction } from "mobx";
import { Ref, useRef } from "react";
import { NavigateFunction } from "react-router-dom";
import Selecto, { ElementType } from "selecto";
import { v4 as uuidv4 } from "uuid";
import { SecondaryTabTypeEnum } from "../../../DesignEditor/views/DesignEditor/components/Panels/panelItems/index.ts";
import { AspectRatio } from "../../../components/common/DropDowns/StaticDropdown.tsx";
import { FullScreenImageType } from "../../../components/common/FullScreenViewer/Image/FullScreenImage.tsx";
import { BriaImageProps, ImageErrorType } from "../../../components/common/Galleries/BriaImage.tsx";
import useSelectable from "../../../components/common/Selectable/useSelectable.tsx";
import { getSummerHost } from "../../../config/env.ts";
import { APPS } from "../../../constants/AppsConstants.ts";
import { DEFAULT_BODY_INTENSITY, DEFAULT_WINDOWS_INTENSITY } from "../../../constants/AutomotiveConstants.ts";
import RouterConstants from "../../../constants/RouterConstants.ts";
import useErrorToast from "../../../hooks/useErrorToast.tsx";
import { IRootStore } from "../../../mobx/root-store.tsx";
import {
	ImageEditingViewMode,
	ImageToImageConfigType,
	PlaygroundImage,
	PlaygroundResult,
} from "../../../models/image-to-image.ts";
import {
	AutomotiveImageReflection,
	AutomotiveImageSegments,
	AutomotiveReflectionLayer,
	PlaygroundVideoResult,
	TextResult,
} from "../../../models/playground.ts";
import { TextToImageConfigType } from "../../../models/text-to-image.ts";
import AutomotiveService from "../../../services/AutomotiveService.ts";
import ImagesService from "../../../services/ImagesService.ts";
import UploadImageService from "../../../services/UploadImageService.ts";
import { isFoxApps } from "../../../utils/index.ts";
import useImageUtils from "../../../utils/useImageUtils.tsx";
import {
	IPlaygroundProjectsStore,
	PlaygroundProjectsStore,
} from "../../Automotive/Projects/store/playground-projects-store.tsx";
import {
	IPlaygroundSessionsStore,
	PlaygroundSessionsStore,
} from "../../Automotive/Sessions/store/playground-sessios-store.tsx";
import iframeStore from "../../IframeNew/iframe-store.tsx";
import { AutomotiveStore, IAutomotiveStore } from "../Automotive/automotive-store.tsx";
import { Feedback } from "../Images/Overlay/PlaygroundImageOverlay.tsx";
import { IPlaygroundResultsStore, PlaygroundResultsStore } from "./playground-results-store.tsx";

export interface IPlaygroundStore {
	selectedConfig: APPS;
	playgroundResults: PlaygroundResult[];
	textResults: TextResult[];
	playgroundVideoResults: PlaygroundVideoResult[];
	selectoRef?: Ref<Selecto>;
	loadingExport: boolean;
	loadingSaveToMyImages: boolean;
	loadingSaveToMyImagesAndInsertImageToAd: boolean;
	isText2ImagePopupOpened: boolean;
	showSuccessToastSavedImage: boolean;
	playGroundLoadedImages: PlaygroundImage[];
	textToGenerate: string;
	aspectRatio: AspectRatio;
	setTextToGenerate: (textToGenerate: string) => void;
	setSelectedAspectRatio: (aspectRatio: AspectRatio) => void;
	openModal: () => void;
	closeModal: () => void;
	saveMyImages: (action: SaveImagesLoadingState) => void;
	deleteResult: (id: string) => void;
	getSelecto: () => Selecto | null;
	clearResults: () => void;
	ResetResults: () => void;
	exportImages: (
		images: PlaygroundImage[],
		zipName?: string,
		resolution?: number,
		action?: "download" | "save",
	) => Promise<void>;
	getAvailableImages: (includeLoading?: boolean) => PlaygroundImage[];
	getSelectedImages: () => PlaygroundImage[];
	getSelectedImagesForExport: () => PlaygroundImage[];
	openSelected: () => void;
	handleSelectImagesElements: (imagesElements?: ElementType[], isPopupView?: boolean) => void;
	selectImages: (images: PlaygroundImage[]) => void;
	getFileName: (images: PlaygroundImage) => string;
	onSuccessResult: (image: PlaygroundImage) => Promise<void>;
	onErrorResult: (image: PlaygroundImage, errorType: ImageErrorType) => Promise<void>;
	loadingFeedback: boolean;
	feedbackHistory: Feedback[];
	addOrUpdateFeedback: (newFeedback: Feedback) => Promise<void>;
	clearSelectedImages: () => void;
	handleSelectImage: (image: PlaygroundImage) => void;
	uploadedImagesURLs: string[];
	handleAppChange: (app: APPS) => void;
	onAiEditorButtonClick: (
		image: PlaygroundImage,
		navigate: NavigateFunction,
		forceOldEditor?: boolean,
	) => Promise<void>;
	imageEditingViewMode: ImageEditingViewMode;
	enablePlaygroundBrushMode: boolean;
	togglePlaygroundViewMode: () => void;
	togglePlaygroundBrushMode: (status: boolean) => void;
	handleSelectSingleImage: (image: PlaygroundImage | null) => void;
	singleSelectedImage: PlaygroundImage | null;
	readonly selectedSingleImage: PlaygroundImage | null;
	openUploadModal: () => void;
	closeUploadModal: () => void;
	isUploadModalOpened: boolean | null;
	addTextResults: (textResult: TextResult) => void;
	clearTextResults: () => void;
	removeNotLoadingImages: () => void;
	clearVideoResults: () => void;
	onFullScreenViewerDownload: (
		images: (BriaImageProps & PlaygroundImage)[],
		fullscreenImages: FullScreenImageType[],
	) => void;
	projectsStore: IPlaygroundProjectsStore;
	sessionsStore: IPlaygroundSessionsStore;
	automotiveStore: IAutomotiveStore;
	imagesReflections: AutomotiveImageReflection[];
	imagesEffects: AutomotiveImageReflection[];
	imagesHarmonizations: AutomotiveImageReflection[];
	imagesSegments: AutomotiveImageSegments[];
	generateReflectionLayers: () => Promise<void>;
	getAutomotiveImageReflections: (imageUrl: string) => AutomotiveImageReflection | null;
	getAutomotiveImageEffects: (imageUrl: string) => AutomotiveImageReflection | null;
	getAutomotiveImageHarmonizations: (imageUrl: string) => AutomotiveImageReflection | null;
	getAutomotiveImageSegments: (imageUrl: string) => AutomotiveImageSegments | null;
	addLoadedImageToStore: (image: PlaygroundImage) => void;
	clearPlayGroundLoadedImagesArray: () => void;
	isLoadingSegments: boolean;
	isLoadingReflections: boolean;
	enableAutomotiveReflectionMode: boolean;
	toggleAutomotiveReflectionMode: (status: boolean) => void;
	selectedReflectionLayer: AutomotiveReflectionLayer | null;
	handleSelectReflectionLayer: (layer: AutomotiveReflectionLayer | null) => void;
	updateReflectionLayerIntensity: (imageUrl: string, layerName: string, intensity: number) => void;
	updateEffectLayerIntensity: (imageUrl: string, layerName: string, intensity: number) => void;
	updateHarmonizationLayerIntensity: (imageUrl: string, layerName: string, intensity: number) => void;
	showHideReflectionLayer: (imageUrl: string, layerName: string, status: boolean) => void;
}

export default class PlaygroundStore implements IPlaygroundStore {
	private uploadImageService = new UploadImageService();
	rootStore: IRootStore;
	projectsStore: IPlaygroundProjectsStore;
	sessionsStore: IPlaygroundSessionsStore;
	resultsStore: IPlaygroundResultsStore;
	automotiveStore: IAutomotiveStore;
	selectedConfig: APPS = APPS.TEXT_TO_IMAGE;
	playgroundResults: PlaygroundResult[] = [];
	textResults: TextResult[] = [];
	playgroundVideoResults: PlaygroundVideoResult[] = [];
	selectedImages: PlaygroundImage[] = [];
	selectoRef = useRef<Selecto>(null);
	loadingExport: boolean = false;
	loadingSaveToMyImages: boolean = false;
	loadingSaveToMyImagesAndInsertImageToAd: boolean = false;
	private imageUtils = useImageUtils();
	errorToast = useErrorToast();
	isText2ImagePopupOpened: boolean = false;
	showSuccessToastSavedImage: boolean = false;
	playGroundLoadedImages: PlaygroundImage[] = [];
	loadingFeedback: boolean = false;
	feedbackHistory: Feedback[] = [];
	private imagesQueryService: ImagesService = new ImagesService();
	private automotiveQueryService: AutomotiveService = new AutomotiveService();
	textToGenerate: string = "";
	aspectRatio: AspectRatio = "4:3";
	uploadedImagesURLs: string[] = [];
	imageEditingViewMode: ImageEditingViewMode = ImageEditingViewMode.SINGLE;
	enablePlaygroundBrushMode: boolean = false;
	singleSelectedImage: PlaygroundImage | null = null;
	isUploadModalOpened: boolean | null = null;
	imagesReflections: AutomotiveImageReflection[] = [];
	imagesEffects: AutomotiveImageReflection[] = [];
	imagesHarmonizations: AutomotiveImageReflection[] = [];
	imagesSegments: AutomotiveImageSegments[] = [];
	isLoadingReflections: boolean = false;
	isLoadingSegments: boolean = false;
	enableAutomotiveReflectionMode: boolean = false;
	selectedReflectionLayer: AutomotiveReflectionLayer | null = null;

	constructor(rootStore: IRootStore) {
		makeAutoObservable(this);
		this.rootStore = rootStore;
		this.projectsStore = new PlaygroundProjectsStore(this);
		this.sessionsStore = new PlaygroundSessionsStore(this);
		this.resultsStore = new PlaygroundResultsStore(this);
		this.automotiveStore = new AutomotiveStore(this);
	}

	onFullScreenViewerDownload = (
		images: (BriaImageProps & PlaygroundImage)[],
		fullscreenImages: FullScreenImageType[],
	) => {
		const foundImages: PlaygroundImage[] = [];
		fullscreenImages.forEach((fullscreenImage) => {
			const foundImage = images.find((img) => img.url === fullscreenImage.src);
			if (foundImage) {
				foundImages.push(foundImage);
			}
		});
		this.rootStore.uiStore.showExportImagePopup({ images: foundImages });
	};

	togglePlaygroundViewMode = (): void => {
		runInAction(() => {
			if (iframeStore.isIframe() && !iframeStore.iframe.config.image_editing_config?.enable_bulk) {
				this.imageEditingViewMode === ImageEditingViewMode.SINGLE;
			} else {
				this.imageEditingViewMode =
					this.imageEditingViewMode === ImageEditingViewMode.SINGLE
						? ImageEditingViewMode.GALLERY
						: ImageEditingViewMode.SINGLE;
				this.enablePlaygroundBrushMode = false;
			}
		});
	};

	togglePlaygroundBrushMode = (status: boolean): void => {
		runInAction(() => {
			this.enablePlaygroundBrushMode = status;
		});
	};

	toggleAutomotiveReflectionMode = (status: boolean): void => {
		runInAction(() => {
			this.enableAutomotiveReflectionMode = status;
		});
	};

	setTextToGenerate = (textToGenerate: string): void => {
		this.textToGenerate = textToGenerate;
	};

	setSelectedAspectRatio = (aspectRatio: AspectRatio): void => {
		this.aspectRatio = aspectRatio;
	};
	handleAppChange = (app: APPS) => {
		runInAction(() => {
			this.selectedConfig = app;
		});
	};

	deleteResult = async (id: string) => {
		if (this.selectedConfig === APPS.AUTOMOTIVE) {
			await this.resultsStore.deleteResult(id);
		}
		this.playgroundResults = this.playgroundResults.filter((result) => result.id !== id);
	};

	clearVideoResults = (): void => {
		this.playgroundVideoResults = [];
	};

	onSuccessResult = async (image: PlaygroundImage): Promise<void> => {
		const foundImage = this.rootStore.playgroundStore.playgroundResults
			.flatMap((result) => result.images)
			.find((searchSameImg) => searchSameImg.id === image.id);
		if (foundImage) {
			foundImage.loading = false;
			if (foundImage.type === APPS.PRODUCT_PLACEMENT) {
				foundImage.aiEditorButtonLoading = true;
				this.uploadImageService
					.getVhash(image.url)
					.then((vhash) => (foundImage.vhash = vhash))
					.finally(() => (foundImage.aiEditorButtonLoading = false));
			}
		}
	};

	addTextResults = (textResult: TextResult) => {
		runInAction(() => {
			this.textResults.push(textResult);
		});
	};

	clearTextResults = () => {
		runInAction(() => {
			this.textResults = [];
		});
	};

	openLabsAiEditor = (vhash?: string) => {
		vhash && window.open(`${getSummerHost()}/gallery/${vhash}?from_platform_hub=true&isGenerated=true`, "_blank");
	};

	onAiEditorButtonClick = async (
		image: PlaygroundImage,
		navigate: NavigateFunction,
		forceOldEditor: boolean = false,
	): Promise<void> => {
		if (!isFoxApps()) {
			if (image.type === APPS.PRODUCT_PLACEMENT || forceOldEditor) {
				if (!image.vhash && !image.aiEditorButtonLoading) {
					image.aiEditorButtonLoading = true;
					this.uploadImageService
						.getVhash(image.url)
						.then((vhash) => {
							image.vhash = vhash;
							this.openLabsAiEditor(image.vhash);
						})
						.finally(() => (image.aiEditorButtonLoading = false));
				} else {
					this.openLabsAiEditor(image.vhash);
				}
			} else {
				this.selectImages([image]);
				this.imageEditingViewMode = ImageEditingViewMode.SINGLE;
				this.selectedConfig = APPS.IMAGE_TO_IMAGE;
				navigate(`/${RouterConstants.APPS.path}/${RouterConstants.IMAGE_TO_IMAGE.path}`);
			}
		} else {
			this.rootStore.aiEditorStore.setProperty("selectedImageUrl", image.url);
			this.rootStore.aiEditorStore.setProperty("aiEditorPopup", true);
			this.handleSelectSingleImage(image);
			if (image.type === APPS.IMAGE_TO_IMAGE) {
				this.rootStore.objectsStore.setProperty("refine", {
					...this.rootStore.objectsStore.refine,
					tailored_style: (image.config as ImageToImageConfigType).style?.tailored_style,
					sub_style: (image.config as ImageToImageConfigType).style?.sub_style,
					originalImage: (image.config as ImageToImageConfigType).original_image.url,
				});
			}
		}
	};

	onErrorResult = async (image: PlaygroundImage, errorType: ImageErrorType): Promise<void> => {
		const foundImage = this.rootStore.playgroundStore.playgroundResults
			.flatMap((result) => result.images)
			.find((searchSameImg) => searchSameImg.id === image.id);
		if (foundImage) {
			foundImage.loading = false;
			foundImage.error = { type: errorType };
		}
	};

	exportImages = async (
		images: PlaygroundImage[],
		zipName: string = "bria_gen",
		resolution: number = 1,
		action: "download" | "save" = "download",
	) => {
		try {
			this.loadingExport = true;

			if (images.length === 1) {
				await this.imageUtils.exportImage(images[0].url, this.getFileName(images[0]), resolution, action);
			} else {
				const scaledImages = [];
				for (const image of images) {
					const scaledUrl =
						resolution === 1
							? image.url
							: await new ImagesService().increaseResolution(image.url, resolution);

					scaledImages.push({ fileName: this.getFileName(image), imageUrl: scaledUrl });
				}

				await this.imageUtils.exportZipImages(scaledImages, zipName, action);
			}

			// Reset loading state
			runInAction(() => {
				this.loadingExport = false;
			});
		} catch (e: any) {
			this.loadingExport = false;
			return Promise.reject(`Error downloading images zip: ${e.message || e.toString()}`);
		}
	};

	clearResults = () => {
		runInAction(() => {
			this.playgroundResults = [];
			this.selectedImages = [];
			this.textResults = [];
			this.playgroundVideoResults = [];
			this.clearPlayGroundLoadedImagesArray();
		});
	};
	// for IFrame:Reset all the results except the starting image
	ResetResults = () => {
		runInAction(() => {
			this.playgroundResults = this.playgroundResults.slice(0, 1);
			this.selectedImages = this.selectedImages.slice(0, 1);
			this.textResults = [];
			this.playgroundVideoResults = [];
			this.clearPlayGroundLoadedImagesArray();
		});
	};

	getSelecto = () => this.selectoRef.current;

	handleSelectImagesElements = (imagesElements?: ElementType[], isPopupView: boolean = false) => {
		if (!isPopupView && this.rootStore.imagesStore.secondaryTabType !== SecondaryTabTypeEnum.SMART_IMAGE) {
			const selectedImagesIds: string[] | undefined = imagesElements?.map((el) =>
				JSON.parse(el.ariaValueText ?? "{}"),
			);
			this.playgroundResults.forEach(
				(result) =>
					result.images?.forEach((image) => {
						image.selected = selectedImagesIds?.some((id) => id === image.id);
					}),
			);
		} else {
			// Get the ID of the last selected image element, if any
			if (imagesElements && imagesElements.length > 0 && imagesElements[0] != undefined) {
				const lastSelectedImageId = imagesElements?.length
					? JSON.parse(imagesElements[imagesElements.length - 1]?.ariaValueText ?? "")
					: null;

				// Update the selection state for each last Image Id
				this.playgroundResults.forEach((result) => {
					result.images?.forEach((image) => {
						// Set selected to true only if it matches the last selected image ID
						image.selected = image.id === lastSelectedImageId;
					});
				});
			} else {
				imagesElements = [];
				const selectedImagesIds: string[] | undefined = imagesElements.map((element) =>
					JSON.parse(element.ariaValueText ?? "{}"),
				);
				this.playgroundResults.forEach(
					(result) =>
						result.images?.forEach((image) => {
							image.selected = selectedImagesIds?.some((id) => id === image.id);
						}),
				);
			}
		}
	};

	selectImages = (images: PlaygroundImage[]) => {
		// Timeout to assure that playgroundResults HTML Elements are rendered before
		setTimeout(() => {
			const elementsToSelect: ElementType[] | undefined = this.getSelecto()
				?.getSelectableElements()
				?.filter((el) => images.some((image) => image.id === JSON.parse(el.ariaValueText ?? "{}")));
			this.handleSelectImagesElements(elementsToSelect);
			useSelectable(this.getSelecto()).selectElements(elementsToSelect);
		}, 1000);
	};

	getFileName = (image: PlaygroundImage): string => {
		if (image.type === "textToImage") {
			return (
				(image.config as TextToImageConfigType)?.prompt?.substring(0, 80) +
				(image.vhash ? "_" + image.vhash : "")
			);
		} else if (image.type === "upload") {
			const fileNameWithoutExtension = image?.file?.name?.split(".")?.slice(0, -1)?.join(".");
			return `${fileNameWithoutExtension ? fileNameWithoutExtension : "bria"}_${image.id}`;
		} else if (image.type === "imageToImage") {
			const originalImage: PlaygroundImage = (image.config as ImageToImageConfigType)?.original_image;
			if (originalImage?.type === "upload") {
				const fileNameWithoutExtension = originalImage?.file?.name?.split(".")?.slice(0, -1)?.join(".");
				return `${fileNameWithoutExtension ? fileNameWithoutExtension : "bria"}_${image.id}`;
			}
			if (originalImage?.type === "textToImage")
				return `${(originalImage.config as TextToImageConfigType).prompt?.substring(0, 80)}_${image.id}`;
			return `${image.id}-${image.style_props?.face_sd_edit}-${image.style_props?.face_seed}-${image.style_props?.body_seed}`;
		}
		return `${image.type ? image.type : "bria"}_result`;
	};

	getAvailableImages = (): PlaygroundImage[] =>
		this.playgroundResults.flatMap((results) => results.images?.filter((image) => !image.loading && !image.error));

	getSelectedImages = (): PlaygroundImage[] =>
		this.playgroundResults.flatMap(
			(results) => results.images?.filter((image) => !image.loading && image.selected),
		);

	getSelectedImagesForExport = () => {
		const selectedImages = this.getSelectedImages();
		const availableImages = this.getAvailableImages();
		const singleImage = this.singleSelectedImage;
		return this.rootStore.imageToImageStore.isEraseConfigsEnabled && singleImage
			? [singleImage]
			: selectedImages.length > 0
			? selectedImages
			: availableImages;
	};

	openSelected = () => {
		const selectedImages = this.getSelectedImages();
		if (selectedImages.length) {
			const newImages: PlaygroundImage[] = selectedImages.map((image) => ({ ...image, id: uuidv4() }));
			this.playgroundResults = [...this.playgroundResults, { id: uuidv4(), images: newImages, type: "new" }];
			this.selectImages(newImages);
		}
	};

	saveMyImages = async (action: SaveImagesLoadingState) => {
		try {
			this[action] = true;
			const selectedImages = this.getSelectedImages();
			for (const selectedImage of selectedImages) {
				const res = await this.rootStore.imagesStore.uploadImageViaAPI(
					undefined,
					this.rootStore.imagesStore.uploadToUserGallery.toString(),
					selectedImage.url,
				);
				if (res?.imageUrl) {
					this.uploadedImagesURLs.push(res?.imageUrl);
				}
			}
			this.showSuccessToastSavedImage = true;
			this.rootStore.imagesStore.uploadProcessDone = true;
			this[action] = false;
		} catch (e: any) {
			this[action] = false;
			return Promise.reject(`Error saving images : ${e.message || e.toString()}`);
		}
	};
	openModal = () => (this.isText2ImagePopupOpened = true);
	closeModal = () => {
		this.isText2ImagePopupOpened = false;
		this.setTextToGenerate("");
		this.setSelectedAspectRatio("4:3");
		this.rootStore.imagesStore.setProperty("isLoadingWizardImages", false);
		const showDesignEditorContainer =
			this.rootStore?.designEditorStore?.containerRef && this.rootStore.designEditorStore.containerRef?.current;
		if (showDesignEditorContainer) this.rootStore.uiStore.showDesignEditorContainer();
	};
	addOrUpdateFeedback = async (newFeedback: Feedback): Promise<void> => {
		try {
			runInAction(() => {
				this.loadingFeedback = true;
				const index = this.feedbackHistory.findIndex(
					(feedback: Feedback) => feedback.imageUrl === newFeedback.imageUrl,
				);
				if (index !== -1) {
					this.feedbackHistory[index] = newFeedback;
				} else {
					this.feedbackHistory.push(newFeedback);
				}
			});

			await this.imagesQueryService.sendFeedback(newFeedback);
			this.loadingFeedback = false;
		} catch (e) {
			this.loadingFeedback = false;
			return Promise.reject(e);
		}
	};

	clearSelectedImages = () => {
		runInAction(() => {
			this.selectedImages = [];
			this.playgroundResults.forEach(
				(result) =>
					result.images?.forEach((image) => {
						image.selected = false;
					}),
			);
		});
	};

	handleSelectImage = (image: PlaygroundImage) => {
		runInAction(() => {
			const existingImageIndex = this.selectedImages?.findIndex((selectedImage) => selectedImage.id === image.id);

			const resultIndex = this.playgroundResults.findIndex(
				(result) => result.images?.some((img) => img.id === image.id),
			);

			if (resultIndex !== 0) {
				if (existingImageIndex !== -1) {
					this.selectedImages.splice(existingImageIndex, 1);
				} else {
					if (this.selectedImages.length < 2) {
						this.selectedImages.push(image);
					}
				}

				this.playgroundResults.forEach((result) => {
					result.images?.forEach((img) => {
						img.selected = this.selectedImages.some((selectedImage) => selectedImage.id === img.id);
					});
				});
			}
		});
	};

	get selectedSingleImage(): PlaygroundImage | null {
		return this.playgroundResults.flatMap((results) => results.images?.filter((image) => image.selected))[0];
	}

	handleSelectSingleImage = (selectedImage: PlaygroundImage | null) => {
		this.singleSelectedImage = null;

		this.playgroundResults.forEach(
			(result) =>
				result.images?.forEach((image) => {
					image.selected = selectedImage?.id === image.id;
					if (image.selected) this.singleSelectedImage = image;
				}),
		);
	};

	openUploadModal = () => {
		this.isUploadModalOpened = true;
	};
	closeUploadModal = () => {
		this.isUploadModalOpened = false;
	};

	removeNotLoadingImages = () => {
		const playgroundResults = [...this.playgroundResults];
		if (playgroundResults.length === 0) return;

		const lastIndex = playgroundResults.reduce(
			(lastIndex, item, index) => (item.type === this.selectedConfig ? index : lastIndex),
			-1,
		);

		if (lastIndex === -1) return;

		const lastResult = playgroundResults[lastIndex];

		const loadedImages = lastResult.images.filter((image) => !image.loading);

		if (loadedImages.length === 0) {
			playgroundResults.splice(lastIndex, 1);
		} else if (loadedImages.length !== lastResult.images.length) {
			playgroundResults[lastIndex] = {
				...lastResult,
				images: loadedImages,
			};
		}

		runInAction(() => {
			this.playgroundResults = playgroundResults;
		});
	};

	generateReflectionLayers = async () => {
		try {
			const imageUrl = this.singleSelectedImage?.url;
			if (!imageUrl) {
				console.error("No image selected for processing.");
				return;
			}
			runInAction(() => {
				this.isLoadingReflections = true;
			});

			// Generate segmentation masks
			const existingSegments = this.getAutomotiveImageSegments(imageUrl);
			const segments = existingSegments ?? (await this.automotiveQueryService.generateSegmentation(imageUrl));
			if (!segments) {
				console.error("Failed to generate segmentation masks.");
				return;
			}

			runInAction(() => {
				this.imagesSegments.push({
					url: imageUrl,
					segments: segments,
				});
			});
			const existingReflections = this.getAutomotiveImageReflections(imageUrl);
			if (!existingReflections) {
				const response = await this.automotiveQueryService.getReflectionLayers(imageUrl, segments);
				const mappedLayers: AutomotiveReflectionLayer[] = Object.entries(response.layers).map(
					([name, url]) => ({
						name,
						url: (url as string) || null,
						intensity: name === "body" ? DEFAULT_BODY_INTENSITY : DEFAULT_WINDOWS_INTENSITY,
						hidden: false,
					}),
				);
				runInAction(() => {
					this.imagesReflections.push({
						url: imageUrl,
						layers: mappedLayers,
					});
				});
			}
			console.log("Reflection layers generated successfully.");
		} catch (error) {
			console.error("Error generating reflection layers:", error);
		} finally {
			runInAction(() => {
				this.isLoadingReflections = false;
			});
		}
	};

	getAutomotiveImageReflections = (imageUrl: string): AutomotiveImageReflection | null => {
		return this.imagesReflections.find((reflection) => reflection.url === imageUrl) || null;
	};

	getAutomotiveImageEffects = (imageUrl: string): AutomotiveImageReflection | null => {
		return this.imagesEffects.find((reflection) => reflection.url === imageUrl) || null;
	};

	getAutomotiveImageHarmonizations = (imageUrl: string): AutomotiveImageReflection | null => {
		return this.imagesHarmonizations.find((reflection) => reflection.url === imageUrl) || null;
	};

	getAutomotiveImageSegments = (imageUrl: string): AutomotiveImageSegments | null => {
		return this.imagesSegments.find((segment) => segment.url === imageUrl) || null;
	};

	handleSelectReflectionLayer = (layer: AutomotiveReflectionLayer | null) => {
		runInAction(() => {
			this.selectedReflectionLayer = layer;
		});
	};

	updateReflectionLayerIntensity = (imageUrl: string, layerName: string, intensity: number): void => {
		runInAction(() => {
			const imageReflectionIndex = this.imagesReflections.findIndex((reflection) => reflection.url === imageUrl);

			if (imageReflectionIndex !== -1) {
				const layers = this.imagesReflections[imageReflectionIndex].layers;

				const isWindowsLayer = layerName.toLowerCase().includes("wind");

				if (isWindowsLayer) {
					layers.forEach((layer) => {
						if (layer.name.toLowerCase().includes("wind")) {
							layer.intensity = intensity;
						}
					});
				} else {
					const layerIndex = layers.findIndex((layer) => layer.name === layerName);
					if (layerIndex !== -1) {
						layers[layerIndex].intensity = intensity;
					}
				}
			}
		});
	};

	updateEffectLayerIntensity = (imageUrl: string, layerName: string, intensity: number): void => {
		runInAction(() => {
			const imageEffectIndex = this.imagesEffects.findIndex((effect) => effect.url === imageUrl);

			if (imageEffectIndex !== -1) {
				const layers = this.imagesEffects[imageEffectIndex].layers;
				const layerIndex = layers.findIndex((layer) => layer.name === layerName);

				if (layerIndex !== -1) {
					this.imagesEffects[imageEffectIndex].layers[layerIndex].intensity = intensity;
				}
			}
		});
	};

	updateHarmonizationLayerIntensity = (imageUrl: string, layerName: string, intensity: number): void => {
		runInAction(() => {
			const imageHarmonizationIndex = this.imagesHarmonizations.findIndex((effect) => effect.url === imageUrl);

			if (imageHarmonizationIndex !== -1) {
				const layers = this.imagesHarmonizations[imageHarmonizationIndex].layers;
				const layerIndex = layers.findIndex((layer) => layer.name === layerName);

				if (layerIndex !== -1) {
					this.imagesHarmonizations[imageHarmonizationIndex].layers[layerIndex].intensity = intensity;
				}
			}
		});
	};

	showHideReflectionLayer = (imageUrl: string, layerName: string, status: boolean): void => {
		runInAction(() => {
			const imageReflectionIndex = this.imagesReflections.findIndex((reflection) => reflection.url === imageUrl);

			if (imageReflectionIndex !== -1) {
				const layers = this.imagesReflections[imageReflectionIndex].layers;
				const layerIndex = layers.findIndex((layer) => layer.name === layerName);

				if (layerIndex !== -1) {
					this.imagesReflections[imageReflectionIndex].layers[layerIndex].hidden = status;
				}
			}
		});
	};

	addLoadedImageToStore = (image: PlaygroundImage): void => {
		// Store the fully loaded image in `playGroundLoadedImages` to enable scrolling to the last result in the playground.
		this.playGroundLoadedImages = [...this.playGroundLoadedImages, image as PlaygroundImage];
	};
	clearPlayGroundLoadedImagesArray = (): void => {
		// clear `playGroundLoadedImages`.
		this.playGroundLoadedImages = [];
	};
}

export enum SaveImagesLoadingState {
	Save = "loadingSaveToMyImages",
	SaveAndAddToCanvas = "loadingSaveToMyImagesAndInsertImageToAd",
}
