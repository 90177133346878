import { onAuthStateChanged } from "firebase/auth";
import { makeAutoObservable, runInAction } from "mobx";
import { BriaAxios } from "../../config/axios";
import { firebaseAuth } from "../../config/firebase.ts";
import { ORG_BLOCK_REASONS, ORG_SUBSCRIPTION_PLANS, ORG_SUBSCRIPTION_STATUSES } from "../../constants/billing.ts";
import { AESCipher } from "../../helpers/encryption.ts";
import { getUserWithUID } from "../../helpers/firebase.ts";
import {
	clearSelectedOrganization,
	getSelectedOrganization,
	setSelectedOrganization,
} from "../../helpers/localStorage.ts";
import { IRootStore } from "../../mobx/root-store.tsx";
import { OrgFeatures, OrgSubscription, OrgUsage } from "../../models/billing.ts";
import { RelatedOrganization, UserOrganization } from "../../models/organization.ts";
import User from "../../models/user.ts";
import BillingService from "../../services/BillingService.ts";
import iframeStore from "../IframeNew/iframe-store.tsx";

export interface IAuthStore {
	user: User | null;
	isLoadingUserOrganizations: boolean;
	userOrganizations: UserOrganization[];
	isLoggedIn: boolean;
	isLoading: boolean;
	isInitialized: boolean;
	isLoadingOrgSubscriptions: boolean;
	isLoadingOrgUsage: boolean;
	isOrgBlocked: boolean;
	orgPassedFreeLimit: boolean;
	isSubscriptionInactive: boolean;
	isPremiumOrgSubscription: boolean;
	isError: boolean;
	orgSubscription: OrgSubscription | null;
	orgUsage: OrgUsage | null;
	registrationSource?: string | null;
	parentOrganization: RelatedOrganization | null;
	subOrganizations: RelatedOrganization[];
	isParentOrganizationLoading: boolean;
	isSubOrganizationsLoading: boolean;
	isCreateSubOrgsLoading: boolean;

	resetStore(): void;

	logout(): Promise<void>;

	setUser(user: User | null): Promise<void>;

	grantUserPermission(orgId: string): Promise<void>;

	createOrganization(org_name: string): Promise<any>;

	createSubOrganization(orgName: string): Promise<any>;

	searchOrganizations(query: string, signal?: AbortSignal): Promise<UserOrganization[]>;

	loadUserData(): Promise<void>;

	loadOrgSubscription(): Promise<void>;

	isFeatureEnabled(feature: OrgFeatures): boolean;

	loadOrgUsage(): Promise<void>;

	loadOrganization(orgId: string): Promise<UserOrganization>;

	setPostRegistrationConfigs(): Promise<void>;

	loadOrganizations(): Promise<any>;

	loadParentOrganization(): Promise<any>;

	loadSubOrganizations(): Promise<any>;

	blockOrganization(orgID: string): Promise<any>;

	unBlockOrganization(orgID: string): Promise<any>;
}

export default class AuthStore implements IAuthStore {
	rootStore: IRootStore;
	user: User | null = null;
	userOrganizations: UserOrganization[] = [];
	isLoggedIn = false;
	isError = false;
	isLoading = true;
	isInitialized = false;
	isLoadingOrgSubscriptions = false;
	isLoadingUserOrganizations = false;
	isOrgBlocked = false;
	orgPassedFreeLimit = false;
	isSubscriptionInactive = false;
	isPremiumOrgSubscription = false;
	isLoadingOrgUsage = false;
	orgSubscription: OrgSubscription | null = null;
	orgUsage: OrgUsage | null = null;
	billingService = new BillingService();
	registrationSource = undefined;
	parentOrganization = null;
	subOrganizations: RelatedOrganization[] = [];
	isParentOrganizationLoading: boolean = false;
	isSubOrganizationsLoading: boolean = false;
	isCreateSubOrgsLoading: boolean = false;

	constructor(rootStore: IRootStore) {
		makeAutoObservable(this);
		this.rootStore = rootStore;
		onAuthStateChanged(firebaseAuth, (user) => {
			if (user) {
				this.isLoading = true;
				this.isError = false;

				getUserWithUID(user.uid)
					.then((userObject) => {
						if (userObject) {
							this.setUser(userObject);
						} else {
							this.setUser(null);
						}
					})
					.catch((error) => {
						console.log(error);
						this.setUser(null);
					});
			} else {
				this.setUser(null);
			}
		});
	}

	resetStore = (): void => {
		this.orgSubscription = null;
		this.orgUsage = null;
		this.parentOrganization = null;
		this.subOrganizations = [];
	}

	logout = async (): Promise<void> => {
		try {
			this.rootStore.resetStores()
			await firebaseAuth.signOut();
			this.isLoggedIn = false;
			localStorage.clear();
		} catch (e) {
			runInAction(() => {
				this.isLoading = false;
				this.isError = true;
			});
			return Promise.reject(e);
		}
	};

	setUser = async (user: User | null): Promise<void> => {
		try {
			this.user = user;
			if (user != null) {
				this.rootStore.analyticsStore.setUser(user, this.registrationSource);
				await this.loadUserData();
			}
			this.isLoggedIn = user !== null;
			this.isLoading = false;
			this.isError = false;
		} catch (e) {
			runInAction(() => {
				this.isLoading = false;
				this.isError = true;
			});
			return Promise.reject(e);
		} finally {
			this.isInitialized = true;
		}
	};

	createOrganization = async (org_name: string): Promise<any> => {
		this.isLoading = false;
		this.isError = false;
		const data = {
			name: org_name,
		};
		try {
			const response = await (await BriaAxios()).post(`/create_organization/`, data);
			runInAction(() => {
				this.isLoading = false;
				this.isError = false;
			});
			if (this.user) {
				getUserWithUID(this.user.uid).then((userObject) => {
					if (userObject) {
						this.setUser(userObject);
					} else {
						this.setUser(null);
					}
				});
			}
			return response.data;
		} catch (e) {
			runInAction(() => {
				this.isLoading = false;
				this.isError = true;
			});
			return Promise.reject(e);
		}
	};

	loadOrganization = async (orgId: string): Promise<UserOrganization> => {
		try {
			const response = await (await BriaAxios()).get(`/organization/${orgId}`);
			return response.data as UserOrganization;
		} catch (e) {
			return Promise.reject(e);
		}
	};

	loadOrganizations = async (): Promise<any> => {
		this.isLoadingUserOrganizations = true;
		try {
			let response: any;
			if (this.user?.isSuperAdmin()) {
				response = await this.searchOrganizations("bria");
			} else {
				response = await (await BriaAxios()).get("/organizations/");
				response = response.data;
			}
			runInAction(() => {
				this.userOrganizations = response;

				const selectedOrg = getSelectedOrganization();
				if (this.userOrganizations.length > 0) {
					if (!selectedOrg) {
						if (this.userOrganizations.length === 1 || this.user?.isSuperAdmin()) {
							setSelectedOrganization(this.userOrganizations[0]);
						}
					} else {
						const foundOrg = this.userOrganizations.find(
							(org) => org.organization.uid === selectedOrg.organization.uid,
						);
						if (foundOrg) {
							setSelectedOrganization(foundOrg);
						} else {
							if (this.user?.isSuperAdmin()) {
								this.userOrganizations = [selectedOrg, ...this.userOrganizations];
							} else {
								setSelectedOrganization(this.userOrganizations[0]);
							}
						}
					}
				} else {
					clearSelectedOrganization();
				}
			});
		} catch (e) {
			this.isLoading = false;
			this.isError = true;
			return Promise.reject(e);
		} finally {
			this.isLoadingUserOrganizations = false;
		}
	};

	loadUserData = async () => {
		if (!iframeStore.isIframe()) {
			await this.loadOrganizations();
		}
		if (
			getSelectedOrganization() &&
			(this.userOrganizations.length > 0 || (iframeStore.isIframe() && !iframeStore.isPreview()))
		) {
			this.loadOrgSubscription();
			if (!iframeStore.isIframe()) {
				this.loadOrgUsage();
			}
		}
	};

	searchOrganizations = async (query: string, signal?: AbortSignal) => {
		const res = await (
			await BriaAxios()
		).get("/search_organizations/", {
			params: {
				query,
			},
			signal,
		});
		return res.data;
	};

	setPostRegistrationConfigs = async (): Promise<void> => {
		try {
			await (await BriaAxios()).post("/users/set_post_registration_configs", {});
		} catch (e) {
			return Promise.reject(e);
		}
	};

	isFeatureEnabled = (feature: OrgFeatures) => {
		// you should use this only if the isLoadingOrgSubscriptions is false, otherwise, add a loader in the frontend based on isLoadingOrgSubscriptions
		return this.orgSubscription?.features?.includes(feature) ?? false;
	};

	loadOrgSubscription = async (): Promise<void> => {
		runInAction(() => {
			this.isError = false;
			this.isLoadingOrgSubscriptions = true;
		});
		try {
			const response = await this.billingService.loadOrgSubscription();
			runInAction(() => {
				this.orgSubscription = response;
				this.isError = false;

				const plan = this.orgSubscription?.plan_name ?? ORG_SUBSCRIPTION_PLANS.free.name;
				this.isPremiumOrgSubscription =
					plan !== ORG_SUBSCRIPTION_PLANS.free.name && plan !== ORG_SUBSCRIPTION_PLANS.starter.name;
				this.isOrgBlocked =
					(this.orgSubscription?.is_blocked ?? false) &&
					this.orgSubscription?.block_reason !== ORG_BLOCK_REASONS.PASSED_FREE_LIMITS;
				this.isLoadingOrgSubscriptions = false;
				this.orgPassedFreeLimit =
					(this.orgSubscription?.is_blocked ?? false) &&
					this.orgSubscription?.plan_name === ORG_SUBSCRIPTION_PLANS.free.name &&
					this.orgSubscription?.block_reason === ORG_BLOCK_REASONS.PASSED_FREE_LIMITS;
				this.isSubscriptionInactive =
					!this.orgPassedFreeLimit &&
					((this.orgSubscription?.is_blocked ?? false) ||
						(this.orgSubscription?.status !== ORG_SUBSCRIPTION_STATUSES.PAST_DUE &&
							this.orgSubscription?.status !== ORG_SUBSCRIPTION_STATUSES.ACTIVE));
			});
		} catch (e) {
			runInAction(() => {
				this.isLoadingOrgSubscriptions = false;
				this.isError = true;
			});
			return Promise.reject(e);
		}
	};

	loadOrgUsage = async (): Promise<void> => {
		runInAction(() => {
			this.isError = false;
			this.isLoadingOrgUsage = true;
		});
		try {
			const response = await this.billingService.loadOrgUsage();
			runInAction(() => {
				this.orgUsage = response;
				this.isError = false;
				this.isLoadingOrgUsage = false;
			});
		} catch (e) {
			runInAction(() => {
				this.isLoadingOrgUsage = false;
				this.isError = true;
			});
			return Promise.reject(e);
		}
	};

	grantUserPermission = async (orgId: string): Promise<void> => {
		const aesCipher = new AESCipher();
		const encryptedData = await aesCipher.encrypt(orgId);
		await (await BriaAxios()).post(`/grant_permission/`, { enc: encryptedData });
	};

	loadParentOrganization = async (): Promise<any> => {
		this.isParentOrganizationLoading = true;
		this.isError = false;
		try {
			const response = await (
				await BriaAxios()
			).get(`/organizations/parent_organization`);
			runInAction(() => {
				this.parentOrganization = response.data.parent_org;
			});
		} catch (e) {
			runInAction(() => {
				this.isError = true;
			});
			return Promise.reject(e);
		} finally {
			this.isParentOrganizationLoading = false;
		}
	};

	loadSubOrganizations = async (): Promise<any> => {
		this.isSubOrganizationsLoading = true;
		this.isError = false;
		try {
			const response = await (
				await BriaAxios()
			).get(`/organizations/sub_organizations`);
			runInAction(() => {
				this.subOrganizations = response.data;
			});

		} catch (e) {
			runInAction(() => {
				this.isError = true;
			});
			return Promise.reject(e);
		} finally {
			this.isSubOrganizationsLoading = false;
		}
	};

	createSubOrganization = async (orgName: string): Promise<any> => {
		this.isCreateSubOrgsLoading = true;
		this.isError = false;
		const data = {
			name: orgName,
		};
		try {
			const selectedOrg = getSelectedOrganization();
			if (selectedOrg) {
				const response = await (
					await BriaAxios()
				).post(`/organizations/create_sub_organization`, data);
				runInAction(() => {
					this.isCreateSubOrgsLoading = false;
					this.isError = false;
				});
				return response.data as UserOrganization;
			}
		} catch (e) {
			runInAction(() => {
				this.isCreateSubOrgsLoading = false;
				this.isError = true;
			});
			return Promise.reject(e);
		}
	};
	blockOrganization = async (orgID: string): Promise<any> => {
		this.isLoading = false;
		this.isError = false;
		try {
			await (await BriaAxios()).get(`/organizations/${orgID}/block`);
			runInAction(() => {
				this.isLoading = false;
				this.isError = false;
			});
		} catch (e) {
			runInAction(() => {
				this.isLoading = false;
				this.isError = true;
			});
			return Promise.reject(e);
		}
	};

	unBlockOrganization = async (orgID: string): Promise<any> => {
		this.isLoading = false;
		this.isError = false;
		try {
			await (await BriaAxios()).get(`/organizations/${orgID}/unblock`);
			runInAction(() => {
				this.isLoading = false;
				this.isError = false;
			});
		} catch (e) {
			runInAction(() => {
				this.isLoading = false;
				this.isError = true;
			});
			return Promise.reject(e);
		}
	};
}
