import { isProduction } from "../config/env.ts";
import { OrgSubscriptionPlan } from "../models/billing.ts";

export const SubscriptionIntervalMapping: { [key: string]: string } = {
	month: "Monthly",
};

export enum ORG_BLOCK_REASONS {
	PASSED_FREE_LIMITS = "PASSED_FREE_LIMITS",
	BLOCKED_BY_ADMIN = "BLOCKED_BY_ADMIN",
	BLOCKED_BY_ORG_ADMIN = "BLOCKED_BY_ORG_ADMIN",
	OTHER = "OTHER",
}

export enum ORG_SUBSCRIPTION_STATUSES {
	ACTIVE = "active",
	PAST_DUE = "past_due",
	INCOMPLETE = "incomplete",
	INCOMPLETE_EXPIRED = "incomplete_expired",
	TRIALING = "trialing",
	CANCELED = "canceled",
	UNPAID = "unpaid",
	PAUSED = "paused",
}

export const ORG_SUBSCRIPTION_PLANS: {
	[key: string]: OrgSubscriptionPlan;
} = {
	free: {
		name: "free",
		displayName: "Free",
		selfService: false,
	},
	starter: {
		name: "starter",
		displayName: "Starter",
		selfService: true,
		priceIds: isProduction()
			? ["price_1Paw0sHW6TJCmNekeykY3829", "price_1Pb2ljHW6TJCmNekHuKAc6Ts"]
			: ["price_1PdC5cHW6TJCmNekCehbNZOm", "price_1PdCAdHW6TJCmNekRybg6d0L"],
		termsAndConditionsLink:
			"https://pages.bria.ai/hubfs/Terms%20and%20Conditions/Bria%20AI%20Online%20Terms%20and%20Conditions%20(June%202024)%20v1.pdf",
	},
	pro: {
		name: "pro",
		displayName: "Pro",
		selfService: false,
	},
	enterprise: {
		name: "enterprise",
		displayName: "Enterprise",
		selfService: false,
	},
	custom: {
		name: "custom",
		displayName: "Custom",
		selfService: true,
	},
};

export enum ORG_STATUSES {
	active = "active",
	PASSED_FREE_LIMITS = "passedFreeLimits",
	BLOCKED_BY_ADMIN = "blockedByAdmin",
	BLOCKED_BY_ORG_ADMIN = "blockedByOrgAdmin",
}

export type IOrgStatusKey = keyof typeof ORG_STATUSES;

export enum RelatedOrgSubscriptionTypes {
	free = "free",
	payAsYouGo = "payAsYouGo"
}