import {
	createRoutesFromChildren,
	matchRoutes,
	Navigate,
	Route,
	Routes,
	useLocation,
	useNavigationType,
} from "react-router-dom";

import * as Sentry from "@sentry/react";
import smartlookClient from "smartlook-client";
import ForgotPasswordScreen from "./pages/auth/ForgotPassword/ForgotPassword.tsx";
import LoginScreen from "./pages/auth/Login/Login.tsx";
import RegisterScreen from "./pages/auth/Register/Register.tsx";
import PasswordResetScreen from "./pages/auth/ResetPassword/ResetPassword.tsx";
import UserInfoScreen from "./pages/auth/UserInfo/UserInfo.tsx";

import { Alert } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import "./assets/styles/main.scss";
import CreateModel from "./components/TailoredGeneration/CreateModel/CreateModel.tsx";
import DatasetCreation from "./components/TailoredGeneration/DatasetCreation/DatasetCreation.tsx";
import TgPlayground from "./components/TailoredGeneration/Playground/TgPlayground.tsx";
import ViewDataset from "./components/TailoredGeneration/ViewDataset/ViewDataset.tsx";
import { ENV_VARS, getCurrentEnv, isCloudEnv, isIntegration, isLocal } from "./config/env";
import { APICardType } from "./constants/APICardsConstants.ts";
import { ModelCardType } from "./constants/ModelCardsConstants.ts";
import RouterConstants from "./constants/RouterConstants.ts";
import BriaDesignEditor from "./DesignEditor/BriaDesignEditor.tsx";
import { useAppStore } from "./hooks/useStores.tsx";
import AppLayout from "./layout/AppLayout/AppLayout.tsx";
import AuthLayout from "./layout/AuthLayout/AuthLayout.tsx";
import AutomotiveLayout from "./layout/AutomotiveLayout/AutomotiveLayout.tsx";
import FoxLayout from "./layout/FoxLayout/FoxLayout.tsx";
import HubLayout from "./layout/HubLayout/HubLayout.tsx";
import IframeLayout from "./layout/IframeLayout/IframeLayout.tsx";
import InitIframe from "./layout/IframeLayout/InitIframe.tsx";
import InvitationLayout from "./layout/InvitationLayout/InvitationLayout.tsx";
import MobileChecker from "./layout/MobileChecker.tsx";
import PrivateLayout from "./layout/PrivateLayout/PrivateLayout.tsx";
import Account from "./pages/Account/Account.tsx";
import ApiKeys from "./pages/Account/ApiKeys/ApiKeys.tsx";
import RelatedOrganizations from "./pages/Account/RelatedOrganizations/RelatedOrganizations.tsx";
import Subscription from "./pages/Account/Subscription/Subscription.tsx";
import UsageAndBilling from "./pages/Account/UsageAndBilling/UsageAndBilling.tsx";
import Users from "./pages/Account/Users/Users.tsx";
import AIEditor from "./pages/AIEditor/AIEditor.tsx";
import API from "./pages/API/Api.tsx";
import BriaAPIs from "./pages/API/BriaAPIs/BriaAPIs.tsx";
import AccountVerification from "./pages/auth/AccountVerification/AccountVerification.tsx";
import AuthPage from "./pages/auth/AuthPage/AuthPage.tsx";
import Automotive from "./pages/Automotive/Automotive.tsx";
import AutomotiveProjects from "./pages/Automotive/Projects/AutomotiveProjects.tsx";
import CreateAutomotiveProject from "./pages/Automotive/Projects/Create/CreateAutomotiveProject.tsx";
import AutomotiveProject from "./pages/Automotive/Projects/Viewer/AutomotiveProject.tsx";
import Brands from "./pages/Campaign/Brands/Brands.tsx";
import BrandEditor from "./pages/Campaign/Brands/Editor/BrandEditor.tsx";
import Campaign from "./pages/Campaign/Campaign.tsx";
import Fonts from "./pages/Campaign/Fonts/Fonts.tsx";
import LayoutEditor from "./pages/Campaign/Layouts/Editor/LayoutEditor.tsx";
import TemplateEditor from "./pages/Campaign/Templates/Editor/TemplateEditor.tsx";
import Templates from "./pages/Campaign/Templates/Templates.tsx";
import FoxHub from "./pages/FoxHub/FoxHub.tsx";
import Home from "./pages/Home/Home.tsx";
import IframeConfig from "./pages/Iframe/IframeConfig/IframeConfig.tsx";
import IframeEditor from "./pages/Iframe/IframeEditor/IframeEditor.tsx";
import Iframes from "./pages/Iframe/Iframes.tsx";
import NewIframeConfig from "./pages/IframeNew/Config/NewIframeConfig.tsx";
import iframeStore from "./pages/IframeNew/iframe-store.tsx";
import NewIframeEditor from "./pages/IframeNew/IframeEditor/NewIframeEditor.tsx";
import NewIframes from "./pages/IframeNew/NewIframes.tsx";
import MobileNotSupportedPage from "./pages/MobileNotSupportedPage/MobileNotSupportedPage.tsx";
import BriaModels from "./pages/Models/BriaModels/BriaModels.tsx";
import Models from "./pages/Models/Models.tsx";
import TgDatasetViewer from "./pages/NewTG/Datasets/Viewer/TgDatasetViewer.tsx";
import TgAccessMethods from "./pages/NewTG/Integrations/TgAccessMethods.tsx";
import TgModelPlayground from "./pages/NewTG/Models/Viewer/Playground/TgModelPlayground.tsx";
import TgModelViewer from "./pages/NewTG/Models/Viewer/TgModelViewer.tsx";
import NewTailoredGeneration from "./pages/NewTG/NewTailoredGeneration.tsx";
import TgProjectEditor from "./pages/NewTG/Projects/Editor/TgProjectEditor.tsx";
import TgProjects from "./pages/NewTG/Projects/TgProjects.tsx";
import EditTgProjectDataset from "./pages/NewTG/Projects/Viewer/Datasets/Edit/EditTgProjectDataset.tsx";
import TgProjectDatasetLayout from "./pages/NewTG/Projects/Viewer/Datasets/Layout/TgProjectDatasetLayout.tsx";
import TgProjectDatasets from "./pages/NewTG/Projects/Viewer/Datasets/TgProjectDatasets.tsx";
import CreateTgProjectModel from "./pages/NewTG/Projects/Viewer/Models/Create/CreateTgProjectModel.tsx";
import TgProjectModels from "./pages/NewTG/Projects/Viewer/Models/TgProjectModels.tsx";
import TgProjectViewer from "./pages/NewTG/Projects/Viewer/TgProjectViewer.tsx";
import PageNotFound from "./pages/PageNotFound/PageNotFound.tsx";
import PlatformHub from "./pages/PlatformHub/PlatformHub.tsx";
import AutomotiveConfig from "./pages/Playground/Automotive/Config/AutomotiveConfig.tsx";
import ImageToImageConfig from "./pages/Playground/ImageToImage/Config/ImageToImageConfig.tsx";
import Playground from "./pages/Playground/Playground.tsx";
import ProductPlacementConfig from "./pages/Playground/ProductPlacement/Config/ProductPlacementConfig.tsx";
import ProductPlacement from "./pages/Playground/ProductPlacement/ProductPlacement.tsx";
import SandboxAPIConfig from "./pages/Playground/SandboxAPI/Config/SandboxAPIConfig.tsx";
import TextToImageConfig from "./pages/Playground/TextToImage/Config/TextToImageConfig.tsx";
import TgDataset from "./pages/TailoredGeneration/Dataset/TgDatasets.tsx";
import TgModels from "./pages/TailoredGeneration/Models/TgModels.tsx";
import Profile from "./pages/UserProfile/Profile/Profile.tsx";
import UserProfile from "./pages/UserProfile/UserProfile.tsx";
import AttributionReports from "./pages/Account/AttributionReports/AttributionReports.tsx";
import GenerationReport from "./pages/Account/AttributionReports/GenerationReport/GenerationReport.tsx";
import CatalogReport from "./pages/Account/AttributionReports/CatalogReport/CatalogReport.tsx";
import TopImages from "./pages/Account/AttributionReports/TopImages/TopImages.tsx";
import PartnersEarnings from "./pages/Account/AttributionReports/PartnersEarnings/PartnersEarnings.tsx";

let PlatformRoutes = Routes;
if (isCloudEnv()) {
	smartlookClient.init("ec245a49a6bf8b418a2b34e773128025ec739027");
	smartlookClient.record({
		forms: true,
		ips: true,
		numbers: true,
		emails: true,
		api: true,
	});

	Sentry.init({
		dsn: "https://32df6e7b9dab6be875c86509240c9a14@o417868.ingest.sentry.io/4505997423345664",
		integrations: [
			new Sentry.BrowserProfilingIntegration(), // for profiling, "Document-Policy" response header should be set to "js-profiling"
			new Sentry.BrowserTracing({
				routingInstrumentation: Sentry.reactRouterV6Instrumentation(
					useEffect,
					useLocation,
					useNavigationType,
					createRoutesFromChildren,
					matchRoutes,
				),
			}),
			new Sentry.Replay({
				maskAllText: false,
				maskAllInputs: false,
				blockAllMedia: false,
				networkDetailAllowUrls: [window.location.origin, ENV_VARS.VITE_PLATFORM_API_URL ?? ""],
			}),
		],
		debug: isIntegration() || isLocal(),
		environment: getCurrentEnv(),

		tracesSampleRate: 0,
		replaysSessionSampleRate: 0,
		replaysOnErrorSampleRate: 1.0,
	});
	PlatformRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
}

function App() {
	const { uiStore } = useAppStore();
	return (
		<>
			<InitIframe />
			<MobileChecker />
			<PlatformRoutes>
				{iframeStore.isIframe() ? (
					<Route element={<IframeLayout />}>
						{iframeStore.iframe?.config.enabled_pages.textToImage && (
							<Route path={RouterConstants.TEXT_TO_IMAGE.fullPath} element={<Playground />}>
								<Route path="" element={<TextToImageConfig />} />
							</Route>
						)}
						{iframeStore.iframe?.config.enabled_pages.textToVector && (
							<Route path={RouterConstants.TEXT_TO_VECTOR.fullPath} element={<Playground />}>
								<Route path="" element={<TextToImageConfig />} />
							</Route>
						)}
						{iframeStore.iframe?.config.enabled_pages.imageToImage && (
							<Route path={RouterConstants.IMAGE_TO_IMAGE.fullPath} element={<Playground />}>
								<Route path="" element={<ImageToImageConfig />} />
							</Route>
						)}
						{iframeStore.iframe?.config.enabled_pages.campaign && (
							<Route path={RouterConstants.DESIGN_EDITOR.fullPath} element={<BriaDesignEditor />}>
								<Route path="templates/:type" element={<BriaDesignEditor />} />
								<Route path="campaigns/:type" element={<BriaDesignEditor />} />
							</Route>
						)}
					</Route>
				) : (
					<>
						<Route path={RouterConstants.AUTH.path} element={<AuthPage />} />
						<Route element={<AuthLayout ignoreRedirect />}>
							<Route path={RouterConstants.PASSWORD_RESET.path} element={<PasswordResetScreen />} />
						</Route>
						<Route element={<InvitationLayout />}>
							<Route element={<AuthLayout />}>
								<Route path={RouterConstants.LOGIN.path} element={<LoginScreen />} />
								<Route path={RouterConstants.SIGNUP.path} element={<RegisterScreen />} />
								<Route path={RouterConstants.FORGOT_PASSWORD.path} element={<ForgotPasswordScreen />} />
							</Route>
							<Route element={<PrivateLayout ignoreUserChecks />}>
								{/* Authenticated user is required */}
								<Route element={<AuthLayout ignoreRedirect />}>
									<Route path={RouterConstants.USER_INFO.path} element={<UserInfoScreen />} />
									<Route
										path={RouterConstants.ACCOUNT_VERIFICATION.path}
										element={<AccountVerification />}
									/>
									{/*<Route*/}
									{/*	path={RouterConstants.CREATE_ORGANIZATION.path}*/}
									{/*	element={<CreateOrganizationScreen />}*/}
									{/*/>*/}
									{/*<Route*/}
									{/*	path={RouterConstants.SELECT_ORGANIZATION.path}*/}
									{/*	element={<SelectOrganization />}*/}
									{/*/>*/}
								</Route>
							</Route>
							<Route element={<PrivateLayout />}>
								{/* Authenticated user is required */}
								<Route element={<AppLayout />}>
									<Route
										path="/"
										element={
											<Navigate
												to={`${RouterConstants.CONSOLE.path}${window.location.search}`}
												replace={true}
											/>
										}
									/>
									<Route path={RouterConstants.CONSOLE.path}>
										<Route path="" element={<Home />} />
										<Route path={RouterConstants.USER_PROFILE.path} element={<UserProfile />}>
											<Route path={RouterConstants.PROFILE.path} element={<Profile />} />
										</Route>
										<Route path={RouterConstants.MODELS.path} element={<Models />}>
											<Route
												path={RouterConstants.FOUNDATION_MODELS.path}
												element={<BriaModels type={ModelCardType.FOUNDATION_MODEL} />}
											/>
											<Route
												path={RouterConstants.AUXILIARY_MODELS.path}
												element={<BriaModels type={ModelCardType.AUXILIARY_MODEL} />}
											/>
											<Route
												path={RouterConstants.ACCESS_MODELS.path}
												element={<BriaModels type={ModelCardType.ACCESS_MODEL} />}
											/>
										</Route>
										<Route path={RouterConstants.API.path} element={<API />}>
											<Route
												path={RouterConstants.IMAGE_GENERATION_APIS.path}
												element={<BriaAPIs type={APICardType.ImageGenerationAPICards} />}
											/>
											<Route
												path={RouterConstants.IMAGE_EDITING_APIS.path}
												element={<BriaAPIs type={APICardType.ImageEditingAPICards} />}
											/>
											<Route
												path={RouterConstants.PRODUCT_SHOT_APIS.path}
												element={<BriaAPIs type={APICardType.ProductShot} />}
											/>
											<Route
												path={RouterConstants.TAILORED_GEN_APIS.path}
												element={<BriaAPIs type={APICardType.TailoredGenAPICards} />}
											/>
											<Route
												path={RouterConstants.AI_SEARCH_APIS.path}
												element={<BriaAPIs type={APICardType.AISearchAPICards} />}
											/>
											<Route
												path={RouterConstants.VIDEO_EDITING_APIS.path}
												element={<BriaAPIs type={APICardType.VideoEditingAPICards} />}
											/>
											<Route
												path={RouterConstants.CAMPAIGN_APIS.path}
												element={<BriaAPIs type={APICardType.CampaignCards} />}
											/>
										</Route>
										<Route path={`${RouterConstants.API.path}/:subAPI`} element={<Playground />}>
											<Route path="" element={<SandboxAPIConfig />} />
										</Route>
										<Route
											path={`${RouterConstants.API.path}/:subAPI/:model`}
											element={<Playground />}
										>
											<Route path="" element={<SandboxAPIConfig />} />
										</Route>
										<Route path={RouterConstants.IFRAME.path} element={<Iframes />}>
											<Route
												path={RouterConstants.IFRAME_CONFIG.path}
												element={<IframeConfig />}
											/>
										</Route>
										<Route path={RouterConstants.NEW_IFRAMES.path} element={<NewIframes />}>
											<Route
												path={RouterConstants.NEW_IFRAMES_CONFIG.path}
												element={<NewIframeConfig />}
											/>
										</Route>
										<Route path={RouterConstants.ACCOUNT.path} element={<Account />}>
											<Route
												path={RouterConstants.USAGE_BILLING.path}
												element={<UsageAndBilling />}
											/>
											<Route path={RouterConstants.AUTH_KEYS.path} element={<ApiKeys />} />
											<Route path={RouterConstants.USERS.path} element={<Users />} />
											<Route
												path={RouterConstants.RELATED_ORGANIZATIONS.path}
												element={<RelatedOrganizations />}
											/>
											<Route
												path={RouterConstants.SUBSCRIPTION.path}
												element={<Subscription />}
											/>
											<Route path={RouterConstants.AttributionReports.path} element={<AttributionReports />}>
												<Route
													path={RouterConstants.GENERATION_REPORT.path}
													element={<GenerationReport />}
												/>
												<Route
													path={RouterConstants.CATALOG_REPORT.path}
													element={<CatalogReport />}
												/>
												<Route
													path={RouterConstants.TOP_IMAGES.path}
													element={<TopImages />}
												/>
												<Route
													path={RouterConstants.PARTNERS_EARNINGS.path}
													element={<PartnersEarnings />}
												/>
											</Route>
										</Route>
										<Route
											path={RouterConstants.TAILORED_GENERATION.path}
											element={<Navigate to="/" replace />}
										>
											<Route path={RouterConstants.TG_MODELS.path} element={<TgModels />} />
											<Route path={RouterConstants.TG_DATASET.path} element={<TgDataset />} />
											<Route
												path={RouterConstants.TG_CREATE_MODEL.path}
												element={<CreateModel />}
											/>
											<Route
												path={RouterConstants.TG_CREATE_DATASET.path}
												element={<DatasetCreation />}
											/>
											<Route
												path={RouterConstants.TG_PLAYGROUND.fullPath}
												element={<TgPlayground />}
											/>
											<Route path={RouterConstants.DATASET.fullPath} element={<ViewDataset />} />
										</Route>
										<Route
											path={RouterConstants.NEW_TAILORED_GENERATION.path}
											element={<NewTailoredGeneration />}
										>
											<Route index element={<Navigate to={RouterConstants.TG_PROJECTS.path} />} />
											<Route path={RouterConstants.TG_PROJECTS.path} element={<TgProjects />} />
											<Route
												path={RouterConstants.TG_ACCESS_METHODS.path}
												element={<TgAccessMethods />}
											/>
										</Route>
										<Route
											path={RouterConstants.TG_PROJECTS.createPath}
											element={<TgProjectEditor />}
										/>
										<Route
											path={RouterConstants.TG_PROJECTS.editPath}
											element={<TgProjectEditor />}
										/>
										<Route
											path={RouterConstants.TG_PROJECTS.viewPath}
											element={<TgProjectViewer />}
										>
											<Route
												index
												element={<Navigate to={RouterConstants.NEW_TG_MODELS.path} />}
											/>
											<Route
												path={RouterConstants.NEW_TG_MODELS.fullPath}
												element={<TgProjectModels />}
											/>
											<Route
												path={RouterConstants.NEW_TG_DATASETS.fullPath}
												element={<TgProjectDatasets />}
											/>
										</Route>
										<Route
											path={RouterConstants.NEW_TG_MODELS.viewPath}
											element={<TgModelViewer />}
										>
											<Route
												index
												element={<Navigate to={RouterConstants.TG_MODEL_PLAYGROUND.path} />}
											/>
											<Route
												path={RouterConstants.TG_MODEL_PLAYGROUND.fullPath}
												element={<TgModelPlayground />}
											/>
											<Route
												path={RouterConstants.TG_MODEL_DATASET.fullPath}
												element={<TgDatasetViewer embeddedView />}
											/>
										</Route>
										<Route
											path={RouterConstants.NEW_TG_DATASETS.viewPath}
											element={<TgProjectDatasetLayout />}
										>
											<Route index element={<TgDatasetViewer />} />
											<Route
												path={RouterConstants.NEW_TG_DATASETS.editPath}
												element={<EditTgProjectDataset />}
											/>
										</Route>

										<Route
											path={RouterConstants.NEW_TG_MODELS.createFullPath}
											element={<CreateTgProjectModel />}
										/>
										<Route path={RouterConstants.IFRAME_EDITOR.path} element={<IframeEditor />} />
										<Route
											path={RouterConstants.NEW_IFRAMES_EDITOR.fullPath}
											element={<NewIframeEditor />}
										/>
										<Route path={RouterConstants.CAMPAIGN.path} element={<Campaign />}>
											<Route path={RouterConstants.TEMPLATES.path} element={<Templates />} />
											<Route path={RouterConstants.FONTS.path} element={<Fonts />} />
											<Route path={RouterConstants.BRANDS.path} element={<Brands />} />
										</Route>
										<Route
											path={RouterConstants.TEMPLATE_EDITOR.fullPath}
											element={<TemplateEditor />}
										/>
										<Route path={RouterConstants.BRAND_EDITOR.fullPath} element={<BrandEditor />} />
										<Route
											path={RouterConstants.LAYOUT_EDITOR.fullPath}
											element={<LayoutEditor />}
										/>
									</Route>
								</Route>

								<Route
									path="apps/image-to-image"
									element={
										<Navigate
											to={`/${RouterConstants.APPS.path}/${RouterConstants.IMAGE_TO_IMAGE.path}`}
											replace
										/>
									}
								/>

								<Route
									path="apps/text-to-image"
									element={
										<Navigate
											to={`/${RouterConstants.APPS.path}/${RouterConstants.TEXT_TO_IMAGE.path}`}
											replace
										/>
									}
								/>
								{/* Authenticated user is required */}
							</Route>
						</Route>
						<Route element={<HubLayout />}>
							<Route path={RouterConstants.APPS.path}>
								<Route path="" element={<PlatformHub />} />
								<Route element={<PrivateLayout ignoreOrgs />}>
									<Route path={RouterConstants.PRODUCT_PLACEMENT.path} element={<Playground />}>
										<Route path="" element={<ProductPlacementConfig />} />
									</Route>
									<Route
										path={RouterConstants.PRODUCT_PLACEMENT_GALLERY.path}
										element={<ProductPlacement />}
									/>
									<Route path={`:subAPI`} element={<Playground />}>
										<Route path="" element={<SandboxAPIConfig />} />
									</Route>
									<Route path={`:subAPI/:model`} element={<Playground />}>
										<Route path="" element={<SandboxAPIConfig />} />
									</Route>
								</Route>
								<Route element={<PrivateLayout ignoreOrgs displayAppsWelcomePopup />}>
									<Route path={RouterConstants.TEXT_TO_IMAGE.path} element={<Playground />}>
										<Route path="" element={<TextToImageConfig />} />
									</Route>
									<Route path={RouterConstants.TEXT_TO_VECTOR.path} element={<Playground />}>
										<Route path="" element={<TextToImageConfig />} />
									</Route>
									<Route path={RouterConstants.IMAGE_TO_IMAGE.path} element={<Playground />}>
										<Route path="" element={<ImageToImageConfig />} />
									</Route>
									<Route path={RouterConstants.AI_EDITOR.path} element={<AIEditor />} />
									<Route path={RouterConstants.DESIGN_EDITOR.fullPath} element={<BriaDesignEditor />}>
										<Route path="templates/:type" element={<BriaDesignEditor />} />
										<Route path="campaigns/:type" element={<BriaDesignEditor />} />
									</Route>
								</Route>
							</Route>
						</Route>

						<Route element={<FoxLayout />}>
							<Route path={RouterConstants.FOX_APPS.path}>
								<Route path="" element={<FoxHub />} />
								<Route element={<PrivateLayout ignoreOrgs />}>
									<Route path={RouterConstants.TEXT_TO_IMAGE.path} element={<Playground />}>
										<Route path="" element={<TextToImageConfig />} />
									</Route>
									<Route path={RouterConstants.IMAGE_TO_IMAGE.path} element={<Playground />}>
										<Route path="" element={<ImageToImageConfig />} />
									</Route>
									<Route path={RouterConstants.AI_EDITOR.path} element={<AIEditor />} />
								</Route>
							</Route>
						</Route>
						<Route
							path={`${RouterConstants.MOBILE_NOT_SUPPORTED_PAGE.path}`}
							element={<MobileNotSupportedPage />}
						/>
						<Route path={`${RouterConstants.PAGE_NOT_FOUND.path}`} element={<PageNotFound />} />
						<Route
							path="*"
							element={<Navigate to={RouterConstants.PAGE_NOT_FOUND.path} replace={true} />}
						/>
						<Route element={<AutomotiveLayout />}>
							<Route element={<PrivateLayout ignoreOrgs />}>
								<Route path={RouterConstants.AUTOMOTIVE.path}>
									<Route path="" element={<Automotive />} />
								</Route>
								<Route path={RouterConstants.AUTOMOTIVE_EDITOR.fullPath} element={<Playground />}>
									<Route path="" element={<AutomotiveConfig />} />
								</Route>
								<Route path={RouterConstants.NEW_AUTOMOTIVE_PROJECT.fullPath}>
									<Route path="" element={<CreateAutomotiveProject />} />
								</Route>
								<Route path={RouterConstants.AUTOMOTIVE_PROJECTS.fullPath}>
									<Route path="" element={<AutomotiveProjects />} />
								</Route>
								<Route path={RouterConstants.AUTOMOTIVE_PROJECT_UPLOADED.fullPath}>
									<Route path="" element={<AutomotiveProject />} />
								</Route>
								<Route path={RouterConstants.AUTOMOTIVE_PROJECT_FAVORITES.fullPath}>
									<Route path="" element={<AutomotiveProject />} />
								</Route>
								<Route
									path={RouterConstants.AUTOMOTIVE_PROJECT_SESSION.fullPath}
									element={<Playground />}
								>
									<Route path="" element={<AutomotiveConfig />} />
								</Route>
							</Route>
						</Route>
					</>
				)}
			</PlatformRoutes>
			<ToastContainer
				className="toast"
				position="top-center"
				autoClose={3000}
				hideProgressBar
				closeOnClick
				pauseOnHover={false}
				draggable={false}
				icon={false}
			/>
			{uiStore.snackBarProps?.open && (
				<Snackbar
					open={uiStore.snackBarProps?.open}
					autoHideDuration={uiStore.snackBarProps?.autoHideDuration ?? 2000}
					onClose={() => uiStore.hideSnackBarAlert()}
					ContentProps={{ sx: { whiteSpace: "pre-line" } }}
				>
					<Alert
						onClose={() => uiStore.hideSnackBarAlert()}
						severity={uiStore.snackBarProps?.severity}
						sx={{ whiteSpace: "pre-line" }}
					>
						{uiStore.snackBarProps?.message}
					</Alert>
				</Snackbar>
			)}
		</>
	);
}

export default isCloudEnv() ? Sentry.withProfiler(observer(App)) : observer(App);
