import { Box } from "@mui/material";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import FileUploader from "../../../../../../components/common/FileUploader/FileUploader";
import RouterConstants from "../../../../../../constants/RouterConstants";
import useSecureNavigate from "../../../../../../hooks/useSecureNavigate";
import { useAppStore } from "../../../../../../hooks/useStores";
import styles from "../../TgProjectViewer.module.scss";
import { EditTgProjectDatasetLocationState } from "../Edit/EditTgProjectDataset";

const TgProjectDatasetsEmpty = () => {
	const { t } = useTranslation("translation", { keyPrefix: "newTg.datasets.dashboard" });
	const navigate = useSecureNavigate();
	const { projectId } = useParams();
	const { tgStore } = useAppStore();
	const { tgDatasetStore, tgProjectStore } = tgStore;

	const createAndUploadDataset = async (e: ChangeEvent<HTMLInputElement>) => {
		const files: File[] | null = Array.from(e.target.files || []);
		e.target.value = "";

		if (!files.length) return;

		let newDataset;
		// Generate Prefix using the generate prefix API only when IP type is Stylezed scene
		if (tgProjectStore.projectToView.ip_type === "stylized_scene") {
			const generatedPrefix = await tgDatasetStore.imagesSampleGeneratePrefix(tgProjectStore.projectToView, {
				images: files,
			});
			if (!generatedPrefix) return;
			newDataset = await tgDatasetStore.createDataset({
				...tgDatasetStore.datasetForm,
				project_id: Number(projectId),
			});

			newDataset.caption_prefix = generatedPrefix.prefix;
			await tgDatasetStore.updateDataset(newDataset.id, newDataset);
		} else {
			newDataset = await tgDatasetStore.createDataset({
				...tgDatasetStore.datasetForm,
				project_id: Number(projectId),
			});
		}
		tgDatasetStore.datasetForm = newDataset;
		tgDatasetStore.uploadImages(newDataset.id, files);
		const editDatasetState: EditTgProjectDatasetLocationState = {
			datasetToEdit: toJS(tgDatasetStore.datasetForm),
		};
		navigate(
			`${RouterConstants.TG_PROJECTS.fullPath}/${projectId}/${RouterConstants.NEW_TG_DATASETS.path}/${newDataset.id}/edit`,
			{ state: editDatasetState },
		);
	};

	return (
		<Box className={styles.emptyState}>
			<Box>{t("emptyState")}</Box>
			<FileUploader
				buttonText={t("createDataset")}
				buttonType="primaryMedium"
				onUpload={createAndUploadDataset}
				maxFilesLimit={200}
				inputProps={{ accept: "image/png, image/jpg, image/jpeg, image/webp", multiple: true }}
				loading={tgDatasetStore.loadingCreateDataset || tgDatasetStore.loadingGeneratePrefix}
			/>
		</Box>
	);
};

export default observer(TgProjectDatasetsEmpty);
