import { Alert, Box, Divider, Snackbar } from "@mui/material";
import clsx from "clsx";
import i18n from "i18next";
import { observer } from "mobx-react-lite";
import { ChangeEvent, useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import useDesignEditorUtils from "../../../DesignEditor/CustomUtils/UseDesignEditor.tsx";
import { DesignEditorContext } from "../../../DesignEditor/contexts/DesignEditor.tsx";
import { useEditor } from "../../../DesignEditor/hooks/useEditor.tsx";
import useWizardImagesHeader from "../../../DesignEditor/hooks/useWizardImagesHeader.tsx";
import { IScene } from "../../../DesignEditor/types";
import {
	ObjectsEnum,
	SecondaryTabTypeEnum,
} from "../../../DesignEditor/views/DesignEditor/components/Panels/panelItems";
import { useSmartImageUtils } from "../../../DesignEditor/views/DesignEditor/utils/smartImageUtils.ts";
import BulkModeIcon from "../../../assets/images/icons/BulkModeIcon.tsx";
import SingleModeIcon from "../../../assets/images/icons/SingleModeIcon.tsx";
import BriaButton from "../../../components/common/BriaButton/BriaButton";
import ConfirmationPopup from "../../../components/common/ConfirmationPopup/ConfirmationPopup";
import ExportButton from "../../../components/common/ExportButton/ExportButton.tsx";
import FileUploader from "../../../components/common/FileUploader/FileUploader";
import FullScreenViewer from "../../../components/common/FullScreenViewer/FullScreenViewer";
import { FullScreenImageType } from "../../../components/common/FullScreenViewer/Image/FullScreenImage.tsx";
import { BriaImageProps } from "../../../components/common/Galleries/BriaImage.tsx";
import useSelectable from "../../../components/common/Selectable/useSelectable";
import { APPS, IMAGES_FORMATS } from "../../../constants/AppsConstants.ts";
import { useLogDownloadEvents } from "../../../hooks/useLogDownloadEvents.ts";
import { useAppStore } from "../../../hooks/useStores";
import {
	ImageEditingResetBehavior,
	ImageEditingViewMode,
	ImageToImageConfigType,
	PlaygroundImage,
} from "../../../models/image-to-image";
import { IframePostMessageActionTypes, IframePostMessageItemTypes } from "../../../models/new-iframe.ts";
import { ImageModificationsSubAPIType, VideoEditingSubAPIType } from "../../../models/sandboxAPI";
import { TextToImageConfigType } from "../../../models/text-to-image.ts";
import { isAutomotiveApp, isCampaignEditor, isFoxApps } from "../../../utils";
import { supportedImageFileTypes } from "../../../utils/useImageUtils.tsx";
import iframeStore from "../../IframeNew/iframe-store.tsx";
import CodeGeneratorModal from "../SandboxAPI/CodeGeneratorModal/CodeGeneratorModal";
import { SaveImagesLoadingState } from "../store/playground-store.tsx";
import styles from "./PlaygroundActionsHeader.module.scss";

const PlaygroundActionsHeader = () => {
	const { t } = useTranslation("translation", { keyPrefix: "playground.actionsHeader" });
	const {
		playgroundStore,
		sandboxAPIStore,
		imagesStore,
		imageToImageStore,
		designEditorStore,
		authStore,
		textToImageStore,
	} = useAppStore();
	const { logImageDownloadEvents } = useLogDownloadEvents();
	const { selectAll } = useSelectable(playgroundStore.getSelecto());
	const selectedImages = playgroundStore.getSelectedImages();
	const availableImages = playgroundStore.getAvailableImages();
	const [clearWarningPopup, setClearWarningPopup] = useState(false);
	const [saveToMyCampaignLoading, setSaveToMyCampaignLoading] = useState(false);
	const [isViewerOpen, setIsViewerOpen] = useState(false);
	const { addImageObjectToCanvas } = useDesignEditorUtils();
	const { isPopupView, scenes, setCurrentScene, setScenes } = useContext(DesignEditorContext);
	const editor = useEditor();
	const { uploadAndRegisterBackgroundImages, resetRectangleInAGroup, findObjectFromScene } = useSmartImageUtils();
	const { uploadAndRegisterNewWizardImages } = useWizardImagesHeader();
	const fileInputRef = useRef<HTMLInputElement>(null);
	const handleSelectAll = () => {
		selectAll();
		playgroundStore.handleSelectImagesElements(playgroundStore.getSelecto()?.getSelectedTargets());
	};
	const isSingleMode = playgroundStore.imageEditingViewMode === ImageEditingViewMode.SINGLE;
	const centerActions = playgroundStore.selectedConfig !== APPS.SANDBOX_API;
	const isRestInIframeImageToImage =
		iframeStore.isIframe() &&
		playgroundStore.selectedConfig === APPS.IMAGE_TO_IMAGE &&
		iframeStore.iframe.config.image_editing_config?.default_reset_behavior === ImageEditingResetBehavior.RESET;

	const defaultAcceptTypes = supportedImageFileTypes.map((type) => `image/${type}`).join(", ");

	const SaveTheImageToMyAssetsAndAdditToCanvas = async () => {
		await playgroundStore.saveMyImages(SaveImagesLoadingState.SaveAndAddToCanvas);
		for (const imageUrl of playgroundStore.uploadedImagesURLs) {
			addImageObjectToCanvas({
				url: imageUrl,
				id: uuidv4(),
				visual_hash: uuidv4(),
			});
		}
		setTimeout(() => {
			playgroundStore.showSuccessToastSavedImage = false;
			playgroundStore.closeModal();
		}, 1000);
		playgroundStore.uploadedImagesURLs = [];
	};

	const saveTextToImageResultToCampaign = async () => {
		setSaveToMyCampaignLoading(true);
		const selectedImages = playgroundStore.getSelectedImages();
		const firstSelectedImage = selectedImages[0];
		const url = firstSelectedImage.url;
		if (editor) {
			const _currentScene = editor.scene.exportToJSON();
			if (!imagesStore.isLoadingWizardImages) {
				await uploadAndRegisterBackgroundImages({} as ChangeEvent<HTMLInputElement>, url, _currentScene);
				await resetRectangleInAGroup(_currentScene, ObjectsEnum.OuterRectangle);
				const updatedPreview = (await editor?.renderer.render(_currentScene)) as string;
				const updatedScene = { ..._currentScene, preview: updatedPreview, duration: 1000 };
				const updatedScenes = scenes.map((scene: IScene) => {
					if (scene.id === updatedScene.id) {
						return { ...updatedScene, preview: updatedPreview };
					}
					return editor.scene.formalizeSceneAttributes(scene);
				}) as IScene[];
				setScenes(updatedScenes);
				if (isPopupView) designEditorStore.setProperty("isSceneFullyLoaded", false);
				setCurrentScene(updatedScene);
			} else {
				const activeObject = editor?.canvas.canvas.getActiveObject();
				let currentActiveImage = activeObject && (await findObjectFromScene(_currentScene, activeObject.id));
				if (!currentActiveImage) {
					currentActiveImage = designEditorStore.originalActiveObject;
				}
				currentActiveImage &&
					(await uploadAndRegisterNewWizardImages(
						{} as ChangeEvent<HTMLInputElement>,
						url,
						designEditorStore.currentSceneInWizard,
						currentActiveImage,
					));
				imagesStore.setProperty("isLoadingWizardImages", false);
			}
		}

		playgroundStore.showSuccessToastSavedImage = false;
		playgroundStore.closeModal();

		setSaveToMyCampaignLoading(false);
	};

	const hideToastHandler = () => {
		playgroundStore.showSuccessToastSavedImage = false;
	};

	const handleUploadButtonClick = () => {
		fileInputRef.current?.click();
	};

	const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];
		if (file) {
			imageToImageStore.handleUploadImages(e);
		}
	};

	const onDownload = async () => {
		const images = selectedImages.length ? selectedImages : availableImages;
		if (images.length) {
			playgroundStore.exportImages(images);
			const firstImage = images[0];
			const defaultFileType = textToImageStore.isTextToVector() ? IMAGES_FORMATS.SVG : IMAGES_FORMATS.PNG;
			const fileType = (firstImage?.config as TextToImageConfigType)?.format?.toUpperCase() || defaultFileType;
			logImageDownloadEvents(images, fileType);
		}
	};

	const onIframeSave = async () => {
		const images = selectedImages.length ? selectedImages : availableImages;
		if (images.length) {
			iframeStore.sendExportPostMessage(
				IframePostMessageActionTypes.Save,
				IframePostMessageItemTypes.Image,
				images.map((image) => ({
					type: IframePostMessageItemTypes.Image,
					src: image.url,
				})),
			);
		}
	};
	const imageEditingConfig = iframeStore.iframe?.config.image_editing_config;
	const { enable_bulk } = imageEditingConfig ?? {};
	const shouldHideButtonInIframe = iframeStore.isIframe() && !enable_bulk;

	const shouldShowButton =
		(playgroundStore.selectedConfig === APPS.IMAGE_TO_IMAGE ||
			playgroundStore.selectedConfig === APPS.AUTOMOTIVE) &&
		!isFoxApps() &&
		!playgroundStore?.isText2ImagePopupOpened &&
		!shouldHideButtonInIframe;

	return (
		<Box className={clsx(styles.root, { [styles.centerActions]: centerActions })}>
			<Box className={clsx(styles.actionsContainer)}>
				{shouldShowButton && (
					<BriaButton
						className={styles.actionButton}
						buttonType="textSmall"
						startIcon={!isSingleMode ? <SingleModeIcon /> : <BulkModeIcon />}
						onClick={playgroundStore.togglePlaygroundViewMode}
					>
						{!isSingleMode ? t("singleMode") : t("bulkMode")}
					</BriaButton>
				)}

				{!isSingleMode &&
					playgroundStore.selectedConfig !== APPS.PRODUCT_PLACEMENT &&
					!isPopupView &&
					playgroundStore.selectedConfig !== APPS.SANDBOX_API && (
						<BriaButton
							className={clsx(styles.actionButton)}
							buttonType="textSmall"
							onClick={handleSelectAll}
						>
							{t("selectAll")}
						</BriaButton>
					)}
				{!isAutomotiveApp() && (
					<BriaButton
						className={clsx({ [styles.actionButton]: centerActions })}
						buttonType="textSmall"
						onClick={() =>
							(playgroundStore.playgroundResults.length ||
								playgroundStore.textResults.length ||
								playgroundStore.playgroundVideoResults.length) &&
							setClearWarningPopup(true)
						}
					>
						{isRestInIframeImageToImage ? t("reset") : t("clear")}
					</BriaButton>
				)}
				<ConfirmationPopup
					open={clearWarningPopup}
					title={isRestInIframeImageToImage ? t("resetClearWarning.title") : t("clearWarning.title")}
					description={
						isRestInIframeImageToImage ? t("resetClearWarning.description") : t("clearWarning.description")
					}
					confirmButtonText={t("clearWarning.confirmBtn")}
					firstButtonText={t("clearWarning.closeBtn")}
					onClick={() => {
						isRestInIframeImageToImage ? playgroundStore.ResetResults() : playgroundStore.clearResults();
						setClearWarningPopup(false);
						if (playgroundStore.selectedConfig === APPS.IMAGE_TO_IMAGE && !isFoxApps()) {
							imageToImageStore.abortImageGeneration();
						}
					}}
					onClose={() => setClearWarningPopup(false)}
				/>
				{!isSingleMode && (
					<>
						{/* {playgroundStore.selectedConfig !== APPS.PRODUCT_PLACEMENT &&
							playgroundStore.selectedConfig !== APPS.SANDBOX_API &&
							!playgroundStore?.isText2ImagePopupOpened &&
							selectedImages.length > 0 &&
							!isPopupView && (
								<BriaButton
									className={clsx(styles.actionButton)}
									buttonType="textSmall"
									onClick={playgroundStore.openSelected}
								>
									{t("openSelected", {
										countSelected: selectedImages.length,
										countAvailable: availableImages.length,
									})}
								</BriaButton>
							)} */}
						{playgroundStore.selectedConfig !== APPS.SANDBOX_API && selectedImages.length > 0 && (
							<BriaButton
								className={clsx(styles.actionButton)}
								buttonType="textSmall"
								onClick={() => setIsViewerOpen(true)}
							>
								{t("fullScreenView.button")}
							</BriaButton>
						)}
					</>
				)}
				{playgroundStore?.isText2ImagePopupOpened &&
					!isPopupView &&
					imagesStore.secondaryTabType !== SecondaryTabTypeEnum.SMART_IMAGE &&
					!imagesStore.isLoadingWizardImages && (
						<>
							<BriaButton
								buttonType="textSmall"
								onClick={async () => {
									await playgroundStore.saveMyImages(SaveImagesLoadingState.Save);
									playgroundStore.uploadedImagesURLs = [];
								}}
								loading={playgroundStore?.loadingSaveToMyImages}
								className={clsx(styles.actionButton)}
								disabled={selectedImages.length == 0}
							>
								{t("saveToMyAssets")}
							</BriaButton>
							<BriaButton
								buttonType="secondaryMedium"
								onClick={SaveTheImageToMyAssetsAndAdditToCanvas}
								loading={playgroundStore?.loadingSaveToMyImagesAndInsertImageToAd}
								className={clsx(styles.actionButton, styles.secondaryActionBtn)}
								disabled={selectedImages.length == 0}
							>
								{t("insertAndClose")}
							</BriaButton>
						</>
					)}
				{((playgroundStore?.isText2ImagePopupOpened && isPopupView) ||
					imagesStore.isLoadingWizardImages ||
					(playgroundStore?.isText2ImagePopupOpened &&
						imagesStore.secondaryTabType === SecondaryTabTypeEnum.SMART_IMAGE)) && (
					<BriaButton
						buttonType="secondaryMedium"
						onClick={saveTextToImageResultToCampaign}
						loading={saveToMyCampaignLoading}
						className={clsx(styles.actionButton, styles.secondaryActionBtn)}
						disabled={selectedImages.length == 0 || saveToMyCampaignLoading}
					>
						{t("saveToMyCampaign")}
					</BriaButton>
				)}
				{playgroundStore.selectedConfig === APPS.SANDBOX_API && (
					<>
						{(sandboxAPIStore.config.selectedSubAPI === ImageModificationsSubAPIType.Eraser ||
							sandboxAPIStore.config.selectedSubAPI === ImageModificationsSubAPIType.GenFill) &&
							playgroundStore.playgroundResults.length !== 0 && (
								<CodeGeneratorModal playgroundResult={playgroundStore.playgroundResults[0]} />
							)}

						{playgroundStore.playgroundVideoResults.length > 0 &&
							sandboxAPIStore.config.selectedSubAPI === VideoEditingSubAPIType.RemoveVideoBackground && (
								<CodeGeneratorModal playgroundResult={playgroundStore.playgroundVideoResults[0]} />
							)}

						{sandboxAPIStore.hasImageUpload ? (
							<FileUploader
								buttonText={t("uploadOneImage")}
								buttonType="secondaryMedium"
								onUpload={sandboxAPIStore.handleUploadImages}
								inputProps={{ accept: "image/png, image/jpeg, image/jpg", multiple: false }}
								buttonProps={{ className: styles.uploadBtn }}
								maxFilesLimit={sandboxAPIStore.MAX_FILES_LIMIT}
								maxFileSize={sandboxAPIStore.MAX_FILE_SIZE}
								disabled={sandboxAPIStore.isUploadingImage || sandboxAPIStore.isLoading}
							/>
						) : (
							sandboxAPIStore.hasVideoUpload && (
								<FileUploader
									buttonText={t("uploadVideo")}
									buttonType="secondaryMedium"
									onUpload={(e) => {
										const file: File | null = e.target.files && e.target.files[0];
										if (file) {
											sandboxAPIStore.handleUploadVideo(file, 10);
										}
									}}
									inputProps={{
										accept: "video/mp4, video/quicktime, video/avi, video/gif, video/webm",
										multiple: false,
									}}
									buttonProps={{ className: styles.uploadBtn }}
									disabled={sandboxAPIStore.isUploadingVideo || sandboxAPIStore.isLoading}
								/>
							)
						)}
					</>
				)}
				{!isCampaignEditor &&
					playgroundStore.selectedConfig !== APPS.SANDBOX_API &&
					playgroundStore.selectedConfig !== APPS.PRODUCT_PLACEMENT && (
						<Divider orientation="vertical" variant="middle" flexItem className={styles.divider} />
					)}
				{(playgroundStore.selectedConfig === APPS.IMAGE_TO_IMAGE ||
					playgroundStore.selectedConfig === APPS.AUTOMOTIVE) &&
					(!iframeStore.isIframe() || iframeStore.iframe.config.general_config?.enable_upload) && (
						<>
							<BriaButton
								className={styles.actionButton}
								buttonType="textSmall"
								onClick={handleUploadButtonClick}
								loading={authStore.isLoadingOrgSubscriptions}
								disabled={
									authStore.isLoadingOrgSubscriptions ||
									authStore.orgPassedFreeLimit ||
									authStore.isSubscriptionInactive
								}
							>
								{t("upload")}
							</BriaButton>
							<input
								ref={fileInputRef}
								type="file"
								accept={defaultAcceptTypes}
								style={{ display: "none" }}
								onChange={handleFileUpload}
								multiple
							/>
						</>
					)}
				{!isCampaignEditor &&
					(playgroundStore.selectedConfig === APPS.IMAGE_TO_IMAGE ||
						playgroundStore.selectedConfig === APPS.AUTOMOTIVE ||
						playgroundStore.selectedConfig === APPS.TEXT_TO_IMAGE) && (
						<ExportButton
							onDownload={onDownload}
							onIframeSave={onIframeSave}
							buttonType="secondaryMedium"
							className={clsx(styles.actionButton, styles.secondaryActionBtn)}
						/>
					)}
			</Box>
			<Snackbar
				autoHideDuration={2000}
				onClose={hideToastHandler}
				open={playgroundStore.showSuccessToastSavedImage}
				className={styles.saveSuccessToast}
			>
				<Alert onClose={hideToastHandler} severity="success">
					{t("saveImageSuccess")}
				</Alert>
			</Snackbar>
			{isViewerOpen && (
				<FullScreenViewer
					open={isViewerOpen}
					images={playgroundStore.getSelectedImages().map((image) => ({
						src: image.url,
						fileName: playgroundStore.getFileName(image),
						compareTo: (image.config as ImageToImageConfigType)?.original_image?.url,
						compareLabel:
							isFoxApps() && image.type === "imageToImage"
								? `${i18n.t("playground.imageToImage.result.gallery.variationLabel", {
										variationNum: image.variationNum?.toString().padStart(2, "0"),
								  })}`
								: undefined,
					}))}
					onClose={() => setIsViewerOpen(false)}
					displayMode="scroll"
					onDownload={(fullScreenImages: FullScreenImageType[]) =>
						playgroundStore.onFullScreenViewerDownload(
							playgroundStore.getSelectedImages() as (BriaImageProps & PlaygroundImage)[],
							fullScreenImages,
						)
					}
				/>
			)}
		</Box>
	);
};

export default observer(PlaygroundActionsHeader);
