import { Box, TextField } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import BriaButton from "../../../../components/common/BriaButton/BriaButton";
import { useAppStore } from "../../../../hooks/useStores.tsx";
import InputLayout from "../../../../layout/InputLayout/InputLayout";
import styles from "./SuggestAccessMethod.module.scss";

type SuggestAccessMethodProps = {
	className?: string;
	onSuccess?: () => void;
};

const SuggestAccessMethod = ({ className, onSuccess }: SuggestAccessMethodProps) => {
	const { t } = useTranslation("translation", { keyPrefix: "newTg.suggestAccessMethod" });
	const { appStore, uiStore } = useAppStore();
	const [suggestMessage, setSuggestMessage] = useState("");
	const [error, setError] = useState("");

	const handleOnClick = async () => {
		if (suggestMessage.length > 0) {
			await appStore.suggestAccessMethod(suggestMessage);
			uiStore.showSnackBarAlert("success", t("success"), 5000);
			onSuccess?.();
			setSuggestMessage("");
			setError("");
		} else {
			setError(t("error"));
		}
	};

	return (
		<Box className={clsx({ className, [styles.root]: true })}>
			<InputLayout className={styles.inputText} label={t("description")} labelClassName={styles.description}>
				<TextField
					value={suggestMessage}
					placeholder={t("placeholder")}
					multiline
					minRows={3}
					className={clsx({
						[styles.errorField]: !!error,
					})}
					onChange={(e) => setSuggestMessage(e.target?.value)}
					InputProps={{
						classes: {
							root: styles.bigTextField,
						},
					}}
				/>
				{error && <Box className={styles.error}>{error}</Box>}
			</InputLayout>
			<BriaButton
				className={styles.send}
				buttonType="primaryMedium"
				onClick={handleOnClick}
				loading={appStore.isSendSuggestMethodLoading}
				disabled={appStore.isSendSuggestMethodLoading}
			>
				{t(`send`)}
			</BriaButton>
		</Box>
	);
};

export default observer(SuggestAccessMethod);
