import { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import BriaButton from "../../../../components/common/BriaButton/BriaButton";
import styles from "./CampaignJsonImportButton.module.scss";
interface CampaignJsonImportButtonProps {
	onImport: (data: any) => void;
	isLoading: boolean;
}

const CampaignJsonImportButton: React.FC<CampaignJsonImportButtonProps> = ({ onImport, isLoading }) => {
	const inputFileRef = useRef<HTMLInputElement>(null);
	const { t } = useTranslation("translation", { keyPrefix: "editor.header" });

	const handleImportTemplate = useCallback(
		async (data: any) => {
			onImport(data);
		},
		[onImport],
	);

	const handleInputFileRefClick = () => {
		inputFileRef.current?.click();
	};

	const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];
		if (file) {
			const reader = new FileReader();
			reader.onload = (res) => {
				try {
					const result = res.target?.result as string;
					const design = JSON.parse(result);
					handleImportTemplate(design);
				} catch (error) {
					console.error("Error parsing imported file:", error);
				}
			};
			reader.onerror = (err) => console.error("File reading error:", err);
			reader.readAsText(file);
		}
	};

	return (
		<>
			<input
				className={styles.hiddenInput}
				type="file"
				ref={inputFileRef}
				multiple={false}
				onChange={handleFileInput}
			/>
			<BriaButton
				disabled={isLoading}
				loading={isLoading}
				onClick={handleInputFileRefClick}
				className={styles.btn}
				buttonType="textMedium"
			>
				{t("import")}
			</BriaButton>
		</>
	);
};

export default CampaignJsonImportButton;
