import { Box, Grid, Link, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import aiImageSearch from "../../assets/images/pngs/Hub.png";
import imageToImage from "../../assets/images/pngs/Image-to-image.png";
import briaLogo from "../../assets/images/pngs/bria-logo.png";
import productPlacement from "../../assets/images/pngs/product_placement.png";
import textToImageCardBg from "../../assets/images/pngs/text-to-image-card.png";
import textToVectorCardBg from "../../assets/images/svgs/text-to-vector-card.svg";
import HubCardsComponent from "../../components/PlatformHub/HubCardsComponent/HubCardsComponent";
import PlatformHubSection from "../../components/PlatformHub/PlatformHubSection/PlatformHubSection.tsx";
import SandboxAPIs from "../../components/PlatformHub/SandboxAPIs/SandboxAPIs.tsx";
import BriaButton from "../../components/common/BriaButton/BriaButton";
import GalleryHub from "../../components/common/GalleryHub/GalleryHub";
import HubImageOverlay from "../../components/common/HubImageOverlay/HubImageOverlay";
import LoadingPlaceholder from "../../components/common/LoadingPlaceholder/LoadingPlaceholder";
import { HomeCard, galleryExampleImages, galleryExampleImagesColumns } from "../../constants/HubGalleryImagesConstants";
import LinkConstants from "../../constants/LinkConstants";
import RouterConstants from "../../constants/RouterConstants";
import useSecureNavigate from "../../hooks/useSecureNavigate.tsx";
import { isBriaDemosOrg, isMccannOrg, isPublicisOrg, isWppPocOrg } from "../../utils";
import styles from "./PlatformHub.module.scss";

const PlatformHub = () => {
	const [numberOfLoadedImages, setNumberOfLoadedImages] = useState(0);
	const navigate = useSecureNavigate();
	const { t } = useTranslation("translation", { keyPrefix: "platformHub" });

	const cardsList: HomeCard[] = [
		{
			title: "Cards.textToImage.title",
			description: "Cards.textToImage.description",
			image: textToImageCardBg,
			onClick: () => navigate(RouterConstants.TEXT_TO_IMAGE.path),
		},
		{
			title: "Cards.imageToImage.title",
			description: "Cards.imageToImage.description",
			image: imageToImage,
			onClick: () => navigate(RouterConstants.IMAGE_TO_IMAGE.path),
		},
		{
			title: "Cards.textToVector.title",
			description: "Cards.textToVector.description",
			image: textToVectorCardBg,
			onClick: () => navigate(RouterConstants.TEXT_TO_VECTOR.path),
		},
		{
			title: "Cards.aIImageSearch.title",
			description: "Cards.aIImageSearch.description",
			image: aiImageSearch,
			onClick: () => window.open(LinkConstants.AI_IMAGE_SEARCH, "_blank"),
		},
	];

	if (isMccannOrg() || isBriaDemosOrg() || isWppPocOrg() || isPublicisOrg()) {
		cardsList.unshift({
			title: "Cards.productPlacement.title",
			description: "Cards.productPlacement.description",
			image: productPlacement,
			onClick: () => navigate(RouterConstants.PRODUCT_PLACEMENT_GALLERY.path),
		});
	}

	return (
		<>
			<Box className={styles.container}>
				<Typography className={styles.platformHubTitle}> {t("title")}</Typography>
				<LoadingPlaceholder
					className={styles.loaderStyle}
					isLoading={numberOfLoadedImages < cardsList.length}
					alwaysRenderChildren={true}
				>
					<PlatformHubSection
						title={t("appsSection.title")}
						subtitle={t("appsSection.subtitle")}
						className={styles.appsSection}
					>
						<Box className={styles.cardsContainer}>
							<Grid container className={styles.gridContainer}>
								{cardsList.map((card: HomeCard) => (
									<HubCardsComponent
										title={t(card.title)}
										description={t(card.description)}
										image={card.image}
										onClick={card.onClick}
										onImageLoad={() => setNumberOfLoadedImages((prevCount) => prevCount + 1)}
									/>
								))}
							</Grid>
						</Box>
					</PlatformHubSection>
					<PlatformHubSection
						title={t("sandboxAPI.title")}
						subtitle={t("sandboxAPI.subtitle")}
						className={styles.sandboxSection}
					>
						<SandboxAPIs />
					</PlatformHubSection>

					<PlatformHubSection title={t("endlessCreationsDoneonBRIA")} className={styles.hubGallerySection}>
						<Box className={styles.Hubgallery}>
							<GalleryHub
								buildStaticGallery={true}
								galleryExampleImagesColumns={galleryExampleImagesColumns.map((column) =>
									column.map((image) => ({
										...image,
										ImageOverlay: <HubImageOverlay {...{ image }} />,
									})),
								)}
								galleryImages={galleryExampleImages.map((image) => ({
									ImageOverlay: <HubImageOverlay {...{ image }} />,
									...image,
								}))}
							/>
						</Box>
					</PlatformHubSection>

					<Box className={styles.buttonContainer}>
						<BriaButton
							className={styles.primaryButtonStyle}
							onClick={() => navigate(RouterConstants.TEXT_TO_IMAGE.path)}
							buttonType="primary"
						>
							{t("StartgeneratingOnBria")}
						</BriaButton>
					</Box>
					<Box className={styles.platformHubFooter}>
						<Box className={styles.termsAndPrivacy}>
							<Typography>
								<Link
									className={styles.subLink}
									href={LinkConstants.PRIVACY_POLICY}
									target="_blank"
									rel="noopener noreferrer"
								>
									{t("privacyPolicy")}
								</Link>
							</Typography>
							<Typography>
								<Link
									className={styles.subLink}
									href={LinkConstants.LEGAL_LOBBY}
									target="_blank"
									rel="noopener noreferrer"
								>
									{t("termsOfUse")}
								</Link>
							</Typography>
						</Box>

						<Box className={styles.BriaIcon}>
							<img src={briaLogo} />
						</Box>
						<Box className={styles.allRightsSection}>
							<Typography>{t("allRightsReservedToBRIA")}</Typography>
						</Box>
					</Box>
				</LoadingPlaceholder>
			</Box>
		</>
	);
};

export default observer(PlatformHub);
