import { Box, Typography } from "@mui/material";
import i18next from "i18next";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useParams } from "react-router-dom";
import BriaTooltip from "../../../../components/common/BriaTooltip/BriaTooltip.tsx";
import FileUploader from "../../../../components/common/FileUploader/FileUploader";
import LoadingPlaceholder from "../../../../components/common/LoadingPlaceholder/LoadingPlaceholder";
import RouterConstants from "../../../../constants/RouterConstants";
import useSecureNavigate from "../../../../hooks/useSecureNavigate";
import { useAppStore } from "../../../../hooks/useStores";
import TabsLayout from "../../../../layout/TabsLayout/TabsLayout";
import BestPracticesPopup from "../../Models/Popups/BestPractices/BestPracticesPopup.tsx";
import { TgProject, defaultProject } from "../store/tg-projects-store";
import { EditTgProjectDatasetLocationState } from "./Datasets/Edit/EditTgProjectDataset";
import styles from "./TgProjectViewer.module.scss";

export type ViewTgProjectLocationState = {
	projectToView?: TgProject;
};

const TgProjectViewer = () => {
	const { t } = useTranslation("translation", { keyPrefix: "newTg.projects.viewer" });
	const navigate = useSecureNavigate();
	const location = useLocation();
	const { projectId } = useParams();
	const { uiStore, tgStore } = useAppStore();
	const [openBestPracticesPopup, setOpenBestPracticesPopup] = useState(false);
	const { tgProjectStore, tgDatasetStore } = tgStore;
	const pathSegments = location.pathname.split("/");
	const selectedTab = pathSegments[pathSegments.length - 1];
	const projectHeaderFields: (keyof TgProject)[] = useMemo(
		() => ["ip_medium", "ip_type", "ip_name", "ip_description"],
		[],
	);

	const subTabs = [RouterConstants.NEW_TG_MODELS.path, RouterConstants.NEW_TG_DATASETS.path];

	useEffect(() => {
		uiStore.hideSideBar();
		uiStore.showBackButton(RouterConstants.NEW_TAILORED_GENERATION.fullPath, undefined, t("backButton"));

		tgProjectStore.projectToView = location.state?.projectToView || defaultProject;

		(async () => {
			try {
				if (!tgProjectStore.projectToView.id && projectId) {
					const project = await tgProjectStore.getProject(Number(projectId));
					tgProjectStore.projectToView = project;
				}
			} catch (err: any) {
				if (err.response?.status === 404) {
					navigate(RouterConstants.NEW_TAILORED_GENERATION.fullPath);
				}
			}
		})();

		return () => {
			uiStore.showSideBar();
			uiStore.hideBackButton();
			window.history.replaceState({}, "");
		};
	}, []);

	const primaryButtonClick = () => {
		switch (selectedTab) {
			case RouterConstants.NEW_TG_MODELS.path:
				return navigate(
					`${RouterConstants.TG_PROJECTS.fullPath}/${projectId}/${RouterConstants.NEW_TG_MODELS.createPath}`,
				);
		}
	};

	const secondaryButtonClickHandler = () => {
		setOpenBestPracticesPopup(true);
	};

	const onCloseBestPracticesPopup = () => {
		setOpenBestPracticesPopup(false);
	};

	const createAndUploadDataset = async (e: ChangeEvent<HTMLInputElement>) => {
		const files: File[] | null = Array.from(e.target.files || []);
		e.target.value = "";

		if (!files.length) return;

		let newDataset;
		// Generate Prefix using the generate prefix API only when IP type is Stylezed scene
		if (tgProjectStore.projectToView.ip_type === "stylized_scene") {
			const generatedPrefix = await tgDatasetStore.imagesSampleGeneratePrefix(tgProjectStore.projectToView, {
				images: files,
			});
			if (!generatedPrefix) return;
			newDataset = await tgDatasetStore.createDataset({
				...tgDatasetStore.datasetForm,
				project_id: Number(projectId),
			});

			newDataset.caption_prefix = generatedPrefix.prefix;
			await tgDatasetStore.updateDataset(newDataset.id, newDataset);
		} else {
			newDataset = await tgDatasetStore.createDataset({
				...tgDatasetStore.datasetForm,
				project_id: Number(projectId),
			});
		}

		tgDatasetStore.datasetForm = newDataset;
		tgDatasetStore.uploadImages(newDataset.id, files);
		const editDatasetState: EditTgProjectDatasetLocationState = {
			datasetToEdit: toJS(tgDatasetStore.datasetForm),
		};
		navigate(
			`${RouterConstants.TG_PROJECTS.fullPath}/${projectId}/${RouterConstants.NEW_TG_DATASETS.path}/${newDataset.id}/edit`,
			{ state: editDatasetState },
		);
	};

	const getPrimaryButton = () => {
		switch (selectedTab) {
			case RouterConstants.NEW_TG_MODELS.path:
				return t(`primaryButton.${selectedTab}`);
			case RouterConstants.NEW_TG_DATASETS.path:
				return (
					<FileUploader
						buttonText={t(`primaryButton.${selectedTab}`)}
						buttonType="primaryMedium"
						onUpload={createAndUploadDataset}
						maxFilesLimit={200}
						inputProps={{ accept: "image/png, image/jpg, image/jpeg, image/webp", multiple: true }}
						loading={tgDatasetStore.loadingCreateDataset || tgDatasetStore.loadingGeneratePrefix}
					/>
				);
		}
	};

	return (
		<TabsLayout
			className={styles.tabsLayout}
			title={
				<LoadingPlaceholder
					className={`${styles.loadingPlaceholder} ${styles.title}`}
					isLoading={tgProjectStore.loadingProject}
					size={28}
				>
					<BriaTooltip
						className={styles.briaToolTip}
						displayOn="click"
						title={tgProjectStore.projectToView?.project_name || ""}
					>
						<Typography className={styles.title}>
							{tgProjectStore.projectToView?.project_name
								? tgProjectStore.projectToView.project_name.length > 70
									? `${tgProjectStore.projectToView.project_name.slice(0, 70)}...`
									: tgProjectStore.projectToView.project_name
								: ""}
						</Typography>
					</BriaTooltip>

					<Box className={styles.fields}>
						{projectHeaderFields.map((field) => {
							const ipType = tgProjectStore.projectToView.ip_type;
							const shouldRenderField =
								field !== "ip_name" && field !== "ip_description"
									? true
									: (field === "ip_name" && ipType === "defined_character") ||
									  (field === "ip_description" &&
											(ipType === "defined_character" || ipType === "object_variants"));

							if (!shouldRenderField || !tgProjectStore.projectToView[field]) return null;

							return (
								<Box className={styles.field}>
									<Typography className={styles.fieldKey}>
										{field === "ip_name" || field === "ip_description"
											? i18next.t(`newTg.projects.editor.ipType.types.${ipType}.${field}.label`)
											: t(`title.${field}`)}
										:
									</Typography>

									{field === "ip_description" || field === "ip_name" ? (
										<BriaTooltip
											className={styles.briaToolTip}
											displayOn="click"
											title={tgProjectStore.projectToView[field]}
										>
											<Typography className={styles.fieldValue}>
												{typeof tgProjectStore.projectToView[field] === "string" &&
												(tgProjectStore.projectToView[field] as string).length > 15
													? `${(tgProjectStore.projectToView[field] as string).slice(
															0,
															15,
													  )}...`
													: tgProjectStore.projectToView[field] || ""}
											</Typography>
										</BriaTooltip>
									) : (
										<Typography className={styles.fieldValue}>
											{field === "ip_type"
												? i18next.t(
														`newTg.projects.editor.ipType.types.${tgProjectStore.projectToView[field]}.name`,
												  )
												: tgProjectStore.projectToView[field]?.toString()}
										</Typography>
									)}
								</Box>
							);
						})}
					</Box>
				</LoadingPlaceholder>
			}
			subTabs={subTabs}
			isLoading={tgProjectStore.loadingProject}
			primaryButtonLabel={getPrimaryButton()}
			primaryButtonClickHandler={primaryButtonClick}
			secondaryButtonLabel={t(`secondaryButton.${selectedTab}`)}
			secondaryButtonClickHandler={secondaryButtonClickHandler}
		>
			<Outlet />
			<BestPracticesPopup open={openBestPracticesPopup} onClose={onCloseBestPracticesPopup} />
		</TabsLayout>
	);
};

export default observer(TgProjectViewer);
