import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import howitWorks from "../../../../../assets/images/pngs/ImageReferenceGallery/portraitHowItWorks.png";
import { SubMenuHeader } from "../../../../../components/common/SubMenuHeader/SubMenuHeader";
import { IMAGE_REFERENCE_TYPES } from "../ImageReference/ImageReference";
import GalleryItem from "./GalleryItem";
import styles from "./ImageReferenceGallery.module.scss";
type ImageReferenceGalleryProps = {
	onClose?: () => void;
	activeTab: string;
	imageTabs: {
		name: string;
		title: string;
		description?: string;
		subTitle: string;
		images: { title: string; description?: string; images: { url: string; prompt: string; influence: number }[] }[];
	}[];
};

const ImageReferenceGallery = ({ onClose, activeTab, imageTabs }: ImageReferenceGalleryProps) => {
	const panelRef = useRef<HTMLDivElement>(null);
	const { t } = useTranslation("translation", {
		keyPrefix: "playground.textToImage.config.imageReference",
	});
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			const target = event.target as Node;

			if (panelRef.current && !panelRef.current.contains(target)) {
				onClose?.();
			}
		};

		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [onClose]);

	const activeTabData = imageTabs.find((tab) => tab.name === activeTab);

	return (
		<Box className={styles.ImageReferenceGalleryContainer} ref={panelRef}>
			<SubMenuHeader
				title={activeTabData?.title || ""}
				subTitle={activeTabData?.subTitle || ""}
				noBorder
				onClose={onClose}
			/>
			<Box>
				{activeTabData?.images.map((item, index) => (
					<GalleryItem
						key={index}
						title={item.title}
						description={item.description}
						images={item.images}
						onClose={onClose}
						activeTab={activeTab}
					/>
				))}
			</Box>
			{activeTab === IMAGE_REFERENCE_TYPES.PORTRAIT && (
				<Box>
					<Typography className={styles.howItWorksTitle}>{t("howitWorks.portrait.title")}</Typography>
					<Typography className={styles.howItWorksDescription}>
						{t("howitWorks.portrait.description")}
					</Typography>
					<img src={howitWorks} alt="" />
				</Box>
			)}
		</Box>
	);
};

export default observer(ImageReferenceGallery);
