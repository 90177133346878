import { makeAutoObservable, runInAction } from "mobx";
import { FilterByOptions } from "../../../../components/common/BriaTable/BriaTable";
import { PaginatedItems } from "../../../../models/common";
import QueryService from "../../../../utils/QueryService";
import { TgDataset } from "../../Datasets/store/tg-dataset-store";
import { TgModel } from "../../Models/store/tg-model-store";
import { ITgStore } from "../../store/new-tg-stores";

export interface ITgProjectStore {
	paginatedProjects: PaginatedItems<TgProject>;
	rowsPerPage: number;
	projectForm: TgProject;
	formErrors: {
		save?: boolean;
		requiredFields: { [key in keyof Partial<TgProject>]: boolean };
	};
	projectToView: TgProject;

	paginatedModels: PaginatedItems<TgModel>;
	paginatedDatasets: PaginatedItems<TgDataset>;

	loadingOrgProjects: boolean;
	loadingProject: boolean;
	loadingCreateProject: boolean;
	loadingUpdateProject: boolean;
	loadingDeleteProject: boolean;

	loadingProjectModels: boolean;
	loadingProjectDatasets: boolean;

	handleFormChange: <K extends keyof TgProject>(key: K, value: TgProject[K]) => void;
	getOrgProjects: (page?: number, filterBy?: FilterByOptions<TgProject>) => Promise<PaginatedItems<TgProject>>;
	getProject: (id: number) => Promise<TgProject>;
	createProject: (projectToCreate: TgProject) => Promise<TgProject>;
	updateProject: (id: number, updatedProject: TgProject) => Promise<TgProject>;
	deleteProject: (id: number) => Promise<void>;

	getModels: (
		projectId: number,
		page?: number,
		filterBy?: FilterByOptions<TgModel>,
	) => Promise<PaginatedItems<TgModel>>;
	getDatasets: (
		projectId: number,
		page?: number,
		filterBy?: FilterByOptions<TgProject>,
	) => Promise<PaginatedItems<TgDataset>>;
}

export class TgProjectStore implements ITgProjectStore {
	private queryService: QueryService = new QueryService("/new-tailored-generation/projects");
	tgStore: ITgStore;

	paginatedProjects: PaginatedItems<TgProject> = { items: [], total: 0 };
	rowsPerPage: number = 12;

	projectForm: TgProject = defaultProject;
	formErrors: {
		save?: boolean;
		requiredFields: { [key in keyof Partial<TgProject>]: boolean };
	} = {
		requiredFields: { ip_description: false, ip_name: false },
	};
	projectToView: TgProject = defaultProject;

	paginatedModels: PaginatedItems<TgModel> = { items: [], total: 0 };
	paginatedDatasets: PaginatedItems<TgDataset> = { items: [], total: 0 };

	loadingOrgProjects: boolean = false;
	loadingProject: boolean = false;
	loadingCreateProject: boolean = false;
	loadingUpdateProject: boolean = false;
	loadingDeleteProject: boolean = false;

	loadingProjectModels: boolean = false;
	loadingProjectDatasets: boolean = false;

	constructor(tgStore: ITgStore) {
		makeAutoObservable(this);
		this.tgStore = tgStore;
	}

	handleFormChange = <K extends keyof TgProject>(key: K, value: TgProject[K]) => {
		this.projectForm = { ...this.projectForm, [key]: value };
	};

	getOrgProjects = async (page?: number, filterBy?: FilterByOptions<TgProject>) => {
		try {
			this.loadingOrgProjects = true;
			const paginatedProjects: PaginatedItems<TgProject> = await this.queryService.get("/", {
				params: {
					filter_by: filterBy,
					page: page,
					per_page: this.rowsPerPage,
				},
			});

			runInAction(() => {
				this.paginatedProjects = paginatedProjects;
				this.loadingOrgProjects = false;
			});

			return this.paginatedProjects;
		} catch (err: any) {
			this.loadingOrgProjects = false;
			return Promise.reject(err);
		}
	};

	getProject = async (id: number) => {
		try {
			this.loadingProject = true;
			const project = await this.queryService.get(`/${id}`);

			runInAction(() => {
				this.loadingProject = false;
			});

			return project;
		} catch (err: any) {
			this.loadingProject = false;
			return Promise.reject(err);
		}
	};

	createProject = async (projectToCreate: TgProject) => {
		try {
			this.loadingCreateProject = true;
			const newProject = await this.queryService.post(`/`, projectToCreate);

			runInAction(() => {
				this.loadingCreateProject = false;
			});

			return newProject;
		} catch (err: any) {
			this.loadingCreateProject = false;

			return Promise.reject(err);
		}
	};

	updateProject = async (id: number, projectToUpdate: TgProject) => {
		try {
			this.loadingUpdateProject = true;
			const updatedProject = await this.queryService.put(`/${id}`, projectToUpdate);

			runInAction(() => {
				this.paginatedProjects.items = this.paginatedProjects?.items.map((project) =>
					project.id === id ? updatedProject : project,
				);
				this.loadingUpdateProject = false;
			});
			return updatedProject;
		} catch (err: any) {
			this.loadingUpdateProject = false;
			return Promise.reject(err);
		}
	};

	deleteProject = async (id: number) => {
		try {
			this.loadingDeleteProject = true;
			await this.queryService.delete(`/${id}`);

			runInAction(() => {
				this.paginatedProjects = {
					items: this.paginatedProjects.items.filter((project) => project.id !== id),
					total: this.paginatedProjects.total - 1,
				};
				this.loadingDeleteProject = false;
			});
		} catch (err: any) {
			this.loadingDeleteProject = false;
			const parsed = JSON.parse(err.response.data.message);
			const message = parsed.detail.trim();

			return Promise.reject(message);
		}
	};

	getModels = async (projectId: number, page?: number, filterBy?: FilterByOptions<TgModel>) => {
		try {
			this.loadingProjectModels = true;
			const paginatedModels: PaginatedItems<TgModel> = await this.queryService.get(`/${projectId}/models`, {
				params: {
					filter_by: filterBy,
					page: page,
					per_page: this.rowsPerPage,
				},
			});

			runInAction(() => {
				this.paginatedModels = paginatedModels;
				this.loadingProjectModels = false;
			});

			return this.paginatedModels;
		} catch (err: any) {
			this.loadingProjectModels = false;
			return Promise.reject(err);
		}
	};

	getDatasets = async (projectId: number, page?: number, filterBy?: FilterByOptions<TgProject>) => {
		try {
			this.loadingProjectDatasets = true;
			const paginatedDatasets: PaginatedItems<TgDataset> = await this.queryService.get(`/${projectId}/datasets`, {
				params: {
					filter_by: filterBy,
					page: page,
					per_page: this.rowsPerPage,
				},
			});

			if (!this.paginatedModels.items.length) {
				await this.getModels(projectId);
			}

			if (this.paginatedModels.items.length) {
				paginatedDatasets.items?.forEach((dataset) => {
					dataset.models = [];
					this.paginatedModels.items?.forEach((model) => {
						if (model.dataset_id === dataset.id) {
							dataset.models?.push(model);
						}
					});
				});
			}

			runInAction(() => {
				this.paginatedDatasets = paginatedDatasets;
				this.loadingProjectDatasets = false;
			});

			return this.paginatedDatasets;
		} catch (err: any) {
			this.loadingProjectDatasets = false;
			return Promise.reject(err);
		}
	};
}

export type TgIpMediums = "illustration" | "photography";

export type TgProject = {
	id: number;
	project_name: string;
	project_description?: string;
	ip_medium: TgIpMediums;
	ip_type: TgIpType;
	ip_name?: string;
	ip_description?: string;
	status: TgProjectStatus;
	created_at: string;
	updated_at: string;
	models: number[];
};

export type TgIpType =
	| "multi_object_set"
	| "object_variants"
	| "icons"
	| "defined_character"
	| "character_variants"
	| "stylized_scene";

export type TgProjectStatus = "Active" | "Deleted";

export const defaultProject: TgProject = {
	id: NaN,
	project_name: "",
	project_description: "",
	ip_medium: "illustration",
} as TgProject;
