import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import BriaButton from "../../../components/common/BriaButton/BriaButton";
import BriaTooltip from "../BriaTooltip/BriaTooltip";
import styles from "./ProjectCard.module.scss";

interface CardField {
	key: string;
	value: string;
}

interface ProjectCardProps {
	name?: string;
	description?: string;
	cardFields?: CardField[];
	onSecondaryAction?: () => void;
	secondaryButtonLabel?: string;
	onPrimaryAction?: () => void;
	primaryButtonLabel?: string;
	actionsPopover: React.ReactNode;
	className?: string;
}

const ProjectCard: React.FC<ProjectCardProps> = ({
	name,
	description,
	cardFields,
	onSecondaryAction,
	secondaryButtonLabel,
	onPrimaryAction,
	primaryButtonLabel,
	actionsPopover,
	className,
}) => {
	return (
		<Box className={clsx(styles.projectCard, className)}>
			{actionsPopover}
			<Box className={styles.cardDetails}>
				<BriaTooltip className={styles.briaToolTip} displayOn="click" title={name}>
					<Typography className={styles.cardName}>
						{name && name?.length > 50 ? `${name?.substring(0, 25)}...` : name}
					</Typography>
				</BriaTooltip>
				<BriaTooltip className={styles.briaToolTip} displayOn="click" title={description}>
					<Typography className={styles.cardDesc}>
						{description && description?.length > 50 ? `${description?.substring(0, 50)}...` : description}
					</Typography>
				</BriaTooltip>
				{cardFields && (
					<Box className={styles.cardFields}>
						{cardFields.map((field) => (
							<Box key={field.key} className={styles.cardField}>
								<Typography className={styles.fieldKey}>{field.key}</Typography>
								<Typography className={styles.fieldValue}>{field.value}</Typography>
							</Box>
						))}
					</Box>
				)}
			</Box>

			<Box className={styles.actionsButtons}>
				{secondaryButtonLabel && (
					<BriaButton className={styles.cardButton} buttonType="secondaryMedium" onClick={onSecondaryAction}>
						{secondaryButtonLabel}
					</BriaButton>
				)}

				{primaryButtonLabel && (
					<BriaButton className={styles.cardButton} buttonType="primaryMedium" onClick={onPrimaryAction}>
						{primaryButtonLabel}
					</BriaButton>
				)}
			</Box>
		</Box>
	);
};

export default observer(ProjectCard);
