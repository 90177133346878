const ResetLayer = () => (
	<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M17.1735 9.2076C16.9697 7.74112 16.2894 6.38232 15.2374 5.34051C14.1854 4.29871 12.82 3.6317 11.3516 3.44223C9.8832 3.25276 8.39323 3.55134 7.11121 4.29197C5.82919 5.03261 4.82625 6.17421 4.25688 7.54093M3.84021 4.2076V7.54093H7.17354M3.84021 10.8743C4.04401 12.3408 4.72432 13.6996 5.77634 14.7414C6.82837 15.7832 8.19375 16.4502 9.66215 16.6396C11.1306 16.8291 12.6205 16.5305 13.9025 15.7899C15.1846 15.0493 16.1875 13.9077 16.7569 12.5409M17.1735 15.8743V12.5409H13.8402"
			stroke="#FAFAFA"
			strokeOpacity="0.85"
			strokeWidth="1.25"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default ResetLayer;
