import { makeAutoObservable, runInAction } from "mobx";
import QueryService from "../../../utils/QueryService";
import { IPlaygroundStore } from "./playground-store";

export interface IPlaygroundResultsStore {
	loadingResult: boolean;
	loadingCreateResult: boolean;
	loadingUpdateResult: boolean;
	loadingDeleteResult: boolean;

	getResult: (id: string) => Promise<PlaygroundResult>;
	createResult: (resultToCreate: PlaygroundResult) => Promise<PlaygroundResult>;
	updateResult: (id: string, updatedResult: PlaygroundResult) => Promise<PlaygroundResult>;
	deleteResult: (id: string) => Promise<void>;
}

export class PlaygroundResultsStore implements IPlaygroundResultsStore {
	private queryService: QueryService = new QueryService("/playground-results");
	playgroundStore: IPlaygroundStore;

	loadingOrgResults: boolean = false;
	loadingResult: boolean = false;
	loadingCreateResult: boolean = false;
	loadingUpdateResult: boolean = false;
	loadingDeleteResult: boolean = false;

	loadingResultModels: boolean = false;
	loadingResultDatasets: boolean = false;
	selectedResult: PlaygroundResult | null = null;

	constructor(playgroundStore: IPlaygroundStore) {
		makeAutoObservable(this);
		this.playgroundStore = playgroundStore;
	}

	getResult = async (id: string) => {
		try {
			this.loadingResult = true;
			const result = await this.queryService.get(`/${id}`);

			runInAction(() => {
				this.loadingResult = false;
			});

			return result;
		} catch (err) {
			runInAction(() => {
				this.loadingResult = false;
			});
			return Promise.reject(err);
		}
	};

	createResult = async (resultToCreate: PlaygroundResult) => {
		try {
			this.loadingCreateResult = true;
			const newResult = await this.queryService.post(`/`, resultToCreate);

			runInAction(() => {
				this.loadingCreateResult = false;
			});

			return newResult;
		} catch (err: any) {
			this.loadingCreateResult = false;

			return Promise.reject(err);
		}
	};

	updateResult = async (id: string, resultToUpdate: PlaygroundResult) => {
		try {
			this.loadingUpdateResult = true;
			const updatedResult = await this.queryService.put(`/`, resultToUpdate);

			runInAction(() => {
				this.loadingUpdateResult = false;
				this.playgroundStore.playgroundResults = this.playgroundStore.playgroundResults.map((result) =>
					result.id === id ? updatedResult : result,
				);
			});
			return updatedResult;
		} catch (err: any) {
			this.loadingUpdateResult = false;
			return Promise.reject(err);
		}
	};

	deleteResult = async (id: string) => {
		try {
			this.loadingDeleteResult = true;
			await this.queryService.delete(`/${id}`);

			runInAction(() => {
				this.loadingDeleteResult = false;
			});
		} catch (err: any) {
			this.loadingDeleteResult = false;
			const parsed = JSON.parse(err.response.data.message);
			const message = parsed.detail.trim();

			return Promise.reject(message);
		}
	};
}

export type PlaygroundResult = {
	id: string;
	name: string;
	created_at?: string;
	updated_at?: string;
	project_id: string;
	results?: PlaygroundResult[];
};

export const defaultResult: PlaygroundResult = {
	id: "",
	name: "",
} as PlaygroundResult;
