import i18n from "i18next";
import BriaButton, { ButtonTypes } from "../BriaButton/BriaButton";

export type SurpriseTextsType = "textToImage" | "textToImage2" | "textToImage31" | "textToVector" | "replaceBg" | "presentersStyle";

export const getRandomText = (textsListType: SurpriseTextsType) => {
	const textList: string[] = Object.values(i18n.t(`surpriseMe.${textsListType}`, { returnObjects: true }));
	const randomNumber = Math.floor(Math.random() * textList.length);
	return textList[randomNumber];
};

type Props = {
	textsListType: SurpriseTextsType;
	onSurprise: (surpriseText: string) => void;
	buttonType?: ButtonTypes;
	buttonLabel?: string;
};

const SurpriseButton = ({
	textsListType,
	onSurprise,
	buttonType = "textSmall",
	buttonLabel = i18n.t("surpriseMe.label"),
}: Props) => {
	return (
		<BriaButton buttonType={buttonType} onClick={() => onSurprise(getRandomText(textsListType))}>
			{buttonLabel}
		</BriaButton>
	);
};

export default SurpriseButton;
