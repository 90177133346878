import { AxiosError } from "axios";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { getModelKey } from "../../components/common/DropDowns/TextToImageModelsDropdown/TextToImageModelsDropdown.tsx";
import { APPS } from "../../constants/AppsConstants.ts";
import { USER_ORGANIZATION_ROLES } from "../../constants/UserConstants.ts";
import { setSelectedOrganization } from "../../helpers/localStorage";
import { useAuthService } from "../../hooks/useAuthService";
import { useBriaTheme } from "../../hooks/useBriaTheme";
import { useIframe } from "../../hooks/useIframe";
import { useAppStore } from "../../hooks/useStores";
import { ImageEditingViewMode } from "../../models/image-to-image.ts";
import { Iframe, IframePostMessageTypes } from "../../models/new-iframe";
import { UserOrganization } from "../../models/organization";
import { modelTypes } from "../../models/sandboxAPI.ts";
import iframeStore from "../../pages/IframeNew/iframe-store.tsx";

function InitIframe() {
	const { textToImageStore, campaignStore, playgroundStore, imageToImageStore, authStore } = useAppStore();
	const { getIframeId, authenticateIframe, navigateToLandingPage, getPassedImageUrl } = useIframe();
	const { convertUnderscoreToCssVar, setCssVariableValue } = useBriaTheme();
	const { loginAsGuest } = useAuthService();

	const handlePreviewMessage = async (event: MessageEvent) => {
		const { type, message, sessionId } = JSON.parse(event.data);
		if (type === IframePostMessageTypes.InitPreview && message && sessionId === iframeStore.iframeSessionId) {
			const { iframe, fb_organization, orgSubscription } = message;
			authStore.orgSubscription = orgSubscription;
			await updateIframe({ ...iframe, id: "preview" }, fb_organization);
			navigateToLandingPage();
		}
	};

	useEffect(() => {
		const getIframeAnonymous = async (iframeId: string) => {
			try {
				if (!authStore.user) {
					await loginAsGuest();
				}
				return await iframeStore.getIframeAnonymous(iframeId);
			} catch (error: any) {
				if (error instanceof AxiosError && (error as AxiosError).response?.status === 403) {
					// in case we have different iframes loaded in the same browser
					await loginAsGuest();
					return await iframeStore.getIframeAnonymous(iframeId);
				}
			}
		};

		const initIframe = async () => {
			const iframeId = getIframeId();
			if (!iframeId) return;

			if (iframeStore.isPreview()) {
				window.addEventListener("message", handlePreviewMessage);
				iframeStore.sendPostMessage(IframePostMessageTypes.InitialLoadComplete);
			} else {
				const passedImageUrl = getPassedImageUrl();
				if (passedImageUrl) {
					playgroundStore.playgroundResults = [
						{
							id: uuidv4(),
							type: APPS.IMAGE_TO_IMAGE,
							config: imageToImageStore.config,
							images: [
								{
									id: uuidv4(),
									url: "",
									config: imageToImageStore.config,
									type: APPS.IMAGE_TO_IMAGE,
									loading: true,
									selected: true,
								},
							],
						},
					];
				}

				let res = await getIframeAnonymous(iframeId);
				if (res && iframeStore.isCampaignApi()) {
					const queryParams = new URLSearchParams(window.location.search);
					res.fb_organization = {
						organization: { uid: queryParams.get("organizationId") ?? "", name: "" },
						role: USER_ORGANIZATION_ROLES.ADMIN,
					};
				}
				res && (await updateIframe(res.iframe, res.fb_organization));
				navigateToLandingPage();

				if (
					passedImageUrl &&
					playgroundStore.playgroundResults.length > 0 &&
					playgroundStore.playgroundResults[0].images.length > 0
				) {
					playgroundStore.playgroundResults[0].images[0] = {
						...playgroundStore.playgroundResults[0].images[0],
						url: passedImageUrl,
					};
				}
			}
			return () => {
				window.removeEventListener("message", handlePreviewMessage);
			};
		};
		if (authStore.isInitialized && !iframeStore.isInitialized && !iframeStore.isIframeConfigLoaded) {
			initIframe();
		}
	}, [authStore.isInitialized, iframeStore.isInitialized, iframeStore.isIframeConfigLoaded]);

	const updateIframe = async (iframe: Iframe, fb_organization: UserOrganization) => {
		iframeStore.iframe = iframe;
		updateTheme();
		setSelectedOrganization(fb_organization);
		await Promise.all([authenticateIframe(iframe), initConfigs()]);
		iframeStore.setProperty("isIframeConfigLoaded", true);
	};

	const initConfigs = async () => {
		return Promise.all([initImageGenerationConfig(), initImageEditingConfig(), initCampaignConfig()]);
	};

	const initImageGenerationConfig = async () => {
		const defaultAspectRatio = iframeStore.iframe?.config.image_generation_config?.default_aspect_ratio;
		defaultAspectRatio && textToImageStore.handleConfigChange("aspect_ratio", defaultAspectRatio);
		handleLoadedEnabledFoundationMdels();
	};

	const initImageEditingConfig = async () => {
		playgroundStore.imageEditingViewMode =
			iframeStore.iframe?.config.image_editing_config?.default_view_mode ?? ImageEditingViewMode.GALLERY;
	};

	const initCampaignConfig = async () => {
		campaignStore.handleAdminModeChange(!!iframeStore.iframe?.config.campaign_config?.enable_admin_mode);
	};

	// Checks if "bria 3.1" exists in the enabled foundation models within the iframe.
	// If not found, selects the first available model as the default.
	const handleLoadedEnabledFoundationMdels = () => {
		const defaultBriaModel = "base_3.1";
		const enabledModels = iframeStore.iframe.config.image_generation_config?.enabled_foundation_models;
		const currentModel = textToImageStore.config.model + "_" + textToImageStore.config.model_version;
		// we are chekcing the current default model Bria 3.1
		if (iframeStore.isIframe() && currentModel === defaultBriaModel && enabledModels) {
			let foundDefaultBriaModel = false;
			let firstModel = null;

			const sortedModelKeys = Object.keys(enabledModels).sort();

			for (const model of sortedModelKeys) {
				const sortedVersions = Object.keys(enabledModels[model]).sort();

				for (const version of sortedVersions) {
					const displayName = enabledModels[model][version];

					if (`${model}_${version}` === defaultBriaModel) {
						foundDefaultBriaModel = true;
						break;
					}

					if (!firstModel) {
						firstModel = { key: model, version, value: displayName };
					}
				}

				if (foundDefaultBriaModel) break;
			}

			if (!foundDefaultBriaModel && firstModel) {
				const { key: model, version: modelVersion, value: modelName } = firstModel;
				const modelId = getModelKey(model, modelVersion);
				textToImageStore.handleConfigChange("model_id", modelId);
				textToImageStore.handleConfigChange("model_name", modelName);
				textToImageStore.handleConfigChange("model", model);
				textToImageStore.handleConfigChange("model_version", modelVersion);
				if (firstModel.value.toLowerCase().includes("fast")) {
					textToImageStore.handleConfigChange("negative_prompt", undefined);
					textToImageStore.hideNegativePrompt = true;
				}
				textToImageStore.handleConfigChange("fast", model === modelTypes.Fast);
			}
		}
	};

	const updateTheme = () => {
		const style = iframeStore.iframe?.config.custom_style;
		Object.entries(style?.cssVars ?? {}).forEach(([cssKey, value]) =>
			setCssVariableValue(convertUnderscoreToCssVar(cssKey), value),
		);
	};
	return null;
}

export default observer(InitIframe);
