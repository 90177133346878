import { BriaAxios } from "../config/axios.tsx";
import { ICrmEvent } from "../models/crmEvents.ts";

const BASE_URL = "/crm";

class CrmService {
	logEvents = async (crmEvents: ICrmEvent[]) => {
		const response = await (await BriaAxios()).post(`${BASE_URL}/event/`, crmEvents);
		return response.data;
	};

	upsertContact = async () => {
		const response = await (await BriaAxios()).post(`${BASE_URL}/contact/`);
		return response.data;
	};
}

export default CrmService;
