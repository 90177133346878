import { Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import FileUploader from "../../../../components/common/FileUploader/FileUploader";
import { useAttributionReports } from "../../../../hooks/useAttributionReports.tsx";
import { useAppStore } from "../../../../hooks/useStores.tsx";
import TableLayout, { TableRow } from "../../../../layout/TableLayout/TableLayout.tsx";
import { IPartnerEarningTableRow, IPartnerEarnings } from "../../../../models/attribution-reports.ts";
import { showErrorToast } from "../../../../utils/toast.tsx";
import { toJS } from "mobx";

const PartnersEarnings = () => {
	const { t } = useTranslation("translation", { keyPrefix: "account.attributionReports" });
	const { authStore, attributionReportsStore } = useAppStore();
	const { createPartnersEarningsDataTableRow } = useAttributionReports();
	const isMounted = useRef<boolean>(false);
	const [partnersEarningsTableData, setPartnersEarningsTableData] = useState<TableRow<IPartnerEarningTableRow>[]>([]);
	const tableHeaders = t("tables.partnersEarnings.headers", { returnObjects: true }) as string[];

	useEffect(() => {
		const loadData = async () => {
			if (!attributionReportsStore.generationReportData.length || isMounted.current) {
				try {
					await attributionReportsStore.loadPartnersEarningsData();
				} catch (e: any) {
					showErrorToast(t("pleaseTryAgainLater"));
				}
			}
			setPartnersEarningsTableData(
				attributionReportsStore.partnersEarningsData.map((data: IPartnerEarnings) =>
					createPartnersEarningsDataTableRow(data),
				),
			);
		};
		loadData();
	}, [JSON.stringify(toJS(attributionReportsStore.selectedDateRange)), attributionReportsStore.partnersEarningsPPGFile]);

	useEffect(() => {
		isMounted.current = true;
		attributionReportsStore.setSelectedTab(t("tabs.partnersEarnings"));
	}, []);

	return authStore.user?.isSuperAdmin() ? (
		<>
			<TableLayout
				hideHeader={true}
				headerArray={tableHeaders}
				tableData={partnersEarningsTableData}
				loading={attributionReportsStore.isPartnersEarningsDataLoading}
				emptyStateComponent={
					<>
						{attributionReportsStore.partnersEarningsPPGFile && (
							<Typography>{t("tables.partnersEarnings.emptyState")}</Typography>
						)}
						<FileUploader
							inputProps={{ accept: ".csv,text/csv" }}
							buttonType="primaryMedium"
							buttonText={t("uploadFinancials")}
							onUpload={(e) =>
								(attributionReportsStore.partnersEarningsPPGFile = e.target.files && e.target.files[0])
							}
							loading={attributionReportsStore.isPartnersEarningsDataLoading}
						/>
					</>
				}
			/>
		</>
	) : null;
};

export default observer(PartnersEarnings);
