import { Close } from "@mui/icons-material";
import { Box, Dialog, IconButton, TextField, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import BriaButton from "../../../../components/common/BriaButton/BriaButton.tsx";
import RouterConstants from "../../../../constants/RouterConstants.ts";
import { useAppStore } from "../../../../hooks/useStores.tsx";
import InputLayout from "../../../../layout/InputLayout/InputLayout.tsx";
import { PlaygroundResult } from "../../../../models/playground.ts";
import { PlaygroundProject } from "../../Projects/store/playground-projects-store.tsx";
import styles from "./AddSessionDialog.module.scss";

type Props = {
	project: PlaygroundProject;
};

const AddSessionDialog = ({ project }: Props) => {
	const navigate = useNavigate();
	const { uiStore, playgroundStore } = useAppStore();
	const { sessionsStore, projectsStore } = playgroundStore;
	const { t } = useTranslation("translation", { keyPrefix: "automotiveApp.project" });
	const { t: tc } = useTranslation();

	useEffect(() => {
		sessionsStore.clearSessionForm();
	}, []);

	const createNewSession = async () => {
		const newSessionId = await sessionsStore.createSession({
			...sessionsStore.sessionForm,
			project_id: project.id,
		});

		if (projectsStore.selectedImages.length > 0) {
			const result: Partial<PlaygroundResult> = {
				images: projectsStore.selectedImages,
				type: "upload",
				config: projectsStore.selectedImages[0].config,
			};
			await projectsStore.createPlaygroundResult(result, newSessionId);
		}

		uiStore.hideDialog("AddSessionDialog");
		navigate(`${RouterConstants.AUTOMOTIVE_PROJECT.path}/${project.id}/session/${newSessionId}`);
	};

	return (
		<Box className={styles.form}>
			<Dialog onClose={() => uiStore.hideDialog("AddSessionDialog")} open={uiStore.AddSessionDialog}>
				<Box className={styles.dialogContent}>
					<IconButton onClick={() => uiStore.hideDialog("AddSessionDialog")} className={styles.closeButton}>
						<Close />
					</IconButton>
					<Typography variant="h6" className={styles.title}>
						{t("createNewSession")}
					</Typography>
					<InputLayout label={t("sessionName")}>
						<TextField
							value={sessionsStore.sessionForm.name}
							onChange={(e) => sessionsStore.handleFormChange("name", e.target.value)}
							placeholder={t("sessionName")}
							fullWidth
							InputProps={{ classes: { root: styles.textField } }}
						/>
					</InputLayout>
					<Box className={styles.dialogActions}>
						<BriaButton
							onClick={() => uiStore.hideDialog("AddSessionDialog")}
							className={styles.cancelBtn}
							buttonType="tertiaryMedium"
						>
							{tc("cancel")}
						</BriaButton>
						<BriaButton
							buttonType="primaryMedium"
							className={styles.button}
							onClick={createNewSession}
							loading={sessionsStore.loadingCreateSession}
						>
							{t("create")}
						</BriaButton>
					</Box>
				</Box>
			</Dialog>
		</Box>
	);
};

export default observer(AddSessionDialog);
