import { Close } from "@mui/icons-material";
import { Box, Dialog, IconButton, TextField, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import BriaButton from "../../../../../components/common/BriaButton/BriaButton.tsx";
import { useAppStore } from "../../../../../hooks/useStores.tsx";
import InputLayout from "../../../../../layout/InputLayout/InputLayout.tsx";
import styles from "./EditProjectDialog.module.scss";
import { PlaygroundProject } from "../../store/playground-projects-store.tsx";

type Props = {
	open: boolean;
	onClose: () => void;
};

const EditProjectDialog = ({ open, onClose }: Props) => {
	const { playgroundStore } = useAppStore();
	const { projectsStore } = playgroundStore;
	const { t } = useTranslation("translation", { keyPrefix: "automotiveApp" });
	const { t: tc } = useTranslation();
	const projectForm = projectsStore.project;

	const editProject = async () => {
		const { sessions , ...updatedProject} = projectForm as PlaygroundProject;
		await projectsStore.updateProject(projectForm.id, updatedProject);
		onClose();
	};

	return (
		<Dialog open={open} onClose={onClose}>
			<Box className={styles.dialogContent}>
				<IconButton onClick={onClose} className={styles.closeButton}>
					<Close />
				</IconButton>
				<Typography variant="h6" className={styles.title}>
					{t("updateDetails")}
				</Typography>
				<InputLayout label={t("newProject.projectName")}>
					<TextField
						value={projectForm.name}
						onChange={(e) => projectsStore.handleFormChange("name", e.target.value)}
						placeholder={t("newProject.projectName")}
						fullWidth
						InputProps={{ classes: { root: styles.textField } }}
					/>
				</InputLayout>
				<InputLayout label={t("newProject.projectDescription")}>
					<TextField
						value={projectForm.description}
						onChange={(e) => projectsStore.handleFormChange("description", e.target.value)}
						placeholder={t("newProject.projectDescription")}
						fullWidth
						multiline
						minRows={3}
						InputProps={{
							classes: {
								root: styles.bigTextField,
							},
						}}
					/>
				</InputLayout>

				<Box className={styles.dialogActions}>
					<BriaButton onClick={onClose} className={styles.cancelBtn} buttonType="tertiaryMedium">
						{tc("cancel")}
					</BriaButton>
					<BriaButton
						buttonType="primaryMedium"
						className={styles.button}
						onClick={editProject}
						loading={projectsStore.loadingUpdateProject}
					>
						{t("edit")}
					</BriaButton>
				</Box>
			</Box>
		</Dialog>
	);
};

export default observer(EditProjectDialog);
