const OpacityIcon = () => (
	<svg width="25" height="25" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M8.09533 7.49777H11.0715M8.09533 9.28348H10.4763M8.09533 11.0692H8.69056M8.09533 5.71205H10.4763M8.09533 3.92634H8.69056M12.8572 7.49777C12.8572 10.4564 10.4588 12.8549 7.50009 12.8549C4.54142 12.8549 2.14294 10.4564 2.14294 7.49777C2.14294 4.5391 4.54142 2.14062 7.50009 2.14062C10.4588 2.14062 12.8572 4.5391 12.8572 7.49777Z"
			stroke="#FAFAFA"
			strokeOpacity="0.85"
			strokeWidth="1.25"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default OpacityIcon;
