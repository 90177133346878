import { debounce } from "@mui/material";
import type { PostData } from "har-format";
import { throttle } from "lodash";
import { makeAutoObservable } from "mobx";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { IGroupOption } from "../../../../components/common/CustomSingleSelectDropdown/SingleSelectGroupedDropDown";
import { SANDBOX_API_BASE_URL } from "../../../../constants/AppsConstants.ts";
import { BrushActions } from "../../../../hooks/brush/useBrushActions.tsx";
import { imageDimensions } from "../../../../models/image-to-image.ts";
import {
	APIParameter,
	ImageGenerationsSubAPIType,
	ImageModificationsSubAPIType,
	SandboxAPIType,
	guidanceMethods,
} from "../../../../models/sandboxAPI.ts";
import { ISandboxAPIStore } from "../../../../pages/Playground/SandboxAPI/sandbox-api-store.tsx";
import { truncateToTwoDecimals } from "../../../../utils";
import useImageUtils from "../../../../utils/useImageUtils.tsx";
import { validateField } from "../../../../utils/validators";
import { IPlaygroundStore } from "../../store/playground-store.tsx";

export interface ISandboxUtils {
	sandboxAPIStore: ISandboxAPIStore;
	setPayload?: Dispatch<SetStateAction<Record<string, any>>>;
	getFieldByName: (name: string) => APIParameter | undefined;
	getFieldById: (id: string) => APIParameter | undefined;
	setStepsNumField: (defaultValue: number, min: number, max: number) => void;
	setNumOfResultsField: (numOfResults: number) => void;
	handleColorChange: (field: APIParameter, value: string) => void;
	handleInputWithPrefixChange: (field: APIParameter, value: string) => void;
	handleIntArrayChange: (field: APIParameter, index: number, value: number | null) => void;
	handlePlacementType: (placement: string) => void;
	handleShadowType: (type: string) => void;
	handleGenerationMode: (mode: boolean) => void;
	validateRefImage: (name: string, value: string) => void;
	handleUploadImage: (e: ChangeEvent<HTMLInputElement>, name: string) => Promise<void>;
	handleDeleteImage: (name: string) => Promise<void>;
	handleButtonClick: (action: string) => Promise<void>;
	handleSecondaryButtonClick: (action: string) => void;
	viewCode: (payload: Record<string, any>) => void;
}

export enum PlacementTypeEnum {
	Original = "original",
	Automatic = "automatic",
	ManualPlacement = "manual_placement",
	ManualPadding = "manual_padding",
}

export enum ShadowTypeEnum {
	Regular = "regular",
	Float = "float",
}

export class SandboxUtils implements ISandboxUtils {
	sandboxAPIStore: ISandboxAPIStore;
	playgroundStore: IPlaygroundStore;
	setPayload?: Dispatch<SetStateAction<Record<string, any>>>;
	payload?: Record<string, any>;
	imageUtils = useImageUtils();

	constructor(
		sandboxAPIStore: ISandboxAPIStore,
		playgroundStore: IPlaygroundStore,
		payload?: Record<string, any>,
		setPayload?: Dispatch<SetStateAction<Record<string, any>>>,
	) {
		this.sandboxAPIStore = sandboxAPIStore;
		this.playgroundStore = playgroundStore;
		this.payload = payload;
		this.setPayload = setPayload;
		makeAutoObservable(this);
	}

	public getFieldByName = (name: string) =>
		this.sandboxAPIStore.config.apiConfig?.parameters?.find((field) => field.name === name);

	public getFieldById = (id: string) =>
		this.sandboxAPIStore.config.apiConfig?.parameters?.find((field) => field.id === id);

	public setStepsNumField = (defaultValue: number, min: number, max: number) => {
		const stepsNumField = this.getFieldByName("steps_num");
		if (stepsNumField) {
			stepsNumField.defaultValue = defaultValue;
			stepsNumField.min = min;
			stepsNumField.max = max;
			stepsNumField.labeledMarks = [
				{ value: min, label: String(min) },
				{ value: max, label: String(max) },
			];
		}
		this.setPayload?.((prevPayload) => ({ ...prevPayload, steps_num: defaultValue }));
	};

	public setNumOfResultsField = (numOfResults: number) => {
		const numOfResultsField = this.getFieldByName("num_results");
		if (numOfResultsField) {
			numOfResultsField.defaultValue = numOfResults;
		}
		this.setPayload?.((prevPayload) => ({ ...prevPayload, num_results: numOfResults }));
	};

	public handleExpandImageApiDimensionsUpdate = throttle((index?: number, value?: number) => {
		const originalImageSize = this.getFieldByName("original_image_size");
		if (!originalImageSize) return;

		const isConstAspectRatio = this.payload?.["constrain_proportions"];
		const imageUrl = this.playgroundStore.getSelectedImages()?.[0]?.url;
		const cachedDimensions = this.playgroundStore.getSelectedImages()[0]?.dimensions;

		if (isConstAspectRatio) {
			const updatePayload = (dimensions: imageDimensions) => {
				this.setPayload?.((prevPayload) => {
					if (index !== undefined && value !== undefined) {
						if (index === 0) {
							prevPayload["original_image_size"][0] = value;
							prevPayload["original_image_size"][1] = truncateToTwoDecimals(
								value / dimensions.numerical_aspect_ratio,
							);
						} else {
							prevPayload["original_image_size"][0] = truncateToTwoDecimals(
								value * dimensions.numerical_aspect_ratio,
							);
							prevPayload["original_image_size"][1] = value;
						}
						return { ...prevPayload };
					} else {
						prevPayload["original_image_size"][0] = dimensions.width;
						prevPayload["original_image_size"][1] = dimensions.height;
						return { ...prevPayload };
					}
				});
			};

			if (cachedDimensions) {
				updatePayload(cachedDimensions);
			} else if (imageUrl) {
				const img = new Image();
				img.onload = () => {
					const dimensions = {
						width: img.width,
						height: img.height,
						numerical_aspect_ratio: img.width / img.height,
					};
					this.playgroundStore.getSelectedImages()[0].dimensions = dimensions;
					updatePayload(dimensions);
				};
				img.src = imageUrl;
			} else if (value !== undefined) {
				updatePayload({
					width: value,
					height: value,
					numerical_aspect_ratio: 1,
				});
			}
		} else if (index !== undefined && value !== undefined) {
			this.setPayload?.((prevPayload) => {
				const updatedPayload = { ...prevPayload };
				updatedPayload[originalImageSize.name][index] = value;
				return updatedPayload;
			});
		}
	}, 200);

	public handleIntArrayChange = (field: APIParameter, index: number, value: number | null) => {
		if (
			value !== null &&
			this.sandboxAPIStore.config.apiConfig?.id === "IMG_MODIFICATIONS_API_3" &&
			field.name === "original_image_size"
		) {
			this.handleExpandImageApiDimensionsUpdate(index, value);
		} else {
			this.setPayload?.((prevPayload) => {
				// To initiate the updated inputs that were hidden
				if (prevPayload[field.name] === undefined) {
					prevPayload[field.name] = field.defaultValue;
				}
				prevPayload[field.name][index] = value;
				return { ...prevPayload };
			});
		}

		debounce(() => {
			const validationSchema = field.validationSchema;
			if (validationSchema) {
				this.payload?.[field.name].some((fieldValue: number | null) => {
					field.validationMessage = validateField(validationSchema, fieldValue);
					return !!field.validationMessage;
				});
			}
		}, 200)();
	};

	public handleColorChange = (field: APIParameter, value: string) => {
		if (field.validationSchema) {
			field.validationMessage = validateField(field.validationSchema, value);
		}

		this.setPayload?.((prevPayload) => ({
			...prevPayload,
			[field.name]: value,
		}));
	};

	public handleInputWithPrefixChange = (field: APIParameter, value: string) => {
		this.setPayload?.((prevPayload) => ({
			...prevPayload,
			[field.name]: value,
		}));

		if (field.validationSchema) {
			field.validationMessage = validateField(field.validationSchema, value, {
				name: `${field.name}`,
				...this.payload,
			});
		}
	};

	public handlePlacementType = (placement: string) => {
		const manualPlacement = this.getFieldByName("manual_placement_selection");
		const paddingValues = this.getFieldByName("padding_values");
		const numResults = this.getFieldByName("num_results");
		const shotSize = this.getFieldByName("shot_size");
		const originalQuality = this.getFieldByName("original_quality");
		if (!manualPlacement || !paddingValues || !numResults || !shotSize || !originalQuality) return;
		shotSize.hidden = placement === PlacementTypeEnum.Original || placement === PlacementTypeEnum.ManualPadding;
		numResults.hidden =
			placement === PlacementTypeEnum.Automatic || placement === PlacementTypeEnum.ManualPlacement;
		manualPlacement.hidden = placement !== PlacementTypeEnum.ManualPlacement;
		paddingValues.hidden = placement !== PlacementTypeEnum.ManualPadding;
		originalQuality.hidden = placement !== PlacementTypeEnum.Original;
	};

	public handleShadowType = (type: string) => {
		const shadowWidth = this.getFieldByName("shadow_width");
		const shadowHeight = this.getFieldByName("shadow_height");
		if (!shadowWidth || !shadowHeight) return;

		switch (type) {
			case ShadowTypeEnum.Regular:
				shadowWidth.hidden = true;
				shadowHeight.hidden = true;
				break;
			case ShadowTypeEnum.Float:
				shadowWidth.hidden = false;
				shadowHeight.hidden = false;
				break;
		}
	};

	public handleGenerationMode = (mode: boolean) => {
		const negativePromptField = this.getFieldByName("negative_prompt");
		const excludeElementsField = this.getFieldByName("exclude_elements");

		negativePromptField && (negativePromptField.hidden = mode);
		excludeElementsField && (excludeElementsField.hidden = mode);
	};

	public validateRefImage = (name: string, value: string) => {
		const field = this.getFieldByName(name);
		if (field && field.validationSchema) {
			field.validationMessage = validateField(field.validationSchema, value, {
				name: `${field.name}`,
				...this.payload,
			});

			//On ref_image_file change clear bg_prompt errors
			const bgPromptField = this.getFieldByName("bg_prompt");
			if (bgPromptField?.validationSchema) {
				bgPromptField.validationMessage = "";
			}
		}
	};

	public handleUploadImage = async (e: ChangeEvent<HTMLInputElement>, name: string) => {
		const file = e.target.files?.[0];
		e.target.value = "";
		if (file) {
			const image_file = (await this.imageUtils.getBase64(file)) as string;
			this.setPayload?.((prevPayload) => ({
				...prevPayload,
				[name]: image_file,
			}));

			this.validateRefImage(name, image_file);
		}
	};

	public handleDeleteImage = async (name: string) => {
		this.setPayload?.((prevPayload) => ({
			...prevPayload,
			[name]: null,
		}));

		this.validateRefImage(name, "");
	};

	public handleFoundationModelsSuccess = (foundationModels: IGroupOption[]) => {
		if (this.sandboxAPIStore.config.selectedSubAPI === ImageGenerationsSubAPIType.reimagine) {
			const disabledModels = ["hd_2.2", "base_3.1"];

			foundationModels.forEach((model) => {
				if (disabledModels.includes(model.id)) {
					model.disabled = true;
				}
			});
		}
	};

	public handleTiloredModelsSuccess = (tailoredModels: IGroupOption[]) => {
		const pathParameter = this.sandboxAPIStore.config.apiConfig?.pathParameter;
		if (tailoredModels?.length) {
			if (pathParameter) {
				pathParameter.model_id = tailoredModels[0]?.id;
			}
		}
	};

	public handleButtonClick = async (action: string): Promise<void> => {
		switch (action) {
			case "erase_object":
				if (
					this.sandboxAPIStore.brushCanvasRefs.length > 0 &&
					this.sandboxAPIStore.brushCanvasRefs[0].canvasRef
				) {
					const brushActions = new BrushActions(
						this.sandboxAPIStore.brushCanvasRefs[0].canvasRef.current,
						this.sandboxAPIStore.brushCanvasRefs[0].canvasOverlayRef.current,
					);
					const imageUrl = this.playgroundStore.playgroundResults[0].images[0].url;
					const maskFileBase64 = await brushActions.handleCanvasDownload();
					if (maskFileBase64 && this.playgroundStore.playgroundResults.length > 0) {
						if (this.sandboxAPIStore.config.selectedSubAPI === ImageModificationsSubAPIType.GenFill) {
							const parameters = this.sandboxAPIStore.config.apiConfig?.parameters;
							const finalPayload: Record<string, string> = {};

							const img = new Image();
							img.src = imageUrl;

							parameters?.forEach((field: APIParameter) => {
								const value = this.payload?.[field.name];
								const ruleSet = field.validationSchema;
								if (ruleSet) {
									field.validationMessage = validateField(ruleSet, value);
								}

								if (
									value === null ||
									value === undefined ||
									value === "" ||
									field.hidden ||
									field.type === "imageUpload"
								) {
									return;
								}
								finalPayload[field.name] = value;
							});

							img.onload = async () => {
								finalPayload["mask_width"] = `${img.width}`;
								finalPayload["mask_height"] = `${img.height}`;

								if (
									!this.sandboxAPIStore.config.apiConfig?.parameters?.some(
										(item) => item?.validationMessage,
									)
								) {
									const newImageUrl = await this.sandboxAPIStore.eraseImageObject(
										imageUrl,
										maskFileBase64,
										finalPayload,
									);
									if (newImageUrl) {
										this.playgroundStore.playgroundResults[0].isFromUpload = false;
										this.playgroundStore.playgroundResults[0].images[0].url = newImageUrl;
									}
								}
							};
						} else {
							const newImageUrl = await this.sandboxAPIStore.eraseImageObject(imageUrl, maskFileBase64);
							if (newImageUrl) {
								this.playgroundStore.playgroundResults[0].isFromUpload = false;
								this.playgroundStore.playgroundResults[0].images[0].url = newImageUrl;
							}
						}
					}
				}
				break;

			case "reset_image":
				if (this.sandboxAPIStore.uploadImageSrc) {
					this.playgroundStore.playgroundResults[0].isFromUpload = true;
					this.playgroundStore.playgroundResults[0].images[0].url = this.sandboxAPIStore.uploadImageSrc;
					this.sandboxAPIStore.brushConfigs.reset = true;
				}
		}
	};

	public handleSecondaryButtonClick = (action: string) => {
		if (action === "resetPrefix") {
			const tgPromptField = this.getFieldById("prompt");
			if (tgPromptField) {
				tgPromptField.validationMessage = undefined;
			}
			const pathParameter = this.sandboxAPIStore.config.apiConfig?.pathParameter;
			if (pathParameter) {
				this.setPayload?.((prevPayload) => ({
					...prevPayload,
					prompt: pathParameter.generation_prefix,
				}));
			}
		}
	};

	public viewCode = (payload: Record<string, any>) => {
		const savedIndex = this.playgroundStore.playgroundResults.length - 1;
		const apiConfig = this.sandboxAPIStore.config.apiConfig;

		if (
			apiConfig &&
			(this.playgroundStore.playgroundVideoResults[0] || this.playgroundStore.playgroundResults[savedIndex])
		) {
			let endpoint = apiConfig.endpoint;
			const getModelVersion = (model: string) => {
				if (["base", "fast"].includes(model)) {
					return "2.3";
				} else if (model === "hd") {
					return "2.2";
				} else {
					return payload["model_id"];
				}
			};

			//Convert guidance methods list into numbered parameters
			function convertGuidanceMethod(guidanceMethods: guidanceMethods[]): Record<string, any> {
				const params: Record<string, any> = {};
				guidanceMethods?.forEach((method, index) => {
					const methodIndex = index + 1;
					params[`guidance_method_${methodIndex}`] = method.guidance_method;
					params[`guidance_method_${methodIndex}_image_file`] = method.guidance_method_image_file;
					params[`guidance_method_${methodIndex}_scale`] = method.guidance_method_scale;
				});

				delete payload["guidance_methods"];
				return params;
			}

			switch (apiConfig.endpoint) {
				case "/text-to-image":
				case "/text-to-vector":
					endpoint = `${apiConfig.endpoint}/${payload["model"]}/${getModelVersion(payload["model"])}`;
					delete payload["model_id"];
					delete payload["model"];
					break;
				case "/background/remove":
					if (this.sandboxAPIStore.config.selectedAPI === SandboxAPIType.VideoEditing) {
						endpoint = "/video/background/remove";
					}
					break;
				case "/reimagine":
					delete payload["model"];
					break;
				case "/image_expansion":
					delete payload["constrain_proportions"];
					break;
				case "/image/increase_resolution":
					endpoint = `${apiConfig.endpoint}?desired_increase=${payload["desired_increase"]}`;
					delete payload["desired_increase"];
					break;
				case "/crop_out_foreground":
					endpoint = "/crop";
					break;
			}

			const guidanceMethodsParams = convertGuidanceMethod(payload["guidance_methods"]);
			let requestPayload = { ...payload, ...guidanceMethodsParams };

			let postData: PostData = {
				mimeType: apiConfig.contentType || "application/json",
				text: JSON.stringify(requestPayload, null, 2),
			};

			switch (apiConfig.contentType) {
				case "application/x-www-form-urlencoded":
					const params = Object.entries(requestPayload).map(([name, value]) => ({ name, value }));
					postData = {
						mimeType: apiConfig.contentType,
						params: params,
					};
					break;
			}

			const apiRequestData = {
				url: SANDBOX_API_BASE_URL + endpoint,
				method: apiConfig.method,
				headers: [
					{
						name: "Content-Type",
						value: apiConfig.contentType || "application/json",
					},
					{
						name: "api_token",
						value: "*****************",
					},
				],
				postData: postData,
			};

			if (this.sandboxAPIStore.config.selectedAPI === SandboxAPIType.VideoEditing) {
				this.playgroundStore.playgroundVideoResults[0].apiRequestData = apiRequestData;
			} else {
				this.playgroundStore.playgroundResults[savedIndex].apiRequestData = apiRequestData;
			}
		}
	};
}
