import Image1 from "../assets/images/pngs/ImageReferenceGallery/image1.png";
import Image11 from "../assets/images/pngs/ImageReferenceGallery/image11.jpg";
import Image13 from "../assets/images/pngs/ImageReferenceGallery/image13.jpg";
import Image17 from "../assets/images/pngs/ImageReferenceGallery/image17.png";
import Image18 from "../assets/images/pngs/ImageReferenceGallery/image18.png";
import Image19 from "../assets/images/pngs/ImageReferenceGallery/image19.png";
import Image2 from "../assets/images/pngs/ImageReferenceGallery/image2.png";
import Image20 from "../assets/images/pngs/ImageReferenceGallery/image20.png";
import Image3 from "../assets/images/pngs/ImageReferenceGallery/image3.png";
import Image4 from "../assets/images/pngs/ImageReferenceGallery/image4.png";
import Image5 from "../assets/images/pngs/ImageReferenceGallery/image5.png";
import Image6 from "../assets/images/pngs/ImageReferenceGallery/image6.png";
import Image7 from "../assets/images/pngs/ImageReferenceGallery/image7.png";
import Image8 from "../assets/images/pngs/ImageReferenceGallery/image8.png";

export const ImageReferenceGalleryConstants = {
	sketch_to_illustration: [
		{
			url: Image1,
			prompt: "imageGallery.sketch_to_illustration.image1.prompt",
			influence: 0.15,
		},
		{
			url: Image2,
			prompt: "imageGallery.sketch_to_illustration.image2.prompt",
			influence: 0.2,
		},
	],
	photo_variations: [
		{
			url: Image3,
			prompt: "imageGallery.photo_variations.image1.prompt",
			influence: 0.75,
		},
		{
			url: Image4,
			prompt: "imageGallery.photo_variations.image2.prompt",
			influence: 0.05,
		},
	],
	illustration_variations: [
		{
			url: Image5,
			prompt: "imageGallery.illustration_variations.image1.prompt",
			influence: 0.2,
		},
		{
			url: Image6,
			prompt: "imageGallery.illustration_variations.image2.prompt",
			influence: 0.75,
		},
	],
	typography_logo_variations: [
		{
			url: Image7,
			prompt: "imageGallery.typography_logo_variations.image1.prompt",
			influence: 0.15,
		},
		{
			url: Image8,
			prompt: "imageGallery.typography_logo_variations.image2.prompt",
			influence: 0.75,
		},
	],
	abstract_conceptual: [
		{
			url: Image17,
			prompt: "imageGallery.abstract_conceptual.image1.prompt",
		},
		{
			url: Image18,
			prompt: "imageGallery.abstract_conceptual.image2.prompt",
		},
		{
			url: Image19,
			prompt: "imageGallery.abstract_conceptual.image3.prompt",
		},
		{
			url: Image20,
			prompt: "imageGallery.abstract_conceptual.image4.prompt",
		},
	],
	portrait: [
		{
			url: Image11,
			prompt: "imageGallery.portrait.image1.prompt",
			influence: 0.7,
		},
		{
			url: Image13,
			prompt: "imageGallery.portrait.image3.prompt",
			influence: 0.7,
		},
	],
};
