import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppStore } from "../../../../hooks/useStores.tsx";

import { useParams } from "react-router-dom";
import LoadingPlaceholder from "../../../../components/common/LoadingPlaceholder/LoadingPlaceholder.tsx";
import RouterConstants from "../../../../constants/RouterConstants.ts";
import { isAutomotiveFavoritesPage, isAutomotiveUploadedPage } from "../../../../utils/index.ts";
import AddSessionDialog from "../../Sessions/AddSessionDialog/AddSessionDialog.tsx";
import EditSessionDialog from "../../Sessions/EditSessionDialog/EditSessionDialog.tsx";
import { PlaygroundSession } from "../../Sessions/store/playground-sessios-store.tsx";
import styles from "./AutomotiveProject.module.scss";
import ProjectFavoriteImages from "./Favorite/ProjectFavoriteImages.tsx";
import ProjectHeaderActions from "./Header/Actions/ProjectHeaderActions.tsx";
import ProjectHeader from "./Header/ProjectHeader.tsx";
import ProjectSidebar from "./Sidebar/ProjectSidebar.tsx";
import ProjectUploadedImages from "./Uploaded/ProjectUploadedImages.tsx";

export interface IActiveSection {
	title: string;
	session?: PlaygroundSession | null;
}

const AutomotiveProject = () => {
	const { t } = useTranslation("translation", { keyPrefix: "automotiveApp.project" });
	const { t: tc } = useTranslation("translation", { keyPrefix: "automotiveApp" });
	const { uiStore, playgroundStore } = useAppStore();
	const { projectsStore } = playgroundStore;
	const { project_id } = useParams<{ project_id: string }>();

	useEffect(() => {
		uiStore.showSideBar();
		playgroundStore.togglePlaygroundViewMode();
	}, [uiStore]);

	useEffect(() => {
		if (project_id) {
			getProject(project_id);
		}
	}, [project_id]);

	const getProject = async (project_id: string) => {
		if (!projectsStore.project.id.trim()) {
			await projectsStore.getProject(project_id);
		}
		uiStore.showBackButton(
			RouterConstants.AUTOMOTIVE_PROJECTS.fullPath,
			undefined,
			tc("projectManagement"),
			undefined,
			undefined,
			undefined,
			projectsStore.project.name,
		);
	};

	return (
		<Box className={styles.container}>
			<LoadingPlaceholder isLoading={projectsStore.loadingProject} className={styles.loadingPlaceholder}>
				<Box className={styles.projectWrapper}>
					<ProjectHeader />
					<Box className={styles.projectContent}>
						<ProjectSidebar />
						<Box className={styles.projectMainContent}>
							<Box className={styles.contentHeader}>
								<Typography className={styles.title}>
									{isAutomotiveUploadedPage() ? t("uploadedImages") : t("favoriteImages")}
								</Typography>
								<ProjectHeaderActions />
							</Box>
							{isAutomotiveUploadedPage() && <ProjectUploadedImages />}
							{isAutomotiveFavoritesPage() && <ProjectFavoriteImages />}
						</Box>
					</Box>
					<EditSessionDialog />
					<AddSessionDialog project={projectsStore.project} />
				</Box>
			</LoadingPlaceholder>
		</Box>
	);
};

export default observer(AutomotiveProject);
