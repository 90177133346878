import { Box, SelectChangeEvent, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import UploadSVG from "../../../../../assets/images/svgs/cloud-upload.svg";
import BriaButton from "../../../../../components/common/BriaButton/BriaButton";
import BriaButtonGroup from "../../../../../components/common/BriaButtonGroup/BriaButtonGroup";
import BriaDropDown, { DropDownItem } from "../../../../../components/common/BriaDropDown/BriaDropDown";
import FileUploader from "../../../../../components/common/FileUploader/FileUploader";
import LoadingPlaceholder from "../../../../../components/common/LoadingPlaceholder/LoadingPlaceholder";
import { useAppStore } from "../../../../../hooks/useStores";
import BestPracticesPopup from "../../Popups/BestPractices/BestPracticesPopup";
import styles from "./SelectTgDatasetStep.module.scss";

type SelectTgDatasetStepProps = {
	handleNext: () => void;
};

const SelectTgDatasetStep = ({ handleNext }: SelectTgDatasetStepProps) => {
	const { t } = useTranslation("translation", { keyPrefix: "newTg.models.create.steps.selectDataset" });
	const { projectId } = useParams();
	const { tgStore } = useAppStore();
	const { tgProjectStore, tgModelStore, tgDatasetStore } = tgStore;
	const [datasetOptions, setDatasetOptions] = useState<DropDownItem[]>([]);
	const [openBestPracticesPopup, setOpenBestPracticesPopup] = useState(false);

	useEffect(() => {
		const skipSelectDatasetStep = async () => {
			tgDatasetStore.loadingDataset = true;
			tgDatasetStore.datasetForm = await tgDatasetStore.getDataset(tgModelStore.modelForm.dataset_id);
			handleNext();
			tgModelStore.isDatasetFromExisting = true;
			tgModelStore.handleFormChange("dataset_id", tgDatasetStore.datasetForm.id);
		};

		if (tgModelStore.modelForm.dataset_id) {
			skipSelectDatasetStep();
		} else {
			tgProjectStore.paginatedDatasets = { items: [], total: 0 };
			tgProjectStore.getDatasets(Number(projectId));
		}
	}, []);

	useEffect(() => {
		const datasets = tgProjectStore.paginatedDatasets.items;
		datasets.length && setDatasetOptions(datasets?.map((dataset) => ({ key: dataset.name, value: dataset.id })));
	}, [tgProjectStore.paginatedDatasets.items]);

	const createAndUploadDataset = async (e: ChangeEvent<HTMLInputElement>) => {
		const files: File[] | null = Array.from(e.target.files || []);
		e.target.value = "";

		if (!files.length) return;

		let newDataset;
		// Generate Prefix using the generate prefix API only when IP type is Stylezed scene
		if (tgProjectStore.projectToView.ip_type === "stylized_scene") {
			const generatedPrefix = await tgDatasetStore.imagesSampleGeneratePrefix(tgProjectStore.projectToView, {
				images: files,
			});
			if (!generatedPrefix) return;
			newDataset = await tgDatasetStore.createDataset({
				...tgDatasetStore.datasetForm,
				project_id: Number(projectId),
			});

			newDataset.caption_prefix = generatedPrefix.prefix;
			await tgDatasetStore.updateDataset(newDataset.id, newDataset);
		} else {
			newDataset = await tgDatasetStore.createDataset({
				...tgDatasetStore.datasetForm,
				project_id: Number(projectId),
			});
		}
		tgDatasetStore.datasetForm = newDataset;
		tgModelStore.modelForm.dataset_id = newDataset.id;
		tgDatasetStore.uploadImages(newDataset.id, files);
		handleNext();
	};

	const handleSelectDataset = async (e: SelectChangeEvent<number>) => {
		const selectedDataset = tgProjectStore.paginatedDatasets.items.find(
			(dataset) => dataset.id === Number(e.target.value),
		);
		if (selectedDataset) {
			tgDatasetStore.datasetForm = selectedDataset;
			tgModelStore.isDatasetFromExisting = true;
			tgModelStore.handleFormChange("dataset_id", selectedDataset.id);
			handleNext();
		}
	};

	const secondaryButtonClickHandler = () => {
		setOpenBestPracticesPopup(true);
	};

	const onCloseBestPracticesPopup = () => {
		setOpenBestPracticesPopup(false);
	};

	return (
		<LoadingPlaceholder isLoading={tgDatasetStore.loadingDataset} className={styles.stepWrapper}>
			<Box className={styles.stepWrapper}>
				<Box className={styles.content}>
					<Typography className={styles.header}>{t("header")}</Typography>
					<Typography className={styles.description}>
						{tgProjectStore.projectToView.ip_medium === "photography"
							? t("photographyDescription")
							: t("description")}
					</Typography>
					<BriaButtonGroup>
						<FileUploader
							buttonText={t("primaryButton")}
							buttonType="primaryMedium"
							onUpload={createAndUploadDataset}
							maxFilesLimit={200}
							inputProps={{ accept: "image/png, image/jpg, image/jpeg, image/webp", multiple: true }}
							buttonProps={{ startIcon: <img src={UploadSVG} /> }}
							loading={tgDatasetStore.loadingCreateDataset || tgDatasetStore.loadingGeneratePrefix}
						/>
						<BriaButton buttonType="secondaryMedium" onClick={secondaryButtonClickHandler}>
							{t("secondaryButton")}
						</BriaButton>
					</BriaButtonGroup>
					<>
						<Typography className={styles.or}>{t("or")}</Typography>
						<BriaDropDown
							placeholder={
								tgProjectStore.loadingProjectDatasets || tgProjectStore.paginatedDatasets.items.length
									? t("dropdown.placeholder")
									: t("dropdown.emptyPlaceholder")
							}
							items={datasetOptions}
							value={tgModelStore.modelForm.dataset_id}
							onChange={handleSelectDataset}
							fullWidth
							loading={tgProjectStore.loadingProjectDatasets || tgDatasetStore.loadingDuplicateDataset}
						/>
					</>
					<Typography className={styles.supportedFiles}>{t("supportedFiles")}</Typography>
				</Box>
				<BestPracticesPopup open={openBestPracticesPopup} onClose={onCloseBestPracticesPopup} />
			</Box>
		</LoadingPlaceholder>
	);
};

export default observer(SelectTgDatasetStep);
