import { onValue, ref, set, update } from "firebase/database";
import { firebaseDatabase } from "../config/firebase.ts";
import { USER_ROLES } from "../constants/UserConstants.ts";
import { UserOrganizationFlat } from "../models/organization.ts";
import User from "../models/user.ts";

export async function getUserWithUID(uid: string): Promise<User | null> {
	return new Promise<User | null>((resolve, reject) => {
		const databaseRef = ref(firebaseDatabase, "users/" + uid);
		onValue(
			databaseRef,
			(snapshot) => {
				const firebaseUser = snapshot.val();
				if (firebaseUser) {
					const user = new User(
						firebaseUser.user_name,
						firebaseUser.uid,
						firebaseUser.profile_picture,
						firebaseUser.role,
						firebaseUser.company,
						firebaseUser.userRole,
						firebaseUser.getInfo,
						firebaseUser.email,
						firebaseUser.settings,
						firebaseUser.user_organizations,
					);
					resolve(user);
				} else {
					resolve(null);
				}
			},
			(error) => {
				reject(error);
			},
		);
	});
}

export async function createFirebaseUser(
	user: any,
	getInfo: boolean,
	username?: string,
	company?: string,
	role?: string,
	userOrganizations?: UserOrganizationFlat[],
): Promise<any> {
	return new Promise<any>(async (resolve, reject) => {
		try {
			const userToRegister = {
				user_name: user.displayName ?? username ?? "",
				uid: user.uid,
				email: user.email,
				profile_picture: user.photoURL ?? "",
				role: USER_ROLES.VIEWER,
				company: company ?? "",
				userRole: role ?? "",
				getInfo: getInfo,
				user_organizations: userOrganizations ?? [],
			};
			await set(ref(firebaseDatabase, `users/${user.uid}`), userToRegister);
			resolve(userToRegister);
		} catch (error) {
			reject(error);
		}
	});
}

export async function updateUserInfo(user: User, username: string, company?: string, role?: string): Promise<any> {
	try {
		if (user) {
			await update(ref(firebaseDatabase, "users/" + user.uid), {
				user_name: username ?? "",
				company: company ?? "",
				userRole: role ?? "",
				getInfo: true,
			});
			const userObject = await getUserWithUID(user.uid);
			return Promise.resolve(userObject);
		}
		return Promise.reject(null);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function addUserToOrgsIfMissing(user: User, orgsToAdd?: UserOrganizationFlat[]): Promise<User | null> {
	return new Promise<User | null>(async (resolve, reject) => {
		try {
			const userOrgsIdsFlat = user.user_organizations?.map((org: UserOrganizationFlat) => org.org_uid) ?? [];
			const newOrgs =
				orgsToAdd?.filter((org: UserOrganizationFlat) => !userOrgsIdsFlat.includes(org.org_uid)) ?? [];
			if (newOrgs.length > 0) {
				await set(ref(firebaseDatabase, `users/${user.uid}/user_organizations`), [
					...(user.user_organizations ?? []),
					...newOrgs,
				]);
				const userObject = await getUserWithUID(user.uid);
				resolve(userObject);
			} else {
				resolve(user);
			}
		} catch (error) {
			reject(error);
		}
	});
}
