export function fileDownload(fileUrl: string, passedFileName?: string, forceExt?: string) {
	try {
		const link = document.createElement("a");
		link.href = fileUrl;
		const filenameFromUrl = passedFileName ?? new URL(fileUrl).pathname.split("/").pop() ?? "";
		const ext = forceExt ?? filenameFromUrl?.split(".").pop();
		const filename = `${passedFileName}.${ext}`;
		link.setAttribute("download", filename);
		document.body.appendChild(link);
		link.click();
		link.parentNode?.removeChild(link);
	} catch (error) {
		console.log(error);
		throw error;
	}
}

export async function csvFileDownload (data: string, fileName: string) {
    try {
        const blob = new Blob([data], { type: 'text/csv' });

        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName;

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(link.href);
    } catch (error) {
        console.error("Error downloading CSV:", error);
    }
};
