import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import RequestAccessContainer from "../../../../../components/TailoredGeneration/RequestAccessContainer/RequestAccessContainer";
import BriaAccordion from "../../../../../components/common/BriaAccordion/BriaAccordion.tsx";
import BriaButton, { ButtonTypes } from "../../../../../components/common/BriaButton/BriaButton.tsx";
import BriaInput from "../../../../../components/common/BriaInput/BriaInput.tsx";
import ConfirmationPopup from "../../../../../components/common/ConfirmationPopup/ConfirmationPopup.tsx";
import CopyToClipboardButton from "../../../../../components/common/CopyToClipboardButton/CopyToClipboardButton.tsx";
import ImageViewer from "../../../../../components/common/Image/Image";
import LinkConstants from "../../../../../constants/LinkConstants.ts";
import RouterConstants from "../../../../../constants/RouterConstants.ts";
import { CrmEventTargets, CrmOrgEvents, CrmUserEvents } from "../../../../../constants/crm.ts";
import useSecureNavigate from "../../../../../hooks/useSecureNavigate.tsx";
import { useAppStore } from "../../../../../hooks/useStores.tsx";
import InputLayout from "../../../../../layout/InputLayout/InputLayout.tsx";
import { OrgFeatures } from "../../../../../models/billing.ts";
import SuggestAccessMethod from "../../../Integrations/SuggestAccessMethod/SuggestAccessMethod.tsx";
import { TgProject } from "../../../Projects/store/tg-projects-store.tsx";
import { TgModel } from "../../store/tg-model-store.tsx";
import FigmaInstallationPopup from "../FigmaInstallationPopup/FigmaInstallationPopup.tsx";
import PhotoshopInstallationPopup from "../PhotoshopInstallationPopup/PhotoshopInstallationPopup.tsx";
import styles from "./ModelAccessPopup.module.scss";

type ModelAccessPopupProps = {
	project: TgProject;
	model: TgModel;
	disabled?: boolean;
	buttonType?: ButtonTypes;
};

const ModelAccessPopup = ({ project, model, disabled = false, buttonType = "textMedium" }: ModelAccessPopupProps) => {
	const { appStore, tgStore, authStore } = useAppStore();
	const { tgModelStore } = tgStore;
	const { t } = useTranslation("translation", { keyPrefix: "newTg.models.popups.modelAccessPopup" });
	const { t: t1 } = useTranslation("translation", { keyPrefix: "models_page.accessModel" });
	const navigate = useSecureNavigate();
	const [openGetAccessPopup, setOpenGetAccessPopup] = useState(false);
	const [openFigmaInstallationPopup, setOpenFigmaInstallationPopup] = useState(false);
	const [openPhotoshopInstallationPopup, setOpenPhotoshopInstallationPopup] = useState(false);
	const accessMethods = t("accessMethods", { returnObjects: true });
	const TG_TRAINING_API_ENDPOINT = `https://engine.prod.bria-api.com/v1/text-to-image/tailored/${model.id}`;

	const openBriaAPIDocs = () => {
		window.open(LinkConstants.BRIA_API_TG_GENERATE_IMAGES, "_blank");
	};

	const navigateToIframes = () => {
		window.open(RouterConstants.NEW_IFRAMES_CONFIG.fullPath, "_blank");
	};

	const downloadFigmaPlugin = async () => {
		await appStore.downloadFigmaPlugin();
	};

	const downloadPhotoshopPlugin = async () => {
		await appStore.downloadPhotoshopPlugin();
	};

	const openComfyUi = () => {
		window.open(LinkConstants.BRIA_COMFYUI_TAILORED_GEN, "_blank");
	};

	const getDesignatedAPIKey = () => {
		navigate(RouterConstants.AUTH_KEYS.fullPath);
	};

	const openFigmaInstallationGuide = () => {
		setOpenFigmaInstallationPopup(true);
	};

	const downloadModel = async () => {
		await tgModelStore.downloadModel(model.id);
	};

	const getBaseModel = async () => {
		const baseModelCardURL = await tgModelStore.getBaseModel(model.id);
		window.open(baseModelCardURL, "_blank");
	};

	const openPhotoshopInstallationGuide = () => {
		setOpenPhotoshopInstallationPopup(true);
	};

	const getAccordionContent = (method: string) => {
		switch (method) {
			case "api":
				return (
					<>
						<BriaInput
							className={styles.readOnlyInput}
							disabled={true}
							aria-readonly={true}
							value={TG_TRAINING_API_ENDPOINT}
							size="small"
						/>
						<Box className={styles.ctas}>
							<BriaButton buttonType="textMedium" onClick={openBriaAPIDocs}>
								{t(`accessMethods.${method}.secondaryBtn`)}
							</BriaButton>
							<CopyToClipboardButton
								className={styles.primaryCopy}
								buttonText={t(`accessMethods.${method}.primaryBtn`)}
								buttonType={"primaryMedium"}
								hideIcon={true}
								textToCopy={TG_TRAINING_API_ENDPOINT}
							/>
						</Box>
					</>
				);
			case "iframe":
				return (
					<Box className={styles.ctas}>
						<BriaButton buttonType="textMedium" onClick={navigateToIframes}>
							{t(`accessMethods.${method}.primaryBtn`)}
						</BriaButton>
					</Box>
				);
			case "figma":
				return (
					<Box className={styles.ctas}>
						<BriaButton buttonType="textMedium" onClick={openFigmaInstallationGuide}>
							{t(`accessMethods.${method}.secondaryBtn`)}
						</BriaButton>
						<BriaButton
							buttonType="primaryMedium"
							onClick={downloadFigmaPlugin}
							loading={appStore.isDownloadingFigmaPlugin}
							disabled={appStore.isDownloadingFigmaPlugin}
						>
							{t(`accessMethods.${method}.primaryBtn`)}
						</BriaButton>
					</Box>
				);
			case "photoshop":
				return (
					<Box className={styles.ctas}>
						<BriaButton buttonType="textMedium" onClick={openPhotoshopInstallationGuide}>
							{t(`accessMethods.${method}.secondaryBtn`)}
						</BriaButton>
						<BriaButton
							buttonType="primaryMedium"
							onClick={downloadPhotoshopPlugin}
							loading={appStore.isDownloadingPhotoshopPlugin}
							disabled={appStore.isDownloadingPhotoshopPlugin}
						>
							{t(`accessMethods.${method}.primaryBtn`)}
						</BriaButton>
					</Box>
				);
			case "comfyUi":
				return (
					<>
						<BriaInput
							className={styles.readOnlyInput}
							disabled={true}
							aria-readonly={true}
							value={model.id}
							size="small"
							customEndAdornmentText={
								<CopyToClipboardButton className={styles.copyIcon} textToCopy={String(model.id)} />
							}
						/>
						<Box className={styles.ctas}>
							<BriaButton buttonType={"secondaryMedium"} onClick={getDesignatedAPIKey}>
								{t(`accessMethods.${method}.getDesignatedAPIKey`)}
							</BriaButton>
							<BriaButton buttonType="primaryMedium" onClick={openComfyUi}>
								{t(`accessMethods.${method}.primaryBtn`)}
							</BriaButton>
						</Box>
					</>
				);
			case "model":
				return authStore.isFeatureEnabled(OrgFeatures.FOUNDATION_MODELS) ? (
					<>
						<InputLayout label={t("generationPrefix")}>
							<BriaInput
								className={styles.readOnlyInput}
								disabled={true}
								aria-readonly={true}
								value={model.generation_prefix}
								size="small"
								customEndAdornmentText={
									<CopyToClipboardButton
										className={styles.copyIcon}
										textToCopy={String(model.generation_prefix)}
									/>
								}
							/>
						</InputLayout>
						<Box className={styles.ctas}>
							<BriaButton
								onClick={getBaseModel}
								className={styles.secondaryCopy}
								buttonType={"textMedium"}
							>
								{t(`accessMethods.${method}.secondaryBtn`)}
							</BriaButton>
							<BriaButton
								buttonType="primaryMedium"
								onClick={downloadModel}
								disabled={tgModelStore.downloadingModel}
								loading={tgModelStore.downloadingModel}
							>
								{t(`accessMethods.${method}.primaryBtn`)}
							</BriaButton>
						</Box>
					</>
				) : (
					<Box className={styles.ctas}>
						<RequestAccessContainer
							showCrown={false}
							requestFeature={t1("key")}
							crmEvents={[
								{
									eventTarget: CrmEventTargets.ORG,
									eventType: CrmOrgEvents.ORG_REQUESTED_FOUNDATION_MODELS,
								},
								{
									eventTarget: CrmEventTargets.USER,
									eventType: CrmUserEvents.USER_REQUESTED_FOUNDATION_MODELS,
								},
							]}
							buttonType="secondaryMedium"
							buttonMessage={t1("cards.buttons.requestAQuote")}
							requestTitle={t1("cards.card1.requestTitle")}
							requestMessage={t1("cards.card1.requestMessage")}
							submissionMessage={t1("cards.card1.submissionMessage")}
							modelId={String(model.id)}
							modelName={model.name}
							projectId={String(project.id)}
							projectName={project.project_name}
						/>
					</Box>
				);
			case "suggestMethod":
				return <SuggestAccessMethod />;
		}
	};

	const getCrmEvents = (method: string) => {
		switch (method) {
			case "copilot":
				return [
					{
						eventTarget: CrmEventTargets.ORG,
						eventType: CrmOrgEvents.ORG_REQUESTED_TG_COPILOT,
					},
					{
						eventTarget: CrmEventTargets.USER,
						eventType: CrmUserEvents.USER_REQUESTED_TG_COPILOT,
					},
				];
			case "powerPoint":
				return [
					{
						eventTarget: CrmEventTargets.ORG,
						eventType: CrmOrgEvents.ORG_REQUESTED_TG_POWERPOINT,
					},
					{
						eventTarget: CrmEventTargets.USER,
						eventType: CrmUserEvents.USER_REQUESTED_TG_POWERPOINT,
					},
				];
			default:
				return [
					{
						eventTarget: CrmEventTargets.ORG,
						eventType: CrmOrgEvents.ORG_REQUESTED_TAILORED_MODELS,
					},
					{
						eventTarget: CrmEventTargets.USER,
						eventType: CrmUserEvents.USER_REQUESTED_TAILORED_MODELS,
					},
				];
		}
	};

	return (
		<>
			<BriaButton
				className={styles.getAccessBtn}
				disabled={disabled}
				buttonType={buttonType}
				onClick={() => setOpenGetAccessPopup(true)}
			>
				{t("getAccess")}
			</BriaButton>
			<ConfirmationPopup
				open={openGetAccessPopup}
				title={t("header")}
				maxWidth="616px"
				paperClassName={styles.paperWrap}
				description={
					<Box className={styles.popupContent}>
						<Box className={styles.subHeader}>{t("subHeader")}</Box>

						{Object.keys(accessMethods).map((method) => (
							<Box className={styles.accordionWraper}>
								{t(`accessMethods.${method}.contactUs`) === "true" && (
									<RequestAccessContainer
										className={styles.contactUsBtn}
										requestTitle={t(`contactUsPopup.title`)}
										requestMessage={t(`contactUsPopup.message`)}
										requestFeature={t(`accessMethods.${method}.feature`)}
										crmEvents={getCrmEvents(method)}
										buttonMessage={t("contactUs")}
										buttonType={"secondarySmall"}
										showCrown={false}
										modelId={String(model.id)}
										modelName={model.name}
										projectId={String(project.id)}
										projectName={project.project_name}
									/>
								)}
								<BriaAccordion
									className={styles.accordionContainer}
									titleClass={styles.accordionTitle}
									accordionTitle={
										<Box className={styles.titleWrap}>
											{t(`accessMethods.${method}.hideIcon`) !== "true" && (
												<ImageViewer name={`images/svgs/TgAccessMethods/${method}_popup.svg`} />
											)}
											{t(`accessMethods.${method}.title`)}
										</Box>
									}
									disabled={t(`accessMethods.${method}.contactUs`) === "true"}
								>
									{t(`accessMethods.${method}.hideDescription`) !== "true" && (
										<Typography className={styles.accordionDescription}>
											{t(`accessMethods.${method}.description`)}
										</Typography>
									)}

									<Box className={styles.accordion}>{getAccordionContent(method)}</Box>
								</BriaAccordion>
							</Box>
						))}
					</Box>
				}
				hideActions
				onClose={() => setOpenGetAccessPopup(false)}
			/>

			<FigmaInstallationPopup
				open={openFigmaInstallationPopup}
				onClose={() => setOpenFigmaInstallationPopup(false)}
				onClick={downloadFigmaPlugin}
				loading={appStore.isDownloadingFigmaPlugin}
				disabled={appStore.isDownloadingFigmaPlugin}
			/>
			<PhotoshopInstallationPopup
				open={openPhotoshopInstallationPopup}
				onClose={() => setOpenPhotoshopInstallationPopup(false)}
				onClick={downloadPhotoshopPlugin}
				loading={appStore.isDownloadingPhotoshopPlugin}
				disabled={appStore.isDownloadingPhotoshopPlugin}
			/>
		</>
	);
};

export default observer(ModelAccessPopup);
