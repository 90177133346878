import { AlertColor, AlertPropsColorOverrides } from "@mui/material";
import { OverridableStringUnion } from "@mui/types";
import { makeAutoObservable, runInAction } from "mobx";
import { MutableRefObject, useRef } from "react";
import { NavigateOptions } from "react-router-dom";
import { BackButtonProps } from "./components/common/BackButton/BackButton.tsx";
import { ExportImageProps } from "./components/common/ExportImagePopup/ExportImagePopup.tsx";
import { IRootStore } from "./mobx/root-store.tsx";

export interface IUIStore {
	sideBar: MutableRefObject<null | HTMLDivElement>;
	tgUploadFileInput: MutableRefObject<null | HTMLInputElement>;
	TGButtonsRef: MutableRefObject<null | HTMLDivElement>;
	guideSectionContainerRef: MutableRefObject<null | HTMLDivElement>;
	tgCSVUploadFileInput: MutableRefObject<null | HTMLInputElement>;
	tg_step: null | string;
	prev_tg_step: null | string;
	TGCaptionDialog: boolean;
	updateTGDataset: boolean; // This flag controls the tabs that should appear when clicking on datasets from the Dataset Management page.
	duplicateDatasetFlow: boolean;
	backButton?: BackButtonProps;
	TGPublishToTrainingDialog: boolean;
	TGPDataSetCreationDialogAndChangeDatasetName: boolean;
	createDataSet: boolean; // This flag to indicate that we are creating dataset from Dataset Management page.
	GuidelinesForTailoredModelsDialog: boolean;
	FoxGuidelinesPopup: boolean;
	datasetCreationSuccessSnackbar: boolean;
	checkoutPopupSelectedPriceId: string | undefined;
	openCheckoutPopup: boolean;
	openPricingPopup: boolean;
	opensaveNewTemplatePopup: boolean;
	SubscriptionLinkGeneratorPopup: boolean;
	OrgInfoPopup: boolean;
	ExportImagePopup: boolean;
	exportImageProps: ExportImageProps;
	ApiLimitReachedPopup: boolean;
	ApiLimitReachedBanner: boolean;
	InvalidSubscriptionBanner: boolean;
	InvalidSubscriptionPopup: boolean;
	selectedGuideIndex: number;
	showGuideSection: boolean;
	showGuideToolTip: boolean;
	isNewModelBannerVisible: boolean;
	snackBarProps?: {
		open: boolean;
		severity: OverridableStringUnion<AlertColor, AlertPropsColorOverrides> | undefined;
		message: string;
		autoHideDuration?: number;
	};

	showExportImagePopup(exportImageProps?: ExportImageProps): void;

	showSideBar(): void;

	hideSideBar(): void;

	showTGButtons(): void;

	hideTGButtons(): void;

	updateTailoredGenerationDataset(): void;

	setIsDuplicateDatasetFlow(): void;

	updateTgStep(new_step: null | string): void;

	showDialog(targetDialog: keyof IUIStore): void;

	hideDialog(targetDialog: keyof IUIStore): void;

	showSnackbar(targetDialog: keyof IUIStore): void;

	hideSnackbar(targetDialog: keyof IUIStore): void;

	showRefElement(targetElement: keyof IUIStore): void;

	hideRefElement(targetElement: keyof IUIStore): void;

	hideAllFeedbackBoxes(event: any): void;

	showBackButton(
		to?: string,
		navigateOptions?: NavigateOptions,
		text?: string,
		showWarning?: boolean,
		loadingWarning?: boolean,
		onWarningConfirm?: () => Promise<void>,
		currentAppName?: string,
	): void;

	hideBackButton(): void;

	CreateOrganizationDialog: boolean;

	setProperty<K extends keyof IUIStore>(key: K, value: IUIStore[K]): void;

	hideDesignEditorContainer(): void;

	showDesignEditorContainer(): void;

	showSnackBarAlert(
		severity: OverridableStringUnion<AlertColor, AlertPropsColorOverrides> | undefined,
		message: string,
		autoHideDuration?: number,
	): void;

	hideSnackBarAlert(): void;
	EditProjectDialog: boolean;
	EditSessionDialog: boolean;
	AddSessionDialog: boolean;
}

export default class UIStore implements IUIStore {
	rootStore: IRootStore;
	sideBar = useRef<null | HTMLDivElement>(null);
	guideSectionContainerRef = useRef<null | HTMLDivElement>(null);
	TGButtonsRef = useRef<null | HTMLDivElement>(null);
	tgUploadFileInput = useRef<null | HTMLInputElement>(null);
	tgCSVUploadFileInput = useRef<null | HTMLInputElement>(null);
	tg_step: null | string = null;
	prev_tg_step: null | string = null;
	backButton?: BackButtonProps;
	TGCaptionDialog = false;
	TGPublishToTrainingDialog = false;
	TGPDataSetCreationDialogAndChangeDatasetName = false;
	createDataSet = false;
	GuidelinesForTailoredModelsDialog = false;
	FoxGuidelinesPopup = false;
	datasetCreationSuccessSnackbar = false;
	updateTGDataset = false;
	duplicateDatasetFlow = false;
	openCheckoutPopup: boolean = false;
	openPricingPopup: boolean = false;
	opensaveNewTemplatePopup: boolean = false;
	SubscriptionLinkGeneratorPopup: boolean = false;
	OrgInfoPopup: boolean = false;
	ExportImagePopup: boolean = false;
	exportImageProps: ExportImageProps = {};
	ApiLimitReachedPopup: boolean = true;
	ApiLimitReachedBanner: boolean = true;
	InvalidSubscriptionBanner: boolean = true;
	InvalidSubscriptionPopup: boolean = true;
	selectedGuideIndex: number = 0;
	showGuideSection: boolean = false;
	showGuideToolTip: boolean = false;
	isNewModelBannerVisible: boolean = true;
	checkoutPopupSelectedPriceId: string | undefined = undefined;
	CreateOrganizationDialog = false;
	EditProjectDialog = false;
	EditSessionDialog = false;
	AddSessionDialog = false;

	snackBarProps?: {
		open: boolean;
		severity: OverridableStringUnion<AlertColor, AlertPropsColorOverrides> | undefined;
		message: string;
		autoHideDuration?: number;
	} = undefined;

	constructor(rootStore: IRootStore) {
		makeAutoObservable(this);
		this.rootStore = rootStore;
	}

	showExportImagePopup = (exportImageProps: ExportImageProps = {}) => {
		runInAction(() => {
			this.ExportImagePopup = true;
			this.exportImageProps = exportImageProps;
		});
	};

	showSnackBarAlert = (
		severity: OverridableStringUnion<AlertColor, AlertPropsColorOverrides> | undefined,
		message: string,
		autoHideDuration: number = 2000,
	) => {
		runInAction(() => {
			this.snackBarProps = { open: true, severity, message, autoHideDuration };
		});
	};

	hideSnackBarAlert = () => {
		runInAction(() => {
			this.snackBarProps = undefined;
		});
	};

	showSideBar = (): void => {
		runInAction(() => {
			if (this.sideBar.current) {
				this.sideBar.current.style.minWidth = "240px";
				this.sideBar.current.style.maxWidth = "unset";
			}
		});
	};

	hideSideBar = (): void => {
		runInAction(() => {
			if (this.sideBar.current) {
				this.sideBar.current.style.maxWidth = "0";
				this.sideBar.current.style.minWidth = "0";
			}
		});
	};

	showTGButtons = (): void => {
		runInAction(() => {
			if (this.TGButtonsRef.current) {
				this.TGButtonsRef.current.style.visibility = "visible";
			}
		});
	};

	hideTGButtons = (): void => {
		runInAction(() => {
			if (this.TGButtonsRef.current) {
				this.TGButtonsRef.current.style.visibility = "hidden";
			}
		});
	};

	updateTailoredGenerationDataset = (): void => {
		runInAction(() => {
			this.updateTGDataset = true;
		});
	};

	setIsDuplicateDatasetFlow = (): void => {
		runInAction(() => {
			this.duplicateDatasetFlow = true;
		});
	};

	showBackButton = async (
		to?: string,
		navigateOptions?: NavigateOptions,
		text?: string,
		showWarning?: boolean,
		loadingWarning?: boolean,
		onWarningConfirm?: () => Promise<void>,
		currentAppName?: string,
	): Promise<void> => {
		try {
			runInAction(() => {
				this.backButton = {
					to,
					navigateOptions,
					text,
					showWarning,
					loadingWarning,
					onWarningConfirm,
					currentAppName,
				};
			});
		} catch (e) {
			return Promise.reject(e);
		}
	};

	hideBackButton = async (): Promise<void> => {
		try {
			runInAction(() => {
				this.backButton = undefined;
			});
		} catch (e) {
			return Promise.reject(e);
		}
	};

	updateTgStep = (new_step: null | string): void => {
		runInAction(() => {
			this.prev_tg_step = this.tg_step;
			this.tg_step = new_step;
		});
	};

	showRefElement = (targetElement: keyof IUIStore): void => {
		runInAction(() => {
			if ((this as any)[targetElement].current) {
				(this as any)[targetElement].current.style.display = "flex";
			}
		});
	};

	hideRefElement = (targetElement: keyof IUIStore): void => {
		runInAction(() => {
			if ((this as any)[targetElement].current) {
				(this as any)[targetElement].current.style.display = "none";
			}
		});
	};

	showDialog = (targetDialog: keyof IUIStore): void => {
		runInAction(() => {
			(this as any)[targetDialog] = true;
		});
	};

	hideDialog = (targetDialog: keyof IUIStore): void => {
		runInAction(() => {
			(this as any)[targetDialog] = false;
		});
	};

	showSnackbar = (targetSnackbar: keyof IUIStore) => {
		runInAction(() => {
			(this as any)[targetSnackbar] = true;
		});
	};

	hideSnackbar = (targetSnackbar: keyof IUIStore) => {
		runInAction(() => {
			(this as any)[targetSnackbar] = false;
		});
	};

	hideAllFeedbackBoxes = (event: any): void => {
		const elements = document.querySelectorAll(".feedbackContainerRef") ?? [];
		elements.forEach((element) => {
			const closeButton = element.querySelector(".closeIcon");
			if (
				!(element as HTMLElement).contains(event.target) ||
				(closeButton && (closeButton as HTMLElement).contains(event.target))
			) {
				(element as HTMLElement).style.display = "none";
			}
		});
	};

	setProperty = async <K extends keyof IUIStore>(key: K, value: IUIStore[K]) => {
		runInAction(() => ((this as IUIStore)[key] = value));
	};

	hideDesignEditorContainer = (): void => {
		const container = this.rootStore.designEditorStore.containerRef?.current;
		if (container) {
			container.style.display = "none";
		}
	};

	showDesignEditorContainer = (): void => {
		const container = this.rootStore.designEditorStore.containerRef?.current;
		if (container) {
			container.style.display = "flex";
		}
	};
}
