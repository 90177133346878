import { getSummerHost } from "../config/env";

class LinkConstants {
	public static readonly BRIA_WEBSITE: string = "https://bria.ai/";
	public static readonly PRIVACY_POLICY: string = "https://bria.ai/privacy-policy";
	public static readonly LEGAL_LOBBY: string = "https://bria.ai/legal-lobby";
	public static readonly BRIA_API: string = "https://docs.bria.ai";
	public static readonly IFRAME_DOCS: string = "https://github.com/Bria-AI/iframe-doc";
	public static readonly IFRAME_v2_DOCS: string = "https://github.com/Bria-AI/iframe-doc/tree/iframe-v2";
	public static readonly CONTACT_US_FORM_LINK: string = "https://bria.ai/contact-us";
	public static readonly TERMS_AND_CONDITIONS_LINK: string = "https://bria.ai/terms-and-conditions/";
	public static readonly PRESENTER_RECASTING: string = `${getSummerHost()}/gallery/ade0c69399697896?selectedTab=presenters`;
	public static readonly DISCORD_COMMUNITY: string = "https://discord.gg/Nxe9YW9zHS";
	public static readonly SLACK_API_COMMUNITY: string =
		"https://join.slack.com/t/briaapi/shared_invite/zt-2urw5ppmc-4K8hxWLQ1wYLvekB66zm7A";
	public static readonly BRIA_HUGGING_FACE: string = "https://huggingface.co/briaai";
	public static readonly BRIA_AGENT: string = "https://github.com/Bria-AI/agent";
	public static readonly AI_EDITOR: string = `${getSummerHost()}/gallery/8679a552aa79cc96/?from_platform_hub=true&openUploadPopup=true`;
	public static readonly AI_IMAGE_SEARCH: string = `${getSummerHost()}/gallery/?from_platform_hub=true`;
	public static readonly BRIA_IMAGES_URL: string = "https://images.bria.ai/";
	public static readonly BRIA_USER_ASSETS_CLOUDFRONT: string = "https://user-assets.bria.ai/";
	public static readonly BRIA_ORG_ASSETS_CLOUDFRONT: string = "https://org-assets.bria.ai/";
	public static readonly BRIA_AI_AWS_MARKETPLACE: string = `https://aws.amazon.com/marketplace/seller-profile?id=seller-ilfk2fw5juhfi`;
	public static readonly BRIA_AI_AZURE_MARKETPLACE: string =
		"https://azuremarketplace.microsoft.com/en-us/marketplace/apps/briaartificialintelligenceltd1704805814390.bria_image_generation_model_offer?tab=Overview";
	public static readonly BRIA_HF_PRODUCT_SHOT_GEN: string =
		"https://huggingface.co/spaces/briaai/Product-Shot-Generation";
	public static readonly BRIA_FAL_AI: string = "https://fal.ai/models?keywords=bria";
	public static readonly INVOKE_PLATFORM: string = "https://www.invoke.com/";
	// ComfyUI
	public static readonly BRIA_COMFYUI_API: string = "https://github.com/Bria-AI/ComfyUI-BRIA-API";
	public static readonly BRIA_COMFYUI_IMAGE_GEN: string = `${this.BRIA_COMFYUI_API}/tree/main?tab=readme-ov-file#image-generation-nodes`;
	public static readonly BRIA_COMFYUI_IMAGE_EDITING: string = `${this.BRIA_COMFYUI_API}/tree/main?tab=readme-ov-file#image-editing-nodes`;
	public static readonly BRIA_COMFYUI_PRODUCT_SHOT_GEN: string = `${this.BRIA_COMFYUI_API}/tree/main?tab=readme-ov-file#product-shot-editing-nodes`;
	public static readonly BRIA_COMFYUI_TAILORED_GEN: string = `${this.BRIA_COMFYUI_API}/tree/main?tab=readme-ov-file#tailored-generation-nodes`;
	// Image Generation
	public static readonly BRIA_API_TEXT_TO_IMAGE: string = `${this.BRIA_API}/image-generation/endpoints/text-to-image-base`;
	public static readonly BRIA_API_TEXT_TO_IMAGE_FAST: string = `${this.BRIA_API}/image-generation/endpoints/text-to-image-fast`;
	public static readonly BRIA_API_TEXT_TO_IMAGE_HD: string = `${this.BRIA_API}/image-generation/endpoints/text-to-image-hd`;
	public static readonly BRIA_API_TEXT_TO_VECTOR: string = `${this.BRIA_API}/image-generation/endpoints/text-to-vector-base`;
	public static readonly BRIA_API_TEXT_TO_VECTOR_FAST: string = `${this.BRIA_API}/image-generation/endpoints/text-to-vector-fast`;
	public static readonly BRIA_API_IMAGE_REFERENCE: string = `${this.BRIA_API}/image-generation/endpoints/reimagine-structure-reference`;
	public static readonly BRIA_API_PROMPT_ENHANCE: string = `${this.BRIA_API}/image-generation/endpoints/prompt-enhancer`;
	public static readonly BRIA_API_REFINE_FACES: string = `${this.BRIA_API}/image-generation/endpoints/refine-faces`;
	// Tailored Generation
	public static readonly BRIA_API_TG_GENERATE_IMAGES: string = `${this.BRIA_API}/tailored-generation/generation-endpoints/text-to-image-tailored`;
	public static readonly BRIA_API_TG_RESTYLE_PORTRAITS: string = `${this.BRIA_API}/tailored-generation/generation-endpoints/restyle-portrait`;
	public static readonly BRIA_API_BRANDED_MODELS_MANAGEMENT: string = `${this.BRIA_API}/tailored-generation/training-endpoints/create-project`;
	public static readonly BRIA_API_DATASET_MANAGMENT: string = `${this.BRIA_API}/tailored-generation/training-endpoints/create-dataset`;
	public static readonly BRIA_API_TRAIN_MODELS: string = `${this.BRIA_API}/tailored-generation/training-endpoints/create-model`;
	//  Image Editing
	public static readonly BRIA_API_REMOVE_OBJECT: string = `${this.BRIA_API}/image-editing/endpoints/eraser`;
	public static readonly BRIA_API_ERASE_AND_REPLACE: string = `${this.BRIA_API}/image-editing/endpoints/gen-fill`;
	public static readonly BRIA_API_BACKGROUND_REMOVE: string = `${this.BRIA_API}/image-editing/endpoints/background-remove`;
	public static readonly BRIA_API_BACKGROUND_GENERATION: string = `${this.BRIA_API}/image-editing/endpoints/background-replace`;
	public static readonly BRIA_API_ERASER_FOREGROUND: string = `${this.BRIA_API}/image-editing/endpoints/erase-foreground`;
	public static readonly BRIA_API_BACKGROUND_BLUR: string = `${this.BRIA_API}/image-editing/endpoints/blur-bg`;
	public static readonly BRIA_API_IMAGE_EXPANSTION: string = `${this.BRIA_API}/image-editing/endpoints/image-expansion`;
	public static readonly BRIA_API_INCREASE_RESOLUTION: string = `${this.BRIA_API}/image-editing/endpoints/increase-resolution`;
	public static readonly BRIA_API_IMAGE_TO_PSD: string = `${this.BRIA_API}/image-editing/endpoints/image-to-psd`;
	public static readonly BRIA_API_CROP_OUT_FOREGROUND: string = `${this.BRIA_API}/image-editing/endpoints/crop`;
	public static readonly BRIA_API_MASK_GENERATOR: string = `${this.BRIA_API}/image-editing/endpoints/objects-mask-generator`;
	public static readonly BRIA_API_MODIFY_PRESENTER: string = `${this.BRIA_API}/image-editing/endpoints/create`;
	// Video Editing
	public static readonly BRIA_API_VIDEO_BACKGROUND_REMOVE: string = `${this.BRIA_API}/video-editing/endpoints/remove-video-background`;
	// Product Shot Editing
	public static readonly BRIA_API_PRODUCT_CUTOUTS: string = `${this.BRIA_API}/product-shot-editing/endpoints/product-cutout`;
	public static readonly BRIA_API_PRODUCT_PACK_SHOTS: string = `${this.BRIA_API}/product-shot-editing/endpoints/product-packshot`;
	public static readonly BRIA_API_PRODUCT_SHADOW: string = `${this.BRIA_API}/product-shot-editing/endpoints/product-shadow`;
	public static readonly BRIA_API_TEXT_PROMPTS_LIFESTYLE: string = `${this.BRIA_API}/product-shot-editing/endpoints/product-lifestyle-shot-by-text`;
	public static readonly BRIA_API_LIFESTYLE_PRODUCT_SHOT_BY_IMAGE: string = `${this.BRIA_API}/product-shot-editing/endpoints/product-lifestyle-shot-by-image`;
	public static readonly BRIA_API_CONSISTENT_STUDIO_SHOTS: string = `${this.BRIA_API}/product-shot-editing/endpoints/consistent-product-shots`;
	public static readonly BRIA_API_CONTEXTUAL_KEYWORD_EXTRACTION: string = `${this.BRIA_API}/product-shot-editing/endpoints/contextual-keyword-extraction`;
	// AI Search
	public static readonly BRIA_API_LABELESS_AI_IMAGE_SEARCH: string = `${this.BRIA_API}/ai-search/endpoints/search`;
	// Campaing
	public static readonly BRIA_API_CAMPAIGN_GENERATE: string = `${this.BRIA_API}/ad-generation/endpoints/generate-campaign`;
	public static readonly BRIA_API_CAMPAIGN_GET_BRANDS: string = `${this.BRIA_API}/ad-generation/endpoints/get-brands`;
	public static readonly BRIA_API_CAMPAIGN_GET_TEMPLATES: string = `${this.BRIA_API}/ad-generation/endpoints/get-templates`;
	public static readonly BRIA_API_CAMPAIGN_GET_TEMPLATE_BY_ID: string = `${this.BRIA_API}/ad-generation/endpoints/get-template-by-id`;
}

export default LinkConstants;
