import { Box, Divider, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import BriaInput from "../../../../components/common/BriaInput/BriaInput";
import BriaFormSection from "../../../../components/common/FormSection/BriaFormSection";
import RouterConstants from "../../../../constants/RouterConstants";
import useSecureNavigate from "../../../../hooks/useSecureNavigate";
import { useAppStore } from "../../../../hooks/useStores";
import EditorLayout from "../../../../layout/EditorLayout/EditorLayout";
import { TgIpMediums, TgProject, defaultProject } from "../store/tg-projects-store";
import TgIpTypes from "./IpTypes/TgIpTypes";
import styles from "./TgProjectEditor.module.scss";

export type EditTgProjectLocationState = {
	projectToEdit?: TgProject;
};

const TgProjectEditor = () => {
	const { t } = useTranslation("translation", { keyPrefix: "newTg.projects.editor" });
	const location = useLocation();
	const navigate = useSecureNavigate();
	const { projectId } = useParams();
	const { uiStore, tgStore } = useAppStore();
	const { tgProjectStore } = tgStore;
	const isEditMode = !!tgProjectStore.projectForm.id;
	const [loadingSave, setLoadingSave] = useState(false);

	useEffect(() => {
		uiStore.hideSideBar();
		uiStore.showBackButton(RouterConstants.NEW_TAILORED_GENERATION.fullPath, undefined, t("backButton"));

		tgProjectStore.projectForm = location.state?.projectToEdit ?? defaultProject;

		(async () => {
			try {
				if (!location.state?.projectToEdit && projectId && projectId !== "create") {
					const project = await tgProjectStore.getProject(Number(projectId));
					tgProjectStore.projectForm = project;
				}
			} catch (err: any) {
				if (err.response?.status === 404) {
					navigate(RouterConstants.NEW_TAILORED_GENERATION.fullPath);
				}
			}
		})();

		return () => {
			uiStore.showSideBar();
			uiStore.hideBackButton();
			window.history.replaceState({}, "");
		};
	}, []);

	const isValidField = <K extends keyof TgProject>(key: K) => !!tgProjectStore.projectForm[key];

	const isDisabledSave = () => {
		const type = tgProjectStore.projectForm.ip_type;

		return (
			(type === "defined_character" && !isValidField("ip_name")) ||
			((type === "defined_character" || type === "object_variants") && !isValidField("ip_description")) ||
			!type ||
			!tgProjectStore.projectForm.project_name ||
			tgProjectStore.formErrors.requiredFields["ip_name"] ||
			tgProjectStore.formErrors.requiredFields["ip_description"]
		);
	};

	const saveProject = async () => {
		try {
			setLoadingSave(true);

			if (isEditMode) {
				await tgProjectStore.updateProject(tgProjectStore.projectForm.id, tgProjectStore.projectForm);
				navigate(RouterConstants.TG_PROJECTS.fullPath);
			} else {
				const newProj = await tgProjectStore.createProject(tgProjectStore.projectForm);
				navigate(`${RouterConstants.TG_PROJECTS.fullPath}/${newProj.id}/${RouterConstants.NEW_TG_MODELS.path}`);
			}
		} finally {
			setLoadingSave(false);
		}
	};

	return (
		<EditorLayout
			name={tgProjectStore.projectForm?.project_name || t("title")}
			primaryButton={isEditMode ? t("primaryButton.edit") : t("primaryButton.create")}
			primaryButtonClick={saveProject}
			loadingPrimaryButton={loadingSave}
			loading={tgProjectStore.loadingProject}
			disablePrimary={isDisabledSave()}
		>
			<Box className={styles.topDescription}>{t("description")}</Box>
			<BriaFormSection
				className={styles.detailsSection}
				header={t("details.header")}
				subHeader={t("details.subHeader")}
			>
				<Box className={styles.detailsInputs}>
					<BriaInput
						className={styles.detailsName}
						placeholder={t("details.name.placeholder")}
						value={tgProjectStore.projectForm.project_name}
						onChange={(e) => tgProjectStore.handleFormChange("project_name", e.target.value)}
						height="40px"
					/>
					<BriaInput
						className={styles.detailsDesc}
						placeholder={t("details.description.placeholder")}
						value={tgProjectStore.projectForm.project_description}
						onChange={(e) => tgProjectStore.handleFormChange("project_description", e.target.value)}
						height="40px"
						inputProps={{ maxLength: 110 }}
					/>
				</Box>
			</BriaFormSection>
			<Divider className={styles.divider} />
			<BriaFormSection
				header={t("medium.header")}
				subHeader={t("medium.subHeader")}
				className={styles.mediumSection}
			>
				<RadioGroup
					className={styles.ipTypeOptions}
					value={tgProjectStore.projectForm.ip_medium}
					onChange={(e) => tgProjectStore.handleFormChange("ip_medium", e.target.value as TgIpMediums)}
				>
					<Box className={styles.ipTypeOption}>
						<FormControlLabel
							className={styles.radioButtonControl}
							value={"illustration"}
							checked={tgProjectStore.projectForm.ip_medium === "illustration"}
							disabled={isEditMode}
							control={<Radio />}
							label={<Box className={styles.labelContent}>{t("tgIpMediums.illustration")}</Box>}
						/>
					</Box>
					<Box className={styles.ipTypeOption}>
						<FormControlLabel
							className={styles.radioButtonControl}
							value={"photography"}
							checked={tgProjectStore.projectForm.ip_medium === "photography"}
							disabled={isEditMode}
							control={<Radio />}
							label={<Box className={styles.labelContent}>{t("tgIpMediums.photography")}</Box>}
						></FormControlLabel>
					</Box>
				</RadioGroup>
			</BriaFormSection>
			<Divider className={styles.divider} />
			<BriaFormSection header={t("ipType.header")} subHeader={t("ipType.subHeader")}>
				<TgIpTypes />
			</BriaFormSection>
		</EditorLayout>
	);
};

export default observer(TgProjectEditor);
