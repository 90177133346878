import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import BriaButton from "../../components/common/BriaButton/BriaButton";
import BriaButtonGroup from "../../components/common/BriaButtonGroup/BriaButtonGroup.tsx";
import LoadingPlaceholder from "../../components/common/LoadingPlaceholder/LoadingPlaceholder";
import RouterConstants from "../../constants/RouterConstants.ts";
import { TAILORED_GENERATION_MODEL_STEP } from "../../constants/TGConstants.ts";
import useSecureNavigate from "../../hooks/useSecureNavigate.tsx";
import { useAppStore } from "../../hooks/useStores.tsx";
import styles from "./TabsLayout.module.scss";

export type ITabLayout = {
	disabled: boolean;
	name: string;
	link: string;
};

interface IProps {
	children: React.ReactNode;
	title: string | ReactNode;
	subTabs: any;
	isLoading?: boolean;
	primaryButtonLabel?: string | ReactNode;
	primaryButtonClickHandler?: (event: any) => void;
	primaryButtonDisabled?: boolean;
	secondaryButtonLabel?: string | ReactNode;
	secondaryButtonClickHandler?: (event: any) => void;
	hideTitle?: boolean;
	className?: string;
	extraComponent?: React.ReactNode;
}

const TabsLayout = ({
	children,
	title,
	subTabs,
	isLoading = false,
	primaryButtonLabel,
	primaryButtonClickHandler,
	primaryButtonDisabled = false,
	secondaryButtonLabel,
	secondaryButtonClickHandler,
	hideTitle = false,
	className,
	extraComponent,
}: IProps) => {
	const navigate = useSecureNavigate();
	const { uiStore, tailoredGenerationStore } = useAppStore();
	const { t } = useTranslation("translation", { keyPrefix: "tabsLayout" });

	function isInstanceOfITabLayout(tab: any): tab is ITabLayout {
		return typeof tab === "object" && tab !== null && "name" in tab && "link" in tab;
	}

	return (
		<Box className={`${className} ${styles.container}`}>
			<Box className={styles.headingContainer}>
				<Box>{!hideTitle && <Typography className={styles.titleStyle}>{title}</Typography>}</Box>
				{(primaryButtonLabel || secondaryButtonLabel) && (
					<BriaButtonGroup>
						{typeof secondaryButtonLabel === "string" ? (
							<BriaButton onClick={secondaryButtonClickHandler} buttonType="textMedium">
								{secondaryButtonLabel}
							</BriaButton>
						) : (
							secondaryButtonLabel
						)}
						{typeof primaryButtonLabel === "string" ? (
							<BriaButton
								onClick={primaryButtonClickHandler}
								buttonType="primaryMedium"
								disabled={primaryButtonDisabled}
							>
								{primaryButtonLabel}
							</BriaButton>
						) : (
							primaryButtonLabel
						)}
					</BriaButtonGroup>
				)}

				{uiStore.tg_step === TAILORED_GENERATION_MODEL_STEP.LIST_MODELS && (
					<BriaButtonGroup>
						<BriaButton
							onClick={() => uiStore.showDialog("GuidelinesForTailoredModelsDialog")}
							buttonType="textMedium"
						>
							{t("buttons.datasetBestPractices")}
						</BriaButton>
						{tailoredGenerationStore.models.length > 0 && (
							<BriaButton
								className={styles.primaryButton}
								buttonType="primaryMedium"
								onClick={() => navigate(RouterConstants.TG_CREATE_MODEL.fullPath)}
								requiresApiAccess={true}
							>
								{t("buttons.createModel")}
							</BriaButton>
						)}
					</BriaButtonGroup>
				)}
			</Box>

			<Box className={styles.tabsContainer}>
				{subTabs.map((tab: any, index: number) => (
					<NavLink
						key={index}
						to={isInstanceOfITabLayout(tab) ? tab.link : tab}
						className={clsx({
							[styles.tabContainer]: true,
							[styles.disabled]: isInstanceOfITabLayout(tab) ? tab.disabled : false,
						})}
					>
						{({ isActive }: { isActive: boolean }) => (
							<>
								<h3 className={isActive ? styles.boldText : ""}>
									{t(isInstanceOfITabLayout(tab) ? tab.name : tab, { keyPrefix: false })}
								</h3>
								{isActive && <div />}
							</>
						)}
					</NavLink>
				))}
				{extraComponent && <Box className={styles.extraComponentsContainer}>{extraComponent}</Box>}
			</Box>
			<LoadingPlaceholder isLoading={isLoading} className={styles.loadingPlaceholder}>
				<Box className={styles.contentContainer}>{children}</Box>
			</LoadingPlaceholder>
		</Box>
	);
};

const ObservedComponent = observer(TabsLayout);
export default ObservedComponent;
