import { Box } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { BriaImageProps } from "../../../../../components/common/Galleries/BriaImage.tsx";
import ScrollableGallery from "../../../../../components/common/Galleries/ScrollableGallery.tsx";
import Selectable from "../../../../../components/common/Selectable/Selectable.tsx";
import { useAppStore } from "../../../../../hooks/useStores.tsx";
import { PlaygroundImage } from "../../../../../models/image-to-image.ts";
import PlaygroundImageOverlay from "../../../../Playground/Images/Overlay/PlaygroundImageOverlay.tsx";
import styles from "./ProjectFavoriteImages.module.scss";

const ProjectFavoriteImages = () => {
	const { playgroundStore } = useAppStore();
	const { projectsStore } = playgroundStore;
	const [openFullScreen, setOpenFullScreen] = useState(false);
	const resultsRef = useRef<HTMLDivElement>(null);
	const { project_id } = useParams();

	useEffect(() => {
		getImages();
		return () => {
			projectsStore.handleSelectImagesElements([], "favoriteImages");
		};
	}, [project_id]);

	const getImages = () => {
		if (project_id) {
			projectsStore.getFavoriteImages(project_id, 1);
		}
	};

	return (
		<Box className={clsx(styles.uploadORSaveGalleryWrapper, "galleryWrapper")}>
			<Selectable
				selectoRef={playgroundStore.selectoRef as any}
				selectableTargets={[".selectableImage"]}
				onSelect={(e) => projectsStore.handleSelectImagesElements(e.selected, "favoriteImages")}
				innerScrollRef={resultsRef}
				className={styles.selectable}
				type={"multi"}
			>
				<ScrollableGallery
					images={projectsStore.favoriteImages.items.map(
						(image) =>
							({
								...image,
								ImageOverlay: <PlaygroundImageOverlay {...{ image }} hideAiEditorButton/>,
								displayOverlay: "customHover",
								selectable: true,
								htmlJsonData: image.id,
								fullScreenProps: {
									fileName: image.file?.name.split(".")[0],
								},
								downloadProps: { fileName: playgroundStore.getFileName(image) },
							}) as BriaImageProps & PlaygroundImage,
					)}
					fullScreenProps={{
						open: openFullScreen,
						onClose: () => setOpenFullScreen(false),
						displayMode: "scroll",
					}}
					className={styles.gallery}
				/>
			</Selectable>
		</Box>
	);
};

export default observer(ProjectFavoriteImages);
