import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useAppStore } from "../../../hooks/useStores.tsx";
import { isBriaMember, isBriaOrg } from "../../../utils/index.ts";
import styles from "./CustomerViewBanner.module.scss";

const CustomerViewBanner = () => {
	const { authStore } = useAppStore();
	const { t } = useTranslation("translation", { keyPrefix: "CustomerViewBanner" });

	return (
		<>{isBriaMember(authStore.user) && !isBriaOrg() && <Box className={styles.anouncementBar}>{t("title")}</Box>}</>
	);
};

export default observer(CustomerViewBanner);
