import { Close } from "@mui/icons-material";
import { Alert, Box, CircularProgress, Collapse } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import BriaButton from "../../../../../components/common/BriaButton/BriaButton";
import BriaButtonGroup from "../../../../../components/common/BriaButtonGroup/BriaButtonGroup";
import BriaInput from "../../../../../components/common/BriaInput/BriaInput";
import { useAppStore } from "../../../../../hooks/useStores";
import styles from "./TgPrefixInput.module.scss";

type TgPrefixInputProps = {
	triggerOnChangeExisting: () => Promise<boolean>;
};

const TgPrefixInput = ({ triggerOnChangeExisting }: TgPrefixInputProps) => {
	const { t } = useTranslation("translation", { keyPrefix: "newTg.datasets.editor.form" });
	const { tgStore } = useAppStore();
	const { tgDatasetStore, tgProjectStore } = tgStore;
	const originalCaptionPrefix = useMemo(() => tgDatasetStore.datasetForm.caption_prefix, []);
	const [captionPrefix, setCaptionPrefix] = useState(tgDatasetStore.datasetForm.caption_prefix);
	const [openAlert, setOpenAlert] = useState(false);
	const disableActions =
		tgDatasetStore.loadingRegenerate ||
		tgDatasetStore.loadingDuplicateDataset ||
		tgDatasetStore.loadingGeneratePrefix ||
		tgDatasetStore.datasetForm.images.some((img) => img.uploading);
	const [loadingUpdate, setLoadingUpdate] = useState(false);

	const resetPrefix = () => {
		setCaptionPrefix(originalCaptionPrefix);
	};

	const updatePrefix = async (newPrefix: string) => {
		setLoadingUpdate(true);
		try {
			await triggerOnChangeExisting();
			await tgDatasetStore.updateDataset(tgDatasetStore.datasetForm.id, {
				...tgDatasetStore.datasetForm,
				caption_prefix: newPrefix,
			});
			tgDatasetStore.handleFormChange("caption_prefix", newPrefix);
			setOpenAlert(true);
		} finally {
			setLoadingUpdate(false);
		}
	};

	const closeWarning = () => {
		setOpenAlert(false);
	};

	const generateAllCaptions = async () => {
		await tgDatasetStore.regenerateAllCaptions(tgDatasetStore.datasetForm.id);
		closeWarning();
	};

	const regeneratePrefix = async () => {
		try {
			const imagesURLS = tgDatasetStore.datasetForm.images.map((image) => image.image_url);
			const generatedPrefix = await tgDatasetStore.imagesSampleGeneratePrefix(tgProjectStore.projectToView, {
				imagesURLs: imagesURLS,
			});
			setCaptionPrefix(generatedPrefix.prefix);
		} catch (e) {
			console.error(e);
		}
	};

	const prefixAction = () => {
		if (tgProjectStore.projectToView.ip_type === "stylized_scene") {
			return (
				<BriaButton
					buttonType="textSmall"
					onClick={regeneratePrefix}
					disabled={disableActions || loadingUpdate}
				>
					{t("prefix.regenerateButton")}
				</BriaButton>
			);
		} else {
			return (
				<BriaButton
					buttonType="textSmall"
					onClick={resetPrefix}
					disabled={captionPrefix === originalCaptionPrefix}
				>
					{t("prefix.resetButton")}
				</BriaButton>
			);
		}
	};

	return (
		<>
			<Box className={styles.prefixInputWrapper}>
				<BriaInput
					className={styles.prefixInput}
					placeholder={t("prefix.placeholder")}
					value={captionPrefix}
					onChange={(e) => setCaptionPrefix(e.target.value)}
					disabled={disableActions || loadingUpdate}
					InputProps={{
						className: clsx(styles.inputBase, {
							[styles.InputGeneratingPrefix]: tgDatasetStore.loadingGeneratePrefix,
						}),
						endAdornment: prefixAction(),
					}}
				/>
				<BriaButton
					className={styles.updateButton}
					buttonType="secondaryMedium"
					onClick={() => updatePrefix(captionPrefix)}
					loading={loadingUpdate}
					disabled={disableActions || captionPrefix === tgDatasetStore.datasetForm.caption_prefix}
				>
					{t("prefix.updateButton")}
				</BriaButton>
				{tgDatasetStore.loadingGeneratePrefix && (
					<Box className={styles.generatingPrefix}>
						<CircularProgress color="primary" size={18} />
						{t("prefix.generating")}
					</Box>
				)}
			</Box>
			<Collapse in={openAlert}>
				<Alert
					className={styles.warning}
					severity="info"
					color="warning"
					action={
						<BriaButtonGroup spacing={0.5}>
							<BriaButton
								buttonType="textSmall"
								onClick={generateAllCaptions}
								loading={tgDatasetStore.loadingRegenerate}
								disabled={disableActions}
							>
								{t("prefix.warning.button")}
							</BriaButton>
							<Close className={styles.closeButton} sx={{ fontSize: "20px" }} onClick={closeWarning} />
						</BriaButtonGroup>
					}
				>
					{t("prefix.warning.message")}
				</Alert>
			</Collapse>
		</>
	);
};

export default observer(TgPrefixInput);
