import { fabric } from "fabric";

export function loadFabricImageFromURL(src: string) {
	return new Promise((resolve) => {
		fabric.Image.fromURL(src, function (img) {
			resolve(img);
		});
	});
}
export function loadImageFromURL(src: string): Promise<HTMLImageElement> {
	return new Promise((resolve) => {
		const image = new Image();
		const isBase64 = src.startsWith("data:image");

		if (!isBase64) {
			image.crossOrigin = "Anonymous";
			image.src = src + `?${new Date().getTime()}`;
		} else {
			image.src = src;
		}

		image.onload = () => resolve(image);
	});
}
