import { Box, FormControlLabel, FormGroup } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import BriaCheckbox from "../../../../components/common/BriaCheckbox/BriaCheckbox";
import DebouncedInput from "../../../../components/common/DebouncedInput/DebouncedInput.tsx";
import { GeneralIframeConfig } from "../../../../models/new-iframe";
import iframeStore from "../../iframe-store.tsx";
import styles from "./NewIframeForm.module.scss";

const IframeGeneralConfig = () => {
	const { iframeForm, handleFormChange } = iframeStore;
	const { t } = useTranslation("translation", { keyPrefix: "newIframes.editor.form.generalConfig" });

	const handleControlChange = (configKey: keyof GeneralIframeConfig, checked: boolean) => {
		handleFormChange("config", {
			...iframeForm.config,
			general_config: {
				...iframeForm.config.general_config,
				[configKey]: checked,
			},
		});
	};

	const handleSaveLabelChange = (value: string) => {
		handleFormChange("config", {
			...iframeForm.config,
			general_config: {
				...iframeForm.config.general_config,
				save_label: value,
			},
		});
	};

	const handleFeedbackEmailChange = (value: string) => {
		handleFormChange("config", {
			...iframeForm.config,
			general_config: {
				...iframeForm.config.general_config,
				user_notification_email: value,
			},
		});
	};

	return (
		<FormGroup>
			{Object.entries(iframeForm.config.general_config || {})
				.filter(([_key, value]) => typeof value === "boolean")
				.map(([key, value]) => (
					<Box
						key={key}
						className={clsx({
							[styles.checkboxWithField]: true,
							[styles.feedbackCheckboxWithField]: key === "enable_feedback_buttons",
						})}
					>
						{((key !== "enable_psd" && key !== "enable_increase_res") ||
							(iframeForm.config.general_config || {}).enable_download ||
							(iframeForm.config.general_config || {}).enable_save) && (
							<FormControlLabel
								key={key}
								control={
									<BriaCheckbox
										checked={value as boolean}
										onChange={(e) =>
											handleControlChange(key as keyof GeneralIframeConfig, e.target.checked)
										}
									/>
								}
								label={t(`checkboxes.${key}`)}
							/>
						)}
						{key === "enable_save" && value && (
							<DebouncedInput
								value={iframeForm?.config.general_config?.save_label}
								onChange={(e) => handleSaveLabelChange(e.target.value)}
								placeholder={t("saveLabel.placeholder")}
								InputProps={{ classes: { root: styles.smallTextField } }}
							/>
						)}
						{key === "enable_feedback_buttons" && value && (
							<DebouncedInput
								value={iframeForm?.config.general_config?.user_notification_email}
								onChange={(e) => handleFeedbackEmailChange(e.target.value)}
								placeholder={t("feedback.placeholder")}
								InputProps={{ classes: { root: styles.smallTextField } }}
							/>
						)}
					</Box>
				))}
		</FormGroup>
	);
};

export default observer(IframeGeneralConfig);
