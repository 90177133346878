import { Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAttributionReports } from "../../../../hooks/useAttributionReports.tsx";
import { useAppStore } from "../../../../hooks/useStores.tsx";
import TableLayout, { TableRow } from "../../../../layout/TableLayout/TableLayout.tsx";
import { ICatalogReport, ICatalogReportTableRow } from "../../../../models/attribution-reports.ts";
import { showErrorToast } from "../../../../utils/toast.tsx";
import { toJS } from "mobx";

const CatalogReport = () => {
	const { t } = useTranslation("translation", { keyPrefix: "account.attributionReports" });
	const { attributionReportsStore } = useAppStore();
	const { createCatalogReportTableRow } = useAttributionReports();
	const isMounted = useRef(false);
	const [catalogReportTableData, setCatalogReportTableData] = useState<TableRow<ICatalogReportTableRow>[]>([]);
	const tableHeaders = t("tables.catalogReport.headers", { returnObjects: true }) as string[];

	useEffect(() => {
		const loadData = async () => {
			if (!attributionReportsStore.catalogReportData.length || isMounted.current) {
				try {
					await attributionReportsStore.loadCatalogReportData();
				} catch (e: any) {
					showErrorToast(t("pleaseTryAgainLater"));
				}
			}
			setCatalogReportTableData(
				attributionReportsStore.catalogReportData.map((data: ICatalogReport) => {
					return createCatalogReportTableRow(data);
				}),
			);
		};
		loadData();
	}, [JSON.stringify(toJS(attributionReportsStore.selectedDateRange))]);

	useEffect(() => {
		isMounted.current = true;
		attributionReportsStore.setSelectedTab(t("tabs.catalogReport"));
	}, []);

	return (
		<>
			<TableLayout
				hideHeader={true}
				headerArray={tableHeaders}
				tableData={catalogReportTableData}
				loading={attributionReportsStore.isCatalogReportDataLoading}
				emptyStateComponent={<Typography>{t("tables.catalogReport.emptyState")}</Typography>}
			/>
		</>
	);
};
export default observer(CatalogReport);
