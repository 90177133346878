import { logEvent, setUserId, setUserProperties } from "firebase/analytics";
import { makeAutoObservable } from "mobx";
import { firebaseAnalytics } from "./config/firebase.ts";
import { getSelectedOrganization } from "./helpers/localStorage.ts";
import { IRootStore } from "./mobx/root-store.tsx";
import { ImageToImageConfigType, PlaygroundImage } from "./models/image-to-image.ts";
import User from "./models/user.ts";
import iframeStore from "./pages/IframeNew/iframe-store.tsx";
import CrmService from "./services/CrmService.ts";

export enum ANALYTICS_EVENTS {
	REGISTER = "REGISTER",
	LOGIN = "LOGIN",
	IMAGE_UPLOAD_ITI = "IMAGE_UPLOAD_ITI",
	IMAGE_DOWNLOAD_ITI = "IMAGE_DOWNLOAD_ITI",
	IMAGE_ERROR_ITI = "IMAGE_ERROR_ITI",
	IMAGE_COMPARE_ITI = "IMAGE_COMPARE_ITI",
	PLAYGROUND_IMAGE_DOWNLOAD_ITI = "PLAYGROUND_IMAGE_DOWNLOAD_ITI",
	PLAYGROUND_IMAGE_UPLOAD = "PLAYGROUND_IMAGE_UPLOAD",
	PLAYGROUND_IMAGE_SAVE_ITI = "PLAYGROUND_IMAGE_SAVE_ITI",
	ITI_GENERATE = "ITI_GENERATE",
	ITI_MIX_FACE_BODY = "ITI_MIX_FACE_BODY",
	ITI_REFINE_IMAGE = "ITI_REFINE_IMAGE",
	ITI_COLOR_REFINE_IMAGE = "ITI_COLOR_REFINE_IMAGE",
	IMAGE_DOWNLOAD = "IMAGE_DOWNLOAD",
	IMAGE_UPLOAD_PP = "IMAGE_UPLOAD_PP",
	IMAGE_DOWNLOAD_PP = "IMAGE_DOWNLOAD_PP",
	PP_GENERATE = "PP_GENERATE",
	MANUAL_PP_GENERATE = "MANUAL_PP_GENERATE",
	IMAGE_GENERATION_GENERATE = "IMAGE_GENERATION_GENERATE",
	IMAGE_GENERATION_BRIA_BASE = "IMAGE_GENERATION_BRIA_BASE",
	IMAGE_GENERATION_BRIA_FAST = "IMAGE_GENERATION_BRIA_FAST",
	IMAGE_GENERATION_BRIA_HD = "IMAGE_GENERATION_BRIA_HD",
	IMAGE_GENERATION_TAILORED_MODELS = "IMAGE_GENERATION_TAILORED_MODELS",
	IMAGE_GENERATION_IMAGE_REFERENCE = "IMAGE_GENERATION_IMAGE_REFERENCE",
	IMAGE_GENERATION_DOWNLOAD_GENERAL = "IMAGE_GENERATION_DOWNLOAD_GENERAL",
	IMAGE_GENERATION_DOWNLOAD_BRIA_BASE = "IMAGE_GENERATION_DOWNLOAD_BRIA_BASE",
	IMAGE_GENERATION_DOWNLOAD_BRIA_FAST = "IMAGE_GENERATION_DOWNLOAD_BRIA_FAST",
	IMAGE_GENERATION_DOWNLOAD_BRIA_HD = "IMAGE_GENERATION_DOWNLOAD_BRIA_HD",
	IMAGE_GENERATION_DOWNLOAD_TAILORED_MODELS = "IMAGE_GENERATION_DOWNLOAD_TAILORED_MODELS",
	IMAGE_GENERATION_DOWNLOAD_IMAGE_REFERENCE = "IMAGE_GENERATION_DOWNLOAD_IMAGE_REFERENCE",
	IMAGE_GENERATION_INCREASE_RESOLUTION = "IMAGE_GENERATION_INCREASE_RESOLUTION",
	IMAGE_EDITING_GENERATE = "IMAGE_EDITING_GENERATE",
	IMAGE_EDITING_REMOVE_BACKGROUND = "IMAGE_EDITING_REMOVE_BACKGROUND",
	IMAGE_EDITING_EXPAND_IMAGE = "IMAGE_EDITING_EXPAND_IMAGE",
	IMAGE_EDITING_BLUR_BACKGROUND = "IMAGE_EDITING_BLUR_BACKGROUND",
	IMAGE_EDITING_SOLID_BACKGROUND = "IMAGE_EDITING_SOLID_BACKGROUND",
	IMAGE_EDITING_GENERATE_BACKGROUND_BY_TEXT = "IMAGE_EDITING_GENERATE_BACKGROUND_BY_TEXT",
	IMAGE_EDITING_ASPECT_RATIO = "IMAGE_EDITING_ASPECT_RATIO",
	IMAGE_EDITING_CUSTOM_SIZE = "IMAGE_EDITING_CUSTOM_SIZE",
	IMAGE_EDITING_MEDIUM_DESTINATION = "IMAGE_EDITING_MEDIUM_DESTINATION",
	IMAGE_EDITING_GENERATE_FOREGROUND = "IMAGE_EDITING_GENERATE_FOREGROUND",
	IMAGE_EDITING_DOWNLOAD_GENERAL = "IMAGE_EDITING_DOWNLOAD_GENERAL",
	IMAGE_EDITING_DOWNLOAD_REMOVE_BACKGROUND = "IMAGE_EDITING_DOWNLOAD_REMOVE_BACKGROUND",
	IMAGE_EDITING_DOWNLOAD_EXPAND_IMAGE = "IMAGE_EDITING_DOWNLOAD_EXPAND_IMAGE",
	IMAGE_EDITING_DOWNLOAD_BLUR_BACKGROUND = "IMAGE_EDITING_DOWNLOAD_BLUR_BACKGROUND",
	IMAGE_EDITING_DOWNLOAD_SOLID_BACKGROUND = "IMAGE_EDITING_DOWNLOAD_SOLID_BACKGROUND",
	IMAGE_EDITING_DOWNLOAD_GENERATE_BACKGROUND_BY_TEXT = "IMAGE_EDITING_DOWNLOAD_GENERATE_BACKGROUND_BY_TEXT",
	IMAGE_EDITING_DOWNLOAD_GENERATE_SIZE = "IMAGE_EDITING_DOWNLOAD_GENERATE_SIZE",
	IMAGE_EDITING_DOWNLOAD_ASPECT_RATIO = "IMAGE_EDITING_DOWNLOAD_ASPECT_RATIO",
	IMAGE_EDITING_DOWNLOAD_CUSTOM_SIZE = "IMAGE_EDITING_DOWNLOAD_CUSTOM_SIZE",
	IMAGE_EDITING_DOWNLOAD_MEDIUM_DESTINATION = "IMAGE_EDITING_DOWNLOAD_MEDIUM_DESTINATION",
	IMAGE_EDITING_DOWNLOAD_FOREGROUND = "IMAGE_EDITING_DOWNLOAD_FOREGROUND",
	IMAGE_EDITING_INCREASE_RESOLUTION = "IMAGE_EDITING_INCREASE_RESOLUTION",
	IMAGE_EDITING_ERASE = "IMAGE_EDITING_ERASE",
	SAVE_NEW_CAMPAIGN = "SAVE_NEW_CAMPAIGN",
	CREATE_NEW_TEMPLATE = "CREATE_NEW_TEMPLATE",
	EXPORT_CAMPAIGN = "EXPORT_CAMPAIGN",
	DOWNLOAD_FIGMA_PLUGIN = "DOWNLOAD_FIGMA_PLUGIN",
	DOWNLOAD_PHOTOSHOP_PLUGIN = "DOWNLOAD_PHOTOSHOP_PLUGIN",
	AUTOMOTIVE_GENERATE = "AUTOMOTIVE_GENERATE",
	AUTOMOTIVE_REMOVE_BACKGROUND = "AUTOMOTIVE_REMOVE_BACKGROUND",
	AUTOMOTIVE_EXPAND_IMAGE = "AUTOMOTIVE_EXPAND_IMAGE",
	AUTOMOTIVE_BLUR_BACKGROUND = "AUTOMOTIVE_BLUR_BACKGROUND",
	AUTOMOTIVE_SOLID_BACKGROUND = "AUTOMOTIVE_SOLID_BACKGROUND",
	AUTOMOTIVE_GENERATE_BACKGROUND_BY_TEXT = "AUTOMOTIVE_GENERATE_BACKGROUND_BY_TEXT",
	AUTOMOTIVE_ASPECT_RATIO = "AUTOMOTIVE_ASPECT_RATIO",
	AUTOMOTIVE_CUSTOM_SIZE = "AUTOMOTIVE_CUSTOM_SIZE",
	AUTOMOTIVE_MEDIUM_DESTINATION = "AUTOMOTIVE_MEDIUM_DESTINATION",
	AUTOMOTIVE_GENERATE_FOREGROUND = "AUTOMOTIVE_GENERATE_FOREGROUND",
	AUTOMOTIVE_DOWNLOAD_GENERAL = "AUTOMOTIVE_DOWNLOAD_GENERAL",
	AUTOMOTIVE_DOWNLOAD_REMOVE_BACKGROUND = "AUTOMOTIVE_DOWNLOAD_REMOVE_BACKGROUND",
	AUTOMOTIVE_DOWNLOAD_EXPAND_IMAGE = "AUTOMOTIVE_DOWNLOAD_EXPAND_IMAGE",
	AUTOMOTIVE_DOWNLOAD_BLUR_BACKGROUND = "AUTOMOTIVE_DOWNLOAD_BLUR_BACKGROUND",
	AUTOMOTIVE_DOWNLOAD_SOLID_BACKGROUND = "AUTOMOTIVE_DOWNLOAD_SOLID_BACKGROUND",
	AUTOMOTIVE_DOWNLOAD_GENERATE_BACKGROUND_BY_TEXT = "AUTOMOTIVE_DOWNLOAD_GENERATE_BACKGROUND_BY_TEXT",
	AUTOMOTIVE_DOWNLOAD_GENERATE_SIZE = "AUTOMOTIVE_DOWNLOAD_GENERATE_SIZE",
	AUTOMOTIVE_DOWNLOAD_ASPECT_RATIO = "AUTOMOTIVE_DOWNLOAD_ASPECT_RATIO",
	AUTOMOTIVE_DOWNLOAD_CUSTOM_SIZE = "AUTOMOTIVE_DOWNLOAD_CUSTOM_SIZE",
	AUTOMOTIVE_DOWNLOAD_MEDIUM_DESTINATION = "AUTOMOTIVE_DOWNLOAD_MEDIUM_DESTINATION",
	AUTOMOTIVE_DOWNLOAD_FOREGROUND = "AUTOMOTIVE_DOWNLOAD_FOREGROUND",
	AUTOMOTIVE_INCREASE_RESOLUTION = "AUTOMOTIVE_INCREASE_RESOLUTION",
	AUTOMOTIVE_ERASE = "AUTOMOTIVE_ERASE",
	DOWNLOAD_TG_MODEL_SOURCE = "DOWNLOAD_TG_MODEL_SOURCE",
	TG_MODEL_TRAINING_STARTED = "TG_MODEL_TRAINING_STARTED",
}

export interface IAnalyticsStore {
	setUser(currentUser: User | null, registrationSource?: string): Promise<void>;

	logRegistrationEvent(): void;

	logEvent(eventName: ANALYTICS_EVENTS, eventParams?: { [key: string]: any }): void;

	logImageToImageEvent(eventName: ANALYTICS_EVENTS, image: PlaygroundImage): void;
}

export default class AnalyticsStore implements IAnalyticsStore {
	rootStore: IRootStore;
	crmService = new CrmService();

	constructor(rootStore: IRootStore) {
		makeAutoObservable(this);
		this.rootStore = rootStore;
	}

	setHubspotUser = async (currentUser: User, _registrationSource?: string) => {
		if (!iframeStore.isIframe()) {
			await this.crmService.upsertContact();

			if (currentUser) {
				const _hsq = ((window as any)._hsq = (window as any)._hsq || []);
				_hsq.push([
					"identify",
					{
						email: currentUser.email,
					},
				]);
			}
		}
	};

	setUser = async (currentUser: User | null, registrationSource?: string) => {
		if (currentUser) {
			setUserProperties(firebaseAnalytics, {
				role: currentUser.role,
				company: currentUser.company,
				user_domain: currentUser.email.split("@").pop(),
				user_id: currentUser.uid,
			});
			setUserId(firebaseAnalytics, currentUser.uid);
			await this.setHubspotUser(currentUser, registrationSource);
		}
	};

	logRegistrationEvent = () => {
		this.logEvent(ANALYTICS_EVENTS.REGISTER);
		(window as any).lintrk("track", {
			conversion_id: 13339457,
		});
	};

	logEvent = (eventName: ANALYTICS_EVENTS, eventParams: { [key: string]: any } = {}) => {
		logEvent(firebaseAnalytics, eventName, {
			platformUserId: this.rootStore.authStore.user?.uid,
			platformOrgId: getSelectedOrganization()?.organization.uid,
			platformOrgName: getSelectedOrganization()?.organization.name,
			...eventParams,
		});
	};

	logImageToImageEvent = (eventName: ANALYTICS_EVENTS, image: PlaygroundImage) => {
		this.logEvent(eventName, {
			itiSessionId: image?.sessionId ?? "",
			tailored_style: (image.config as ImageToImageConfigType)?.style?.tailored_style,
			sub_style: (image.config as ImageToImageConfigType)?.style?.sub_style,
		});
	};
}
