import { Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box } from "@mui/system";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import FullScreenViewer from "../../../../components/common/FullScreenViewer/FullScreenViewer.tsx";
import BriaImage from "../../../../components/common/Galleries/BriaImage.tsx";
import ImageLocked from "../../../../assets/images/svgs/image_locked.svg";
import { useAppStore } from "../../../../hooks/useStores.tsx";
import { TableRow as ITableRow } from "../../../../layout/TableLayout/TableLayout.tsx";
import { ITopImages } from "../../../../models/attribution-reports.ts";
import styles from "./TopImages.module.scss";

interface IProps {
	headers: string[];
	rows: ITableRow<ITopImages>[];
}

const theme = createTheme({
	components: {
		// @ts-ignore
		MuiDataGrid: {
			styleOverrides: {
				root: {
					backgroundImage: "#FFF",
				},
			},
		},
	},
});

const TopImagesTable = ({ headers, rows }: IProps) => {
	const { t } = useTranslation("translation", { keyPrefix: "account.attributionReports.tables.topImages" });
	const { authStore } = useAppStore();
	const isSuperAdmin = authStore.user?.isSuperAdmin();
	const [isFullScreenOpen, setIsFullScreenOpen] = useState<boolean>(false);
	const [fullImageUrl, setFullImageUrl] = useState<string>("");

	const handleImageClick = (url: string) => {
		setFullImageUrl(url);
		setIsFullScreenOpen(true);
	};
	const handleImageError = (visual_hash?: string | undefined) => {
		const imageContainer = visual_hash ? document.getElementById(visual_hash): null;
		if (imageContainer) {
			imageContainer.style.pointerEvents = "none";
			const image = imageContainer?.querySelector("img");
			if (image) {
				image.src = ImageLocked;
			}
		}
	};
	const columns: GridColDef[] = [
		{
			field: "partner",
			headerName: headers[0],
			flex: 1,
			cellClassName: styles.partnerCell,
			renderCell: (params: any) => <Typography>{params.value}</Typography>,
		},

		{
			field: "type",
			headerName: headers[1],
			flex: 0,
			cellClassName: styles.typeCell,
			renderCell: (data: any) => (
				<Box className={styles.typesContainer}>
					<Box
						className={clsx(styles.topHintsRow, {
							[styles.hideBorder]: !isSuperAdmin || !data.value.topPaid?.length,
						})}
					>
						<Typography>{t("topHits")}</Typography>
					</Box>
					{isSuperAdmin && Boolean(data.value.topPaid?.length) && (
						<Box className={styles.topPaidRow}>
							<Typography>{t("topPaid")}</Typography>
						</Box>
					)}
				</Box>
			),
		},

		{
			field: "images",
			headerName: headers[2],
			flex: 3,
			cellClassName: styles.imageCell,
			renderCell: (data: any) => (
				<Box className={styles.imagesContainer}>
					<Box
						className={clsx(styles.imageAndValuesContainer, styles.topHintsRow, {
							[styles.hideBorder]: !isSuperAdmin || !data.value.topPaid?.length,
						})}
					>
						{data.value.topHits?.map((image: any, index: number) => (
							<Box id={`top-hit-image-${data.value.partner}-${index}`}>
								<BriaImage
									className={clsx(styles.briaImage, { [styles.staffView]: !isSuperAdmin })}
									image={{ src: image.url }}
									url={image.url}
									hideFullScreenButton={true}
									handleClick={() => handleImageClick(image.url)}
									visualHash={`top-hit-image-${data.value.partner}-${index}`}
									imageErrorCallBack={handleImageError}
								/>
								<Typography>{image.value}</Typography>
							</Box>
						))}
					</Box>
					{isSuperAdmin && Boolean(data.value.topPaid?.length) && (
						<Box className={styles.imageAndValuesContainer}>
							{data.value.topPaid?.map((image: any, index: number) => (
								<Box id={`top-paid-image-${data.value.partner}-${index}`}>
									<BriaImage
										className={styles.briaImage}
										image={{ src: image.url }}
										url={image.url}
										hideFullScreenButton={true}
										handleClick={() => handleImageClick(image.url)}
										visualHash={`top-paid-image-${data.value.partner}-${index}`}
										imageErrorCallBack={handleImageError}
									/>
									<Typography>{image.value}</Typography>
								</Box>
							))}
						</Box>
					)}
				</Box>
			),
		},
	];
	const transformedRows = rows.map((row: ITableRow<ITopImages>, index: number) => ({
		id: index,
		partner: row.data.partner,
		type: row.data,
		images: row.data,
	}));
	return (
		<>
			<ThemeProvider theme={theme}>
				<DataGrid
					sx={{
						height: 300,
						width: "100%",
						borderWidth: "0!important",
						"& .MuiDataGrid-columnSeparator": {
							display: "none", // Hide column separators
						},
					}}
					rowHeight={200}
					rows={transformedRows}
					columns={columns}
					hideFooterPagination={true}
					disableColumnSorting={true}
					disableRowSelectionOnClick={true}
					disableColumnSelector={true}
					disableColumnMenu={true}
					hideFooter={true}
					autoHeight={true}
				/>
			</ThemeProvider>
			<FullScreenViewer
				open={isFullScreenOpen}
				images={[{ src: fullImageUrl }]}
				onClose={() => {
					setIsFullScreenOpen(false);
					setFullImageUrl("");
				}}
				hideDownloadIcon={true}
			/>
		</>
	);
};

export default observer(TopImagesTable);
