import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import InfoIcon from "../../../../../assets/images/icons/InfoIcon";
import BriaButton from "../../../../../components/common/BriaButton/BriaButton";
import BriaTooltip from "../../../../../components/common/BriaTooltip/BriaTooltip";
import { useAppStore } from "../../../../../hooks/useStores";
import { TgTrainingVersion } from "../../store/tg-model-store";
import styles from "./TgTrainingVersionStep.module.scss";

type TgTrainingVersionStepProps = {
	handleNext: () => void;
};

const TgTrainingVersionStep = ({ handleNext }: TgTrainingVersionStepProps) => {
	const { t } = useTranslation("translation", { keyPrefix: "newTg.models.create.steps.trainingVersion" });
	const baseModelTypeOptions = t("options", { returnObjects: true });
	const { tgStore } = useAppStore();
	const { tgModelStore } = tgStore;

	const handleSelect = (trainingVersion: TgTrainingVersion) => {
		tgModelStore.handleFormChange("training_version", trainingVersion);
		handleNext();
	};

	return (
		<Box className={styles.stepWrapper}>
			<Typography className={styles.header}>{t("header")}</Typography>
			<Box className={styles.cards}>
				{Object.keys(baseModelTypeOptions).map((type, index) => (
					<Box key={index} className={styles.card}>
						<Box>
							<Box className={styles.cardNameWrapper}>
								<Typography className={styles.cardName}>
									{t(`options.${type}.name`)}
									<BriaTooltip
										className={styles.tooltip}
										title={t(`options.${type}.tooltip`)}
										displayOn="click"
									>
										<InfoIcon fontSize="small" />
									</BriaTooltip>
								</Typography>
							</Box>
							<Typography className={styles.cardDesc}>{t(`options.${type}.description`)}</Typography>
						</Box>
						<BriaButton
							className={styles.cardButton}
							buttonType="secondaryMedium"
							onClick={() => handleSelect(type as TgTrainingVersion)}
						>
							{t(`options.${type}.button`)}
						</BriaButton>
					</Box>
				))}
			</Box>
		</Box>
	);
};

export default observer(TgTrainingVersionStep);
