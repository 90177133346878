import { makeAutoObservable, runInAction } from "mobx";
import { IRootStore } from "../../../mobx/root-store.tsx";
import {
	ICatalogReport,
	IGenerationReport,
	IPartnerEarnings,
	ITopImages
} from "../../../models/attribution-reports.ts";
import QueryService from "../../../utils/QueryService.ts";
import { convertDateToUtc } from "../../../utils";

export interface IAttributionReportsStore {
	selectedTab: string;
	selectedDateRange: Date[];
	partnersEarningsPPGFile: File | null;
	topPaidImagesPPGFile: File | null;
	isError: boolean;
	generationReportData: IGenerationReport[];
	catalogReportData: ICatalogReport[];
	topImagesData: ITopImages[];
	partnersEarningsData: IPartnerEarnings[];
	isGenerationReportDataLoading: boolean;
	isCatalogReportDataLoading: boolean;
	isTopImagesDataLoading: boolean;
	isPartnersEarningsDataLoading: boolean;

	resetStore: () => void
	setSelectedTab: (selectedTabl: string) => void;
	loadGenerationReportData: () => void;
	loadCatalogReportData: () => void;
	loadTopHitsImagesData: () => void;
	loadTopPaidImagesData: () => void;
	loadPartnersEarningsData: () => void;
}

export default class AttributionReportsStore implements IAttributionReportsStore {
	private queryService: QueryService = new QueryService("/attribution_reports");

	rootStore: IRootStore;
	selectedTab: string = "";
	selectedDateRange: Date[] = [];
	partnersEarningsPPGFile: File | null =  null
	topPaidImagesPPGFile: File | null =  null
	isError: boolean = false;
	generationReportData: IGenerationReport[] = [];
	catalogReportData: ICatalogReport[] = [];
	topImagesData: ITopImages[] = [];
	partnersEarningsData: IPartnerEarnings[] = [];
	isGenerationReportDataLoading: boolean = false;
	isCatalogReportDataLoading: boolean = false;
	isTopImagesDataLoading: boolean = false;
	isPartnersEarningsDataLoading: boolean = false;

	constructor(rootStore: IRootStore) {
		makeAutoObservable(this);
		this.rootStore = rootStore;
	}

	resetStore() {
		this.partnersEarningsPPGFile = null
		this.topPaidImagesPPGFile = null
		this.generationReportData = []
		this.catalogReportData = []
		this.topImagesData = []
		this.partnersEarningsData = []
		this.isGenerationReportDataLoading = false
		this.isCatalogReportDataLoading = false
		this.isTopImagesDataLoading = false
		this.isPartnersEarningsDataLoading = false
	}

	private datesPayload() {
		return {
			startDate: convertDateToUtc(this.selectedDateRange[0]).toISOString(),
			endDate: convertDateToUtc(this.selectedDateRange[1]).toISOString()
		}
	}
	setSelectedTab = (selectedTab: string) => {
		runInAction(() => {
			this.selectedTab = selectedTab;
		});
	};

	loadGenerationReportData = async () => {
		if (this.selectedDateRange.length !== 2) return;
		try {
			this.isGenerationReportDataLoading = true;
			const data: any = await this.queryService.post("/generation", this.datesPayload());
			runInAction(() => {
				this.generationReportData = data;
			});
		} catch (e) {
			runInAction(() => {
				this.isError = true;
			});
			return Promise.reject(e);
		} finally {
			runInAction(() => {
				this.isGenerationReportDataLoading = false;
			});
		}
	};

	loadCatalogReportData = async () => {
		if (this.selectedDateRange.length !== 2) return;
		try {
			this.isCatalogReportDataLoading = true;
			const data: any = await this.queryService.post("/catalog", this.datesPayload());
			runInAction(() => {
				this.catalogReportData = data;
			});
		} catch (e) {
			runInAction(() => {
				this.isError = true;
			});
			return Promise.reject(e);
		} finally {
			runInAction(() => {
				this.isCatalogReportDataLoading = false;
			});
		}
	};

	loadTopHitsImagesData = async () => {
		if (this.selectedDateRange.length !== 2) return;
		try {
			this.isTopImagesDataLoading = true;
			const data: any = await this.queryService.post("/top_hits_images", this.datesPayload());

			runInAction(() => {
				this.topImagesData = data;
			});
		} catch (e) {
			runInAction(() => {
				this.isError = true;
			});
			return Promise.reject(e);
		} finally {
			runInAction(() => {
				this.isTopImagesDataLoading = false;
			});
		}
	};

	loadTopPaidImagesData = async () => {
		if (
			this.selectedDateRange.length !== 2 ||
			this.topPaidImagesPPGFile === null ||
			!this.rootStore.authStore.user?.isSuperAdmin()
		) {
			return;
		}
		
		try {
			this.isTopImagesDataLoading = true;
			const formData = new FormData();
			formData.append("ppgFile", this.topPaidImagesPPGFile);
			formData.append("startDate", this.datesPayload().startDate);
			formData.append("endDate", this.datesPayload().endDate);
			const data: any = await this.queryService.post("/top_paid_images", formData, {
			  "Content-Type": "multipart/form-data",
			});
			runInAction(() => {
				this.topImagesData = this.topImagesData.map((item: ITopImages) => ({
					...item,
					topPaid: data[item.partner] || [],
				}));
			});
		} catch (e) {
			runInAction(() => {
				this.isError = true;
			});
			return Promise.reject(e);
		} finally {
			runInAction(() => {
				this.isTopImagesDataLoading = false;
			});
		}
	}

	loadPartnersEarningsData = async () => {
		if (
			this.selectedDateRange.length !== 2 ||
			this.partnersEarningsPPGFile === null ||
			!this.rootStore.authStore.user?.isSuperAdmin()
		) {
			return;
		}
		try {
			this.isPartnersEarningsDataLoading = true;
			const formData = new FormData();
			formData.append("ppgFile", this.partnersEarningsPPGFile);
			formData.append("startDate", this.datesPayload().startDate);
			formData.append("endDate", this.datesPayload().endDate);
			const data: any = await this.queryService.post("/partners_earnings", formData, {
			  "Content-Type": "multipart/form-data",
			});
			runInAction(() => {
			  this.partnersEarningsData = data;
			});
		} catch (e) {
			runInAction(() => {
				this.isError = true;
			});
			return Promise.reject(e);
		} finally {
			runInAction(() => {
				this.isPartnersEarningsDataLoading = false;
			});
		}
	};
}
