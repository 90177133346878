import { ExpandMore, NavigateNext } from "@mui/icons-material";
import { Accordion, AccordionSummary, Box, Tab, Tabs, Typography } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import CloudUploadSVG from "../../../../../assets/images/icons/CloudUpload";
import BriaButton from "../../../../../components/common/BriaButton/BriaButton";
import BriaSlider from "../../../../../components/common/BriaSlider/BriaSlider";
import ImageUploader from "../../../../../components/common/ImageUploader/ImageUploader";
import { ImageReferenceGalleryConstants } from "../../../../../constants/ImageReferenceGalleryConstants.ts";
import RouterConstants from "../../../../../constants/RouterConstants.ts";
import {
	DEFAULT_PORTRAIT_STRUCTURE_REF_INFLUENCE,
	DEFAULT_STRUCTURE_REF_INFLUENCE,
	IMAGE_REFERENCE_CONFIGS,
	IMAGE_REFERENCE_DISABLED_MODELS,
} from "../../../../../constants/TextToImageConstants.ts";
import { useAppStore } from "../../../../../hooks/useStores";
import InputLayout from "../../../../../layout/InputLayout/InputLayout";
import { ConfigValue } from "../../../../../models/image-to-image";
import iframeStore from "../../../../IframeNew/iframe-store.tsx";
import ImageReferenceGallery from "../ImageReferenceGallery/ImageReferenceGallery";
import styles from "./ImageReference.module.scss";

export enum IMAGE_REFERENCE_TYPES {
	STRUCTURE = "structure",
	PORTRAIT = "portrait",
}

const ImageReference = () => {
	const { t } = useTranslation("translation", {
		keyPrefix: "playground.textToImage.config.imageReference",
	});
	const { textToImageStore } = useAppStore();
	const [isImageGalleryOpen, setIsImageGalleryOpen] = useState<boolean>(false);
	const [isImageReferenceOpen, setIsImageReferenceOpen] = useState<boolean>(true);
	const [activeTab, setActiveTab] = useState(IMAGE_REFERENCE_TYPES.STRUCTURE);
	const isDisabled = IMAGE_REFERENCE_DISABLED_MODELS.includes(
		textToImageStore.config.model + "_" + textToImageStore.config.model_version,
	);
	const tgProjectsLink = RouterConstants.TG_PROJECTS.fullPath;
	const imageReferenceTabs = [
		{
			name: IMAGE_REFERENCE_TYPES.STRUCTURE,
			title: t("referenceGallery"),
			subTitle: t("referenceGallerySubtitle"),
			images: [
				{
					title: t("imageGallery.titles.sketch_to_illustration"),
					images: ImageReferenceGalleryConstants.sketch_to_illustration,
				},
				{
					title: t("imageGallery.titles.photo_variations"),
					images: ImageReferenceGalleryConstants.photo_variations,
				},
				{
					title: t("imageGallery.titles.illustration_variations"),
					images: ImageReferenceGalleryConstants.illustration_variations,
				},
				{
					title: t("imageGallery.titles.typography_logo_variations"),
					images: ImageReferenceGalleryConstants.typography_logo_variations,
				},
			],
		},
		{
			name: IMAGE_REFERENCE_TYPES.PORTRAIT,
			title: t("portraitReferenceGallery"),
			subTitle: t("portraitReferenceGallerySubtitle", { tgProjectsLink }),
			images: [
				{
					title: t("imageGallery.titles.portrait"),
					description: t("imageGallery.descriptions.portrait"),
					images: ImageReferenceGalleryConstants.portrait,
				},
			],
		},
	];

	if (iframeStore.isIframe() && !iframeStore.iframe.config.image_generation_config?.enable_restyle_portraits) {
		imageReferenceTabs.pop();
	}

	const step = activeTab === IMAGE_REFERENCE_TYPES.PORTRAIT ? 0.05 : 0.25;
	const marks = Array.from({ length: Math.floor(1 / step) + 1 }, (_, i) => {
		const value = i * step;
		return {
			value,
			label: value === 0 ? t("loose") : value === 1 ? t("strict") : undefined,
		};
	});

	useEffect(() => {
		if (textToImageStore.config.training_version !== "light" && activeTab === IMAGE_REFERENCE_TYPES.PORTRAIT) {
			setActiveTab(IMAGE_REFERENCE_TYPES.STRUCTURE);
		}
	}, [textToImageStore.config.tailored_model_id]);

	useEffect(() => {
		if (textToImageStore.config.training_version === "light" && activeTab === IMAGE_REFERENCE_TYPES.PORTRAIT) {
			textToImageStore.handleConfigChange("restyle_portrait", true);
			textToImageStore.handleConfigChange("fast", true);
			textToImageStore.handleConfigChange("prompt", "");
			textToImageStore.handleConfigChange("faces_refiner", undefined);
		} else {
			textToImageStore.handleConfigChange("restyle_portrait", false);
		}
	}, [activeTab]);

	const handleConfigChange = (field: string, value: ConfigValue) => {
		textToImageStore.handleConfigChange("image_reference", {
			...textToImageStore.config.image_reference,
			[field]: value,
		});
	};

	const handleUploadImageReference = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
		textToImageStore.handleUploadImage(e);
		if (activeTab === IMAGE_REFERENCE_TYPES.PORTRAIT) {
			textToImageStore.handleConfigChange("tailored_model_influence", 0.9);
			textToImageStore.handleConfigChange("image_reference", {
				...textToImageStore.config.image_reference,
				structure_ref_influence: DEFAULT_PORTRAIT_STRUCTURE_REF_INFLUENCE,
			});
		} else {
			textToImageStore.handleConfigChange("tailored_model_influence", 0.5);
			textToImageStore.handleConfigChange("image_reference", {
				...textToImageStore.config.image_reference,
				structure_ref_influence: DEFAULT_STRUCTURE_REF_INFLUENCE,
			});
		}
		textToImageStore.handleConfigChange(
			"steps_num",
			textToImageStore.config.fast
				? IMAGE_REFERENCE_CONFIGS.steps.fast.default
				: IMAGE_REFERENCE_CONFIGS.steps.not_fast.default,
		);
	};

	const renderTabContent = () => (
		<Box className={styles.advanced}>
			<ImageUploader
				description={t("uploadImage")}
				icon={<CloudUploadSVG />}
				horizontalLayout
				largeIcon
				src={textToImageStore.imageReference?.url}
				onUpload={(e) => handleUploadImageReference(e)}
				onDelete={() => textToImageStore.handleDeleteImageReference()}
			>
				<BriaButton
					className={styles.imageReferenceGallery}
					onClick={() => setIsImageGalleryOpen(!isImageGalleryOpen)}
					endIcon={<NavigateNext />}
				>
					{t("imageReferenceExamples")}
				</BriaButton>
			</ImageUploader>
			<InputLayout label={t("imageInfluence")}>
				<BriaSlider
					value={textToImageStore.config.image_reference?.structure_ref_influence}
					onChange={(_, value) => handleConfigChange("structure_ref_influence", value as number)}
					step={step}
					min={0}
					max={1}
					showMarksPoints
					marks={marks}
					valueLabelDisplay="auto"
					labelsAlwaysGray
				/>
			</InputLayout>
		</Box>
	);

	return (
		<Box className={styles.ImageReferenceContainer}>
			<Accordion
				elevation={0}
				disableGutters
				className={styles.imageReferenceAccordion}
				expanded={isImageReferenceOpen}
				onChange={() => setIsImageReferenceOpen((prev) => !prev)}
			>
				<AccordionSummary
					className={clsx(styles.AccordionSummary, { [styles.isDisabled]: isDisabled })}
					color="initial"
					expandIcon={<ExpandMore />}
				>
					{t("title")}
				</AccordionSummary>
				{isDisabled || textToImageStore.config.model_name === "Bria 3.1" ? (
					<Box className={styles.disabledImageReference}>
						<Typography className={styles.disabledLabel}>{t("disabledMessage")}</Typography>
					</Box>
				) : (
					<Box>
						<Tabs
							value={activeTab}
							onChange={(_, newValue) => setActiveTab(newValue)}
							sx={{
								"& .MuiTabs-flexContainer": { gap: "30px" },
								...(iframeStore.iframe.config.image_generation_config?.enable_restyle_portraits ||
								!iframeStore.isIframe()
									? {
											"&::before": {
												content: '""',
												position: "absolute",
												bottom: "0px",
												left: "49%",
												transform: "translateX(-50%)",
												width: "calc(30px + 4px)",
												height: "1px",
												backgroundColor: "#e7e7e7",
												zIndex: 0,
												top: "31.3%",
											},
									  }
									: {}),
							}}
						>
							{imageReferenceTabs.map((tab) => {
								const isPortraitDisabled =
									tab.name === IMAGE_REFERENCE_TYPES.PORTRAIT &&
									textToImageStore.config.training_version !== "light";

								return (
									<Tab
										key={tab.name}
										className={styles.tab}
										value={tab.name}
										label={
											<Box className={styles.tabLabelContainer}>
												<InputLayout
													label={t(tab.name)}
													info={t(`tooltip.${tab.name}`, { tgProjectsLink })}
												>
													<Outlet />
												</InputLayout>
											</Box>
										}
										sx={{
											borderBottom:
												activeTab === tab.name ? "4px solid #5300C9" : "4px solid #E7E7E7",
											opacity: isPortraitDisabled ? 0.5 : 1,
											pointerEvents: isPortraitDisabled ? "none" : "auto",
										}}
										aria-disabled={isPortraitDisabled}
									/>
								);
							})}
						</Tabs>

						{renderTabContent()}
					</Box>
				)}
			</Accordion>
			{isImageGalleryOpen && (
				<ImageReferenceGallery
					onClose={() => setIsImageGalleryOpen(false)}
					activeTab={activeTab}
					imageTabs={imageReferenceTabs}
				/>
			)}
		</Box>
	);
};

export default observer(ImageReference);
