import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useRef } from "react";
import { BriaImageProps } from "../../../../components/common/Galleries/BriaCanvas";
import { useAppStore } from "../../../../hooks/useStores";
import { AutomotiveReflectionLayer, PlaygroundImage } from "../../../../models/playground";
import AutomotiveCanvas from "../AutomotiveCanvas/AutomotiveCanvas";
import ReflectionControls from "../ReflectionControls/ReflectionControls";
import styles from "./ImageReflections.module.scss";

type CarReflectionsProps = {
	baseImageUrl: string;
	selectedImage: Partial<BriaImageProps & PlaygroundImage>;
};

const ImageReflections = ({ baseImageUrl, selectedImage }: CarReflectionsProps) => {
	const containerRef = useRef<HTMLDivElement | null>(null);
	const { playgroundStore } = useAppStore();
	const { projectsStore } = playgroundStore;

	useEffect(() => {
		playgroundStore.handleSelectSingleImage(selectedImage as PlaygroundImage);
		playgroundStore.handleSelectReflectionLayer(null);
	}, [selectedImage]);

	const handleIntensityChange = (layerName: string, intensity: number) => {
		if (projectsStore.isAutomotiveReflectionConfigEnabled) {
			playgroundStore.updateReflectionLayerIntensity(baseImageUrl, layerName, intensity);
		} else if (projectsStore.isAutomotiveEffectsConfigEnabled) {
			playgroundStore.updateEffectLayerIntensity(baseImageUrl, layerName, intensity);
		} else if (projectsStore.isAutomotiveHarmonizationConfigEnabled) {
			playgroundStore.updateHarmonizationLayerIntensity(baseImageUrl, layerName, intensity);
		}
	};

	const getLayers = () => {
		let newLayers: AutomotiveReflectionLayer[] = [];
		if (projectsStore.isAutomotiveReflectionConfigEnabled) {
			newLayers = playgroundStore.getAutomotiveImageReflections(baseImageUrl)?.layers || [];
		} else if (projectsStore.isAutomotiveEffectsConfigEnabled) {
			newLayers = playgroundStore.getAutomotiveImageEffects(baseImageUrl)?.layers || [];
		} else if (projectsStore.isAutomotiveHarmonizationConfigEnabled) {
			newLayers = playgroundStore.getAutomotiveImageHarmonizations(baseImageUrl)?.layers || [];
		}
		return newLayers;
	};

	return (
		<Box className={styles.container} ref={containerRef}>
			<Box className={styles.canvasWrapper}>
				<AutomotiveCanvas baseImageUrl={baseImageUrl} reflectionLayers={getLayers()} />
			</Box>
			<ReflectionControls onIntensityChange={handleIntensityChange} />
		</Box>
	);
};

export default observer(ImageReflections);
