import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import RouterConstants from "../../../constants/RouterConstants.ts";
import { getSelectedOrganization } from "../../../helpers/localStorage.ts";
import { useIframe } from "../../../hooks/useIframe.tsx";
import { useAppStore } from "../../../hooks/useStores.tsx";
import EditorLayout from "../../../layout/EditorLayout/EditorLayout.tsx";
import { Iframe, getDefaultIframe } from "../../../models/new-iframe.ts";
import iframeStore from "../iframe-store.tsx";
import NewIframeForm from "./Form/NewIframeForm.tsx";
import styles from "./NewIframeEditor.module.scss";
import NewIframePreview from "./Preview/NewIframePreview.tsx";

export type EditIframeLocationState = {
	iframeToEdit?: Iframe;
};

const NewIframeEditor = () => {
	const { t } = useTranslation("translation", { keyPrefix: "newIframes.editor" });
	const { uiStore } = useAppStore();
	const { iframeForm, handleFormChange } = iframeStore;
	const params = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	// const [previewPage, setPreviewPage] = useState(iframeForm?.config.landing_page);
	const { createIframeUrl } = useIframe();
	const [loadingSave, setLoadingSave] = useState(false);
	const loadingEditor = !iframeForm.id && params.id !== "new";

	useEffect(() => {
		uiStore.hideSideBar();
		uiStore.showBackButton(RouterConstants.NEW_IFRAMES_CONFIG.fullPath);

		iframeStore.formErrors = {};
		iframeStore.iframeForm =
			location.state?.iframeToEdit ?? getDefaultIframe(getSelectedOrganization()?.organization?.uid || "");
		if (!location.state?.iframeToEdit && params.id && params.id !== "new") {
			iframeStore.getIframe(params.id).then((iframe) => (iframeStore.iframeForm = iframe));
		}

		return () => {
			uiStore.showSideBar();
			uiStore.hideBackButton();
			window.history.replaceState({}, "");
		};
	}, []);

	const openPreview = () => {
		window.open(createIframeUrl(iframeForm), "_blank");
	};

	const saveIframe = async () => {
		try {
			setLoadingSave(true);
			if (iframeForm.id) {
				await iframeStore.updateIframe(iframeForm);
			} else {
				await iframeStore.createIframe(iframeForm);
			}
			navigate(RouterConstants.NEW_IFRAMES_CONFIG.fullPath);
		} catch (e: any) {
			setLoadingSave(false);
		}
	};

	return (
		<EditorLayout
			name={iframeForm?.name}
			onNameChange={(e) => handleFormChange("name", e.target.value)}
			primaryButton={t("saveBtn")}
			primaryButtonClick={saveIframe}
			loadingPrimaryButton={loadingSave}
			secondaryButton={!iframeForm.id || iframeForm.id === "preview" ? undefined : t("previewBtn")}
			secondaryButtonClick={openPreview}
			errorName={iframeStore.formErrors.invalidName}
			loading={loadingEditor}
		>
			<Box className={styles.content}>
				<NewIframeForm />
				<NewIframePreview />
			</Box>
		</EditorLayout>
	);
};

export default observer(NewIframeEditor);
