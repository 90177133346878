import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BriaButton from "../../../components/common/BriaButton/BriaButton.tsx";
import { FilterByOptions, PaginationOptions } from "../../../components/common/BriaTable/BriaTable.tsx";
import ProjectCard from "../../../components/common/ProjectCard/ProjectCard.tsx";
import { APPS } from "../../../constants/AppsConstants.ts";
import RouterConstants from "../../../constants/RouterConstants.ts";
import useSecureNavigate from "../../../hooks/useSecureNavigate.tsx";
import { useAppStore } from "../../../hooks/useStores.tsx";
import DataDashboardLayout from "../../../layout/DataDashboard/DataDashboardLayout.tsx";
import AddSessionDialog from "../Sessions/AddSessionDialog/AddSessionDialog.tsx";
import styles from "./AutomotiveProjects.module.scss";
import AutomotiveProjectActionsPopover from "./Dashboard/ActionsPopover/AutomotiveProjectActionsPopover.tsx";
import AutomotiveProjectsEmpty from "./EmptyState/AutomotiveProjectsEmpty.tsx";
import { PlaygroundProject, defaultProject } from "./store/playground-projects-store.tsx";

const AutomotiveProjects = () => {
	const navigate = useSecureNavigate();
	const { t } = useTranslation("translation", { keyPrefix: "automotiveApp.projects" });
	const { uiStore, playgroundStore } = useAppStore();
	const { projectsStore } = playgroundStore;
	const paginationOptions: PaginationOptions<PlaygroundProject> = {
		rowsPerPage: projectsStore.rowsPerPage,
		totalRows: projectsStore.paginatedProjects.total,
		loadNextPage: projectsStore.getProjects,
	};
	const [filterBy, setFilterBy] = useState<FilterByOptions<PlaygroundProject>>();
	const [selectedProject, setSelectedProject] = useState<PlaygroundProject>(defaultProject);

	useEffect(() => {
		uiStore.showBackButton(
			`${RouterConstants.AUTOMOTIVE.path}`,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
		);
		return () => {
			uiStore.hideBackButton();
		};
	}, []);

	useEffect(() => {
		uiStore.showSideBar();
		projectsStore.getProjects(1, { filters: { from_app: APPS.AUTOMOTIVE } });
	}, [uiStore, projectsStore]);

	const createFirstSession = (project: PlaygroundProject) => {
		setSelectedProject(project);
		uiStore.showDialog("AddSessionDialog");
	};

	const openProject = (project: PlaygroundProject) => {
		projectsStore.handleSelectProject(project);
		navigate(`${RouterConstants.AUTOMOTIVE_PROJECT.path}/${project.id}/uploaded`);
	};

	const openCreateProject = () => {
		navigate(RouterConstants.NEW_AUTOMOTIVE_PROJECT.fullPath);
	};

	const handleSearchChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const updatedFilterBy: FilterByOptions<PlaygroundProject> = {
			...filterBy,
			search: {
				text: e.target.value,
				fields: ["name", "description"],
			},
		};
		setFilterBy(updatedFilterBy);
		await projectsStore.getProjects(1, updatedFilterBy);
	};

	return (
		<>
			<DataDashboardLayout
				className={styles.layout}
				primaryButton={
					<BriaButton buttonType="primaryMedium" onClick={openCreateProject} className={styles.actionBtn}>
						{t("createNewProject")}
					</BriaButton>
				}
				title={t("title")}
				titleClassName={styles.title}
				description={t("subTitle")}
				descriptionClassName={styles.description}
				enableSearch
				searchInputVisible
				searchBoxPosition="left"
				handleSearchChange={handleSearchChange}
				paginationOptions={paginationOptions}
				filterBy={filterBy}
				loading={projectsStore.loadingOrgProjects}
			>
				{!projectsStore.paginatedProjects?.items?.length ? (
					<AutomotiveProjectsEmpty />
				) : (
					<Box className={styles.cards}>
						{projectsStore.paginatedProjects?.items?.map((project) => {
							const cardFields = project.extra_info?.vehicleModel
								? [
										{
											key: t("card.vehicleModel"),
											value: project.extra_info?.vehicleModel,
										},
								]
								: [];

							const buttonProps =
								project?.sessions && project?.sessions?.length > 0
									? {
											onSecondaryAction: () => openProject(project),
											secondaryButtonLabel: t("card.openProject"),
									}
									: {
											onPrimaryAction: () => createFirstSession(project),
											primaryButtonLabel: t("card.createFirstSession"),
									};
							return (
								<ProjectCard
									className={styles.projectCard}
									key={project.id}
									name={project.name}
									description={project.description}
									cardFields={cardFields}
									actionsPopover={<AutomotiveProjectActionsPopover project={project} />}
									{...buttonProps}
								/>
							);
						})}
					</Box>
				)}
			</DataDashboardLayout>
			<AddSessionDialog project={selectedProject} />
		</>
	);
};

export default observer(AutomotiveProjects);
