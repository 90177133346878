import { Box, Collapse, Divider, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import BriaInput from "../../../../../components/common/BriaInput/BriaInput";
import ImageViewer from "../../../../../components/common/Image/Image";
import { useAppStore } from "../../../../../hooks/useStores";
import InputLayout from "../../../../../layout/InputLayout/InputLayout";
import { TgIpType, TgProject } from "../../store/tg-projects-store";
import styles from "./TgIpTypes.module.scss";

type TgIpTypesProps = {};

const TgIpTypes = ({}: TgIpTypesProps) => {
	const { t } = useTranslation("translation", { keyPrefix: "newTg.projects.editor.ipType" });
	const { tgStore } = useAppStore();
	const { tgProjectStore } = tgStore;
	const isEditMode = !!tgProjectStore.projectForm.id;
	const ipTypeOptions = t("types", { returnObjects: true });

	const isChecked = (type: TgIpType) => tgProjectStore.projectForm.ip_type === type;

	const showIpNameInput = (type: TgIpType) => isChecked(type) && type === "defined_character";

	const showIpDescInput = (type: TgIpType) =>
		isChecked(type) && (type === "defined_character" || type === "object_variants");

	const handleIpInputChange = <K extends keyof TgProject>(
		e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
		key: K,
	) => {
		const value = e.target.value as TgProject[K];
		tgProjectStore.formErrors.requiredFields[key] = !value;
		tgProjectStore.handleFormChange(key, value);
	};

	const handleIpTypeChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		tgProjectStore.formErrors.requiredFields = { ip_description: false, ip_name: false };
		tgProjectStore.handleFormChange("ip_type", e.target.value as TgIpType);
	};

	return (
		<RadioGroup
			className={styles.ipTypeContent}
			value={tgProjectStore.projectForm.ip_type}
			onChange={(e) => handleIpTypeChange(e)}
		>
			{Object.keys(ipTypeOptions).map(
				(type) =>
					t(`types.${type}.${tgProjectStore.projectForm.ip_medium}`) === "true" && (
						<>
							{t(`types.${type}.bottom`) === "true" && (
								<>
									<Divider />
									<Typography className={styles.optionHeader}>{t(`types.${type}.header`)}</Typography>
								</>
							)}
							<Box
								className={clsx({
									[styles.ipTypeOption]: true,
									[styles.otherOption]: t(`types.${type}.bottom`) === "true",
								})}
							>
								<FormControlLabel
									className={styles.radioButtonControl}
									value={type}
									checked={isChecked(type as TgIpType)}
									disabled={isEditMode}
									control={<Radio />}
									label={
										<Box className={styles.ipTypeDetails}>
											<Typography className={styles.ipTypeName}>
												{t(`types.${type}.name`)}
											</Typography>
											<Typography className={styles.ipTypeDesc}>
												{t(`types.${type}.description`)}
											</Typography>
											<Box className={styles.ipProps}>
												<Collapse in={showIpNameInput(type as TgIpType)}>
													<InputLayout
														validateOn={true}
														validationMessage={
															tgProjectStore.formErrors.requiredFields["ip_name"]
																? t("requiredField")
																: null
														}
														label={
															<Box className={styles.nameLabel}>
																{t(`types.${type}.ip_name.label`)}*
															</Box>
														}
														info={`${t(`types.${type}.ip_name.info`)}`}
													>
														<BriaInput
															placeholder={t(`types.${type}.ip_name.placeholder`)}
															value={tgProjectStore.projectForm.ip_name}
															onChange={(e) => handleIpInputChange(e, "ip_name")}
															height="40px"
														/>
													</InputLayout>
												</Collapse>
												<Collapse in={showIpDescInput(type as TgIpType)}>
													<InputLayout
														validateOn={true}
														validationMessage={
															tgProjectStore.formErrors.requiredFields["ip_description"]
																? t("requiredField")
																: null
														}
														label={
															<Box className={styles.descriptionLabel}>
																{t(`types.${type}.ip_description.label`)}*{" "}
																<span className={styles.limit}>
																	{t(`types.${type}.ip_description.limit`)}
																</span>
															</Box>
														}
														info={`${t(`types.${type}.ip_description.info`)}`}
													>
														<BriaInput
															placeholder={t(
																`types.${type}.ip_description.placeholder.${tgProjectStore.projectForm.ip_medium}`,
															)}
															value={tgProjectStore.projectForm.ip_description}
															onChange={(e) => handleIpInputChange(e, "ip_description")}
															multiline
															rows={3}
															inputProps={{ maxLength: 50 }}
															characterCounter
														/>
													</InputLayout>
												</Collapse>
											</Box>
										</Box>
									}
								/>
								<Box className={styles.ipTypeImages}>
									{Array.from({ length: 5 }).map((_, i) => (
										<ImageViewer
											className={styles.ipTypeImage}
											name={`images/pngs/TgIpType/${
												tgProjectStore.projectForm.ip_medium
											}/${type}/${type}-${i + 1}.png`}
										/>
									))}
								</Box>
							</Box>
							{t(`types.${type}.bottom`) === "true" && <Divider />}
						</>
					),
			)}
		</RadioGroup>
	);
};

export default observer(TgIpTypes);
