import { Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAttributionReports } from "../../../../hooks/useAttributionReports.tsx";
import { useAppStore } from "../../../../hooks/useStores.tsx";
import TableLayout, { TableRow } from "../../../../layout/TableLayout/TableLayout.tsx";
import { IGenerationReport, IGenerationReportTableRow } from "../../../../models/attribution-reports.ts";
import { showErrorToast } from "../../../../utils/toast.tsx";
import { toJS } from "mobx";

const GenerationReport = () => {
	const { t } = useTranslation("translation", { keyPrefix: "account.attributionReports" });
	const { authStore, attributionReportsStore } = useAppStore();
	const isMounted = useRef<boolean>(false);
	const { createGenerationReportTableRow } = useAttributionReports();
	const [page, setPage] = useState<number>(1);
	const [generationReportTableData, setGenerationReportTableData] = useState<TableRow<IGenerationReportTableRow>[]>(
		[],
	);
	const tableHeaders = t("tables.generationReport.headers", { returnObjects: true }) as string[];
	const rowsPerPage = 10;

	useEffect(() => {
		const loadData = async () => {
			if (!attributionReportsStore.generationReportData.length || isMounted.current) {
				try {
					await attributionReportsStore.loadGenerationReportData();
				} catch (e: any) {
					showErrorToast(t("pleaseTryAgainLater"));
				}
			}
		};
		loadData();
	}, [JSON.stringify(toJS(attributionReportsStore.selectedDateRange))]);

	useEffect(() => {
		const listLength = attributionReportsStore.generationReportData.length;
		if (page < 1 || listLength <= rowsPerPage) return;
		const startIndex = (page - 1) * rowsPerPage;
		let endIndex = startIndex + rowsPerPage;
		if (endIndex > listLength - 1) endIndex = listLength - 1;
		const paginatedData = attributionReportsStore.generationReportData.slice(startIndex, endIndex);
		setGenerationReportTableData(
			paginatedData.map((data: IGenerationReport) => createGenerationReportTableRow(data)),
		);
	}, [attributionReportsStore.generationReportData, page]);

	useEffect(() => {
		isMounted.current = true;
		attributionReportsStore.setSelectedTab(t("tabs.generationReport"));
	}, []);

	return authStore.user?.isSuperAdmin() ? (
		<>
			<TableLayout
				hideHeader={true}
				headerArray={tableHeaders}
				tableData={generationReportTableData}
				loading={attributionReportsStore.isGenerationReportDataLoading}
				emptyStateComponent={<Typography>{t("tables.generationReport.emptyState")}</Typography>}
				paginationOptions={{
					rowsPerPage: rowsPerPage,
					totalRows: attributionReportsStore.generationReportData.length,
					loadNextPage: async (pageNumber: number) => setPage(pageNumber),
				}}
			/>
		</>
	) : null;
};

export default observer(GenerationReport);
