import { Box, SelectChangeEvent } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import BriaButton from "../../../../../components/common/BriaButton/BriaButton";
import BriaDropDown from "../../../../../components/common/BriaDropDown/BriaDropDown";
import ConfirmationPopup from "../../../../../components/common/ConfirmationPopup/ConfirmationPopup";
import useStaticDropdown from "../../../../../components/common/DropDowns/useStaticDropdown";
import { useAppStore } from "../../../../../hooks/useStores";
import { ImageEditingConfigComponentProps, ImageEditingViewMode } from "../../../../../models/image-to-image";
import { AutomotiveReflectionLayer } from "../../../../../models/playground";
import styles from "./AutomotiveHarmonization.module.scss";
import { AutomotivePreset } from "./automotive-harmonization-store";

type HarmonizationProps = {
	setActiveConfig: (config: string) => void;
} & ImageEditingConfigComponentProps;

const AutomotiveHarmonization = ({ setActiveConfig, onBackButtonClick }: HarmonizationProps) => {
	const { playgroundStore } = useAppStore();
	const { t } = useTranslation("translation", { keyPrefix: "playground.automotive.config.features.harmonization" });
	const { t: tc } = useTranslation("translation", {
		keyPrefix: `playground.${playgroundStore.selectedConfig}.config`,
	});
	const { automotiveStore, projectsStore } = playgroundStore;
	const { harmonizationStore, reflectionStore } = automotiveStore;
	const config = harmonizationStore.config;
	const selectedImage = playgroundStore.singleSelectedImage;
	const { items: harmonizationItems } = useStaticDropdown<AutomotivePreset>({
		tKeyPrefix: "playground.automotive.config.features.harmonization.presetsDropdown.items",
	});

	useEffect(() => {
		projectsStore.toggleAutomotiveHarmonizationConfig(true);
		return () => {
			automotiveStore.toggleIsSaved(true);
			playgroundStore.imagesHarmonizations = [];
			harmonizationStore.handleFormChange("preset", "");
			projectsStore.toggleAutomotiveHarmonizationConfig(false);
		};
	}, [projectsStore]);

	useEffect(() => {
		if (playgroundStore.imageEditingViewMode == ImageEditingViewMode.GALLERY) {
			onBackButtonClick?.();
		}
	}, [playgroundStore.imageEditingViewMode]);

	const handleSave = async () => {
		automotiveStore.isSaved = true;
		const reflectionList: AutomotiveReflectionLayer[] | undefined =
			playgroundStore.getAutomotiveImageHarmonizations(selectedImage?.url as string)?.layers;
		reflectionStore.saveReflection(reflectionList);
	};

	const handleViewEffect = async (e: SelectChangeEvent<AutomotivePreset>) => {
		automotiveStore.isSaved = false;
		harmonizationStore.handleFormChange("preset", e.target.value as AutomotivePreset);
		harmonizationStore.applyHarmonization(e.target.value);
	};

	const closeWarningPopup = () => {
		automotiveStore.openWarningPopup = false;
	};

	const switchConfigWithoutSave = () => {
		closeWarningPopup();
		automotiveStore.isSaved = true;
		setActiveConfig(automotiveStore.configToSwitch ?? "");
	};

	const handleConfirmWarning = async () => {
		await handleSave();
		projectsStore.toggleAutomotiveEffectsConfig(false);
		closeWarningPopup();
		setActiveConfig(automotiveStore.configToSwitch ?? "");
	};

	return (
		<>
			<Box className={styles.wrapper}>
				<BriaDropDown
					items={harmonizationItems}
					value={config.preset}
					onChange={(e) => handleViewEffect(e)}
					placeholder={t("presetsDropdown.placeholder")}
					loading={harmonizationStore.loadingApplyHarmonization}
				/>
				<BriaButton
					buttonType="primaryMedium"
					className={styles.formButton}
					onClick={handleSave}
					loading={reflectionStore.loadingSaveReflection}
					disabled={harmonizationStore.loadingApplyHarmonization || reflectionStore.loadingSaveReflection}
				>
					{t("formButton")}
				</BriaButton>
			</Box>
			<ConfirmationPopup
				open={automotiveStore.openWarningPopup}
				onClose={closeWarningPopup}
				title={tc("warningPopup.title")}
				description={tc("warningPopup.description")}
				confirmButtonText={tc("warningPopup.saveChanges")}
				onClick={handleConfirmWarning}
				onSecondaryClick={switchConfigWithoutSave}
				firstButtonText={tc("warningPopup.undoChanges")}
				loading={playgroundStore.isLoadingReflections}
			/>
		</>
	);
};

export default observer(AutomotiveHarmonization);
