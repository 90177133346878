import { makeAutoObservable, runInAction } from "mobx";
import { v4 as uuidv4 } from "uuid";
import { APPS } from "../../../../../constants/AppsConstants";
import { AutomotiveEffectsProps, ImageToImageConfigType } from "../../../../../models/image-to-image";
import { AutomotiveReflectionLayer, PlaygroundImage, PlaygroundResult } from "../../../../../models/playground";
import QueryService from "../../../../../utils/QueryService";
import { IAutomotiveStore } from "../../automotive-store";

export interface IAutomotiveEffectsStore {
	config: AutomotiveEffectsConfig;

	loadingSaveEffects: boolean;
	loadingApplyEffects: boolean;

	handleFormChange: <K extends keyof AutomotiveEffectsConfig>(key: K, value: AutomotiveEffectsConfig[K]) => void;
	saveEffects: () => Promise<AutomotiveEffectsProps | undefined>;
	applyEffect: (effect: string) => void;
}

export class AutomotiveEffectsStore implements IAutomotiveEffectsStore {
	private queryService: QueryService = new QueryService("/automotive/effects");
	automotiveStore: IAutomotiveStore;

	config: AutomotiveEffectsConfig = { effect: "" as AutomotiveEffect };

	loadingSaveEffects: boolean = false;
	loadingApplyEffects: boolean = false;

	constructor(automotiveStore: IAutomotiveStore) {
		makeAutoObservable(this);
		this.automotiveStore = automotiveStore;
	}

	handleFormChange = <K extends keyof AutomotiveEffectsConfig>(key: K, value: AutomotiveEffectsConfig[K]) => {
		this.config = { ...this.config, [key]: value };
	};

	saveEffects = async () => {
		this.loadingSaveEffects = true;

		const playgroundStore = this.automotiveStore.playgroundStore;
		const originalImage = this.automotiveStore.playgroundStore.selectedSingleImage;
		const savedIndex = playgroundStore.playgroundResults.length;

		try {
			const resultsSkeletons: PlaygroundResult = {
				id: uuidv4(),
				config: playgroundStore.selectedSingleImage?.config,
				type: APPS.AUTOMOTIVE,
				images: [
					{
						id: "",
						url: "",
						loading: true,
						selected: true,
						playground_result_id: "",
					},
				],
			};
			playgroundStore.playgroundResults = [...playgroundStore.playgroundResults, resultsSkeletons];

			const response: AutomotiveEffectsProps = await this.queryService.post(`/`, {
				...this.config,
				image_url: this.automotiveStore.playgroundStore.singleSelectedImage?.url,
				layers: true,
			});

			runInAction(() => {
				this.loadingSaveEffects = false;

				const { playgroundResults, projectsStore } = playgroundStore;
				const savedPlaygroundResult = playgroundResults[savedIndex];

				const newImage: PlaygroundImage = {
					...originalImage,
					...(originalImage?.file ? { file: undefined } : {}),
					id: uuidv4(),
					url: response.url,
					loading: true,
					type: APPS.AUTOMOTIVE,
					config: {
						...savedPlaygroundResult.config,
						original_image: { ...originalImage },
					} as ImageToImageConfigType,
					automotive_props: {
						effects: { ...response },
					},
					seed: response.seed,
				};

				savedPlaygroundResult.images = [newImage];
				playgroundStore.handleSelectSingleImage(newImage);
				projectsStore.createPlaygroundResult(playgroundStore.playgroundResults[savedIndex]);
			});

			return response;
		} catch (err: any) {
			runInAction(() => {
				playgroundStore.playgroundResults = playgroundStore.playgroundResults.filter(
					(_, index) => index !== savedIndex,
				);
				return Promise.reject(err);
			});
		} finally {
			runInAction(() => {
				this.loadingSaveEffects = false;
			});
		}
	};

	applyEffect = async (effect: string) => {
		const playgroundStore = this.automotiveStore.playgroundStore;
		const originalImage = this.automotiveStore.playgroundStore.singleSelectedImage;

		try {
			runInAction(() => {
				this.loadingApplyEffects = true;
				playgroundStore.imagesEffects = [];
				playgroundStore.selectedReflectionLayer = null;
			});

			const response: AutomotiveEffectsProps = await this.queryService.post(`/`, {
				effect: effect,
				image_url: this.automotiveStore.playgroundStore.singleSelectedImage?.url,
				layers: true,
			});

			const layer: AutomotiveReflectionLayer = {
				name: "effects",
				url: response.url,
				intensity: 0.5,
				hidden: false,
			};

			runInAction(() => {
				playgroundStore.imagesEffects.push({
					url: originalImage?.url ?? "",
					layers: [layer],
				});
				playgroundStore.selectedReflectionLayer = layer;
			});
		} catch (err: any) {
			console.log("error applying effect ", err);
		} finally {
			this.loadingApplyEffects = false;
		}
	};
}

export type AutomotiveEffect = "fog" | "snow" | "dust" | "sand" | "lens flare" | "light leaks" | "";

export type AutomotiveEffectsConfig = {
	image_url?: string;
	image_file?: File;
	effect: AutomotiveEffect;
	layers?: boolean;
	seed?: number;
};
