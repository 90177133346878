export enum CrmEventTargets {
	USER = "USER",
	ORG = "ORG",
}

export enum CrmUserEvents {
	USER_COPIED_API_KEY = "USER_COPIED_API_KEY",
	USER_STARTED_TG_MODEL_TRAINING = "USER_STARTED_TG_MODEL_TRAINING",
	USER_REQUESTED_PLAN_QUOTE = "USER_REQUESTED_PLAN_QUOTE",
	USER_REQUESTED_TAILORED_MODELS = "USER_REQUESTED_TAILORED_MODELS",
	USER_REQUESTED_TG_POWERPOINT = "USER_REQUESTED_TG_POWERPOINT",
	USER_REQUESTED_TG_COPILOT = "USER_REQUESTED_TG_COPILOT",
	USER_DOWNLOADED_FIGMA_PLUGIN = "USER_DOWNLOADED_FIGMA_PLUGIN",
	USER_DOWNLOADED_PHOTOSHOP_PLUGIN = "USER_DOWNLOADED_PHOTOSHOP_PLUGIN",
	USER_DOWNLOADED_TG_MODEL_SOURCE = "USER_DOWNLOADED_TG_MODEL_SOURCE",
	USER_REQUESTED_FOUNDATION_MODELS = "USER_REQUESTED_FOUNDATION_MODELS",
}

export enum CrmOrgEvents {
	ORG_USER_COPIED_API_KEY = "ORG_USER_COPIED_API_KEY",
	ORG_USER_STARTED_TG_MODEL_TRAINING = "ORG_USER_STARTED_TG_MODEL_TRAINING",
	ORG_REQUESTED_PLAN_QUOTE = "ORG_REQUESTED_PLAN_QUOTE",
	ORG_REQUESTED_TAILORED_MODELS = "ORG_REQUESTED_TAILORED_MODELS",
	ORG_REQUESTED_TG_POWERPOINT = "ORG_REQUESTED_TG_POWERPOINT",
	ORG_REQUESTED_TG_COPILOT = "ORG_REQUESTED_TG_COPILOT",
	ORG_DOWNLOADED_FIGMA_PLUGIN = "ORG_DOWNLOADED_FIGMA_PLUGIN",
	ORG_DOWNLOADED_PHOTOSHOP_PLUGIN = "ORG_DOWNLOADED_PHOTOSHOP_PLUGIN",
	ORG_DOWNLOADED_TG_MODEL_SOURCE = "ORG_DOWNLOADED_TG_MODEL_SOURCE",
	ORG_REQUESTED_FOUNDATION_MODELS = "ORG_REQUESTED_FOUNDATION_MODELS",
}
