import { makeAutoObservable, runInAction } from "mobx";
import { ElementType } from "selecto";
import { FilterByOptions, SortByOptions } from "../../../../components/common/BriaTable/BriaTable";
import { APPS } from "../../../../constants/AppsConstants";
import { PaginatedItems } from "../../../../models/common";
import { PlaygroundImage, PlaygroundResult } from "../../../../models/playground";
import QueryService from "../../../../utils/QueryService";
import { IPlaygroundStore } from "../../../Playground/store/playground-store";
import { PlaygroundSession } from "../../Sessions/store/playground-sessios-store";

export interface IPlaygroundProjectsStore {
	paginatedProjects: PaginatedItems<PlaygroundProject>;
	rowsPerPage: number;
	project: PlaygroundProject;
	formErrors: {
		save?: boolean;
		requiredFields: { [key in keyof Partial<PlaygroundProject>]: boolean };
	};

	uploadedImages: PaginatedItems<PlaygroundImage>;
	favoriteImages: PaginatedItems<PlaygroundImage>;

	loadingProject: boolean;
	loadingOrgProjects: boolean;
	loadingCreateProject: boolean;
	loadingUpdateProject: boolean;
	loadingDeleteProject: boolean;

	loadingDeleteImages: boolean;

	handleFormChange: <K extends keyof PlaygroundProject>(key: K, value: PlaygroundProject[K]) => void;
	getProjects: (
		page?: number,
		filterBy?: FilterByOptions<PlaygroundProject>,
	) => Promise<PaginatedItems<PlaygroundProject>>;
	getProject: (id: string) => Promise<PlaygroundProject>;
	createProject: (projectToCreate: PlaygroundProject) => Promise<string>;
	updateProject: (id: string, updatedProject: PlaygroundProject) => Promise<PlaygroundProject>;
	deleteProject: (id: string) => Promise<void>;
	handleSelectProject: (project: PlaygroundProject) => void;

	getUploadedImages: (
		projectId: string,
		page?: number,
		filterBy?: FilterByOptions<PlaygroundImage>,
	) => Promise<PaginatedItems<PlaygroundImage>>;
	getFavoriteImages: (
		projectId: string,
		page?: number,
		filterBy?: FilterByOptions<PlaygroundImage>,
	) => Promise<PaginatedItems<PlaygroundImage>>;
	selectedSession: PlaygroundSession | null;
	handleSelectSession: (session: PlaygroundSession) => void;
	createPlaygroundResult: (
		result: Partial<PlaygroundResult>,
		sessionId?: string,
	) => Promise<PlaygroundResult | undefined>;
	selectedImages: PlaygroundImage[];
	handleSelectImages: (images: PlaygroundImage[]) => void;
	handleSelectImagesElements: (imagesElements?: ElementType[], type?: ProjectImagesTypes) => void;
	getSelectedUploadedImages: () => PlaygroundImage[];
	getSelectedFavoriteImages: () => PlaygroundImage[];
	handleAddImageToFavorite: (image: PlaygroundImage, isFavorite: boolean) => Promise<void>;
	handleDeleteImage: (image_id: string) => Promise<void>;
	handleDeleteAllUploads: (project_id: string) => Promise<void>;
	handleDeleteAllFavorites: (project_id: string) => Promise<void>;
	isAutomotiveReflectionConfigEnabled: boolean;
	toggleAutomotiveReflectionConfig: (status: boolean) => void;
	isAutomotiveEffectsConfigEnabled: boolean;
	toggleAutomotiveEffectsConfig: (status: boolean) => void;
	isAutomotiveHarmonizationConfigEnabled: boolean;
	toggleAutomotiveHarmonizationConfig: (status: boolean) => void;
	isCreatingPlaygroundResults: boolean;
}

export class PlaygroundProjectsStore implements IPlaygroundProjectsStore {
	private queryService: QueryService = new QueryService("/playground-projects");
	private resultsQueryService: QueryService = new QueryService("/playground-results");
	private imagesQueryService: QueryService = new QueryService("/playground-images");
	playgroundStore: IPlaygroundStore;
	paginatedProjects: PaginatedItems<PlaygroundProject> = { items: [], total: 0 };
	rowsPerPage: number = 12;

	project: PlaygroundProject = defaultProject;
	formErrors: {
		save?: boolean;
		requiredFields: { [key in keyof Partial<PlaygroundProject>]: boolean };
	} = {
		requiredFields: { name: false },
	};

	uploadedImages: PaginatedItems<PlaygroundImage> = { items: [], total: 0 };
	favoriteImages: PaginatedItems<PlaygroundImage> = { items: [], total: 0 };

	loadingOrgProjects: boolean = false;
	loadingProject: boolean = false;
	loadingCreateProject: boolean = false;
	loadingUpdateProject: boolean = false;
	loadingDeleteProject: boolean = false;

	loadingProjectSessions: boolean = false;
	loadingProjectDatasets: boolean = false;
	isCreatingPlaygroundResults: boolean = false;
	selectedSession: PlaygroundSession | null = null;
	selectedImages: PlaygroundImage[] = [];

	loadingDeleteImages: boolean = false;
	isAutomotiveReflectionConfigEnabled: boolean = false;
	isAutomotiveEffectsConfigEnabled: boolean = false;
	isAutomotiveHarmonizationConfigEnabled: boolean = false;

	constructor(playgroundStore: IPlaygroundStore) {
		makeAutoObservable(this);
		this.playgroundStore = playgroundStore;
	}

	handleSelectImagesElements = (imagesElements?: ElementType[], type?: ProjectImagesTypes) => {
		runInAction(async () => {
			const selectedImages: PlaygroundImage[] = [];
			const selectedImagesIds: string[] | undefined = imagesElements?.map((el) =>
				JSON.parse(el.ariaValueText ?? "{}"),
			);

			if (type === "uploadedImages") {
				this.uploadedImages.items.forEach((image) => {
					image.selected = selectedImagesIds?.some((id) => id === image.id);
					if (image.selected) {
						selectedImages.push(image);
					}
				});
			} else if (type === "favoriteImages") {
				this.favoriteImages.items.forEach((image) => {
					image.selected = selectedImagesIds?.some((id) => id === image.id);
					if (image.selected) {
						selectedImages.push(image);
					}
				});
			}
			this.handleSelectImages(selectedImages);
		});
	};

	getSelectedUploadedImages = (): PlaygroundImage[] =>
		this.uploadedImages.items.filter((image) => !image.loading && image.selected);

	getSelectedFavoriteImages = (): PlaygroundImage[] =>
		this.favoriteImages.items.filter((image) => !image.loading && image.selected);

	handleFormChange = <K extends keyof PlaygroundProject>(key: K, value: PlaygroundProject[K]) => {
		this.project = { ...this.project, [key]: value };
	};

	handleSelectProject = (project: PlaygroundProject) => {
		runInAction(() => {
			this.project = project;
		});
	};

	handleSelectImages = (images: PlaygroundImage[]) => {
		runInAction(() => {
			this.selectedImages = images;
		});
	};

	getProjects = async (
		page?: number,
		filterBy?: FilterByOptions<PlaygroundProject>,
		sortBy: SortByOptions<PlaygroundProject> = { field: "created_at", order: "desc" },
	) => {
		try {
			this.loadingOrgProjects = true;
			const paginatedProjects: PaginatedItems<PlaygroundProject> = await this.queryService.get("/", {
				params: {
					filter_by: filterBy,
					sort_by: sortBy,
					page: page,
					per_page: this.rowsPerPage,
				},
			});

			runInAction(() => {
				this.paginatedProjects = paginatedProjects;
				this.loadingOrgProjects = false;
			});

			return this.paginatedProjects;
		} catch (err: any) {
			this.loadingOrgProjects = false;
			return Promise.reject(err);
		}
	};

	getProject = async (id: string) => {
		try {
			this.loadingProject = true;
			const project = await this.queryService.get(`/${id}`);

			runInAction(() => {
				this.loadingProject = false;
				this.project = project;
			});

			return this.project;
		} catch (err) {
			runInAction(() => {
				this.loadingProject = false;
			});
			return Promise.reject(err);
		}
	};

	createProject = async (projectToCreate: PlaygroundProject) => {
		try {
			this.loadingCreateProject = true;
			const newProjectId = await this.queryService.post(`/`, projectToCreate);

			runInAction(() => {
				this.loadingCreateProject = false;
			});

			return newProjectId;
		} catch (err: any) {
			this.loadingCreateProject = false;

			return Promise.reject(err);
		}
	};

	updateProject = async (id: string, projectToUpdate: PlaygroundProject) => {
		try {
			this.loadingUpdateProject = true;
			const updatedProject = await this.queryService.put(`/`, projectToUpdate);

			runInAction(() => {
				this.paginatedProjects.items = this.paginatedProjects?.items.map((project) =>
					project.id === id ? updatedProject : project,
				);
				projectToUpdate.id = id;
				this.loadingUpdateProject = false;
			});
			this.project = { ...this.project, ...projectToUpdate };
			return projectToUpdate;
		} catch (err: any) {
			this.loadingUpdateProject = false;
			return Promise.reject(err);
		}
	};

	deleteProject = async (id: string) => {
		try {
			this.loadingDeleteProject = true;
			await this.queryService.delete(`/${id}`);

			runInAction(() => {
				this.paginatedProjects = {
					items: this.paginatedProjects.items.filter((item) => item.id !== id),
					total: this.paginatedProjects.total - 1,
				};
				this.project.id = id;
				this.loadingDeleteProject = false;
			});
		} catch (err: any) {
			this.loadingDeleteProject = false;
			const parsed = JSON.parse(err.response.data.message);
			const message = parsed.detail.trim();

			return Promise.reject(message);
		}
	};

	getUploadedImages = async (
		projectId: string,
		page?: number,
		filterBy?: FilterByOptions<PlaygroundImage>,
		sortBy: SortByOptions<PlaygroundImage> = { field: "created_at", order: "desc" },
	) => {
		try {
			this.loadingProjectSessions = true;
			const uploadedImages: PaginatedItems<PlaygroundImage> = await this.queryService.get(
				`/${projectId}/images/uploads`,
				{
					params: {
						filter_by: filterBy,
						sort_by: sortBy,
						page: page,
						per_page: this.rowsPerPage,
					},
				},
			);

			runInAction(() => {
				const selectedIds = new Set(this.getSelectedUploadedImages().map((img) => img.id));
				uploadedImages.items = uploadedImages.items.map((image) => ({
					...image,
					selected: selectedIds.has(image.id),
				}));

				this.uploadedImages = uploadedImages;
				this.loadingProjectSessions = false;
			});

			return this.uploadedImages;
		} catch (err: any) {
			this.loadingProjectSessions = false;
			return Promise.reject(err);
		}
	};

	getFavoriteImages = async (
		projectId: string,
		page?: number,
		filterBy?: FilterByOptions<PlaygroundImage>,
		sortBy: SortByOptions<PlaygroundImage> = { field: "created_at", order: "desc" },
	) => {
		try {
			this.loadingProjectSessions = true;
			const favoriteImages: PaginatedItems<PlaygroundImage> = await this.queryService.get(
				`/${projectId}/images/favorites`,
				{
					params: {
						filter_by: filterBy,
						sort_by: sortBy,
						page: page,
						per_page: this.rowsPerPage,
					},
				},
			);

			runInAction(() => {
				const selectedIds = new Set(this.getSelectedFavoriteImages().map((img) => img.id));

				favoriteImages.items = favoriteImages.items.map((image) => ({
					...image,
					selected: selectedIds.has(image.id),
				}));

				this.favoriteImages = favoriteImages;
				this.loadingProjectSessions = false;
			});

			return this.favoriteImages;
		} catch (err: any) {
			this.loadingProjectSessions = false;
			return Promise.reject(err);
		}
	};

	handleSelectSession = (session: PlaygroundSession) => {
		runInAction(() => {
			this.selectedSession = session;
		});
	};

	createPlaygroundResult = async (
		result: Partial<PlaygroundResult>,
		sessionId?: string,
	): Promise<PlaygroundResult | undefined> => {
		const { sessionsStore } = this.playgroundStore;
		const images = result.images?.map(({ id, ...rest }) => rest);
		const loadingImages = images?.filter((image) => image?.url?.trim() === "");
		const dataToSend = {
			session_id: sessionId ?? sessionsStore.sessionForm.id,
			images: images?.map((img) => ({
				...img,
				project_id: this.project.id,
				bg_props: { original: { result_url: img.url } },
			})),
			type: result.type,
			config: result.config,
		};

		if (loadingImages?.length === 0) {
			try {
				runInAction(() => {
					this.isCreatingPlaygroundResults = true;
				});
				const response = await this.resultsQueryService.post("/", dataToSend);
				return response;
			} catch (e) {
				console.log("Error ", e);
			} finally {
				runInAction(() => {
					this.isCreatingPlaygroundResults = false;
				});
			}
		}

		return undefined;
	};

	handleAddImageToFavorite = async (image: PlaygroundImage, isFavorite: boolean) => {
		try {
			runInAction(() => {
				this.loadingProjectSessions = true;
			});

			const { ImageOverlay, displayOverlay, downloadProps, fullScreenProps, htmlJsonData, ...cleanedImage } =
				image as any;

			await this.imagesQueryService.put("/", { ...cleanedImage, is_favorite: isFavorite });
			runInAction(() => {
				this.loadingProjectSessions = false;
			});
		} catch (e) {
			console.log("Error while add/remove image to favorite");
		}
	};

	handleDeleteImage = async (image_id: string): Promise<void> => {
		try {
			runInAction(() => {
				this.loadingDeleteImages = true;
			});
			await this.imagesQueryService.delete(`/${image_id}`);
		} catch (e) {
			console.log("error deleting image: ", e);
		} finally {
			runInAction(() => {
				this.loadingDeleteImages = true;
			});
		}
	};

	handleDeleteAllUploads = async (project_id: string): Promise<void> => {
		try {
			runInAction(() => {
				this.loadingDeleteImages = true;
			});
			await this.queryService.delete(`/${project_id}/images/uploads`);
		} catch (e) {
			console.log("error deleting uploaded images: ", e);
		} finally {
			runInAction(() => {
				this.loadingDeleteImages = true;
			});
		}
	};

	handleDeleteAllFavorites = async (project_id: string): Promise<void> => {
		try {
			runInAction(() => {
				this.loadingDeleteImages = true;
			});
			await this.queryService.delete(`/${project_id}/images/favorites`);
		} catch (e) {
			console.log("error deleting favorite images: ", e);
		} finally {
			runInAction(() => {
				this.loadingDeleteImages = true;
			});
		}
	};

	toggleAutomotiveReflectionConfig = (status: boolean) => {
		runInAction(() => {
			this.isAutomotiveReflectionConfigEnabled = status;
		});
	};

	toggleAutomotiveEffectsConfig = (status: boolean) => {
		runInAction(() => {
			this.isAutomotiveEffectsConfigEnabled = status;
		});
	};

	toggleAutomotiveHarmonizationConfig = (status: boolean) => {
		runInAction(() => {
			this.isAutomotiveHarmonizationConfigEnabled = status;
		});
	};
}

export type PlaygroundProject = {
	id: string;
	user_id?: string;
	org_id?: string;
	name: string;
	description?: string;
	from_app?: APPS | null;
	extra_info?: any;
	created_at: string;
	updated_at: string;
	sessions?: PlaygroundSession[];
};

export const defaultProject: PlaygroundProject = {
	id: "",
	name: "",
} as PlaygroundProject;

export type ProjectImagesTypes = "uploadedImages" | "favoriteImages";
