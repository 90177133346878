import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import EyeIcon from "../../../../../assets/images/icons/EyeIcon";
import EyeOffIcon from "../../../../../assets/images/icons/EyeOffIcon";
import OpacityIcon from "../../../../../assets/images/icons/OpacityIcon";
import BriaButton from "../../../../../components/common/BriaButton/BriaButton";
import BriaIconButton from "../../../../../components/common/BriaIconButton/BriaIconButton";
import ConfirmationPopup from "../../../../../components/common/ConfirmationPopup/ConfirmationPopup";
import { useAppStore } from "../../../../../hooks/useStores";
import { ImageEditingConfigComponentProps, ImageEditingViewMode } from "../../../../../models/image-to-image";
import { AutomotiveReflectionLayer } from "../../../../../models/playground";
import styles from "./AutomotiveReflection.module.scss";

type ReflectionProps = {
	setActiveConfig: (config: string) => void;
} & ImageEditingConfigComponentProps;

const AutomotiveReflection = ({ setActiveConfig, onBackButtonClick }: ReflectionProps) => {
	const { playgroundStore } = useAppStore();
	const { projectsStore, automotiveStore } = playgroundStore;
	const { reflectionStore } = automotiveStore;
	const selectedImage = playgroundStore.singleSelectedImage;
	const selectedReflectionLayer = playgroundStore.selectedReflectionLayer;
	const [reflectionsList, setReflectionsList] = useState<AutomotiveReflectionLayer[] | undefined>();

	const { t } = useTranslation("translation", {
		keyPrefix: `playground.${playgroundStore.selectedConfig}.config.features.reflection`,
	});

	const { t: tc } = useTranslation("translation", {
		keyPrefix: `playground.${playgroundStore.selectedConfig}.config`,
	});

	useEffect(() => {
		projectsStore.toggleAutomotiveReflectionConfig(true);
		return () => {
			automotiveStore.toggleIsSaved(true);
			playgroundStore.imagesReflections = [];
			projectsStore.toggleAutomotiveReflectionConfig(false);
		};
	}, [projectsStore]);

	useEffect(() => {
		if (playgroundStore.imageEditingViewMode === ImageEditingViewMode.GALLERY) {
			onBackButtonClick?.();
		}
	}, [playgroundStore.imageEditingViewMode]);

	useEffect(() => {
		if (selectedImage) {
			const selectedImageReflections = playgroundStore.getAutomotiveImageReflections(selectedImage?.url);
			setReflectionsList(selectedImageReflections?.layers);
		}
	}, [playgroundStore.selectedSingleImage, playgroundStore.isLoadingReflections]);

	const windowsLayers = reflectionsList?.filter((layer) => layer.name.toLowerCase().includes("wind")) || [];
	const otherLayers = reflectionsList?.filter((layer) => !layer.name.toLowerCase().includes("wind")) || [];
	const areWindowsHidden = windowsLayers.every((layer) => layer.hidden);
	const isAnyWindowsSelected = windowsLayers.some((layer) => layer.name === selectedReflectionLayer?.name);
	const isWindowsGroupDisabled = windowsLayers.every((layer) => !layer.url);

	const toggleWindowsVisibility = () => {
		windowsLayers.forEach((layer) => {
			playgroundStore.showHideReflectionLayer(selectedImage?.url ?? "", layer.name, !areWindowsHidden);
		});
	};

	const toggleSelectedLayer = (layer: AutomotiveReflectionLayer) => {
		if (selectedReflectionLayer?.name === layer.name) {
			playgroundStore.handleSelectReflectionLayer(null);
		} else {
			playgroundStore.handleSelectReflectionLayer(layer);
		}
	};

	const handleGenerate = async () => {
		await playgroundStore.generateReflectionLayers();
		automotiveStore.toggleIsSaved(false);
	};

	const handleSave = async () => {
		automotiveStore.toggleIsSaved(true);
		const reflectionList: AutomotiveReflectionLayer[] | undefined = playgroundStore.getAutomotiveImageReflections(
			selectedImage?.url as string,
		)?.layers;
		reflectionStore.saveReflection(reflectionList);
	};

	const closeWarningPopup = () => {
		automotiveStore.openWarningPopup = false;
	};

	const switchConfigWithoutSave = () => {
		closeWarningPopup();
		automotiveStore.isSaved = true;
		setActiveConfig(automotiveStore.configToSwitch ?? "");
	};

	const handleConfirmWarning = async () => {
		await handleSave();
		projectsStore.toggleAutomotiveReflectionConfig(false);
		closeWarningPopup();
		setActiveConfig(automotiveStore.configToSwitch ?? "");
	};

	return (
		<>
			<Box className={styles.reflectionContainer}>
				{!reflectionsList && <Typography className={styles.description}>{t("clickGenerate")}</Typography>}
				<Box className={styles.reflectionsList}>
					{/* Grouped Windows Layers */}
					{windowsLayers.length > 0 && (
						<Box className={styles.reflectionGroup}>
							<Box className={styles.groupHeader}>
								<Typography className={styles.groupTitle}>{t("windows")}</Typography>
								<Box className={styles.groupActions}>
									<BriaIconButton
										className={clsx(styles.btn, { [styles.disabled]: isWindowsGroupDisabled })}
										onClick={toggleWindowsVisibility}
										disabled={isWindowsGroupDisabled}
									>
										{areWindowsHidden ? <EyeOffIcon /> : <EyeIcon opacity={1} />}
									</BriaIconButton>
									<BriaIconButton
										className={clsx(styles.btn, { [styles.selected]: isAnyWindowsSelected })}
										onClick={() => {
											const firstVisibleLayer = windowsLayers.find((layer) => layer.url);
											if (firstVisibleLayer) toggleSelectedLayer(firstVisibleLayer);
										}}
										disabled={isWindowsGroupDisabled}
									>
										<OpacityIcon />
									</BriaIconButton>
								</Box>
							</Box>
						</Box>
					)}

					{/* Other Layers */}
					{otherLayers.map((layer) => (
						<Box
							key={layer.name}
							className={clsx(styles.reflectionItem, { [styles.disabled]: !layer.url })}
						>
							<Typography className={styles.reflectionTitle}>{t(`layers.${layer.name}`)}</Typography>
							<Box className={styles.reflectionActions}>
								<BriaIconButton
									className={styles.btn}
									onClick={() =>
										playgroundStore.showHideReflectionLayer(
											selectedImage?.url ?? "",
											layer.name,
											!layer.hidden,
										)
									}
								>
									{layer.hidden ? <EyeOffIcon /> : <EyeIcon opacity={1} />}
								</BriaIconButton>
								<BriaIconButton
									className={clsx(styles.btn, {
										[styles.selected]: layer.name === selectedReflectionLayer?.name,
									})}
									onClick={() => toggleSelectedLayer(layer)}
								>
									<OpacityIcon />
								</BriaIconButton>
							</Box>
						</Box>
					))}
				</Box>

				{/* Save/Generate Button */}
				<BriaButton
					buttonType="primaryMedium"
					fullWidth
					className={styles.actionBtn}
					onClick={reflectionsList?.length ? handleSave : handleGenerate}
					disabled={playgroundStore.isLoadingReflections || reflectionStore.loadingSaveReflection}
					loading={playgroundStore.isLoadingReflections}
				>
					{t(reflectionsList?.length ? "save" : "generate")}
				</BriaButton>
			</Box>

			<ConfirmationPopup
				open={automotiveStore.openWarningPopup}
				onClose={closeWarningPopup}
				title={tc("warningPopup.title")}
				description={tc("warningPopup.description")}
				confirmButtonText={tc("warningPopup.saveChanges")}
				onClick={handleConfirmWarning}
				onSecondaryClick={switchConfigWithoutSave}
				firstButtonText={tc("warningPopup.undoChanges")}
				loading={playgroundStore.isLoadingReflections}
			/>
		</>
	);
};

export default observer(AutomotiveReflection);
