import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import { debounce } from "lodash";
import { observer } from "mobx-react-lite";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import BriaButton from "../../../../../components/common/BriaButton/BriaButton";
import BriaInput from "../../../../../components/common/BriaInput/BriaInput";
import { useAppStore } from "../../../../../hooks/useStores";
import styles from "./ProjectHeader.module.scss";

const ProjectHeader = () => {
	const { t } = useTranslation("translation", { keyPrefix: "automotiveApp.project" });
	const { playgroundStore, uiStore } = useAppStore();
	const { projectsStore } = playgroundStore;
	const project = projectsStore.project;

	const [projectData, setProjectData] = useState({
		name: project?.name || "",
		description: project?.description || "",
		vehicleModel: project?.extra_info?.["vehicleModel"] || "",
	});

	// Debounced update function
	const debouncedUpdateProject = useCallback(
		debounce((updatedProject) => {
			if (project) {
				projectsStore.updateProject(project.id, updatedProject);
			}
		}, 500),
		[project],
	);

	const handleProjectChange = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		const updatedProject = {
			...project,
			[field]: value,
		};
		setProjectData((prev) => ({ ...prev, [field]: value }));
		debouncedUpdateProject(updatedProject);
	};

	const handleProjectExtraInfoChange = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		const updatedProject = {
			...project,
			extra_info: {
				...project.extra_info,
				[field]: value,
			},
		};
		setProjectData((prev) => ({ ...prev, [field]: value }));
		debouncedUpdateProject(updatedProject);
	};

	return (
		<Box className={styles.header}>
			<Box className={styles.projectInfo}>
				<BriaInput
					value={projectData.name}
					onChange={handleProjectChange("name")}
					InputProps={{
						className: clsx(styles.inputField, styles.titleStyle),
						disableUnderline: true,
					}}
					variant="standard"
					sx={{ width: `calc(${projectData.name ? projectData.name?.length : 30}ch + 30px)` }}
					placeholder={t("projectTitle")}
				/>

				<Box className={styles.extraInfo}>
					<Box className={styles.infoItem}>
						<Typography className={styles.fieldKey}>{t("vehicleName")}</Typography>
						<BriaInput
							value={projectData.vehicleModel}
							onChange={handleProjectExtraInfoChange("vehicleModel")}
							InputProps={{
								className: clsx(styles.inputField, styles.infoStyle),
								disableUnderline: true,
							}}
							variant="standard"
							sx={{
								width: `calc(${
									projectData.vehicleModel ? projectData.vehicleModel.length : 12
								}ch + 30px)`,
							}}
							placeholder={t("addVehicleName")}
						/>
					</Box>
					<Box className={styles.infoItem}>
						<Typography className={styles.fieldKey}>{t("vehicleDescription")}</Typography>
						<BriaInput
							value={projectData.description}
							onChange={handleProjectChange("description")}
							InputProps={{
								className: clsx(styles.inputField, styles.infoStyle),
								disableUnderline: true,
							}}
							variant="standard"
							sx={{ width: `${projectData.description ? projectData.description.length : 30}ch` }}
							placeholder={t("addVehicleDescription")}
						/>
					</Box>
				</Box>
			</Box>
			<Box className={styles.actions}>
				<BriaButton
					buttonType="primary"
					className={clsx(styles.actionBtn)}
					onClick={() => uiStore.showDialog("AddSessionDialog")}
				>
					{t("newSession")}
				</BriaButton>
			</Box>
		</Box>
	);
};

export default observer(ProjectHeader);
