import { makeAutoObservable, runInAction } from "mobx";
import { PlaygroundResult } from "../../../../models/playground";
import QueryService from "../../../../utils/QueryService";
import { IPlaygroundStore } from "../../../Playground/store/playground-store";

export interface IPlaygroundSessionsStore {
	sessionForm: PlaygroundSession;
	formErrors: {
		save?: boolean;
		requiredFields: { [key in keyof Partial<PlaygroundSession>]: boolean };
	};

	loadingSession: boolean;
	loadingCreateSession: boolean;
	loadingUpdateSession: boolean;
	loadingDeleteSession: boolean;

	handleFormChange: <K extends keyof PlaygroundSession>(key: K, value: PlaygroundSession[K]) => void;
	getSession: (id: string) => Promise<PlaygroundSession>;
	createSession: (sessionToCreate: PlaygroundSession) => Promise<string>;
	updateSession: (id: string, updatedSession: PlaygroundSession) => Promise<PlaygroundSession>;
	deleteSession: (id: string) => Promise<void>;
	clearSessionForm: () => void;
}

export class PlaygroundSessionsStore implements IPlaygroundSessionsStore {
	private queryService: QueryService = new QueryService("/playground-sessions");
	playgroundStore: IPlaygroundStore;
	sessions: PlaygroundSession[] = [];
	sessionForm: PlaygroundSession = defaultSession;
	formErrors: {
		save?: boolean;
		requiredFields: { [key in keyof Partial<PlaygroundSession>]: boolean };
	} = {
		requiredFields: { name: false },
	};

	loadingOrgSessions: boolean = false;
	loadingSession: boolean = false;
	loadingCreateSession: boolean = false;
	loadingUpdateSession: boolean = false;
	loadingDeleteSession: boolean = false;

	loadingSessionModels: boolean = false;
	loadingSessionDatasets: boolean = false;
	selectedSession: PlaygroundSession | null = null;

	constructor(playgroundStore: IPlaygroundStore) {
		makeAutoObservable(this);
		this.playgroundStore = playgroundStore;
	}

	handleFormChange = <K extends keyof PlaygroundSession>(key: K, value: PlaygroundSession[K]) => {
		this.sessionForm = { ...this.sessionForm, [key]: value };
	};

	handleSelectSession = (session: PlaygroundSession) => {
		runInAction(() => {
			this.sessionForm = session;
		});
	};

	getSession = async (id: string) => {
		try {
			this.loadingSession = true;
			const session = await this.queryService.get(`/${id}`);

			runInAction(() => {
				this.loadingSession = false;
				this.sessionForm = session;
				this.playgroundStore.playgroundResults = [];

				this.playgroundStore.playgroundResults = session.results
					.filter((result: any) => result.images?.length > 0) // Exclude results with empty images array
					.map((result: PlaygroundResult) => ({
						...result,
						images: result.images?.map(({ vhash, seed, style_props, ...rest }) => rest),
					}));
			});

			return this.sessionForm;
		} catch (err) {
			runInAction(() => {
				this.loadingSession = false;
			});
			return Promise.reject(err);
		}
	};

	createSession = async (sessionToCreate: PlaygroundSession) => {
		try {
			this.loadingCreateSession = true;
			const newSessionId = await this.queryService.post(`/`, sessionToCreate);

			runInAction(() => {
				this.loadingCreateSession = false;
			});

			return newSessionId;
		} catch (err: any) {
			this.loadingCreateSession = false;

			return Promise.reject(err);
		}
	};

	updateSession = async (id: string, sessionToUpdate: PlaygroundSession) => {
		try {
			this.loadingUpdateSession = true;
			const updatedSession = await this.queryService.put(`/`, sessionToUpdate);

			runInAction(() => {
				this.sessions = this.sessions?.map((session) => (session.id === id ? updatedSession : session));
				sessionToUpdate.id = id;
				this.loadingUpdateSession = false;
			});
			return sessionToUpdate;
		} catch (err: any) {
			this.loadingUpdateSession = false;
			return Promise.reject(err);
		}
	};

	deleteSession = async (id: string) => {
		try {
			this.loadingDeleteSession = true;
			await this.queryService.delete(`/${id}`);

			runInAction(() => {
				this.sessions = this.sessions.filter((session) => session.id !== id);
				this.loadingDeleteSession = false;
			});
		} catch (err: any) {
			this.loadingDeleteSession = false;
			const parsed = JSON.parse(err.response.data.message);
			const message = parsed.detail.trim();

			return Promise.reject(message);
		}
	};

	deleteSessionImages = async (id: string) => {
		try {
			this.loadingDeleteSession = true;
			await this.queryService.delete(`/${id}`);

			runInAction(() => {
				this.sessions = this.sessions.filter((session) => session.id !== id);
				this.loadingDeleteSession = false;
			});
		} catch (err: any) {
			this.loadingDeleteSession = false;
			const parsed = JSON.parse(err.response.data.message);
			const message = parsed.detail.trim();

			return Promise.reject(message);
		}
	};

	clearSessionForm = () => {
		runInAction(() => {
			this.sessionForm = defaultSession;
		});
	};
}

export type PlaygroundSession = {
	id: string;
	name: string;
	created_at?: string;
	updated_at?: string;
	project_id: string;
	results?: PlaygroundResult[];
};

export const defaultSession: PlaygroundSession = {
	id: "",
	name: "",
} as PlaygroundSession;
