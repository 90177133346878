import { Box, SelectChangeEvent } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import BriaButton from "../../../../../components/common/BriaButton/BriaButton";
import BriaDropDown from "../../../../../components/common/BriaDropDown/BriaDropDown";
import ConfirmationPopup from "../../../../../components/common/ConfirmationPopup/ConfirmationPopup";
import useStaticDropdown from "../../../../../components/common/DropDowns/useStaticDropdown";
import { useAppStore } from "../../../../../hooks/useStores";
import { ImageEditingConfigComponentProps, ImageEditingViewMode } from "../../../../../models/image-to-image";
import { AutomotiveReflectionLayer } from "../../../../../models/playground";
import styles from "./AutomotiveEffects.module.scss";
import { AutomotiveEffect } from "./automotive-effects-store";

type EffectsProps = {
	setActiveConfig: (config: string) => void;
} & ImageEditingConfigComponentProps;

const AutomotiveEffects = ({ setActiveConfig, onBackButtonClick }: EffectsProps) => {
	const { playgroundStore } = useAppStore();
	const { automotiveStore, projectsStore } = playgroundStore;
	const { t } = useTranslation("translation", { keyPrefix: "playground.automotive.config.features.effects" });
	const { t: tc } = useTranslation("translation", {
		keyPrefix: `playground.${playgroundStore.selectedConfig}.config`,
	});
	const { effectsStore, reflectionStore } = automotiveStore;
	const config = effectsStore.config;
	const selectedImage = playgroundStore.singleSelectedImage;

	const { items: effectsItems } = useStaticDropdown<AutomotiveEffect>({
		tKeyPrefix: "playground.automotive.config.features.effects.effectsDropdown.items",
	});

	useEffect(() => {
		projectsStore.toggleAutomotiveEffectsConfig(true);
		return () => {
			automotiveStore.toggleIsSaved(true);
			playgroundStore.imagesEffects = [];
			effectsStore.handleFormChange("effect", "");
			projectsStore.toggleAutomotiveEffectsConfig(false);
		};
	}, [projectsStore]);

	useEffect(() => {
		if (playgroundStore.imageEditingViewMode == ImageEditingViewMode.GALLERY) {
			onBackButtonClick?.();
		}
	}, [playgroundStore.imageEditingViewMode]);

	const handleSave = async () => {
		automotiveStore.toggleIsSaved(true);
		const reflectionList: AutomotiveReflectionLayer[] | undefined = playgroundStore.getAutomotiveImageEffects(
			selectedImage?.url as string,
		)?.layers;
		reflectionStore.saveReflection(reflectionList);
	};

	const handleViewEffect = async (e: SelectChangeEvent<AutomotiveEffect>) => {
		automotiveStore.toggleIsSaved(false);
		effectsStore.handleFormChange("effect", e.target.value as AutomotiveEffect);
		effectsStore.applyEffect(e.target.value);
	};

	const closeWarningPopup = () => {
		automotiveStore.openWarningPopup = false;
	};

	const switchConfigWithoutSave = () => {
		closeWarningPopup();
		automotiveStore.isSaved = true;
		setActiveConfig(automotiveStore.configToSwitch ?? "");
	};

	const handleConfirmWarning = async () => {
		await handleSave();
		projectsStore.toggleAutomotiveEffectsConfig(false);
		closeWarningPopup();
		setActiveConfig(automotiveStore.configToSwitch ?? "");
	};

	return (
		<>
			<Box className={styles.wrapper}>
				<BriaDropDown
					items={effectsItems}
					value={config.effect}
					onChange={(e) => handleViewEffect(e)}
					placeholder={t("effectsDropdown.placeholder")}
					loading={effectsStore.loadingApplyEffects}
				/>
				<BriaButton
					buttonType="primaryMedium"
					className={styles.formButton}
					onClick={handleSave}
					loading={reflectionStore.loadingSaveReflection}
					disabled={reflectionStore.loadingSaveReflection || effectsStore.loadingApplyEffects || reflectionStore.loadingSaveReflection}
				>
					{t("formButton")}
				</BriaButton>
			</Box>
			<ConfirmationPopup
				open={automotiveStore.openWarningPopup}
				onClose={closeWarningPopup}
				title={tc("warningPopup.title")}
				description={tc("warningPopup.description")}
				confirmButtonText={tc("warningPopup.saveChanges")}
				onClick={handleConfirmWarning}
				onSecondaryClick={switchConfigWithoutSave}
				firstButtonText={tc("warningPopup.undoChanges")}
				loading={playgroundStore.isLoadingReflections}
			/>
		</>
	);
};

export default observer(AutomotiveEffects);
