import { makeAutoObservable, runInAction } from "mobx";
import { v4 as uuidv4 } from "uuid";
import { APPS } from "../../../../../constants/AppsConstants";
import { ImageToImageConfigType } from "../../../../../models/image-to-image";
import { AutomotiveReflectionLayer, PlaygroundImage, PlaygroundResult } from "../../../../../models/playground";
import QueryService from "../../../../../utils/QueryService";
import { IAutomotiveStore } from "../../automotive-store";
export interface IAutomotiveReflectionStore {
	config: AutomotiveReflectionConfig;

	loadingSaveReflection: boolean;

	handleFormChange: <K extends keyof AutomotiveReflectionConfig>(
		key: K,
		value: AutomotiveReflectionConfig[K],
	) => void;
	saveReflection: (layers: AutomotiveReflectionLayer[] | undefined) => Promise<string | undefined>;
}

export class AutomotiveReflectionStore implements IAutomotiveReflectionStore {
	private queryService: QueryService = new QueryService("/automotive/reflection");
	automotiveStore: IAutomotiveStore;

	config: AutomotiveReflectionConfig = { preset: "" as AutomotivePreset };

	loadingSaveReflection: boolean = false;

	constructor(automotiveStore: IAutomotiveStore) {
		makeAutoObservable(this);
		this.automotiveStore = automotiveStore;
	}

	handleFormChange = <K extends keyof AutomotiveReflectionConfig>(key: K, value: AutomotiveReflectionConfig[K]) => {
		this.config = { ...this.config, [key]: value };
	};

	saveReflection = async (layers: AutomotiveReflectionLayer[]| undefined) => {
		this.loadingSaveReflection = true;

		const playgroundStore = this.automotiveStore.playgroundStore;
		const originalImage = this.automotiveStore.playgroundStore.singleSelectedImage;
		const savedIndex = playgroundStore.playgroundResults.length;

		try {
			const resultsSkeletons: PlaygroundResult = {
				id: uuidv4(),
				config: playgroundStore.selectedSingleImage?.config,
				type: APPS.AUTOMOTIVE,
				images: [
					{
						id: "",
						url: "",
						loading: true,
						selected: true,
						playground_result_id: "",
					},
				],
			};
			playgroundStore.playgroundResults = [...playgroundStore.playgroundResults, resultsSkeletons];

			const formData = new FormData();
			const finalImage = await this.createFinalReflectionImage(originalImage?.url, layers);

			finalImage && formData.append("file", finalImage);

			const imageUrl: string = await this.queryService.post("/", formData, {
				"Content-Type": "multipart/form-data",
			});

			runInAction(() => {
				this.loadingSaveReflection = false;

				const { playgroundResults, projectsStore } = playgroundStore;
				const savedPlaygroundResult = playgroundResults[savedIndex];

				const newImage: PlaygroundImage = {
					...originalImage,
					...(originalImage?.file ? { file: undefined } : {}),
					id: uuidv4(),
					url: imageUrl,
					loading: true,
					type: APPS.AUTOMOTIVE,
					config: {
						...savedPlaygroundResult.config,
						original_image: { ...originalImage },
					} as ImageToImageConfigType,
				};

				savedPlaygroundResult.images = [newImage];
				playgroundStore.handleSelectSingleImage(newImage);
				projectsStore.createPlaygroundResult(playgroundStore.playgroundResults[savedIndex]);
			});

			return imageUrl;
		} catch (err: any) {
			runInAction(() => {
				playgroundStore.playgroundResults = playgroundStore.playgroundResults.filter(
					(_, index) => index !== savedIndex,
				);
				return Promise.reject(err);
			});
		} finally {
			runInAction(() => {
				this.loadingSaveReflection = false;
			});
		}
	};

	async createFinalReflectionImage(
		baseImageUrl: string | undefined,
		reflectionLayers: AutomotiveReflectionLayer[] | undefined,
	): Promise<File | null> {
		if (!baseImageUrl || (reflectionLayers && reflectionLayers.length === 0)) return null;

		const baseImg = new Image();
		baseImg.crossOrigin = "anonymous";
		baseImg.src = baseImageUrl;

		return new Promise<File | null>((resolve, reject) => {
			baseImg.onload = async () => {
				const canvas = document.createElement("canvas");
				const width = baseImg.width;
				const height = baseImg.height;
				canvas.width = width;
				canvas.height = height;
				const ctx = canvas.getContext("2d");

				if (!ctx) return reject("Canvas context not available");

				ctx.drawImage(baseImg, 0, 0, width, height);

				if (reflectionLayers) {
					await Promise.all(
						reflectionLayers.map(
							(layer) =>
								new Promise<void>((layerResolve) => {
									if (layer.hidden) return layerResolve();

									const img = new Image();
									img.crossOrigin = "anonymous";
									img.src = layer.url as string;
									img.onload = () => {
										ctx.save();
										ctx.globalAlpha = layer.intensity;
										ctx.drawImage(img, 0, 0, width, height);
										ctx.restore();
										layerResolve();
									};
									img.onerror = () => layerResolve();
								}),
						),
					);
				}

				canvas.toBlob((blob) => {
					if (blob) {
						const file = new File([blob], "final_image.png", { type: "image/png" });
						resolve(file);
					} else {
						resolve(null);
					}
				}, "image/png");
			};

			baseImg.onerror = reject;
		});
	}
}

export type AutomotivePreset = "warm day" | "cold day" | "warm night" | "cold night";

export type AutomotiveReflectionConfig = {
	image_url?: string;
	image_file?: File;
	preset: AutomotivePreset;
};
