import { useTranslation } from "react-i18next";
import { useAppStore } from "../../../hooks/useStores.tsx";
import iframeStore from "../../../pages/IframeNew/iframe-store.tsx";
import BriaButton, { ButtonTypes } from "../BriaButton/BriaButton";
import BriaDropDown from "../BriaDropDown/BriaDropDown.tsx";
import styles from "./ExportButton.module.scss";
interface IProps {
	onDownload?: () => Promise<void>;
	onJsonExport?: () => void;
	onIframeSave?: () => Promise<void>;
	disabled?: boolean;
	loading?: boolean;
	disableExportPopup?: boolean;
	exportJson?: boolean;
	buttonType?: ButtonTypes;
	className?: string;
}

const ExportButton = ({
	onDownload,
	onJsonExport,
	onIframeSave,
	disabled,
	loading,
	disableExportPopup,
	exportJson,
	buttonType = "textSmall",
	className,
}: IProps) => {
	const { t } = useTranslation("translation", { keyPrefix: "exportButton" });
	const { uiStore } = useAppStore();

	const onExportWithoutPopup = async (action: string) => {
		if (disabled) {
			return;
		}
		if (action === "save") {
			onIframeSave?.();
		} else if (action === "download") {
			onDownload?.();
		} else if (action === "exportJson") {
			onJsonExport?.();
		}
	};

	const ExportButtonWithPopup = () => {
		const savelabel = iframeStore.iframe.config.general_config?.save_label
			? iframeStore.iframe.config.general_config?.save_label
			: t("Save");
		return iframeStore.isIframe() ? (
			<>
				{iframeStore.iframe.config.general_config?.enable_save && (
					<BriaButton
						buttonType="textSmall"
						onClick={() => uiStore.showExportImagePopup({ showOnlySaveButton: true })}
						size="small"
						disabled={disabled}
						loading={loading}
					>
						{savelabel}
					</BriaButton>
				)}
				{iframeStore.iframe.config.general_config?.enable_download && (
					<BriaButton
						className={className}
						buttonType={buttonType}
						onClick={() => uiStore.showExportImagePopup({ showOnlyDownloadButton: true })}
						size="small"
						disabled={disabled}
						loading={loading}
					>
						{t("download")}
					</BriaButton>
				)}
			</>
		) : (
			<BriaButton
				className={className}
				buttonType={buttonType}
				onClick={() => uiStore.showExportImagePopup()}
				size="small"
				disabled={disabled}
				loading={loading}
			>
				{t("export")}
			</BriaButton>
		);
	};

	const ExportButtonWithoutPopup = ({ label, value }: { label: string; value: string }) => {
		return exportJson && !iframeStore.isIframe() ? (
			<BriaDropDown
				loading={loading}
				height={"32px"}
				onChange={(e) => onExportWithoutPopup(e.target.value)}
				disabled={disabled}
				items={[
					{
						key: t("png"),
						value: "download",
					},
					{
						key: t("json"),
						value: "exportJson",
					},
				]}
				className={styles.exportDropdown}
				placeholder={t("export")}
			/>
		) : (
			<BriaButton
				buttonType="textSmall"
				onClick={() => onExportWithoutPopup(value)}
				size="small"
				disabled={disabled}
				loading={loading}
			>
				{label}
			</BriaButton>
		);
	};

	const IframeExport = () => {
		const saveLabel = iframeStore.iframe.config.general_config?.save_label
			? iframeStore.iframe.config.general_config?.save_label
			: t("save");
		if (
			iframeStore.iframe.config.general_config?.enable_download ||
			iframeStore.iframe.config.general_config?.enable_save
		) {
			if (!disableExportPopup) {
				return <ExportButtonWithPopup />;
			} else {
				if (
					iframeStore.iframe.config.general_config?.enable_download &&
					iframeStore.iframe.config.general_config?.enable_save
				) {
					return (
						<>
							<BriaButton
								className={className}
								buttonType={buttonType}
								onClick={() => onExportWithoutPopup("download")}
								size="small"
								disabled={disabled}
							>
								{t("download")}
							</BriaButton>
							<BriaButton
								className={className}
								buttonType={buttonType}
								onClick={() => onExportWithoutPopup("save")}
								size="small"
								disabled={disabled}
							>
								{saveLabel}
							</BriaButton>
						</>
					);
				} else {
					return (
						<ExportButtonWithoutPopup
							label={
								iframeStore.iframe.config.general_config?.enable_download ? t("download") : saveLabel
							}
							value={iframeStore.iframe.config.general_config?.enable_download ? "download" : "save"}
						/>
					);
				}
			}
		} else {
			return <></>;
		}
	};

	return (
		<>
			{iframeStore.isIframe() ? (
				<IframeExport />
			) : disableExportPopup ? (
				<ExportButtonWithoutPopup label={t("export")} value={"download"} />
			) : (
				<ExportButtonWithPopup />
			)}
		</>
	);
};

export default ExportButton;
