import QueryService from "../utils/QueryService.ts";

class AutomotiveService {
	queryService = new QueryService("/automotive_generations/");

	getReflectionLayers = async (imageUrl: string | undefined, masks: Record<string, string>): Promise<any> => {
		const response = await this.queryService.post("generate_reflections", {
			image_url: imageUrl,
			layers: true,
			masks: masks
		});
		return response;
	}

	generateSegmentation = async (imageUrl: string | undefined): Promise<any> => {
		const response = await this.queryService.post("generate_segments", {
			image_url: imageUrl,
		});
		return response;
	}
}

export default AutomotiveService;
