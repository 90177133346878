import { Box, Divider, Slider, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import ResetLayer from "../../../../assets/images/icons/ResetLayer";
import BriaButton from "../../../../components/common/BriaButton/BriaButton";
import { DEFAULT_BODY_INTENSITY, DEFAULT_WINDOWS_INTENSITY } from "../../../../constants/AutomotiveConstants";
import { useAppStore } from "../../../../hooks/useStores";
import styles from "./ReflectionControls.module.scss";

type ReflectionControlsProps = {
	onIntensityChange: (layerName: string, intensity: number) => void;
};

const ReflectionControls = ({ onIntensityChange }: ReflectionControlsProps) => {
	const { t } = useTranslation("translation", {
		keyPrefix: "playground.automotive.config.features",
	});

	const { playgroundStore } = useAppStore();
	const { projectsStore } = playgroundStore;
	const { singleSelectedImage, imagesReflections, selectedReflectionLayer, imagesEffects, imagesHarmonizations } =
		playgroundStore;

	const selectedImageReflections = projectsStore.isAutomotiveReflectionConfigEnabled
		? imagesReflections?.find((reflection) => reflection.url === singleSelectedImage?.url)
		: projectsStore.isAutomotiveEffectsConfigEnabled
		? imagesEffects?.find((reflection) => reflection.url === singleSelectedImage?.url)
		: imagesHarmonizations?.find((reflection) => reflection.url === singleSelectedImage?.url);

	const selectedLayer =
		projectsStore.isAutomotiveEffectsConfigEnabled || projectsStore.isAutomotiveHarmonizationConfigEnabled
			? selectedImageReflections?.layers[0]
			: selectedImageReflections?.layers.find((layer) => layer.name === selectedReflectionLayer?.name);

	if (!selectedLayer) return null;

	return (
		<Box className={styles.controlsContainer}>
			<Typography className={styles.label}>
				{selectedLayer.name === "effects"
					? t("effects.EffectsStrength")
					: selectedLayer.name === "harmonization"
					? t("harmonization.harmonizationDepth")
					: `${t(`reflection.layers.${selectedLayer.name}`)} ${t("reflection.opacity")}`}
			</Typography>
			<Box className={styles.sliderWrapper}>
				<Slider
					value={selectedLayer.intensity}
					min={0}
					max={1}
					step={0.01}
					onChange={(_, value) => {
						onIntensityChange(selectedLayer.name, value as number);
					}}
					valueLabelDisplay="auto"
					valueLabelFormat={(value) => `${Math.round(value * 100)}%`}
					className={styles.slider}
				/>
			</Box>
			<Divider className={styles.divider} />

			<BriaButton
				startIcon={<ResetLayer />}
				buttonType="textMedium"
				size="small"
				className={styles.resetBtn}
				onClick={() =>
					onIntensityChange(
						selectedLayer.name,
						selectedLayer.name === "body" ? DEFAULT_BODY_INTENSITY : DEFAULT_WINDOWS_INTENSITY,
					)
				}
			>
				{t("reflection.resetLayer")}
			</BriaButton>
		</Box>
	);
};

export default observer(ReflectionControls);
