import CloseIcon from "@mui/icons-material/CloseTwoTone";
import { Dialog, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { RelatedOrgSubscriptionTypes } from "../../../constants/billing.ts";
import { useAppStore } from "../../../hooks/useStores.tsx";
import InputLayout from "../../../layout/InputLayout/InputLayout.tsx";
import {
	sendInvitations,
} from "../../../pages/Account/RelatedOrganizations/RelatedOrganizationUtils.tsx";
import { DROPDOWN_HEIGHT } from "../../../pages/Account/UsageAndBilling/UsageAndBillingUtils.tsx";
import { showErrorToast } from "../../../utils/toast.tsx";
import BriaButton from "../../common/BriaButton/BriaButton.tsx";
import BriaDropDown from "../../common/BriaDropDown/BriaDropDown.tsx";
import BriaInput from "../../common/BriaInput/BriaInput.tsx";
import styles from "./CreateSubOrganizationPopup.module.scss";
import {observer} from "mobx-react-lite";
import {UserOrganization} from "../../../models/organization.ts";
import {setSelectedOrganization} from "../../../helpers/localStorage.ts";

interface IProp {
	open: boolean;
	onSubmit?: () => void;
	onClose: () => void;
}

const CreateSubOrganizationPopup = ({ open, onSubmit, onClose }: IProp) => {
	const { t } = useTranslation("translation", { keyPrefix: "account.relatedOrganizations.popup" });
	const { authStore, pricingStore, appStore } = useAppStore();
	const [organizationName, setOrganizationName] = useState("");
	const [subscription, setSubscription] = useState(RelatedOrgSubscriptionTypes.free);
	const [invitedUsersEmails, setInvitedUsersEmails] = useState("");
	const [loading, setLoading] = useState<boolean>(false);
	const subscriptionDropDownItems = [
		{
			key: t(`subscriptionDropDown.${RelatedOrgSubscriptionTypes.free}`),
			value: RelatedOrgSubscriptionTypes.free,
		},
		{
			key: t(`subscriptionDropDown.${RelatedOrgSubscriptionTypes.payAsYouGo}`),
			value: RelatedOrgSubscriptionTypes.payAsYouGo,
		},
	];

	const handleCreateSubOrganization = async () => {
		try {
			setLoading(true);
			const subOrg: UserOrganization = await authStore.createSubOrganization(organizationName);
			if (invitedUsersEmails) {
				await sendInvitations(t, appStore, subOrg.organization.uid, setInvitedUsersEmails, invitedUsersEmails);
			}
			if (subscription === RelatedOrgSubscriptionTypes.payAsYouGo && subOrg.organization.name) {
				setSelectedOrganization(subOrg);
				const extraQueryParams = new URLSearchParams();
				extraQueryParams.append("orgId", subOrg.organization.uid);
				pricingStore.openPricingFlowWithQueryParams(extraQueryParams);
			} else {
				await authStore.loadOrganizations()
				onSubmit && onSubmit();
			}
		} catch (e: any) {
			showErrorToast(e.response?.data?.message ?? t("pleaseTryAgainLater"));
		} finally {
			setLoading(false);
		}
	};

	return (
		<Dialog open={open} onClose={onClose} className={styles.dialogContainer}>
			<Box className={styles.contentContainer}>
				<CloseIcon onClick={onClose} className={styles.closeIcon} />
				<Typography className={styles.title}>{t("title")}</Typography>
				<InputLayout label={`${t("organizationName")}*`}>
					<BriaInput
						id="organization"
						className={styles.formControl}
						placeholder={t("organizationNamePlaceholder")}
						onChange={(e: any) => setOrganizationName(e.target.value)}
						value={organizationName}
					/>
				</InputLayout>
				<InputLayout label={t("selectSubscription")}>
					<BriaDropDown
						value={subscription}
						className={styles.formControl}
						onChange={(e: any) => setSubscription(e.target.value)}
						items={subscriptionDropDownItems}
						height={DROPDOWN_HEIGHT}
					/>
				</InputLayout>
				<InputLayout label={t("inviteUsers")}>
					<Typography className={styles.helpText}>{t("inviteUsersHelpText")}</Typography>
					<BriaInput
						id="inviteUsers"
						alignment={"flex-start"}
						multiline
						className={styles.textFieldStyle}
						height={"108px"}
						type="text"
						placeholder={t("inviteUsersPlaceholder")}
						value={invitedUsersEmails}
						onChange={(e: any) => {
							setInvitedUsersEmails(e.target.value);
						}}
					/>
				</InputLayout>

				<Box className={styles.buttonsContainer}>
					<Box className={styles.buttons}>
						<BriaButton
							className={styles.submitBtn}
							disabled={!organizationName || authStore.isCreateSubOrgsLoading || loading}
							onClick={handleCreateSubOrganization}
							buttonType="primaryMedium"
							loading={loading}
						>
							{t("submitButton")}
						</BriaButton>
					</Box>
				</Box>
			</Box>
		</Dialog>
	);
};

export default observer(CreateSubOrganizationPopup);
