import { MoreVert } from "@mui/icons-material";
import { Box, IconButton, ListItem, Menu, MenuItem, Typography } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import styles from "./SidebarMenuItem.module.scss";

interface SidebarMenuItemProps {
	icon?: React.ReactNode;
	title: string;
	actions?: { label: string; onClick: (title: string) => void }[];
	selected?: boolean;
	small?: boolean;
	onClick?: () => void;
}
const SidebarMenuItem = ({ icon, title, actions, selected, small, onClick }: SidebarMenuItemProps) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	return (
		<ListItem
			disableGutters
			className={clsx(styles.menuItem, {
				[styles.selected]: selected,
				[styles.active]: open,
				[styles.small]: small,
			})}
		>
			<Box className={styles.titleWrapper} onClick={onClick}>
				{icon}
				<Typography className={styles.title}>{title}</Typography>
			</Box>

			{actions && (
				<>
					<IconButton
						onClick={handleMenuOpen}
						className={clsx(styles.menuOpenBtn, {
							[styles.active]: open,
						})}
					>
						<MoreVert />
					</IconButton>
					<Menu
						anchorEl={anchorEl}
						open={open}
						onClose={handleMenuClose}
						anchorOrigin={{
							vertical: "top",
							horizontal: "right",
						}}
						className={styles.actionsMenu}
					>
						{actions.map((action, index) => (
							<MenuItem
								key={index}
								onClick={() => action.onClick(title)}
								className={styles.actionsMenuItem}
							>
								{action.label}
							</MenuItem>
						))}
					</Menu>
				</>
			)}
		</ListItem>
	);
};

export default observer(SidebarMenuItem);
