import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import BriaButton from "../../../../../../components/common/BriaButton/BriaButton";
import ConfirmationPopup from "../../../../../../components/common/ConfirmationPopup/ConfirmationPopup";
import { useAppStore } from "../../../../../../hooks/useStores";
import { PlaygroundImage } from "../../../../../../models/playground";
import { isAutomotiveFavoritesPage, isAutomotiveUploadedPage } from "../../../../../../utils";
import styles from "./ProjectHeaderActions.module.scss";

const ProjectHeaderActions = () => {
	const { t } = useTranslation("translation", { keyPrefix: "automotiveApp.project" });
	const { t: tc } = useTranslation("translation");
	const { playgroundStore, uiStore } = useAppStore();
	const { projectsStore } = playgroundStore;
	const [selectedImages, setSelectedImages] = useState<PlaygroundImage[]>([]);
	const [openDeletePopup, setOpenDeletePopup] = useState(false);

	useEffect(() => {
		setSelectedImages(projectsStore.selectedImages);
	}, [projectsStore.selectedImages]);

	useEffect(() => {
		projectsStore.handleSelectImages([]);
	}, [window.location.pathname]);

	const hasSelectedImages = selectedImages.length > 0;
	const hasUploadedImages = projectsStore.uploadedImages.items.length > 0;
	const hasFavoriteImages = projectsStore.favoriteImages.items.length > 0;

	const disableActions =
		(!hasSelectedImages && isAutomotiveUploadedPage() && !hasUploadedImages) ||
		(!hasSelectedImages && isAutomotiveFavoritesPage() && !hasFavoriteImages);

	const handleDeleteImages = async () => {
		// const availableImages: PlaygroundImage[] = getAvailableImages();
		// await Promise.all(availableImages.map(image => projectsStore.handleDeleteImage(image.id)));

		if (isAutomotiveUploadedPage()) {
			await projectsStore.handleDeleteAllUploads(projectsStore.project.id);
			await projectsStore.getUploadedImages(projectsStore.project.id, 1);
		} else {
			await projectsStore.handleDeleteAllFavorites(projectsStore.project.id);
			await projectsStore.getFavoriteImages(projectsStore.project.id, 1);
		}
		onCloseDeletePopup();
		projectsStore.handleSelectImages([]);
	};

	const handleDownloadImages = () => {
		const availableImages: PlaygroundImage[] = getAvailableImages();
		uiStore.showExportImagePopup({ images: availableImages });
	};

	const getAvailableImages = (): PlaygroundImage[] => {
		let availableImages: PlaygroundImage[] = [];

		if (hasSelectedImages) {
			availableImages = selectedImages;
		} else if (isAutomotiveUploadedPage()) {
			availableImages = projectsStore.uploadedImages.items;
		} else {
			availableImages = projectsStore.favoriteImages.items;
		}
		return availableImages;
	};

	const onCloseDeletePopup = () => {
		setOpenDeletePopup(false);
	};

	return (
		<Box className={styles.projectActions}>
			<BriaButton
				buttonType="textMedium"
				className={styles.actionBtn}
				disabled={!hasSelectedImages}
				onClick={() => uiStore.showDialog("AddSessionDialog")}
			>
				{t("useInNewSession")}
			</BriaButton>
			<BriaButton
				buttonType="textMedium"
				className={styles.actionBtn}
				onClick={()=> setOpenDeletePopup(true)}
				disabled={disableActions}
			>
				{hasSelectedImages ? `${tc("delete")} ${selectedImages.length}` : t("deleteAll")}
			</BriaButton>
			<BriaButton
				buttonType="textMedium"
				className={styles.actionBtn}
				onClick={handleDownloadImages}
				disabled={disableActions}
			>
				{hasSelectedImages ? `${tc("download")} ${selectedImages.length}` : t("downloadAll")}
			</BriaButton>
			<ConfirmationPopup
				open={openDeletePopup}
				onClick={handleDeleteImages}
				loading={sessionStorage.loadingDeleteSession}
				title={t("deleteImagesPopup.title")}
				description={<Trans>{t("deleteImagesPopup.description")}</Trans>}
				subDescription={<Trans>{t("deleteImagesPopup.subDescription")}</Trans>}
				subTitle={t("deleteImagesPopup.subTitle")}
				confirmButtonText={t("deleteImagesPopup.confirmBtn")}
				onClose={onCloseDeletePopup}
			/>
		</Box>
	);
};

export default observer(ProjectHeaderActions);
