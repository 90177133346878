import { makeAutoObservable, runInAction } from "mobx";
import { IPlaygroundStore } from "../store/playground-store";
import { AutomotiveEffectsStore, IAutomotiveEffectsStore } from "./Config/Effects/automotive-effects-store";
import {
	AutomotiveHarmonizationStore,
	IAutomotiveHarmonizationStore,
} from "./Config/Harmonization/automotive-harmonization-store";
import { AutomotiveReflectionStore, IAutomotiveReflectionStore } from "./Config/Reflection/automotive-reflection-store";

export interface IAutomotiveStore {
	isSaved: boolean;
	openWarningPopup: boolean;
	configToSwitch?: string;

	effectsStore: IAutomotiveEffectsStore;
	harmonizationStore: IAutomotiveHarmonizationStore;
	reflectionStore: IAutomotiveReflectionStore;
	playgroundStore: IPlaygroundStore;
	toggleIsSaved: (status: boolean)=> void;
	toggleOpenWarningPopup: (status: boolean) => void;
}

export class AutomotiveStore implements IAutomotiveStore {
	isSaved: boolean = true;
	openWarningPopup: boolean = false;
	configToSwitch: string = "";

	effectsStore: IAutomotiveEffectsStore;
	harmonizationStore: IAutomotiveHarmonizationStore;
	reflectionStore: IAutomotiveReflectionStore;
	playgroundStore: IPlaygroundStore;

	constructor(playgroundStore: IPlaygroundStore) {
		this.effectsStore = new AutomotiveEffectsStore(this);
		this.harmonizationStore = new AutomotiveHarmonizationStore(this);
		this.reflectionStore = new AutomotiveReflectionStore(this);
		this.playgroundStore = playgroundStore;
		makeAutoObservable(this);
	}

	toggleIsSaved = (status: boolean) => {
		runInAction(()=>{
			this.isSaved = status
		})
	}

	toggleOpenWarningPopup = (status: boolean) => {
		runInAction(()=>{
			this.openWarningPopup = status
		})
	}
}
