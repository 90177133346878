import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import ConfirmationPopup from "../../../../../components/common/ConfirmationPopup/ConfirmationPopup.tsx";
import styles from "./PhotoshopInstallationPopup.module.scss";

type ModelAccessPopupProps = {
	open: boolean;
	onClose: () => void;
	onClick: () => void;
	loading?: boolean;
	disabled?: boolean;
};

const PhotoshopInstallationPopup = ({ open, loading, disabled, onClose, onClick }: ModelAccessPopupProps) => {
	const { t } = useTranslation("translation", { keyPrefix: "newTg.models.popups.photoshopInstallationGuide" });
	const guidelines = t("guidelines", { returnObjects: true });

	return (
		<ConfirmationPopup
			open={open}
			onClick={onClick}
			title={t("header")}
			maxWidth="616px"
			hideCancel={true}
			confirmButtonText={t(`primaryBtn`)}
			onClose={onClose}
			loading={loading}
			disableConfirm={disabled}
			paperClassName={styles.paperWrap}
		>
			<Box>
				<ul className={styles.guidelines}>
					{Object.keys(guidelines).map((guideline) => (
						<li>{t(`guidelines.${guideline}`)}</li>
					))}
				</ul>
			</Box>
		</ConfirmationPopup>
	);
};

export default observer(PhotoshopInstallationPopup);
