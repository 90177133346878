import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import BriaInput from "../../../../../components/common/BriaInput/BriaInput";
import ConfirmationPopup from "../../../../../components/common/ConfirmationPopup/ConfirmationPopup";
import RouterConstants from "../../../../../constants/RouterConstants";
import useSecureNavigate from "../../../../../hooks/useSecureNavigate";
import { useAppStore } from "../../../../../hooks/useStores";
import InputLayout from "../../../../../layout/InputLayout/InputLayout";
import { TgDataset } from "../../../Datasets/store/tg-dataset-store";
import styles from "./CreateTgModelPopup.module.scss";

type CreateTgModelPopupProps = {
	open: boolean;
	onClose: () => void;
};

const CreateTgModelPopup = ({ open, onClose }: CreateTgModelPopupProps) => {
	const { t } = useTranslation("translation", { keyPrefix: "newTg.models.popups.create" });
	const navigate = useSecureNavigate();
	const { projectId } = useParams();
	const { tgStore } = useAppStore();
	const { tgModelStore, tgDatasetStore } = tgStore;
	const [datasetName, setDatasetName] = useState(tgDatasetStore.datasetForm.name);
	const loadingCreate = tgModelStore.loadingCreateModel || tgModelStore.loadingStartTraining;

	const validateForm = !tgModelStore.modelForm.name || !datasetName;

	useEffect(() => {
		setDatasetName(tgDatasetStore.datasetForm.name);
	}, [tgDatasetStore.datasetForm.name]);

	const handleCreateModel = async () => {
		const completedDatasetForTraining: TgDataset = {
			...tgDatasetStore.datasetForm,
			name: datasetName,
			status: "Completed",
		};
		const [_updatedDataset, newModel] = await Promise.all([
			tgDatasetStore.updateDataset(tgDatasetStore.datasetForm.id, completedDatasetForTraining),
			tgModelStore.createModel(tgModelStore.modelForm),
		]);
		await tgModelStore.startTraining(newModel);
		tgDatasetStore.datasetForm = completedDatasetForTraining;
		navigate(`${RouterConstants.TG_PROJECTS.fullPath}/${projectId}/${RouterConstants.NEW_TG_MODELS.path}`);
	};

	return (
		<ConfirmationPopup
			open={open}
			title={t("title")}
			description={
				<Box className={styles.form}>
					<Typography className={styles.description}>{t("description")}</Typography>
					<InputLayout label={t("form.name.label")}>
						<BriaInput
							placeholder={t("form.name.placeholder")}
							value={tgModelStore.modelForm.name}
							onChange={(e) => tgModelStore.handleFormChange("name", e.target.value)}
						/>
					</InputLayout>
					<InputLayout label={t("form.description.label")}>
						<BriaInput
							placeholder={t("form.description.placeholder")}
							value={tgModelStore.modelForm.description}
							onChange={(e) => tgModelStore.handleFormChange("description", e.target.value)}
						/>
					</InputLayout>
					{(!tgDatasetStore.datasetForm.name || tgDatasetStore.isFormDuplicateTriggered) && (
						<InputLayout labelClassName={styles.datasetNameLabel} label={t("form.datasetName.label")}>
							<Typography className={styles.datasetNameDesc}>
								{t("form.datasetName.labelDesc")}
							</Typography>
							<BriaInput
								placeholder={t("form.datasetName.placeholder")}
								value={datasetName}
								onChange={(e) => setDatasetName(e.target.value)}
							/>
						</InputLayout>
					)}
				</Box>
			}
			confirmButtonText={t("confirmButton")}
			disableConfirm={validateForm}
			loading={loadingCreate}
			onClick={handleCreateModel}
			onClose={onClose}
		/>
	);
};

export default observer(CreateTgModelPopup);
