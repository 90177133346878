import { Box, Divider } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import StopIcon from "../../../../assets/images/icons/StopIcon.tsx";
import BriaButton from "../../../../components/common/BriaButton/BriaButton.tsx";
import { IGroupOption } from "../../../../components/common/CustomSingleSelectDropdown/SingleSelectGroupedDropDown";
import GenericInput from "../../../../components/common/GenericInput/GenericInput.tsx";
import { APPS } from "../../../../constants/AppsConstants.ts";
import RouterConstants from "../../../../constants/RouterConstants.ts";
import useSecureNavigate from "../../../../hooks/useSecureNavigate.tsx";
import { useAppStore } from "../../../../hooks/useStores.tsx";
import InputLayout from "../../../../layout/InputLayout/InputLayout.tsx";
import {
	APIParameter,
	ImageGenerationsSubAPIType,
	ImageModificationsSubAPIType,
	SandboxAPIType,
	SandboxSubAPITypes,
	VideoEditingSubAPIType,
	eCommerceSuiteSubAPIType,
	modelTypes,
} from "../../../../models/sandboxAPI.ts";
import { isCanvasRequiredForThisApp } from "../../../../utils";
import { validateField } from "../../../../utils/validators";
import APIFamilyDropdown from "../APIFamilyDropdown/APIFamilyDropdown";
import { SandboxUtils } from "../SandboxUtils/SandboxUtils.tsx";
import styles from "./SandboxAPIConfig.module.scss";

const SandboxAPIConfig = () => {
	const { t } = useTranslation("translation", { keyPrefix: "playground.sandboxAPI" });
	const navigate = useSecureNavigate();
	const { sandboxAPIStore, playgroundStore, tailoredGenerationStore } = useAppStore();
	const [payload, setPayload] = useState<Record<string, any>>({});
	const { subAPI, model } = useParams<{ subAPI: SandboxSubAPITypes; model: string }>();
	const sandboxUtils = new SandboxUtils(sandboxAPIStore, playgroundStore, payload, setPayload);

	useEffect(() => {
		if (!sandboxAPIStore.config.apiConfig) {
			navigate(`/${RouterConstants.PAGE_NOT_FOUND.path}`, { replace: true });
		}

		playgroundStore.clearResults();
		playgroundStore.handleAppChange(APPS.SANDBOX_API);

		return () => {
			playgroundStore.clearResults();
			playgroundStore.handleAppChange(APPS.TEXT_TO_IMAGE);
			sandboxAPIStore.config.selectedSubAPI = undefined;
		};
	}, []);

	useEffect(() => {
		const parameters = sandboxAPIStore.config.apiConfig?.parameters;
		const initialPayload: Record<string, any> = {};

		if (model) {
			handelModelChange(model);
		}

		// Check if there is a prompt value to be used form the promptEnahncement app
		if (
			subAPI === ImageGenerationsSubAPIType.GenerateImage ||
			subAPI === ImageGenerationsSubAPIType.GenerateVector
		) {
			const promptField = sandboxAPIStore.config.apiConfig?.parameters.find((item) => item.name === "prompt");
			if (promptField && sandboxAPIStore.textResultToUse !== "") {
				promptField.defaultValue = sandboxAPIStore.textResultToUse;
			}
		}

		parameters?.forEach((field: APIParameter) => {
			if (field.defaultValue !== undefined) {
				initialPayload[field.name] = field.defaultValue;
			} else if (field.type === "intArray" && field.numberOfInputs) {
				initialPayload[field.name] = Array.from({ length: field.numberOfInputs });
			} else {
				initialPayload[field.name] = null;
			}
		});

		setPayload(() => ({
			...initialPayload,
		}));
	}, [sandboxAPIStore.config.selectedSubAPI]);

	useEffect(() => {
		if (sandboxAPIStore.config.apiConfig?.id === "IMG_MODIFICATIONS_API_3" && playgroundStore.singleSelectedImage) {
			sandboxUtils.handleExpandImageApiDimensionsUpdate();
		}
	}, [playgroundStore.selectedSingleImage]);

	useEffect(() => {
		if (
			sandboxAPIStore.config.apiConfig?.IPAdapterPayload?.image_prompt_urls.length ||
			sandboxAPIStore.getGuidanceMethodsPayload().length
		) {
			sandboxUtils.setNumOfResultsField(1);
		} else {
			sandboxUtils.setNumOfResultsField(4);
		}
	}, [
		sandboxAPIStore.config.apiConfig?.IPAdapterPayload?.image_prompt_urls.length,
		sandboxAPIStore.getGuidanceMethodsPayload().length,
	]);

	const handelModelChange = async (value: string, modelVersion?: string) => {
		const { apiConfig } = sandboxAPIStore.config;

		if (!Object.values(modelTypes).includes(model as modelTypes)) {
			navigate(`/${RouterConstants.PAGE_NOT_FOUND.path}`, { replace: true });
			return;
		}

		if (!apiConfig) return;
		const { pathParameter } = apiConfig;

		const modelField = sandboxUtils.getFieldByName("model");
		const fastField = sandboxUtils.getFieldByName("fast");
		const promptEnhancementField = sandboxUtils.getFieldByName("prompt_enhancement");
		const ipAdapterField = sandboxUtils.getFieldByName("imagePromptAdapter");
		const textGuidanceScaleField = sandboxUtils.getFieldByName("text_guidance_scale");
		const negativePromptField = sandboxUtils.getFieldByName("negative_prompt");
		const modelInfluenceField = sandboxUtils.getFieldByName("model_influence");
		const guidanceMethodsField = sandboxUtils.getFieldByName("guidance_methods");
		const resetPrefixButton = sandboxUtils.getFieldByName("resetToPrefix");
		const generationPrefixCheckbox = sandboxUtils.getFieldByName("include_generation_prefix");
		const promptField = sandboxUtils.getFieldById("prompt");
		const tgPromptField = sandboxUtils.getFieldById("tg_prompt");

		if (!modelField || !pathParameter) return;

		const isFast = value === "fast";
		const isHD = value === "hd";
		const isTailored = value === "tailored";
		fastField && (fastField.hidden = true);

		modelInfluenceField && (modelInfluenceField.hidden = !isTailored);
		textGuidanceScaleField && (textGuidanceScaleField.hidden = isFast || isTailored);
		negativePromptField && (negativePromptField.hidden = isFast || isTailored);
		guidanceMethodsField &&
			(guidanceMethodsField.hidden = isHD || (isTailored && pathParameter.training_version === "max"));
		promptEnhancementField && (promptEnhancementField.hidden = isTailored);
		ipAdapterField && (ipAdapterField.hidden = isTailored && pathParameter.training_version === "max");
		generationPrefixCheckbox && (generationPrefixCheckbox.hidden = !isTailored);
		promptField && (promptField.hidden = isTailored && pathParameter.generation_prefix);
		resetPrefixButton && (resetPrefixButton.hidden = promptField?.hidden);
		tgPromptField && (tgPromptField.hidden = !isTailored || !pathParameter.generation_prefix);

		const modelToVersion: Record<string, string> = {
			hd: "2.2",
			base: "3.1",
			fast: "2.3",
		};
		if (modelVersion) {
			modelField.version = modelVersion;
		} else {
			modelField.version = modelToVersion[value];
		}
		setPayload((prevPayload) => ({
			...prevPayload,
			prompt: "",
			include_generation_prefix: true,
		}));

		if (isFast) {
			sandboxUtils.setStepsNumField(8, 4, 10);
		} else if (isTailored) {
			if (pathParameter.generation_prefix) {
				if (tgPromptField) {
					tgPromptField.prefix = pathParameter.generation_prefix;
					tgPromptField.keyId = uuidv4();
				}
			}
			if (pathParameter.training_version === "max") {
				delete payload["fast"];
				sandboxUtils.setStepsNumField(30, 20, 50);
				modelInfluenceField && (modelInfluenceField.defaultValue = 1);
				setPayload((prevPayload) => ({
					...prevPayload,
					model_influence: 1,
				}));
			} else if (pathParameter.training_version === "light") {
				fastField && (fastField.hidden = false);
				payload["fast"] = true;
				modelInfluenceField && (modelInfluenceField.defaultValue = 1);
				sandboxUtils.setStepsNumField(8, 4, 20);
				setPayload((prevPayload) => ({
					...prevPayload,
					model_influence: 0.5,
				}));
			}
		} else {
			sandboxUtils.setStepsNumField(30, 20, 50);
		}

		let tag = "image-generation";
		if (value === "base") {
			modelField.defaultValue = modelVersion ? `${value}_${modelVersion}` : `${value}_3.1`;
		} else if (value === "hd") {
			modelField.defaultValue = `${value}_2.2`;
		} else if (value === "fast") {
			modelField.defaultValue = `${value}_2.3`;
		}
		if (isTailored) {
			modelField.defaultValue = pathParameter.selected_model || "0";
			tag = "tailored-generation";
		}

		apiConfig.docLink = pathParameter.initialDocLink.replace("${model}", value).replace("${tag}", tag);

		pathParameter.model = value;
	};

	const handleInputChange = (field: APIParameter, e: ChangeEvent<HTMLInputElement>) => {
		const apiConfig = sandboxAPIStore.config.apiConfig;
		if (!apiConfig) {
			return;
		}

		let value: string | number | boolean | null;
		const type = e.target.type;
		switch (type) {
			case "checkbox":
				value = e.target.checked;
				break;
			case "number":
				if (e.target.value === null || e.target.value === "") {
					value = null;
				} else {
					value = isNaN(Number(e.target.value)) ? "" : Number(e.target.value);
				}
				break;
			default:
				value = e.target.value;
		}

		switch (field.name) {
			case "brush_size":
				if (typeof value === "number") {
					sandboxAPIStore.brushConfigs.lineWidth = value;
				}
				break;
			case "placement_type":
				if (apiConfig.id === "ECOMMERCE_API_4" || apiConfig.id === "ECOMMERCE_API_5") {
					sandboxUtils.handlePlacementType(value as string);
				}
				break;
			case "type":
				if (apiConfig.id === "ECOMMERCE_API_3") {
					sandboxUtils.handleShadowType(value as string);
				}
				break;
			case "fast":
				switch (apiConfig.id) {
					case "IMG_MODIFICATIONS_API_4":
					case "ECOMMERCE_API_4":
						sandboxUtils.handleGenerationMode(value as boolean);
						break;
					case "IMG_GENERATION_API_1":
					case "IMG_GENERATION_API_2":
						if (value) {
							sandboxUtils.setStepsNumField(8, 4, 20);
						} else {
							sandboxUtils.setStepsNumField(30, 20, 50);
						}
						break;
					case "IMG_GENERATION_API_3":
						if (value) {
							sandboxUtils.setStepsNumField(12, 4, 20);
						} else {
							sandboxUtils.setStepsNumField(30, 20, 50);
						}
						break;
				}
				break;
			case "bg_prompt":
				//On bg_prombt change clear ref_image_file errors
				const refImageField = sandboxUtils.getFieldByName("ref_image_file");
				if (refImageField?.validationSchema) {
					refImageField.validationMessage = "";
				}
				break;
			case "include_generation_prefix":
				const resetPrefixButton = sandboxUtils.getFieldByName("resetToPrefix");
				const promptField = sandboxUtils.getFieldById("prompt");
				const tgPromptField = sandboxUtils.getFieldById("tg_prompt");
				resetPrefixButton && (resetPrefixButton.hidden = Boolean(value));
				promptField && (promptField.hidden = Boolean(value));
				tgPromptField && (tgPromptField.hidden = !Boolean(value));

				setPayload((prevPayload) => ({
					...prevPayload,
					prompt: tgPromptField?.prefix,
				}));
				break;
		}

		if (field.validationSchema) {
			field.validationMessage = validateField(field.validationSchema, value, {
				name: `${field.name}`,
				...payload,
			});
		}

		setPayload((prevPayload) => ({
			...prevPayload,
			[field.name]: value,
		}));
	};

	const handleTailoredModelChange = async (e: React.ChangeEvent<HTMLSelectElement>, selected: IGroupOption) => {
		const value = e.target.value;
		const apiConfig = sandboxAPIStore.config.apiConfig;
		const fastField = sandboxUtils.getFieldByName("fast");
		if (!apiConfig || !fastField) return;
		const { pathParameter } = apiConfig;

		const isReimagineAPI = !pathParameter;
		if (isReimagineAPI) {
			const modelField = sandboxUtils.getFieldByName("tailored_model_id");

			if (modelField) {
				modelField.defaultValue = value;
			}
			const newPayload: Record<string, any> = {};

			const isTailored = selected?.extraData?.model_id !== undefined;
			if (isTailored) {
				sandboxUtils.setStepsNumField(12, 4, 20);
				fastField.hidden = false;
				payload["fast"] = true;
				delete payload["model"];
				newPayload["tailored_model_id"] = selected?.extraData?.model_id;
				setPayload((prevPayload) => ({
					...prevPayload,
					tailored_model_influence: 1,
				}));
			} else {
				fastField.hidden = true;
				const selectedModel = selected?.value.split("_")[0];
				if (selectedModel === "base") {
					sandboxUtils.setStepsNumField(30, 20, 50);
					newPayload["fast"] = false;
				} else if (selectedModel === "fast") {
					sandboxUtils.setStepsNumField(12, 4, 20);
					newPayload["fast"] = true;
				}
				newPayload["model"] = selectedModel;
			}

			setPayload((prevPayload) => ({
				...prevPayload,
				...newPayload,
			}));
		} else if (pathParameter) {
			pathParameter.model = value.split("_")[0];
			const modelVersion = value.split("_")[1];
			pathParameter.selected_model = value;
			const modelField = sandboxUtils.getFieldByName("model");
			if (modelField) {
				modelField.defaultValue = value;
			}

			const isTailored =
				pathParameter.model !== "base" && pathParameter.model !== "fast" && pathParameter.model !== "hd";
			if (isTailored) {
				pathParameter.model = `tailored`;
				pathParameter.model_id = selected?.extraData?.model_id;
				pathParameter.training_version = selected?.extraData?.training_version;
				pathParameter.generation_prefix = selected?.extraData?.generation_prefix;
			}

			handelModelChange(pathParameter.model, modelVersion);
			const newPayload: Record<string, any> = {};
			const selectedModelVersion = selected?.value.split("_")[1];
			newPayload["model_version"] = selectedModelVersion;
			setPayload((prevPayload) => ({
				...prevPayload,
				...newPayload,
			}));
			const newPath = sandboxAPIStore.isConsole
				? `${RouterConstants.API.fullPath}/${subAPI}/${pathParameter.model}`
				: `/${RouterConstants.APPS.path}/${subAPI}/${pathParameter.model}`;
			navigate(newPath, { replace: true });
		}
	};

	const isGenerateDisabled = (): boolean => {
		if (!playgroundStore.getSelectedImages().length && sandboxAPIStore.hasImageUpload) {
			return true;
		}

		let validationError = false;
		validationError = !!sandboxAPIStore.config.apiConfig?.parameters?.some((field: APIParameter) => {
			if (field.hidden) return false;
			const value = payload[field.name];
			if (field.type === "intArray" && value) {
				return (value as number[]).some(() => {
					return !!field.validationMessage;
				});
			} else {
				return !!field.validationMessage;
			}
		});
		if (validationError) {
			return true;
		}

		const isLoading =
			playgroundStore.playgroundResults[playgroundStore.playgroundResults.length - 1]?.images.some(
				(image) => image.loading,
			) ||
			(sandboxAPIStore.config.selectedAPI === SandboxAPIType.ImageGenerations &&
				sandboxAPIStore.isUploadingIPImage);

		if (isLoading) {
			return true;
		}

		const pathParameter = sandboxAPIStore.config.apiConfig?.pathParameter;
		if (model === "tailored" && pathParameter && tailoredGenerationStore.isLoading) {
			return true;
		}
		if (
			sandboxAPIStore.config.selectedSubAPI === VideoEditingSubAPIType.RemoveVideoBackground &&
			!playgroundStore.playgroundVideoResults.length
		) {
			return true;
		}
		return false;
	};

	const generateResults = async (action?: string): Promise<void> => {
		const parameters = sandboxAPIStore.config.apiConfig?.parameters;
		let finalPayload: Record<string, any> = {};

		parameters?.forEach((field: APIParameter) => {
			const value = payload[field.name];

			const ruleSet = field.validationSchema;
			if (ruleSet && !field.hidden) {
				if (field.type === "intArray") {
					(value as number[]).some((item) => {
						field.validationMessage = validateField(ruleSet, item);
						return !!field.validationMessage;
					});
				} else if (
					sandboxAPIStore.config.apiConfig?.id === "IMG_MODIFICATIONS_API_4" &&
					(field.name === "bg_prompt" || field.name === "ref_image_file")
				) {
					field.validationMessage = validateField(ruleSet, value, {
						name: `${field.name}`,
						...payload,
					});
				} else {
					field.validationMessage = validateField(ruleSet, value);
				}
			}

			if (field.type === "guidanceMethods" && sandboxAPIStore.config.apiConfig?.guidanceMethodsPayload) {
				const guidanceMethodsPayload = sandboxAPIStore.getGuidanceMethodsPayload();
				if (guidanceMethodsPayload.length) finalPayload["guidance_methods"] = guidanceMethodsPayload;
			}

			if (value === null || value === undefined || value === "" || field.hidden || field.type === "imageUpload") {
				return;
			}

			if (field.type === "intArray") {
				finalPayload[field.name] = (value as Array<number | null>).map((item) => (item === null ? 0 : item));
				return;
			}
			if (field.type === "tailored_models") {
				finalPayload["model_version"] = field.version;
				return;
			}

			finalPayload[field.name] = value;
		});

		if (parameters?.some((item) => !item.hidden && item?.validationMessage)) {
			return;
		}

		switch (sandboxAPIStore.config.selectedSubAPI) {
			case eCommerceSuiteSubAPIType.LifestyleProductShotByImage:
			case ImageModificationsSubAPIType.GenerateBackground:
				if (payload["ref_image_file"]) {
					const file = payload["ref_image_file"];
					finalPayload = {
						ref_image_file: file,
						...finalPayload,
					};
				}
				break;
			case ImageGenerationsSubAPIType.promptEnhancement:
				action = ImageGenerationsSubAPIType.promptEnhancement;
				break;
			case ImageGenerationsSubAPIType.reimagine:
				if (payload["tailored_model_id"].includes("fast") || payload["tailored_model_id"].includes("base")) {
					finalPayload["model"] = payload["tailored_model_id"].split("_")[0];
				}
				if (payload["model"] || finalPayload["model"]) {
					delete finalPayload["tailored_model_id"];
					delete finalPayload["tailored_model_influence"];
				}
				finalPayload = {
					...{
						fast: payload["fast"],
						model: payload["model"],
						model_version: payload["model_version"],
					},
					...finalPayload,
				};
				break;
			case ImageGenerationsSubAPIType.GenerateImage:
			case ImageGenerationsSubAPIType.GenerateVector:
				if (sandboxAPIStore.config.apiConfig?.IPAdapterPayload?.image_prompt_urls.length) {
					finalPayload = {
						...sandboxAPIStore.config.apiConfig?.IPAdapterPayload,
						model_version: payload["model_version"],
						...finalPayload,
					};
				} else {
					finalPayload = {
						model_version: payload["model_version"],
						...finalPayload,
					};
				}
				break;
		}

		await sandboxAPIStore.generateResults(JSON.stringify(finalPayload), action);
	};

	return (
		<Box key={sandboxAPIStore.config.selectedSubAPI} className={styles.sandboxConfigWrapper}>
			<Box className={styles.sandboxConfig}>
				<Box className={styles.sandboxConfigFields}>
					<APIFamilyDropdown />
					<Divider />
					{sandboxAPIStore.config.apiConfig?.parameters?.map((field: any) => {
						if (field.type === "imageUpload") return null;
						if (field.type === "button") {
							return (
								!field.hidden && (
									<InputLayout
										label={field.label}
										className={clsx({
											[styles[field.className]]: true,
										})}
										labelClassName={styles.inputLabel}
										info={field.info}
									>
										{sandboxAPIStore.isGeneratingResults ? (
											<BriaButton
												buttonType={field.buttonType}
												onClick={() => sandboxAPIStore.abortResultsGeneration()}
												className={styles.stopBtnWithIcon}
											>
												<StopIcon /> {t("stop")}
											</BriaButton>
										) : (
											<BriaButton
												buttonType={field.buttonType}
												onClick={() => {
													generateResults(field.action);
												}}
												disabled={isGenerateDisabled()}
											>
												{field.name}
											</BriaButton>
										)}
									</InputLayout>
								)
							);
						}
						const inputProps = {
							...field,
							onChange: (e: ChangeEvent<HTMLInputElement>) => {
								handleInputChange(field, e);
							},
							...(field.type === "color" && {
								onColorChange: (color: string) => sandboxUtils.handleColorChange(field, color),
							}),
							...(field.type === "intArray" && {
								onIntArrayChange: (index: number, value: number | null) =>
									sandboxUtils.handleIntArrayChange(field, index, value),
							}),
							...(field.type === "buttonsGroup" && {
								onButtonClick: (fieldName: string) => {
									sandboxUtils.handleButtonClick(fieldName);
								},
							}),
							...(field.type === "tailored_models" && {
								onTailoredModelChange: handleTailoredModelChange,
								onFoundationModelsSuccess: sandboxUtils.handleFoundationModelsSuccess,
								onTailoredModelsSuccess: sandboxUtils.handleTiloredModelsSuccess,
							}),
							...(field.type === "imageUploader" && {
								onUploadImage: sandboxUtils.handleUploadImage,
								onDeleteImage: sandboxUtils.handleDeleteImage,
							}),
							...(field.type === "secondaryButton" && {
								onClick: () => {
									sandboxUtils.handleSecondaryButtonClick(field.action);
								},
							}),
							...(field.type === "InputWithHighlightedPrefix" && {
								onInputWithPrefixChange: (value: string) => {
									sandboxUtils.handleInputWithPrefixChange(field, value);
								},
							}),
						};
						return (
							<GenericInput
								key={sandboxAPIStore.config.selectedSubAPI + field.name}
								value={payload[field.name]}
								{...inputProps}
							/>
						);
					})}
				</Box>
			</Box>
			{sandboxAPIStore.config.apiConfig &&
				!sandboxAPIStore.config.apiConfig.parameters?.some((field: any) => field.type === "button") &&
				!isCanvasRequiredForThisApp() && (
					<>
						{sandboxAPIStore.isGeneratingResults ? (
							<BriaButton
								className={clsx(styles.stopButton, styles.stopBtnWithIcon)}
								buttonType="primaryMedium"
								fullWidth
								onClick={() => sandboxAPIStore.abortResultsGeneration()}
							>
								<StopIcon /> {t("stop")}
							</BriaButton>
						) : (
							sandboxAPIStore.config.selectedSubAPI && (
								<BriaButton
									onClick={() => {
										generateResults();
									}}
									className={clsx({
										[styles.button]: true,
										[styles.bottomGenerate]: true,
									})}
									disabled={isGenerateDisabled()}
									buttonType="primaryMedium"
								>
									{!playgroundStore.singleSelectedImage && sandboxAPIStore.hasImageUpload
										? t("noImagesSelected")
										: t("generate")}
								</BriaButton>
							)
						)}
					</>
				)}
		</Box>
	);
};

export default observer(SandboxAPIConfig);
