import { Box } from "@mui/material";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import BriaButton from "../../../../../components/common/BriaButton/BriaButton";
import { FilterByOptions, PaginationOptions } from "../../../../../components/common/BriaTable/BriaTable";
import BriaTooltip from "../../../../../components/common/BriaTooltip/BriaTooltip.tsx";
import RouterConstants from "../../../../../constants/RouterConstants";
import useSecureNavigate from "../../../../../hooks/useSecureNavigate";
import { useAppStore } from "../../../../../hooks/useStores";
import TableLayout, { TableRow } from "../../../../../layout/TableLayout/TableLayout";
import { formatDate } from "../../../../../utils";
import TgRequestAccessButton from "../../../Integrations/RequestAccess/TgRequestAccessButton.tsx";
import ModelAccessPopup from "../../../Models/Popups/ModelAccessPopup/ModelAccessPopup";
import { ViewTgModelLocationState } from "../../../Models/Viewer/TgModelViewer";
import { TgModel } from "../../../Models/store/tg-model-store";
import TgModelActions from "./Dashboard/Actions/TgModelActions.tsx";
import TgModelNameColumn from "./Dashboard/Columns/Name/TgModelNameColumn.tsx";
import TgProjectModelsEmpty from "./Empty/TgProjectModelsEmpty";
import styles from "./TgProjectModels.module.scss";

type TgModelTableRow = {
	name: string | ReactElement;
	id: number;
	training_version: string | ReactElement;
	trained_by: string | ReactElement;
	created: string | ReactElement;
	status: string | ReactElement;
	actions: ReactElement;
	get_access: ReactElement;
	possible_action: ReactElement;
};

const TgProjectModels = () => {
	const { t } = useTranslation("translation", { keyPrefix: "newTg.models.dashboard" });
	const { projectId } = useParams();
	const { tgStore, authStore } = useAppStore();
	const { tgProjectStore, tgModelStore } = tgStore;
	const [loadingRow, setLoadingRow] = useState<{ data: TgModel; column: string }>();
	const tableHeaderArray = [
		t("table.headers.name"),
		t("table.headers.id"),
		t("table.headers.training_version"),
		t("table.headers.trained_by"),
		t("table.headers.created"),
		t("table.headers.status"),
		t("table.headers.actions"),
		t("table.headers.get_access"),
		t("table.headers.possible_action"),
	];
	const navigate = useSecureNavigate();
	// const [filterBy, setFilterBy] = useState<FilterByOptions>();

	const loadNextPage = (page?: number | undefined, filterBy?: FilterByOptions<TgModel> | undefined) =>
		tgProjectStore.getModels(Number(projectId), page, filterBy);

	const paginationOptions: PaginationOptions<TgModel> = {
		rowsPerPage: tgProjectStore.rowsPerPage,
		totalRows: tgProjectStore.paginatedModels.total,
		loadNextPage: loadNextPage,
	};

	useEffect(() => {
		tgProjectStore.getModels(Number(projectId), 1);
	}, []);

	const openPlayground = (model: TgModel) => {
		const modelToViewState: ViewTgModelLocationState = {
			modelToView: model,
		};
		navigate(
			`${RouterConstants.TG_PROJECTS.fullPath}/${model.project_id}/${RouterConstants.NEW_TG_MODELS.path}/${model.id}/${RouterConstants.TG_MODEL_PLAYGROUND.path}`,
			{ state: modelToViewState },
		);
	};
	const getPossibleAction = (model: TgModel) => {
		const handleAction = async (actionFunc: () => any) => {
			setLoadingRow({ data: model, column: "possible_action" });
			try {
				await actionFunc();
			} finally {
				setLoadingRow(undefined);
			}
		};

		switch (model.status) {
			case "Created":
				return (
					<BriaButton
						buttonType="secondaryMedium"
						onClick={() => handleAction(() => tgModelStore.startTraining(model))}
					>
						{t(`table.possible_action.${model.status}`)}
					</BriaButton>
				);
			case "InProgress":
				return (
					<BriaButton
						buttonType="secondaryMedium"
						onClick={() => handleAction(() => tgModelStore.stopTraining(model.id))}
					>
						{t(`table.possible_action.${model.status}`)}
					</BriaButton>
				);
			case "Completed":
				return (
					<BriaButton buttonType="secondaryMedium" onClick={() => handleAction(() => openPlayground(model))}>
						{t(`table.possible_action.${model.status}`)}
					</BriaButton>
				);
			case "Stopping":
				return (
					<BriaButton disabled buttonType="secondaryMedium">
						{t(`table.possible_action.${model.status}`)}
					</BriaButton>
				);
			case "Stopped":
				return (
					<BriaButton
						buttonType="secondaryMedium"
						onClick={() => handleAction(() => tgModelStore.startTraining(model))}
					>
						{t(`table.possible_action.${model.status}`)}
					</BriaButton>
				);
			case "Failed":
				return (
					<BriaButton
						buttonType="secondaryMedium"
						onClick={() => handleAction(() => tgModelStore.startTraining(model))}
					>
						{t(`table.possible_action.${model.status}`)}
					</BriaButton>
				);
			case "Deleted":
				return (
					<BriaButton disabled buttonType="secondaryMedium">
						{t(`table.possible_action.${model.status}`)}
					</BriaButton>
				);
		}
	};

	const createRow = (model: TgModel): TableRow<TgModelTableRow> => {
		const name = <TgModelNameColumn {...{ model }} />;
		const id = model.id;

		const training_version = <Box>{t(`table.training_version.${model.training_version}`)}</Box>;
		const trained_by = (
			<Box>
				{(() => {
					try {
						const trained_by = JSON.parse(model.custom_metadata)?.user_created_the_model?.name;
						return (
							trained_by || (
								<BriaTooltip title={t("trained_by_not_found")}>
									<Box component="span">-</Box>
								</BriaTooltip>
							)
						);
					} catch {
						return (
							<BriaTooltip title={t("trained_by_not_found")}>
								<Box component="span">-</Box>
							</BriaTooltip>
						);
					}
				})()}
			</Box>
		);

		const created = <Box>{formatDate(model.created_at)}</Box>;

		const status = <Box className={styles.statusContainer}>{t(`table.status.${model.status}`)}</Box>;

		const actions = <TgModelActions {...{ model }} />;

		const get_access = (
			<TgRequestAccessButton className={styles.requestAccessButton}>
				<ModelAccessPopup
					buttonType={"primaryMedium"}
					disabled={!(model.status === "Completed")}
					model={model}
					project={tgProjectStore.projectToView}
				/>
			</TgRequestAccessButton>
		);
		const possible_action = getPossibleAction(model);

		const newRow: TableRow<TgModelTableRow> = {
			key: model.id,
			data: {
				name,
				id,
				training_version,
				trained_by,
				created,
				status,
				actions,
				get_access,
				possible_action,
			},
		};

		return newRow;
	};

	// const handleSearchChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
	// 	const updatedFilterBy: FilterByOptions = {
	// 		...filterBy,
	// 		search: {
	// 			text: e.target.value,
	// 			fields: ["name", "description", "training_method", "training_version", "created_at", "status"],
	// 		},
	// 	};
	// 	setFilterBy(updatedFilterBy);
	// 	await tgProjectStore.getModels(Number(projectId), 1, updatedFilterBy);
	// };

	const tableData = tgProjectStore.paginatedModels.items.map((model) => createRow(toJS(model)));

	return (
		<TableLayout
			headerArray={tableHeaderArray}
			tableData={tableData}
			emptyStateComponent={<TgProjectModelsEmpty />}
			loading={tgProjectStore.loadingProjectModels || authStore.isLoadingOrgSubscriptions}
			// enableSearch
			// searchBoxPlaceholder={t("table.searchPlaceholder")}
			// handleSearchChange={handleSearchChange}
			// filterBy={filterBy}
			paginationOptions={paginationOptions}
			loadingRow={loadingRow}
			hideHeader
		/>
	);
};

export default observer(TgProjectModels);
