import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { ScrollPosition, trackWindowScroll } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useAppStore } from "../../../hooks/useStores.tsx";
import { PlaygroundImage } from "../../../models/image-to-image.ts";
import FullScreenViewer, { FullScreenViewerProps } from "../FullScreenViewer/FullScreenViewer";
import { FullScreenImageType } from "../FullScreenViewer/Image/FullScreenImage.tsx";
import BriaImage, { BriaImageProps } from "./BriaImage";
import styles from "./ScrollableGallery.module.scss";

type GalleryProps = {
	images: BriaImageProps[];
	maxInLine?: number;
	className?: string;
	fullScreenProps?: Partial<FullScreenViewerProps>;
	scrollPosition: ScrollPosition;
	onDownload?: (images: FullScreenImageType[]) => void;
};

const ScrollableGallery = ({
	images,
	maxInLine = 4,
	className,
	fullScreenProps,
	scrollPosition,
	onDownload,
}: GalleryProps) => {
	const { playgroundStore } = useAppStore();
	const [imageToView, setImageToView] = useState(0);
	const [isViewerOpen, setIsViewerOpen] = useState<boolean | undefined>(fullScreenProps?.open);
	const [displayMode, setDisplayMode] = useState(fullScreenProps?.displayMode);

	useEffect(() => {
		setIsViewerOpen(fullScreenProps?.open);
	}, [fullScreenProps?.open]);

	const handleOpenFullScreen = (index: number) => {
		setDisplayMode("arrows");
		setImageToView(index);
		setIsViewerOpen(true);
	};

	const handleCloseFullScreen = () => {
		setIsViewerOpen(false);
		fullScreenProps?.onClose?.();
		setDisplayMode(fullScreenProps?.displayMode);
	};

	return (
		<>
			<Box
				className={`${className} ${styles.gallery}`}
				sx={{ "& > span": { width: `calc( ${100 / maxInLine}% - 0.8vw)` } }}
			>
				{images.map((image, index) => (
					<BriaImage
						key={index}
						{...image}
						handleFullScreen={() => handleOpenFullScreen(index)}
						lazyImageProps={{ ...image.lazyImageProps, scrollPosition: scrollPosition }}
						fullScreenProps={image.fullScreenProps}
						hideDownloadIcon={false}
						onDownload={() => {
							onDownload && onDownload([{ src: image.url, ...image.fullScreenProps }]);
						}}
						image={image as BriaImageProps & PlaygroundImage}
					/>
				))}
			</Box>
			{isViewerOpen && (
				<FullScreenViewer
					{...fullScreenProps}
					open={isViewerOpen}
					images={images
						.filter((image) => !image.hideFullScreenButton && !image.errorType)
						.map((image) => ({ src: image.url, is_favorite: image.is_favorite, ...image.fullScreenProps }))}
					currentIndex={imageToView}
					onClose={handleCloseFullScreen}
					displayMode={displayMode}
					onDownload={(fullscreenImages) => {
						playgroundStore.onFullScreenViewerDownload(
							images as (BriaImageProps & PlaygroundImage)[],
							fullscreenImages,
						);
						onDownload && onDownload(fullscreenImages);
					}}
					hideDeleteIcon
					hideFavoriteIcon
					currentSelectedImage={images[imageToView ?? 0] as PlaygroundImage}
				/>
			)}
		</>
	);
};

export default trackWindowScroll(observer(ScrollableGallery));
