import { FolderOutlined, StarOutlineRounded } from "@mui/icons-material";
import { Box, List, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Divider } from "rsuite";
import PlusIcon from "../../../../../assets/images/icons/PlusIcon";
import BriaIconButton from "../../../../../components/common/BriaIconButton/BriaIconButton";
import ConfirmationPopup from "../../../../../components/common/ConfirmationPopup/ConfirmationPopup";
import RouterConstants from "../../../../../constants/RouterConstants";
import useSecureNavigate from "../../../../../hooks/useSecureNavigate";
import { useAppStore } from "../../../../../hooks/useStores";
import { isAutomotiveFavoritesPage, isAutomotiveUploadedPage } from "../../../../../utils";
import { PlaygroundSession } from "../../../Sessions/store/playground-sessios-store";
import styles from "./ProjectSidebar.module.scss";
import SidebarMenuItem from "./SidebarMenuItem/SidebarMenuItem";

const ProjectSidebar = () => {
	const { t } = useTranslation("translation", { keyPrefix: "automotiveApp.project" });
	const navigate = useSecureNavigate();
	const { playgroundStore, uiStore } = useAppStore();
	const { projectsStore, sessionsStore } = playgroundStore;
	const [openDeletePopup, setOpenDeletePopup] = useState(false);
	const project = projectsStore.project;

	const openSession = (session: PlaygroundSession) => {
		projectsStore.handleSelectSession(session);
		navigate(`${RouterConstants.AUTOMOTIVE_PROJECT.path}/${project?.id}/session/${session.id}`);
	};

	const renameSession = (session: PlaygroundSession) => {
		projectsStore.handleSelectSession(session);
		uiStore.showDialog("EditSessionDialog");
	};

	const deleteSession = async () => {
		const selectedSession = projectsStore.selectedSession;
		if (selectedSession) {
			await sessionsStore.deleteSession(selectedSession.id);
			await projectsStore.getProject(project.id);
		}
	};

	const onCloseDeletePopup = () => {
		setOpenDeletePopup(false);
	};

	const openProject = (type: "uploaded" | "favorites") => {
		projectsStore.handleSelectProject(project);
		clearSelectedImages();
		navigate(`${RouterConstants.AUTOMOTIVE_PROJECT.path}/${project.id}/${type}`);
	};

	const clearSelectedImages = () => {
		projectsStore.handleSelectImagesElements([], "uploadedImages");
		projectsStore.handleSelectImagesElements([], "favoriteImages");
	};

	return (
		<Box className={styles.projectSidebar}>
			<List className={styles.uploadAndFavMenu}>
				<SidebarMenuItem
					icon={<FolderOutlined />}
					title={t("uploadedImages")}
					selected={isAutomotiveUploadedPage()}
					onClick={() => openProject("uploaded")}
				/>
				<SidebarMenuItem
					icon={<StarOutlineRounded />}
					title={t("favoriteImages")}
					// actions={[{ label: "Open", onClick: () => {} }]}
					selected={isAutomotiveFavoritesPage()}
					onClick={() => openProject("favorites")}
				/>
			</List>

			<Divider className={styles.divider} />
			<Box className={styles.sessionsListHeader}>
				<Typography className={styles.headerTitle}>{t("sessions")}</Typography>
				<BriaIconButton className={styles.addSessionBtn} onClick={() => uiStore.showDialog("AddSessionDialog")}>
					<PlusIcon />
				</BriaIconButton>
			</Box>
			<List className={styles.sessionsMenu}>
				{project.sessions?.map((session) => (
					<SidebarMenuItem
						title={session.name}
						key={session.id}
						actions={[
							{ label: t("open"), onClick: () => openSession(session) },
							{ label: t("rename"), onClick: () => renameSession(session) },
							{
								label: t("delete"),
								onClick: () => {
									projectsStore.handleSelectSession(session);
									setOpenDeletePopup(true);
								},
							},
						]}
						small
						onClick={() => openSession(session)}
					/>
				))}
			</List>
			<ConfirmationPopup
				open={openDeletePopup}
				onClick={deleteSession}
				loading={sessionsStore.loadingDeleteSession}
				title={t("deletePopup.title")}
				description={<Trans>{t("deletePopup.description")}</Trans>}
				subDescription={<Trans>{t("deletePopup.subDescription")}</Trans>}
				subTitle={t("deletePopup.subTitle")}
				confirmButtonText={t("deletePopup.confirmBtn")}
				onClose={onCloseDeletePopup}
			/>
		</Box>
	);
};

export default observer(ProjectSidebar);
