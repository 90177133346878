import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import DuplicateIcon from "../../../../../../../DesignEditor/components/Icons/DuplicateIcon";
import DeleteIcon from "../../../../../../../assets/images/icons/DeleteIcon";
import { EditIcon2 } from "../../../../../../../assets/images/icons/EditIcon2";
import EyeIcon from "../../../../../../../assets/images/icons/EyeIcon";
import BriaIconButton from "../../../../../../../components/common/BriaIconButton/BriaIconButton";
import ConfirmationPopup from "../../../../../../../components/common/ConfirmationPopup/ConfirmationPopup";
import RouterConstants from "../../../../../../../constants/RouterConstants";
import useSecureNavigate from "../../../../../../../hooks/useSecureNavigate";
import { useAppStore } from "../../../../../../../hooks/useStores";
import { ViewTgDatasetLocationState } from "../../../../../Datasets/Viewer/TgDatasetViewer";
import { TgDataset } from "../../../../../Datasets/store/tg-dataset-store";
import { EditTgProjectDatasetLocationState } from "../../Edit/EditTgProjectDataset";
import styles from "./TgDatasetActions.module.scss";

type TgDatasetActionsProps = {
	dataset: TgDataset;
	setLoadingRow: (row: { data: TgDataset; column: string } | undefined) => void;
};

const TgDatasetActions = ({ dataset, setLoadingRow }: TgDatasetActionsProps) => {
	const { t } = useTranslation("translation", { keyPrefix: "newTg.datasets.dashboard.table.actions" });
	const navigate = useSecureNavigate();
	const { tgStore } = useAppStore();
	const { tgDatasetStore } = tgStore;
	const [openDeletePopup, setOpenDeletePopup] = useState(false);

	const duplicateDataset = async () => {
		setLoadingRow({ data: dataset, column: "actions" });
		try {
			const newDataset = await tgDatasetStore.duplicateDataset(dataset.id);
			const editDatasetState: EditTgProjectDatasetLocationState = {
				datasetToEdit: newDataset,
			};
			navigate(
				`${RouterConstants.TG_PROJECTS.fullPath}/${dataset.project_id}/${RouterConstants.NEW_TG_DATASETS.path}/${newDataset.id}/edit`,
				{ state: editDatasetState },
			);
		} finally {
			setLoadingRow(undefined);
		}
	};

	const openDataset = () => {
		const datasetToViewState: ViewTgDatasetLocationState = {
			datasetToView: dataset,
		};
		navigate(
			`${RouterConstants.TG_PROJECTS.fullPath}/${dataset.project_id}/${RouterConstants.NEW_TG_DATASETS.path}/${dataset.id}`,
			{ state: datasetToViewState },
		);
	};

	const editDataset = () => {
		const editDatasetState: EditTgProjectDatasetLocationState = {
			datasetToEdit: dataset,
		};
		navigate(
			`${RouterConstants.TG_PROJECTS.fullPath}/${dataset.project_id}/${RouterConstants.NEW_TG_DATASETS.path}/${dataset.id}/edit`,
			{ state: editDatasetState },
		);
	};

	const deleteDataset = async () => {
		try {
			await tgDatasetStore.deleteDataset(dataset.id);
		} finally {
			setOpenDeletePopup(false);
		}
	};

	return (
		<>
			<Box className={styles.actions}>
				<BriaIconButton className={styles.iconButton} title={t("duplicate")} onClick={duplicateDataset}>
					<DuplicateIcon />
				</BriaIconButton>
				{dataset.status === "Draft" || !dataset.models?.length ? (
					<>
						<BriaIconButton className={styles.iconButton} title={t("edit")} onClick={editDataset}>
							<EditIcon2 />
						</BriaIconButton>
						<BriaIconButton
							className={styles.iconButton}
							title={t("delete")}
							onClick={() => setOpenDeletePopup(true)}
						>
							<DeleteIcon />
						</BriaIconButton>
					</>
				) : (
					<BriaIconButton className={styles.iconButton} title={t("view")} onClick={openDataset}>
						<EyeIcon sx={{ width: "21px !important" }} />
					</BriaIconButton>
				)}
			</Box>
			<ConfirmationPopup
				open={openDeletePopup}
				onClick={deleteDataset}
				loading={tgDatasetStore.loadingDeleteDataset}
				title={t("deletePopup.title")}
				description={<Trans>{t("deletePopup.description")}</Trans>}
				confirmButtonText={t("deletePopup.confirmBtn")}
				onClose={() => setOpenDeletePopup(false)}
			/>
		</>
	);
};

export default observer(TgDatasetActions);
