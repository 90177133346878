import { Box, FormControlLabel } from "@mui/material";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CloudUploadSVG from "../../../../../../assets/images/icons/CloudUpload.tsx";
import BriaCheckbox from "../../../../../../components/common/BriaCheckbox/BriaCheckbox";
import BriaColorPicker from "../../../../../../components/common/BriaColorPicker/BriaColorPicker";
import BriaInput from "../../../../../../components/common/BriaInput/BriaInput";
import BriaSlider from "../../../../../../components/common/BriaSlider/BriaSlider";
import ImageUploader from "../../../../../../components/common/ImageUploader/ImageUploader.tsx";
import StoppableButton from "../../../../../../components/common/StoppableButton/StoppableButton.tsx";
import { APPS } from "../../../../../../constants/AppsConstants.ts";
import { DEFAULT_BACKGROUND_CONFIGS } from "../../../../../../constants/ImageToImageConstants.ts";
import { useImageToImageConfig } from "../../../../../../hooks/useImageToImageConfig";
import { useAppStore } from "../../../../../../hooks/useStores";
import InputLayout from "../../../../../../layout/InputLayout/InputLayout";
import { ConfigValue, ImageEditingViewMode } from "../../../../../../models/image-to-image";
import { SyntheticEventProps } from "../../../../../../models/text-to-image.ts";
import useImageUtils from "../../../../../../utils/useImageUtils.tsx";
import Config from "../../Config/Config";
import styles from "./BackgroundConfig.module.scss";
import { isAutomotiveApp } from "../../../../../../utils/index.ts";

export type SubMenuConfigProps = {
	onSubMenuOpen: (open: boolean) => void;
	handleGenerateImageToImage: () => void;
	hiddenConfigs?: string[];
};
const BackgroundConfig = ({ onSubMenuOpen, handleGenerateImageToImage, hiddenConfigs }: SubMenuConfigProps) => {
	const { t } = useTranslation("translation", { keyPrefix: "playground.imageToImage.config.features.background" });

	const { imageToImageStore, playgroundStore } = useAppStore();
	const { getBase64 } = useImageUtils();
	const {
		updateSelectedBackgroundConfigs,
		canApplySolidBackground,
		canGenerateBackgroundByText,
		canGenerateBackgroundByImage,
		countSelected,
	} = useImageToImageConfig();
	const solidColor = imageToImageStore.config.background?.solid?.color_code || "#FFFFFF";
	const [activeConfig, setActiveConfig] = useState<string>(imageToImageStore.activeConfig || "");
	const [selectedConfig, setSelectedConfig] = useState<string>("");
	const isSingleMode = playgroundStore.imageEditingViewMode === ImageEditingViewMode.SINGLE;

	useEffect(() => {
		isSingleMode && imageToImageStore.toggleBackgroundConfig(true);
		return () => {
			imageToImageStore.toggleBackgroundConfig(false);
			isSingleMode && imageToImageStore.handleConfigChange("background", DEFAULT_BACKGROUND_CONFIGS);
		};
	}, []);

	useEffect(() => {
		setActiveConfig(t(`${imageToImageStore.activeConfig}`));
	}, [imageToImageStore.activeConfig]);

	const handleBackgroundConfigChange = (field: string, value: ConfigValue) => {
		imageToImageStore.handleConfigChange("background", {
			...imageToImageStore.config.background,
			[field]: value,
		});
		updateSelectedBackgroundConfigs();
	};

	const handleUploadRefImage = async (e: ChangeEvent<HTMLInputElement> | SyntheticEventProps) => {
		const file = e.target.files?.[0];
		e.target.value = "";

		if (file) {
			// const compressedBlob = await imageCompression(file, IMAGE_COMPRESSION_CONFIG);
			// const compressedFile = new File([compressedBlob], file.name, { type: compressedBlob.type });

			const ref_image_url = (await getBase64(file)) as string;
			handleBackgroundConfigChange("generateByImage", {
				...imageToImageStore.config.background?.generateByImage,
				selected: ref_image_url && ref_image_url.length > 0,
				ref_image_url,
			});
		}
	};

	const handleDeleteRefImage = async () => {
		handleBackgroundConfigChange("generateByImage", {
			...imageToImageStore.config.background?.generateByImage,
			ref_image_url: "",
		});
	};

	const handleSolidColorChange = (color: string) => {
		imageToImageStore.handleConfigChange("background", {
			...imageToImageStore.config.background,
			solid: {
				selected: true,
				color_code: color,
			},
		});

		updateSelectedBackgroundConfigs();
	};

	return (
		<Box className={styles.backgroundConfigContainer}>
			{/* Generate by text */}
			<Config
				title={t("generateByText")}
				checked={imageToImageStore.config.background?.generateByText?.selected}
				onClick={(e) => {
					const target = e.target as HTMLInputElement;
					handleBackgroundConfigChange("generateByText", {
						...imageToImageStore.config.background?.generateByText,
						selected: target.checked,
						fast_version: target.checked,
						prompt: !target.checked ? "" : imageToImageStore.config.background?.generateByText?.prompt,
					});
					target.checked && setActiveConfig(t("generateByText"));
				}}
				isActive={activeConfig === t("generateByText") || canGenerateBackgroundByText()}
				setIsActive={setActiveConfig}
				titleOnly={isSingleMode}
			>
				<Box className={styles.accordionContainer}>
					<BriaInput
						value={imageToImageStore.config.background?.generateByText?.prompt}
						placeholder={t("describeTheBackground")}
						onChange={(e) => {
							const target = e.target as HTMLInputElement;
							handleBackgroundConfigChange("generateByText", {
								...imageToImageStore.config.background?.generateByText,
								prompt: target.value,
								selected: target.value.trim().length > 0,
								...(isAutomotiveApp() && { binarize_alpha: true, refine_prompt: true }),
							});
						}}
						multiline
						minRows={3}
						className={styles.modelDescription}
					/>
					{playgroundStore.selectedConfig !== APPS.AUTOMOTIVE && (
						<FormControlLabel
							onClick={(e) => {
								const target = e.target as HTMLInputElement;
								handleBackgroundConfigChange("generateByText", {
									...imageToImageStore.config.background?.generateByText,
									fast_version: target.checked,
								});
							}}
							className={styles.formControlContainer}
							control={<BriaCheckbox title={t("fastVersion")} />}
							label={t("fastVersion")}
							value={imageToImageStore.config.background?.generateByText?.fast_version}
							checked={imageToImageStore.config.background?.generateByText?.fast_version}
						/>
					)}

					<InputLayout label={t("numberOfImages.label")} labelClassName={styles.inputLabel}>
						<BriaSlider
							value={imageToImageStore.config.background?.generateByText?.num_results}
							className={styles.numberSlider}
							onChange={(_e, value) => {
								handleBackgroundConfigChange("generateByText", {
									...imageToImageStore.config.background?.generateByText,
									num_results: value as number,
								});
							}}
							min={1}
							step={1}
							max={4}
							marks={Array.from({ length: 4 }, (_, index) => ({
								value: index + 1,
								label: `${index + 1}`,
							}))}
						/>
					</InputLayout>

					{isSingleMode && (
						<Box className={styles.configActions}>
							<StoppableButton
								condition={
									imageToImageStore.isGeneratingImages && selectedConfig == t("generateByText")
								}
								onStopButtonClick={() => imageToImageStore.abortImageGeneration()}
								onButtonClick={async () => {
									try {
										setSelectedConfig(t("generateByText"));
										handleBackgroundConfigChange("generateByText", {
											...imageToImageStore.config.background?.generateByText,
											fast_version:
												playgroundStore.selectedConfig === APPS.AUTOMOTIVE
													? false
													: imageToImageStore.config.background?.generateByText?.fast_version,
											selected: true,
										});
										handleGenerateImageToImage();
									} catch (e) {
										console.log(e);
										Sentry.captureMessage(`Error while generating background by text: ${e}`);
									} finally {
										handleBackgroundConfigChange("generateByText", {
											...imageToImageStore.config.background?.generateByText,
											selected: false,
										});
									}
								}}
								disabled={
									countSelected == 0 || !imageToImageStore.config.background?.generateByText?.prompt
								}
								buttonLabel={t("generate")}
								stopButtonLabel={t("stop")}
								size="small"
								fullWidth
								className={styles.generateButton}
							/>
						</Box>
					)}
				</Box>
			</Config>

			{/* Generate by image */}
			<Config
				title={t("generateByImage")}
				checked={imageToImageStore.config.background?.generateByImage?.selected}
				onClick={(e) => {
					const target = e.target as HTMLInputElement;
					handleBackgroundConfigChange("generateByImage", {
						...imageToImageStore.config.background?.generateByImage,
						selected: target.checked,
						ref_image_url: !target.checked
							? ""
							: imageToImageStore.config.background?.generateByImage?.ref_image_url,
					});
					target.checked && setActiveConfig(t("generateByImage"));
				}}
				isActive={activeConfig === t("generateByImage") || canGenerateBackgroundByImage()}
				setIsActive={setActiveConfig}
				titleOnly={isSingleMode}
			>
				<Box className={styles.accordionContainer}>
					<ImageUploader
						description={t("uploadImage")}
						supportedFilesText={
							"Match the background from your image (PNG, JPEG, JPG, WebP), Limited up to 12MB."
						}
						icon={<CloudUploadSVG />}
						horizontalLayout
						largeIcon
						maxFilesLimit={1}
						maxFileSize={12 * 1024 * 1024}
						src={imageToImageStore.config.background?.generateByImage?.ref_image_url}
						onUpload={(e) => handleUploadRefImage(e)}
						onDelete={() => handleDeleteRefImage()}
					></ImageUploader>
					<InputLayout label={t("numberOfImages.label")} labelClassName={styles.inputLabel}>
						<BriaSlider
							value={imageToImageStore.config.background?.generateByImage?.num_results}
							className={styles.numberSlider}
							onChange={(_e, value) => {
								handleBackgroundConfigChange("generateByImage", {
									...imageToImageStore.config.background?.generateByImage,
									num_results: value as number,
								});
							}}
							min={1}
							step={1}
							max={4}
							marks={Array.from({ length: 4 }, (_, index) => ({
								value: index + 1,
								label: `${index + 1}`,
							}))}
						/>
					</InputLayout>
					{isSingleMode && (
						<Box className={styles.configActions}>
							<StoppableButton
								condition={
									imageToImageStore.isGeneratingImages && selectedConfig == t("generateByImage")
								}
								onStopButtonClick={() => imageToImageStore.abortImageGeneration()}
								onButtonClick={async () => {
									try {
										setSelectedConfig(t("generateByImage"));
										handleBackgroundConfigChange("generateByImage", {
											...imageToImageStore.config.background?.generateByImage,
											selected: true,
										});
										handleGenerateImageToImage();
									} catch (e) {
										console.log(e);
										Sentry.captureMessage(`Error while generating background by image: ${e}`);
									} finally {
										handleBackgroundConfigChange("generateByImage", {
											...imageToImageStore.config.background?.generateByImage,
											selected: false,
										});
									}
								}}
								disabled={
									countSelected == 0 ||
									!imageToImageStore.config.background?.generateByImage?.ref_image_url
								}
								buttonLabel={t("generate")}
								stopButtonLabel={t("stop")}
								size="small"
								fullWidth
								className={styles.generateButton}
							/>
						</Box>
					)}
				</Box>
			</Config>

			{/* Solid */}
			<Config
				title={t("solid")}
				checked={imageToImageStore.config.background?.solid?.selected}
				onClick={(e) => {
					const target = e.target as HTMLInputElement;
					handleBackgroundConfigChange("solid", {
						selected: target.checked,
						color_code: solidColor,
					});
					target.checked && setActiveConfig(t("solid"));
				}}
				isActive={activeConfig === t("solid") || canApplySolidBackground()}
				setIsActive={setActiveConfig}
				titleOnly={isSingleMode}
			>
				<Box className={styles.solidColorContainer}>
					<BriaColorPicker
						color={solidColor}
						onChange={handleSolidColorChange}
						showInputField
						onColorPickerOpen={onSubMenuOpen}
					/>
					{isSingleMode && (
						<Box className={styles.configActions}>
							<StoppableButton
								condition={imageToImageStore.isGeneratingImages && selectedConfig == t("solid")}
								onStopButtonClick={() => imageToImageStore.abortImageGeneration()}
								onButtonClick={async () => {
									try {
										setSelectedConfig(t("solid"));
										handleBackgroundConfigChange("solid", {
											selected: true,
											color_code: solidColor,
										});
										handleGenerateImageToImage();
									} catch (e) {
										console.log(e);
										Sentry.captureMessage(`Error while replace solid background: ${e}`);
									} finally {
										handleBackgroundConfigChange("solid", {
											selected: false,
											color_code: solidColor,
										});
									}
								}}
								disabled={countSelected == 0}
								buttonLabel={t("generate")}
								stopButtonLabel={t("stop")}
								size="small"
								fullWidth
								className={styles.generateButton}
							/>
						</Box>
					)}
				</Box>
			</Config>

			{/* Remove */}
			<Config
				title={t("remove")}
				checked={imageToImageStore.config.background?.remove}
				onClick={(e) => {
					const target = e.target as HTMLInputElement;
					handleBackgroundConfigChange("remove", target.checked);
				}}
				titleOnly={isSingleMode}
				isActive
				className={styles.config}
			>
				{isSingleMode && (
					<StoppableButton
						condition={imageToImageStore.isGeneratingImages && selectedConfig == t("remove")}
						onStopButtonClick={() => imageToImageStore.abortImageGeneration()}
						onButtonClick={async () => {
							try {
								setSelectedConfig(t("remove"));
								handleBackgroundConfigChange("remove", true);
								handleGenerateImageToImage();
							} catch (e) {
								console.log(e);
								Sentry.captureMessage(`Error while removing background: ${e}`);
							} finally {
								handleBackgroundConfigChange("remove", false);
							}
						}}
						disabled={countSelected == 0}
						buttonLabel={t("removeBackground")}
						stopButtonLabel={t("stop")}
						size="small"
						fullWidth
						className={styles.removeButton}
					/>
				)}
			</Config>

			{/* Expand / Original background */}
			{!isSingleMode && (
				<Config
					title={t("expand")}
					checked={imageToImageStore.config.background?.expand}
					onClick={(e) => {
						const target = e.target as HTMLInputElement;
						handleBackgroundConfigChange("expand", target.checked);
					}}
				/>
			)}

			{/* blur */}
			{!hiddenConfigs?.includes("blur") && (
				<Config
					title={t("blur")}
					checked={imageToImageStore.config.background?.blur}
					onClick={(e) => {
						const target = e.target as HTMLInputElement;
						handleBackgroundConfigChange("blur", target.checked);
					}}
					titleOnly={isSingleMode}
					isActive
					className={styles.config}
				>
					{isSingleMode && (
						<StoppableButton
							condition={imageToImageStore.isGeneratingImages && selectedConfig == t("blur")}
							onStopButtonClick={() => imageToImageStore.abortImageGeneration()}
							onButtonClick={async () => {
								try {
									setSelectedConfig(t("blur"));
									handleBackgroundConfigChange("blur", true);
									handleGenerateImageToImage();
								} catch (e) {
									console.log(e);
									Sentry.captureMessage(`Error while blurring background: ${e}`);
								} finally {
									handleBackgroundConfigChange("blur", false);
								}
							}}
							disabled={countSelected == 0}
							buttonLabel={t("blurBackground")}
							stopButtonLabel={t("stop")}
							size="small"
							fullWidth
							className={styles.blurButton}
						/>
					)}
				</Config>
			)}
		</Box>
	);
};

export default observer(BackgroundConfig);
