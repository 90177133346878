import { Box } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ScrollPosition, trackWindowScroll } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import CopyToClipboardButton from "../../../../../components/common/CopyToClipboardButton/CopyToClipboardButton";
import FullScreenViewer, {
	FullScreenViewerProps,
} from "../../../../../components/common/FullScreenViewer/FullScreenViewer";
import { FullScreenImageType } from "../../../../../components/common/FullScreenViewer/Image/FullScreenImage.tsx";
import BriaImage, { BriaImageProps } from "../../../../../components/common/Galleries/BriaImage";
import { useAppStore } from "../../../../../hooks/useStores";
import { PlaygroundImage } from "../../../../../models/image-to-image";
import styles from "./TextToImageGallery.module.scss";

type TextToImageGalleryProps = {
	images: (BriaImageProps & PlaygroundImage)[];
	maxInLine?: number;
	className?: string;
	fullScreenProps?: Partial<FullScreenViewerProps>;
	scrollPosition: ScrollPosition;
};

const TextToImageGallery = ({
	images,
	maxInLine = 4,
	className,
	fullScreenProps,
	scrollPosition,
}: TextToImageGalleryProps) => {
	const { t } = useTranslation("translation", { keyPrefix: "playground.textToImage" });
	const { textToImageStore, playgroundStore } = useAppStore();
	const [imageToView, setImageToView] = useState(0);
	const [isViewerOpen, setIsViewerOpen] = useState<boolean | undefined>(fullScreenProps?.open);
	const [displayMode, setDisplayMode] = useState(fullScreenProps?.displayMode);

	useEffect(() => {
		setImageToView(0);
	}, [images.length]);

	useEffect(() => {
		setIsViewerOpen(fullScreenProps?.open);
	}, [fullScreenProps?.open]);

	const handleOpenFullScreen = (index: number) => {
		setDisplayMode("arrows");
		setImageToView(index);
		setIsViewerOpen(true);
	};

	const handleCloseFullScreen = () => {
		setIsViewerOpen(false);
		fullScreenProps?.onClose?.();
		setDisplayMode(fullScreenProps?.displayMode);
	};

	return (
		<>
			<Box
				className={clsx(className, styles.ttiGallery)}
				sx={{
					"&  > span": { width: `calc( ${100 / maxInLine}% - 0.8vw)` },
				}}
			>
				{images.map((image, index) => (
					<span className={styles.imageWrapper}>
						<BriaImage
							key={index}
							{...image}
							handleFullScreen={() => handleOpenFullScreen(index)}
							lazyImageProps={{
								...image.lazyImageProps,
								scrollPosition: scrollPosition,
								onLoad: () => {
									playgroundStore.addLoadedImageToStore(image);
								},
							}}
							fullScreenProps={image.fullScreenProps}
							image={image}
							hideDownloadIcon={false}
						/>
						{textToImageStore.config.displaySeed &&
							!Number.isNaN(image.seed) &&
							image.seed &&
							!image.loading && (
								<Box className={styles.seedTextStyle}>
									<Box>{t("seed")}</Box>
									<Box className={styles.seedValue}>{image.seed}</Box>
									<CopyToClipboardButton
										className={styles.copyBtn}
										textToCopy={image.seed.toString()}
									/>
								</Box>
							)}
					</span>
				))}
			</Box>
			{isViewerOpen && (
				<FullScreenViewer
					{...fullScreenProps}
					open={isViewerOpen}
					images={images
						.filter((image) => !image.hideFullScreenButton && !image.errorType)
						.map((image) => ({ src: image.url, ...image.fullScreenProps }))}
					currentIndex={imageToView}
					onClose={handleCloseFullScreen}
					displayMode={displayMode}
					onDownload={(fullScreenImages: FullScreenImageType[]) =>
						playgroundStore.onFullScreenViewerDownload(images, fullScreenImages)
					}
				/>
			)}
		</>
	);
};

export default trackWindowScroll(observer(TextToImageGallery));
