import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import * as XLSX from "xlsx";
import { TableRow } from "../layout/TableLayout/TableLayout.tsx";
import {
	ICatalogReport,
	ICatalogReportTableRow,
	IGenerationReport,
	IGenerationReportTableRow,
	IPartnerEarnings,
	ITopImages,
} from "../models/attribution-reports.ts";
import { showErrorToast } from "../utils/toast.tsx";
import { useAppStore } from "./useStores.tsx";

export const useAttributionReports = () => {
	const { t } = useTranslation("translation", { keyPrefix: "account.attributionReports" });
	const { attributionReportsStore } = useAppStore();
	const selectedReportMapper = {
		"Generation Report": {
			report: attributionReportsStore.generationReportData,
			isLoading: attributionReportsStore.isGenerationReportDataLoading
		},
		"Catalog Report": {
			report: attributionReportsStore.catalogReportData,
			isLoading: attributionReportsStore.isCatalogReportDataLoading
		},
		"Top images": {
			report: attributionReportsStore.topImagesData,
			isLoading: attributionReportsStore.isTopImagesDataLoading
		},
		"Partners earnings": {
			report: attributionReportsStore.partnersEarningsData,
			isLoading: attributionReportsStore.isPartnersEarningsDataLoading
		},
	};

	const createGenerationReportTableRow = (data: IGenerationReport): TableRow<IGenerationReportTableRow> => {
		return {
			rowStyle: {},
			data: {
				organization: <Typography>{data.organization}</Typography>,
				organizationId: <Typography>{data.organizationId}</Typography>,
				numberOfGenerations: <Typography>{data.numberOfGenerations}</Typography>,
			},
		};
	};

	const createCatalogReportTableRow = (data: ICatalogReport): TableRow<ICatalogReportTableRow> => {
		return {
			rowStyle: {},
			data: {
				partner: <Typography>{data.partner}</Typography>,
				imageTotal: <Typography>{data.imageTotal}</Typography>,
				imageTotalPercentage: <Typography>{data.imageTotalPercentage}%</Typography>,
				imagesTrained: <Typography>{data.imagesTrained}</Typography>,
				imagesTrainedPercentage: <Typography>{data.imagesTrainedPercentage}%</Typography>,
				imagesAttributed: <Typography>{data.imagesAttributed}</Typography>,
				imagesAttributedPercentage: <Typography>{data.imagesAttributedPercentage}%</Typography>,
				uniqueImagesAttributed: <Typography>{data.uniqueImagesAttributed}</Typography>,
				uniqueImagesAttributedPercentage: <Typography>{data.uniqueImagesAttributedPercentage}%</Typography>,
			},
		};
	};

	const createPartnersEarningsDataTableRow = (data: IPartnerEarnings) => {
		return {
			rowStyle: {},
			data: {
				partner: <Typography>{data.partner}</Typography>,
				totalPay: <Typography>${data.totalPay}</Typography>,
				payShare: <Typography>{data.payShare}%</Typography>,
			},
		};
	};

	const getSelectedReport = (): any[] => {
		if (!attributionReportsStore.selectedTab) return [];
		return selectedReportMapper[attributionReportsStore.selectedTab as keyof typeof selectedReportMapper].report;
	}

	const isSelectedReportLoading = (): boolean => {
		if (!attributionReportsStore.selectedTab) false;
		return selectedReportMapper[attributionReportsStore.selectedTab as keyof typeof selectedReportMapper].isLoading;
	}

	const generateReportExcelFile = (): any => {
		const selectedReport: any[] = getSelectedReport();
		if (!selectedReport.length) {
			showErrorToast(t("currentReportIsEmpty"));
			return;
		}
		const workbook = XLSX.utils.book_new();
		if (attributionReportsStore.selectedTab === "Top images") {
			const formatData = (key: "topHits" | "topPaid") => {
				return attributionReportsStore.topImagesData.map((row: ITopImages) => {
					const formattedRow: Record<string, any> = { Partner: row.partner };
					const valueLabel = key === "topHits" ? "hits" : "paid";
					// Map topHits or topPaid to columns
					(row[key] || []).forEach((item, index) => {
						formattedRow[`top_${index + 1}_url`] = item.url;
						formattedRow[`top_${index + 1}_${valueLabel}`] = item.value;
					});

					return formattedRow;
				}).filter(row => Object.keys(row).length > 1);
			};
			const topHitsData = formatData("topHits");
			const topPaidData = formatData("topPaid");

			const topHitsSheet = XLSX.utils.json_to_sheet(topHitsData);
			topHitsSheet["!cols"] = [{ wch: 20 }, ...Array(20).fill({ wch: 15 })];
			XLSX.utils.book_append_sheet(workbook, topHitsSheet, "Top Hits");

			if (topPaidData.length) {
				const topPaidSheet = XLSX.utils.json_to_sheet(topPaidData);
				topPaidSheet["!cols"] = [{ wch: 20 }, ...Array(20).fill({ wch: 15 })];
				XLSX.utils.book_append_sheet(workbook, topPaidSheet, "Top Paid");
			}
		} else {
			const worksheet = XLSX.utils.json_to_sheet(selectedReport);
			worksheet["!cols"] = [...Array(Object.keys(selectedReport).length).fill({ wch: 15 })];
			XLSX.utils.book_append_sheet(workbook, worksheet, attributionReportsStore.selectedTab);
		}
		const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
		const excelBlob = new Blob([excelBuffer], {
			type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
		});
		return URL.createObjectURL(excelBlob);
	};

	return {
		createGenerationReportTableRow,
		createCatalogReportTableRow,
		createPartnersEarningsDataTableRow,
		generateReportExcelFile,
		getSelectedReport,
		isSelectedReportLoading
	};
};
