import { Close } from "@mui/icons-material";
import { Box, Dialog, IconButton, TextField, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BriaButton from "../../../../components/common/BriaButton/BriaButton.tsx";
import { useAppStore } from "../../../../hooks/useStores.tsx";
import InputLayout from "../../../../layout/InputLayout/InputLayout.tsx";
import styles from "./EditSessionDialog.module.scss";

const EditSessionDialog = () => {
	const { uiStore, playgroundStore } = useAppStore();
	const { projectsStore, sessionsStore } = playgroundStore;
	const { t } = useTranslation("translation", { keyPrefix: "automotiveApp.project" });
	const { t: tc } = useTranslation();

	const session = projectsStore.selectedSession;
	const [sessionName, setSessionName] = useState(session?.name || "");
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setSessionName(session?.name || "");
	}, [session]);

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		setSessionName(e.target.value);
	};

	const editProject = async () => {
		if (!session || !session.id) return;

		setLoading(true);
		try {
			await sessionsStore.updateSession(session.id, { ...session, name: sessionName });
			const sessions = projectsStore.project?.sessions?.map((s) =>
				s.id === session.id ? { ...s, name: sessionName } : s,
			);
			projectsStore.handleFormChange("sessions", sessions);
			uiStore.hideDialog("EditSessionDialog");
		} catch (error) {
			console.error("Error updating session:", error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Box className={styles.form}>
			<Dialog onClose={() => uiStore.hideDialog("EditSessionDialog")} open={uiStore.EditSessionDialog}>
				<Box className={styles.dialogContent}>
					<IconButton onClick={() => uiStore.hideDialog("EditSessionDialog")} className={styles.closeButton}>
						<Close />
					</IconButton>
					<Typography variant="h6" className={styles.title}>
						{t("renameSession")}
					</Typography>
					<InputLayout label={t("sessionName")}>
						<TextField
							value={sessionName}
							onChange={handleChange}
							placeholder={t("sessionName")}
							fullWidth
							InputProps={{ classes: { root: styles.textField } }}
						/>
					</InputLayout>
					<Box className={styles.dialogActions}>
						<BriaButton
							onClick={() => uiStore.hideDialog("EditSessionDialog")}
							className={styles.cancelBtn}
							buttonType="tertiaryMedium"
						>
							{tc("cancel")}
						</BriaButton>
						<BriaButton
							buttonType="primaryMedium"
							className={styles.button}
							onClick={editProject}
							loading={loading || sessionsStore.loadingUpdateSession}
						>
							{t("renameSession")}
						</BriaButton>
					</Box>
				</Box>
			</Dialog>
		</Box>
	);
};

export default observer(EditSessionDialog);
