import { Box, FormControlLabel, FormGroup, SelectChangeEvent } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import BriaCheckbox from "../../../../components/common/BriaCheckbox/BriaCheckbox.tsx";
import BriaDropDown from "../../../../components/common/BriaDropDown/BriaDropDown.tsx";
import InputLayout from "../../../../layout/InputLayout/InputLayout.tsx";
import { ImageEditingResetBehavior, ImageEditingViewMode } from "../../../../models/image-to-image.ts";
import { ImageEditingIframeConfig } from "../../../../models/new-iframe.ts";
import iframeStore from "../../iframe-store.tsx";
import styles from "../NewIframeEditor.module.scss";

const IframeImageEditingConfig = () => {
	const { iframeForm, handleFormChange } = iframeStore;
	const { t } = useTranslation("translation", { keyPrefix: "newIframes.editor.form.imageEditingConfig" });

	const handleDefaultViewModeChange = (e: SelectChangeEvent<ImageEditingViewMode>) => {
		handleFormChange("config", {
			...iframeForm.config,
			image_editing_config: {
				...(iframeForm.config.image_editing_config ?? {}),
				default_view_mode: e.target.value as ImageEditingViewMode,
			},
		});
	};

	const handleCheckboxChange = (key: keyof ImageEditingIframeConfig, checked: boolean) => {
		const existingConfig = iframeForm.config.image_editing_config;
		const updatedConfig: Partial<ImageEditingIframeConfig> = {
			...existingConfig,
			[key]: checked,
		};

		const isEnableBulkChecked = updatedConfig.enable_bulk ?? false;
		const newDefaultViewMode: ImageEditingViewMode = isEnableBulkChecked
			? existingConfig?.default_view_mode ?? ImageEditingViewMode.SINGLE
			: ImageEditingViewMode.SINGLE;

		handleFormChange("config", {
			...iframeForm.config,
			image_editing_config: {
				...updatedConfig,
				default_view_mode: newDefaultViewMode,
			} as ImageEditingIframeConfig,
		});
	};

	const handleDefaultResetBehaviorChange = (e: SelectChangeEvent<ImageEditingResetBehavior>) => {
		handleFormChange("config", {
			...iframeForm.config,
			image_editing_config: {
				...(iframeForm.config.image_editing_config ?? {}),
				default_reset_behavior: e.target.value as ImageEditingResetBehavior,
				default_view_mode:
					iframeForm.config.image_editing_config?.default_view_mode ?? ImageEditingViewMode.GALLERY,
			},
		});
	};

	return (
		<>
			<FormGroup>
				<Box className={styles.checkboxContainer}>
					<FormControlLabel
						control={
							<BriaCheckbox
								checked={iframeForm?.config.image_editing_config?.enable_bulk ?? false}
								onChange={(e) => handleCheckboxChange("enable_bulk", e.target.checked)}
							/>
						}
						label={t("checkboxes.enableBulkMode")}
					/>
				</Box>
			</FormGroup>
			{iframeForm?.config.image_editing_config?.enable_bulk && (
				<InputLayout label={t("defaultViewMode.label")}>
					<BriaDropDown
						value={iframeForm?.config.image_editing_config?.default_view_mode}
						onChange={handleDefaultViewModeChange}
						placeholder={t("defaultViewMode.placeholder")}
						items={Object.values(ImageEditingViewMode).map((mode) => ({
							key: t(mode),
							value: mode,
						}))}
					/>
				</InputLayout>
			)}

			<InputLayout
				label={t("defaultResetBehavior.label")}
				className={styles.defaultResetBehavior}
				info={t("defaultResetBehavior.toolTip")}
			>
				<BriaDropDown
					value={iframeForm?.config.image_editing_config?.default_reset_behavior}
					onChange={handleDefaultResetBehaviorChange}
					placeholder={t("defaultResetBehavior.placeholder")}
					items={Object.values(ImageEditingResetBehavior).map((mode) => ({
						key: t(mode),
						value: mode,
					}))}
				/>
			</InputLayout>
		</>
	);
};

export default observer(IframeImageEditingConfig);
