import { useEffect, useState } from "react";
import { APPS } from "../constants/AppsConstants.ts";
import { ConfigOptions, MAX_ALLOWED_SELECTED_IMAGES } from "../constants/ImageToImageConstants.ts";
import { isPlaygroundLoadingImages } from "../utils/index.ts";
import { useAppStore } from "./useStores";

export const useImageToImageConfig = () => {
	const { imageToImageStore, playgroundStore, authStore } = useAppStore();
	const countSelected = playgroundStore.getSelectedImages().length;
	const [allSelectedImagesAvailable, setAllSelectedImagesAvailable] = useState(true);

	const checkImagesAvailability = async () => {
		const selectedImages = playgroundStore.getSelectedImages();

		if (!selectedImages.length) {
			setAllSelectedImagesAvailable(true);
			return;
		}

		let allAvailable = true;
		for (const image of selectedImages) {
			try {
				const res = await fetch(image.url);
				if (res.status !== 200) {
					allAvailable = false;
					break;
				}
			} catch (error) {
				allAvailable = false;
				break;
			}
		}
		setAllSelectedImagesAvailable(allAvailable);
	};

	useEffect(() => {
		checkImagesAvailability();
	}, [
		playgroundStore
			.getSelectedImages()
			?.map((image) => image?.url)
			.join(","),
	]);

	const canStyleBackground = (): boolean => {
		return !!(
			imageToImageStore.config.style &&
			imageToImageStore.config.style.tailored_style &&
			imageToImageStore.config.style.sub_style
		);
	};

	const canRemoveBackground = (): boolean => {
		return !!imageToImageStore.config.background?.remove;
	};

	const canBlurBackground = (): boolean => {
		return !!imageToImageStore.config.background?.blur;
	};

	const canCrop = (): boolean => {
		const { size } = imageToImageStore.config;
		return !!size?.crop;
	};

	const canApplyAspectRatio = (): boolean => {
		const { size } = imageToImageStore.config;
		return !!(size?.aspect_ratio?.selected && size?.aspect_ratio?.value?.length);
	};

	const canApplyIncreaseResolution = (): boolean => {
		const { size } = imageToImageStore.config;
		return !!(size?.increase_resolution?.selected && size?.increase_resolution?.value?.length);
	};

	const canAppleCustomSize = (): boolean => {
		const { size } = imageToImageStore.config;
		return !!(size?.custom_size?.selected && size?.custom_size?.width && size?.custom_size?.height);
	};

	const canAppleMediumDestination = (): boolean => {
		const { size } = imageToImageStore.config;
		return !!(size?.medium_destination?.destinations?.length && size?.medium_destination?.selected);
	};

	const canResizeImage = (): boolean => {
		return !!(canCrop() || canApplyAspectRatio() || canAppleCustomSize() || canAppleMediumDestination() || canApplyIncreaseResolution());
	};

	const canExpandBackground = (): boolean => {
		return !!imageToImageStore.config.background?.expand;
	};

	const canApplySolidBackground = (): boolean => {
		const { solid } = imageToImageStore.config.background || {};
		return !!(solid?.selected && solid?.color_code);
	};

	const canGenerateBackgroundByText = (): boolean => {
		const { generateByText } = imageToImageStore.config.background || {};
		return !!(generateByText?.selected && generateByText?.prompt?.length);
	};

	const canGenerateBackgroundByImage = (): boolean => {
		const { generateByImage } = imageToImageStore.config.background || {};
		return !!(generateByImage?.selected && generateByImage?.ref_image_url?.length);
	};

	const canGenerateForegroundPlacement = (): boolean => {
		return !!(
			imageToImageStore.config.foreground_placement?.placements?.selected &&
			imageToImageStore.config.foreground_placement?.placements.values?.length
		);
	};

	const canGenerateBackground = () => {
		return (
			canBlurBackground() ||
			canExpandBackground() ||
			canRemoveBackground() ||
			canApplySolidBackground() ||
			canGenerateBackgroundByText() ||
			canGenerateBackgroundByImage()
		);
	};

	const isGenerateEnabled = (): number | boolean => {
		const hasStyleOrBackground =
			canStyleBackground() || canGenerateBackground() || canResizeImage() || canGenerateForegroundPlacement();

		const isUnderLimit = imageToImageStore.getGeneratedImagesCount() <= MAX_ALLOWED_SELECTED_IMAGES;
		const isNotLoading = !isPlaygroundLoadingImages(playgroundStore.playgroundResults, APPS.IMAGE_TO_IMAGE);

		return (
			hasStyleOrBackground &&
			countSelected &&
			isUnderLimit &&
			isNotLoading &&
			allSelectedImagesAvailable &&
			!playgroundStore.enablePlaygroundBrushMode &&
			!authStore.isLoadingOrgSubscriptions &&
			!authStore.orgPassedFreeLimit &&
			!authStore.isSubscriptionInactive
		);
	};

	const updateSelectedBackgroundConfigs = () => {
		const backgroundConfig = imageToImageStore.config.background;
		const newSelectedConfigs: string[] = [];

		if (canRemoveBackground()) {
			newSelectedConfigs.push(ConfigOptions.REMOVE);
		}

		if (canApplySolidBackground()) {
			newSelectedConfigs.push(ConfigOptions.SOLID);
		}

		if (canExpandBackground() || canGenerateBackgroundByText() || canGenerateBackgroundByImage()) {
			if (canGenerateBackgroundByText()) {
				const numResults = backgroundConfig?.generateByText?.num_results || 0;
				for (let i = 0; i < numResults; i++) {
					newSelectedConfigs.push(`${ConfigOptions.GENERATE_BY_TEXT}_${i}`);
				}
			}

			if (canGenerateBackgroundByImage()) {
				const numResults = backgroundConfig?.generateByImage?.num_results || 0;
				for (let i = 0; i < numResults; i++) {
					newSelectedConfigs.push(`${ConfigOptions.GENERATE_BY_IMAGE}_${i}`);
				}
			}

			if (canExpandBackground()) {
				newSelectedConfigs.push(ConfigOptions.EXPAND);
			}
		} else if (canBlurBackground()) {
			newSelectedConfigs.push(ConfigOptions.BLUR);
		}

		imageToImageStore.handleSelectedConfigsChange("background", newSelectedConfigs);
	};

	const updateSelectedSizeConfigs = () => {
		const sizeConfig = imageToImageStore.config.size;
		const newSelectedConfigs: string[] = [];

		if (canCrop()) {
			newSelectedConfigs.push(ConfigOptions.CROP);
		}
		if (canApplyAspectRatio()) {
			const aspectRatios = sizeConfig?.aspect_ratio?.value || [];
			aspectRatios.forEach((item) => newSelectedConfigs.push(item));
		}
		if (canAppleCustomSize()) {
			newSelectedConfigs.push(ConfigOptions.CUSTOM_SIZE);
		}
		if (canAppleMediumDestination()) {
			const destinations = sizeConfig?.medium_destination?.destinations || [];
			destinations.forEach((item) => newSelectedConfigs.push(item.name));
		}
		if(canApplyIncreaseResolution()) {
			const resolutions = sizeConfig?.increase_resolution?.value || [];
			resolutions.forEach((item) => newSelectedConfigs.push(item));
		}

		imageToImageStore.handleSelectedConfigsChange("size", newSelectedConfigs);
	};

	const updateSelectedForegroundPlacementConfigs = () => {
		const foregroundPlacementConfig = imageToImageStore.config.foreground_placement?.placements;
		const newSelectedConfigs: string[] = [];

		if (
			foregroundPlacementConfig?.selected &&
			foregroundPlacementConfig?.values &&
			foregroundPlacementConfig?.values.length > 0
		) {
			foregroundPlacementConfig.values.forEach((item) => newSelectedConfigs.push(item));
		}

		imageToImageStore.handleSelectedConfigsChange("foreground_placement", newSelectedConfigs);
	};

	return {
		countSelected,
		canStyleBackground,
		canRemoveBackground,
		canGenerateBackground,
		canResizeImage,
		canExpandBackground,
		canApplySolidBackground,
		canGenerateBackgroundByText,
		canGenerateBackgroundByImage,
		canBlurBackground,
		canApplyAspectRatio,
		canApplyIncreaseResolution,
		canAppleCustomSize,
		canAppleMediumDestination,
		canGenerateForegroundPlacement,
		isGenerateEnabled,
		updateSelectedBackgroundConfigs,
		updateSelectedSizeConfigs,
		updateSelectedForegroundPlacementConfigs,
	};
};
