import { makeAutoObservable, runInAction } from "mobx";
import { v4 as uuidv4 } from "uuid";
import { APPS } from "../../../../../constants/AppsConstants";
import { AutomotiveHarmonizationProps, ImageToImageConfigType } from "../../../../../models/image-to-image";
import { AutomotiveReflectionLayer, PlaygroundImage, PlaygroundResult } from "../../../../../models/playground";
import QueryService from "../../../../../utils/QueryService";
import { IAutomotiveStore } from "../../automotive-store";
export interface IAutomotiveHarmonizationStore {
	config: AutomotiveHarmonizationConfig;

	loadingSaveHarmonization: boolean;
	loadingApplyHarmonization: boolean;

	handleFormChange: <K extends keyof AutomotiveHarmonizationConfig>(
		key: K,
		value: AutomotiveHarmonizationConfig[K],
	) => void;
	saveHarmonization: () => Promise<AutomotiveHarmonizationProps | undefined>;
	applyHarmonization: (effect: string) => void;
}

export class AutomotiveHarmonizationStore implements IAutomotiveHarmonizationStore {
	private queryService: QueryService = new QueryService("/automotive/harmonization");
	automotiveStore: IAutomotiveStore;

	config: AutomotiveHarmonizationConfig = { preset: "" as AutomotivePreset };

	loadingSaveHarmonization: boolean = false;
	loadingApplyHarmonization: boolean = false;

	constructor(automotiveStore: IAutomotiveStore) {
		makeAutoObservable(this);
		this.automotiveStore = automotiveStore;
	}

	handleFormChange = <K extends keyof AutomotiveHarmonizationConfig>(
		key: K,
		value: AutomotiveHarmonizationConfig[K],
	) => {
		this.config = { ...this.config, [key]: value };
	};

	saveHarmonization = async () => {
		this.loadingSaveHarmonization = true;

		const playgroundStore = this.automotiveStore.playgroundStore;
		const originalImage = this.automotiveStore.playgroundStore.selectedSingleImage;
		const savedIndex = playgroundStore.playgroundResults.length;

		try {
			const resultsSkeletons: PlaygroundResult = {
				id: uuidv4(),
				config: playgroundStore.selectedSingleImage?.config,
				type: APPS.AUTOMOTIVE,
				images: [
					{
						id: "",
						url: "",
						loading: true,
						selected: true,
						playground_result_id: "",
					},
				],
			};
			playgroundStore.playgroundResults = [...playgroundStore.playgroundResults, resultsSkeletons];

			const response: AutomotiveHarmonizationProps = await this.queryService.post(`/`, {
				...this.config,
				image_url: originalImage?.url,
				layers: true,
			});

			runInAction(() => {
				this.loadingSaveHarmonization = false;

				const { playgroundResults, projectsStore } = playgroundStore;
				const savedPlaygroundResult = playgroundResults[savedIndex];

				const newImage: PlaygroundImage = {
					...originalImage,
					...(originalImage?.file ? { file: undefined } : {}),
					id: uuidv4(),
					url: response.url,
					loading: true,
					type: APPS.AUTOMOTIVE,
					config: {
						...savedPlaygroundResult.config,
						original_image: { ...originalImage },
					} as ImageToImageConfigType,
				};

				savedPlaygroundResult.images = [newImage];
				playgroundStore.handleSelectSingleImage(newImage);
				projectsStore.createPlaygroundResult(playgroundStore.playgroundResults[savedIndex]);
			});

			return response;
		} catch (err: any) {
			runInAction(() => {
				playgroundStore.playgroundResults = playgroundStore.playgroundResults.filter(
					(_, index) => index !== savedIndex,
				);
				return Promise.reject(err);
			});
		} finally {
			runInAction(() => {
				this.loadingSaveHarmonization = false;
			});
		}
	};

	applyHarmonization = async (effect: string) => {
		const playgroundStore = this.automotiveStore.playgroundStore;
		const originalImage = this.automotiveStore.playgroundStore.singleSelectedImage;

		try {
			runInAction(() => {
				this.loadingApplyHarmonization = true;
				playgroundStore.imagesHarmonizations = [];
				playgroundStore.selectedReflectionLayer = null;
			});

			const response: AutomotiveHarmonizationProps = await this.queryService.post(`/`, {
				preset: effect,
				image_url: this.automotiveStore.playgroundStore.singleSelectedImage?.url,
				layers: true,
			});

			const layer: AutomotiveReflectionLayer = {
				name: "harmonization",
				url: response.url,
				intensity: 0.5,
				hidden: false,
			};

			runInAction(() => {
				playgroundStore.imagesHarmonizations.push({
					url: originalImage?.url ?? "",
					layers: [layer],
				});
				playgroundStore.selectedReflectionLayer = layer;
			});
		} catch (err: any) {
			console.log("error applying effect ", err);
		} finally {
			this.loadingApplyHarmonization = false;
		}
	};
}

export type AutomotivePreset = "warm day" | "cold day" | "warm night" | "cold night" | "";

export type AutomotiveHarmonizationConfig = {
	image_url?: string;
	image_file?: File;
	preset: AutomotivePreset;
};
