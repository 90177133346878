import VisibilityIcon from "@mui/icons-material/Visibility";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import BriaButton from "../../../components/common/BriaButton/BriaButton.tsx";
import BriaIconButton from "../../../components/common/BriaIconButton/BriaIconButton.tsx";
import CopyToClipboardButton from "../../../components/common/CopyToClipboardButton/CopyToClipboardButton.tsx";
import { USER_ORGANIZATION_ROLES } from "../../../constants/UserConstants.ts";
import { IOrgStatusKey, ORG_BLOCK_REASONS, ORG_STATUSES } from "../../../constants/billing.ts";
import { setSelectedOrganization } from "../../../helpers/localStorage.ts";
import { RelatedOrganization, UserOrganization } from "../../../models/organization.ts";
import { validateEmail } from "../../../utils";
import { showErrorToast } from "../../../utils/toast.tsx";
import styles from "./RelatedOrganizations.module.scss";
import BriaTooltip from "../../../components/common/BriaTooltip/BriaTooltip.tsx";

const isEditorRole = (role: string) => {
	return (
		role === USER_ORGANIZATION_ROLES.OWNER ||
		role === USER_ORGANIZATION_ROLES.ADMIN ||
		role === USER_ORGANIZATION_ROLES.ADMIN_HIDDEN
	);
};

const handleBlockOrganization = async (
	isParentTable: boolean,
	authStore: any,
	orgID: string,
	setFilteredSubOrganization: any,
) => {
	if (isParentTable) {
		authStore.isParentOrganizationLoading = true;
		await authStore.blockOrganization(orgID);
		await authStore.loadParentOrganization();
	} else {
		authStore.isSubOrganizationsLoading = true;
		await authStore.blockOrganization(orgID);
		await authStore.loadSubOrganizations();
		setFilteredSubOrganization(authStore.subOrganizations);
	}
};

const handleUnBlockOrganization = async (
	isParentTable: boolean,
	authStore: any,
	orgID: string,
	setFilteredSubOrganization: any,
) => {
	if (isParentTable) {
		authStore.isParentOrganizationLoading = true;
		await authStore.unBlockOrganization(orgID);
		await authStore.loadParentOrganization();
	} else {
		authStore.isSubOrganizationsLoading = true;
		await authStore.unBlockOrganization(orgID);
		await authStore.loadSubOrganizations();
		setFilteredSubOrganization(authStore.subOrganizations);
	}
};

const handleSwitchToOrganization = (org: RelatedOrganization) => {
	const userOrg = {
		organization: {
			uid: org.uid,
			name: org.name,
			owner_uid: org.ownerUID
		},
		role: org.role
	} as UserOrganization
	setSelectedOrganization(userOrg);
	window.location.reload();
};

export const createTableData = (
	t: (key: string) => string,
	authStore: any,
	setFilteredSubOrganization: (value: RelatedOrganization[]) => void,
	isParentTable: boolean,
	relatedOrg: RelatedOrganization,
	canUserEditSelectedOrganization: boolean,
) => {
	if (relatedOrg.isBlocked) {
		relatedOrg.status = ORG_STATUSES[relatedOrg.blockReason as IOrgStatusKey];
	}
	const StatusLabel = t(`table.status.${relatedOrg.status}`) || relatedOrg.status.replace("_", " ");
	const isBlockButtonDisabled = (isParentTable && !isEditorRole(relatedOrg.role)) || !canUserEditSelectedOrganization;
	const isUnBlockButtonDisabled =
		relatedOrg.blockReason != ORG_BLOCK_REASONS.BLOCKED_BY_ORG_ADMIN ||
		(isParentTable && !isEditorRole(relatedOrg.role)) ||
		!canUserEditSelectedOrganization;
	
	return {
		rowStyle: {},
		data: {
			creationDate: (
				<Box>
					<Typography>{relatedOrg.creationDate}</Typography>
				</Box>
			),
			name: (
				<Box>
					<Typography>{relatedOrg.name}</Typography>
				</Box>
			),
			ownerEmail: (
				<Box className={styles.emailColumn}>
					<Typography>{relatedOrg.ownerEmail}</Typography>
					<CopyToClipboardButton className={styles.copyIcon} textToCopy={relatedOrg.ownerEmail} />
				</Box>
			),
			subscription: (
				<Box>
					<Typography>{relatedOrg.subscription}</Typography>
				</Box>
			),
			status: (
				<Box>
					<Typography>{StatusLabel}</Typography>
				</Box>
			),
			actions: (
				<Box className={styles.actionColumn}>
					{!relatedOrg.isBlocked ? (
						<BriaTooltip title={t("tooltips.onlyAdminCanBlock")} disable={!isBlockButtonDisabled}>
							<BriaButton
								buttonType="textSmall"
								className={styles.blockAPIButton}
								onClick={() => {
									handleBlockOrganization(
										isParentTable,
										authStore,
										relatedOrg.uid,
										setFilteredSubOrganization,
									);
								}}
								disabled={isBlockButtonDisabled}
							>
								{t("table.blockAPI")}
							</BriaButton>
						</BriaTooltip>
					) : (
						<BriaTooltip title={t("tooltips.onlyAdminCanRevive")} disable={!isUnBlockButtonDisabled}>
							<BriaButton
								buttonType="textSmall"
								className={styles.unblockAPIButton}
								onClick={() => {
									handleUnBlockOrganization(
										isParentTable,
										authStore,
										relatedOrg.uid,
										setFilteredSubOrganization,
									);
								}}
								disabled={isUnBlockButtonDisabled}
							>
								{t("table.unBlockAPI")}
							</BriaButton>
						</BriaTooltip>
					)}
					<BriaTooltip title={t("tooltips.onlyMemberCanLogin")} disable={Boolean(relatedOrg.role)}>
						<BriaIconButton
							className={styles.switchORGButton}
							onClick={() => handleSwitchToOrganization(relatedOrg)}
							disabled={!relatedOrg.role}
						>
							<VisibilityIcon style={{ fill: "white", fontSize: "30px" }} />
						</BriaIconButton>
					</BriaTooltip>
				</Box>
			),
		},
	};
};

export const sendInvitations = async (
	t: (key: string) => string,
	appStore: any,
	orgID: string,
	setInviteUsers: (value: string) => void,
	inviteUsers: string,
) => {
	const emails = inviteUsers.replace(/\s/g, "").toLowerCase().split(",");
	const areAllEmailsValid = emails.every((email: string) => validateEmail(email));
	if (areAllEmailsValid) {
		const distinctEmails = [...new Set(emails)];
		if (distinctEmails.length) {
			await appStore.sendUserInvitations(distinctEmails, orgID);
			setInviteUsers("");
		} else {
			showErrorToast(t("errors.invalidEmails"));
		}
	} else {
		showErrorToast(t("errors.invalidEmails"));
	}
};
