import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AutomotiveBG from "../../../../assets/images/pngs/Automotive/new-automotive-project-bg.png";
import BriaButton from "../../../../components/common/BriaButton/BriaButton.tsx";
import BriaInput from "../../../../components/common/BriaInput/BriaInput.tsx";
import { APPS } from "../../../../constants/AppsConstants.ts";
import RouterConstants from "../../../../constants/RouterConstants.ts";
import useSecureNavigate from "../../../../hooks/useSecureNavigate.tsx";
import { useAppStore } from "../../../../hooks/useStores.tsx";
import InputLayout from "../../../../layout/InputLayout/InputLayout.tsx";
import { defaultProject } from "../store/playground-projects-store.tsx";
import styles from "./CreateAutomotiveProject.module.scss";

const CreateAutomotiveProject = () => {
	const { t } = useTranslation("translation", { keyPrefix: "automotiveApp" });
	const { uiStore, playgroundStore } = useAppStore();
	const { projectsStore } = playgroundStore;
	const navigate = useSecureNavigate();
	const [loadingSave, setLoadingSave] = useState(false);

	useEffect(() => {
		uiStore.showSideBar();
		projectsStore.project = { ...defaultProject, from_app: APPS.AUTOMOTIVE };
	}, []);

	const createProject = async () => {
		try {
			setLoadingSave(true);
			await projectsStore.createProject(projectsStore.project);
			navigate(`${RouterConstants.AUTOMOTIVE_PROJECTS.fullPath}`);
		} finally {
			setLoadingSave(false);
		}
	};

	return (
		<Box className={clsx(styles.container, styles.newProjectContainer)}>
			<Box className={styles.contentWrapper}>
				<Box className={styles.content}>
					<Typography className={styles.title} dangerouslySetInnerHTML={{ __html: t("newProject.title") }} />
					<Typography
						className={styles.subTitle}
						dangerouslySetInnerHTML={{ __html: t("newProject.subTitle") }}
					/>
					<Box className={styles.formWrapper}>
						<Box className={styles.inputsWrapper}>
							<InputLayout
								label={t("newProject.projectName")}
								className={styles.inputField}
								labelClassName={styles.inputLabel}
							>
								<BriaInput
									placeholder={t("newProject.projectName")}
									value={projectsStore.project.name}
									onChange={(e) => projectsStore.handleFormChange("name", e.target.value)}
									height="40px"
								/>
							</InputLayout>
							<InputLayout
								label={t("newProject.vehicleModel")}
								className={styles.inputField}
								optional
								labelClassName={styles.inputLabel}
								optionalLabel={t("newProject.optional")}
							>
								<BriaInput
									placeholder={t("newProject.vehicleModel")}
									value={projectsStore.project.extra_info?.vehicleModel}
									onChange={(e) =>
										projectsStore.handleFormChange("extra_info", {
											...projectsStore.project.extra_info,
											vehicleModel: e.target.value,
										})
									}
									height="40px"
								/>
							</InputLayout>
						</Box>
						<InputLayout
							label={t("newProject.projectDescription")}
							className={styles.inputField}
							optional
							labelClassName={styles.inputLabel}
							optionalLabel={t("newProject.optional")}
						>
							<BriaInput
								placeholder={t("newProject.projectDescription")}
								value={projectsStore.project.description}
								onChange={(e) => projectsStore.handleFormChange("description", e.target.value)}
								minRows={2}
								multiline
							/>
						</InputLayout>

						<Box className={styles.actions}>
							<BriaButton
								buttonType="primaryMedium"
								onClick={createProject}
								loading={loadingSave}
								disabled={!projectsStore.project.name || loadingSave}
							>
								{t("startNewProject")}
							</BriaButton>
						</Box>
					</Box>
				</Box>
			</Box>

			<Box className={styles.bgImages}>
				<img src={AutomotiveBG} />
			</Box>
		</Box>
	);
};

export default observer(CreateAutomotiveProject);
