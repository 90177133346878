import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CreateSubOrganizationPopup from "../../../components/Popups/CreateSubOrganizationPopup/CreateSubOrganizationPopup.tsx";
import { useAppStore } from "../../../hooks/useStores.tsx";
import { useUserChecks } from "../../../hooks/useUserChecks.tsx";
import TableLayout, { TableRow } from "../../../layout/TableLayout/TableLayout.tsx";
import { RelatedOrganization } from "../../../models/organization.ts";
import { createTableData } from "./RelatedOrganizationUtils.tsx";
import BriaTooltip from "../../../components/common/BriaTooltip/BriaTooltip.tsx";
import BriaButton from "../../../components/common/BriaButton/BriaButton.tsx";

export interface ITableRow {
	creationDate: any;
	name: any;
	ownerEmail: any;
	subscription: any;
	status: any;
	actions: any;
}

const RelatedOrganizations = () => {
	const { t } = useTranslation("translation", { keyPrefix: "account.relatedOrganizations" });
	const { authStore } = useAppStore();
	const { canUserEditOrganization } = useUserChecks();
	const [filteredSubOrganization, setFilteredSubOrganization] = useState<RelatedOrganization[]>([]);
	const [parentTableData, setParentTableData] = useState<TableRow<ITableRow>[]>([]);
	const [subTableData, setSubTableData] = useState<TableRow<ITableRow>[]>([]);
	const [showCreateSubOrganizationPopup, setShowCreateSubOrganizationPopup] = useState(false);
	const headerArray = t("table.headers", { returnObjects: true }) as string[];

	useEffect(() => {
		const loadData = async () => {
			if (!authStore.isError && !authStore.parentOrganization && !authStore.subOrganizations.length) {
				await Promise.all([authStore.loadParentOrganization(), authStore.loadSubOrganizations()]);
			}
			setFilteredSubOrganization(authStore.subOrganizations);
		};
		loadData();
	}, []);
	useEffect(() => {
		if (authStore.parentOrganization) {
			setParentTableData([
				createTableData(
					t,
					authStore,
					setFilteredSubOrganization,
					true,
					authStore.parentOrganization,
					canUserEditOrganization(),
				),
			]);
		}
	}, [authStore.parentOrganization]);
	useEffect(() => {
		setSubTableData(
			filteredSubOrganization.map((org) => {
				return createTableData(t, authStore, setFilteredSubOrganization, false, org, canUserEditOrganization());
			}),
		);
	}, [filteredSubOrganization]);

	const handleSearchChange = (value: string) => {
		if (!value) {
			setFilteredSubOrganization(authStore.subOrganizations);
		} else {
			setFilteredSubOrganization(
				filteredSubOrganization.filter(
					(org) =>
						(org.name && org.name.toLowerCase().includes(value.toLowerCase())) ||
						org.ownerEmail.toLowerCase().includes(value.toLowerCase()),
				),
			);
		}
	};

	const handleCreateSubOrganizationSubmit = async () => {
		setShowCreateSubOrganizationPopup(false);
		await authStore.loadSubOrganizations();
		setFilteredSubOrganization(authStore.subOrganizations);
	};
	const isTablePrimaryButtonDisabled = !canUserEditOrganization()
	const tablePrimaryButton = (
		<BriaTooltip title={t("tooltips.onlyAdminCanCreate")} disable={!isTablePrimaryButtonDisabled}>
			<BriaButton
				onClick={() => setShowCreateSubOrganizationPopup(true)}
				loading={authStore.isSubOrganizationsLoading}
				buttonType="primaryMedium"
				disabled={isTablePrimaryButtonDisabled || authStore.isSubOrganizationsLoading}
			>
				{t("table.sub.primaryButton")}
			</BriaButton>
		</BriaTooltip>
	);
	return (
		<>
			{authStore.parentOrganization && (
				<Box>
					<TableLayout
						title={t("table.parent.title")}
						hideSecondaryButton={true}
						headerArray={headerArray}
						tableData={parentTableData}
						loading={authStore.isParentOrganizationLoading}
					/>
				</Box>
			)}
			<Box>
				<TableLayout
					title={t("table.sub.title")}
					hideSecondaryButton={true}
					headerArray={headerArray}
					tableData={subTableData}
					primaryButtonText={tablePrimaryButton}
					loading={authStore.isSubOrganizationsLoading}
					enableSearch={true}
					handleSearchChange={(e) => handleSearchChange(e.target.value)}
					emptyStateComponent={<Typography>{t("table.sub.emptyState")}</Typography>}
				/>
			</Box>
			<CreateSubOrganizationPopup
				open={showCreateSubOrganizationPopup}
				onSubmit={handleCreateSubOrganizationSubmit}
				onClose={() => setShowCreateSubOrganizationPopup(false)}
			/>
		</>
	);
};
export default observer(RelatedOrganizations);
